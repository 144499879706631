import React from "react";

// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";
import {useHistory} from "react-router-dom";
import Header from "./Header";

const UserHeaderCandidat = () => {

    const username = JSON.parse(localStorage.getItem("user")).name;
    const history = useHistory();

    return (
        <>
        <Header/>
            <div 
                className="header pb-8 pt-5 pt-lg-6 d-flex align-items-center"
                style={{
                    minHeight: "300px",
                    backgroundImage:
                        "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top"
                }}
            >
                {/* Mask */}
                {/* <span className="mask bg-gradient-default opacity-8"/> */}
                {/* Header container */}
                <Container className="d-flex align-items-center" fluid>
                    <Row  style={{marginLeft: "40px"}}>
                        <Col lg="7" md="50">
                            <h1 className="2 text-"> {username}</h1>
                            
                            <Button
                                style={{
                                    backgroundColor: "#404954",
                                    color: "white",
                                  }}
                                onClick={() => history.push('/candidat/edit-profile')}
                            >
                                Modifier le profil
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default UserHeaderCandidat;
