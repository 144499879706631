import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    Row,
    CardHeader,
    Button,
    Alert,
    Label,
    FormText,
} from "reactstrap";
import ProfilePartie1 from "../MyProfile/ProfilePartie1";
import CardComp from "../MyProfile/CardComp";
import Select from "react-select";
//import './CSS/Profile.css'
import { options_family_Situation } from "options";
import { Link } from 'react-router-dom'
import { options_Pays } from "options";
import { EditUserParameters } from "network/ApiAxios";
import { EditProfileFrCandidate } from "network/ApiAxios";
import CandidateCompetencies from "../CandidateCompetencies";
import FormationSectionSP from "./FormationSectionSP";
import WorkExperienceSP from "./WorkExperienceSP";
import FormationSection from "../MyProfile/FormationSection";
import SelfSummarySectionSP from "./SelfSummarySectionSP";


const FormCompSP = ({ candidateUser, profileC, competencies, getUser, getProfileCandidate, candidateProgressProfile, getCandidateCompetencies, jobApplication, t }) => {

    let user = JSON.parse(localStorage.getItem("user"));

    return (
        <div className="container">
             {/*   <>
                    <ProfilePartie1 getUser={getUser} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} />
                </>
    <br />*/}
            <SelfSummarySectionSP candidateUser={candidateUser} />

            <CandidateCompetencies competencies={competencies} user={user} candidateUser={candidateUser} getCandidateCompetencies={getCandidateCompetencies} t={t} />
            <br />
            <WorkExperienceSP candidateUser={candidateUser} t={t} />
            <br />
            {candidateUser?.ParsedDataCV?.education?.entries.length ? <FormationSectionSP
                profileC={profileC}
                candidateUser={candidateUser}
                getProfileCandidate={getProfileCandidate}
                t={t}
            />
                : <FormationSection
                    profileC={profileC}
                    candidateUser={candidateUser}
                    getProfileCandidate={getProfileCandidate}
                    t={t}
                />}
        </div>
    );
};

export default FormCompSP;
