import React, { useEffect, useState } from "react";
import PageTitleStyle from "components/styles/PageTitleStyle";
import PageSpace from "components/styles/pageSpace";
import PlaybookOneCard from "components/employer/playbookOneCard";
import { getPlaybooksForOneCompany } from "network/ApiAxios";

import {
  Container,
  Row,
  Col,
} from "reactstrap";

import EmployerLayout from "layouts/EmployerLayout";


  function YourLearningPathsView() {
    var user;
    if (localStorage.getItem("user")) {
      user = JSON.parse(localStorage.getItem("user"));
    } else {
    user = { role: "none" };
    };
    const [playbooks, setPlaybooks] = useState([]);
    

    const getPlaybooksForOneCompanyFunction = async () => {
      if (user.employerCampanyID)
      {
        const response = await getPlaybooksForOneCompany(user.employerCampanyID);
        // console.log("ttttttttttttttttttttt: yourLearningPathsView: user: ", user);
        // console.log("ttttttttttttttttttttt: yourLearningPathsView: employerCampanyID: ", user.employerCampanyID);
        const { data } = response;
        if (data.success) {
      
          setPlaybooks(data.PlaybooksItems);
          // console.log("ttttttttttttttttttttt: yourLearningPathsView: ");
          // console.log("ttttttttttttttttttttt: yourLearningPathsView: data.PlaybooksItems: ", data.PlaybooksItems);
        } else {
        }
      }


  

  };


  useEffect(() => {
    getPlaybooksForOneCompanyFunction();
  }, []);
  
  
    return (
      <>
        <EmployerLayout />
        <PageSpace />
        <PageTitleStyle
          title="Vos parcours de formation"
        >
        </PageTitleStyle>
        <PageSpace />
        <Container>
          <Row>
            <Col lg="3">
            </Col>
            <Col lg="8">
            {playbooks && (
              <>
                {playbooks
                  .map((playbookItem) =>
                  <>
                    {/* console.log("ttttttttttttttttttttt: yourLearningPathsView: return: playbookItem: ", playbookItem) */}
                    <PlaybookOneCard 
                      key={playbookItem._id}
                      playbookID = {playbookItem._id}
                    />
                    <PageSpace />
                </>
                  )
                }

              </>

            )
            }

            </Col>
            <Col lg="1">
            </Col>
          </Row>
        </Container>
      </>
    );
  }


export default YourLearningPathsView;
