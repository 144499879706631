import OpenedCandidatedProfilesForRecruiters from 'components/employer/OpenedCandidatedProfilesForRecruiters'
import EmployerLayout from 'layouts/EmployerLayout'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const OpenCandidatesProfiles = () => {
  return (
    <>
    <EmployerLayout />
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
          <OpenedCandidatedProfilesForRecruiters/>
          </Col>
        </Row>
      </Container> 
    </>
  )
}

export default OpenCandidatesProfiles