import React, { useState } from 'react'
import Select from 'react-select'


const SelectOptionQuestion = ({placeholder,options,onChange,value}) => {

    return (
        <>
            <Select
                className='w-75'
                placeholder={placeholder}
                options={options}
                onChange={onChange}
                value={value}
            />
        </>
    )
}

export default SelectOptionQuestion