import GetOneInvitationAccessInterview from "components/employer/getOneInvitationAccessInterview";
import AlertMessage from "components/styles/AlertMessage";
import {
  saveInvitationRequest,
  getAllInvitationsByCompany,
} from "network/ApiAxios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

const QualifyComp = () => {
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }

  const [msg, setMsg] = useState(false);
  const [invitation, setInvitation] = useState({
    candidateUserEmail: "",
    recruiterUserID: user?._id,
    recruiterEmail: user?.email,
    recruiterCompanyID: user?.employerCampanyID,
    companyName: user?.company,
  });

  const [allInvitationsByCompany, setAllInvitationByCompany] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const getAllInvitations_ByCompanies = async () => {
    const { data } = await getAllInvitationsByCompany(user?.employerCampanyID);
    if (data.success) {
      setAllInvitationByCompany(data.invitations);
    }
  };

  const SendInvitationForQualifiedCandidateandSave = async () => {
    if (invitation.candidateUserEmail) {
      const { data } = await saveInvitationRequest(invitation);
      if (data.success) {
        setMsg(data.msg);
        setIsUpdated(!isUpdated);
      }
    }
  };

  useEffect(() => {
    getAllInvitations_ByCompanies();
  }, []);

  useEffect(() => {
    getAllInvitations_ByCompanies();
  }, [isUpdated]);

  return (
    <>
      <Container>
        <Row className="flex justify-content-center m-1">
          <h2 className="text-center">
            Demander au candidat de passer un entretien de qualification
          </h2>
        </Row>

        <Row>
          <Col lg="8">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Email du candidat:
              </label>
              <Input
                className="form-control-alternative"
                value={invitation.candidateUserEmail}
                id="input-Email"
                placeholder="Email"
                onChange={(e) =>
                  setInvitation({
                    ...invitation,
                    candidateUserEmail: e.target.value,
                  })
                }
                type="text"
              />
            </FormGroup>
          </Col>
          <Col lg="2">
            <label
              className="form-control-label"
              htmlFor="input-button-send"
            ></label>
            <Button
              className="btn-secondary"
              onClick={SendInvitationForQualifiedCandidateandSave}
            >
              Envoyer l'invitation par Email
            </Button>
          </Col>
        </Row>
        {msg && (
          <Row>
            <Col lg="6">
              <Alert
                color="success"
                //className="form-control-label"
                htmlFor="input-button-send"
              >
                {msg}
              </Alert>
            </Col>
          </Row>
        )}
        <hr />
        <Row className="flex justify-content-center m-1">
          <h2>
            Liste des demandes de qualification déjà envoyée
          </h2>
        </Row>
        <Row>
          <Col lg="6" style={{ margin: '0 auto' }}>

            {allInvitationsByCompany.length == 0 && <AlertMessage message={"Aucune demande d'accès n'a été faite"} />}
            {allInvitationsByCompany && allInvitationsByCompany.length !== 0 && allInvitationsByCompany.map((invitation, i) => (
              <GetOneInvitationAccessInterview invitation={invitation} />
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default QualifyComp;
