import { ArchiveIcon, LocationMarkerIcon, OfficeBuildingIcon, PaperClipIcon,InboxIcon,ClockIcon } from '@heroicons/react/solid'
import { UpdateInVitationAccess } from 'network/ApiAxios'

import { getAllInvitationsByCandidateInFr } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const InFrCandidateInvitationsAccessRequests = ({user}) => {
  const [myInvitationsAccess, setMyInvitationsAccess] = useState([])

  const EditInterviewAccessStatus = async(id,status)=>{
    try {
      const {data} = await UpdateInVitationAccess({_id: id, candidateAcceptTheRequestion: status})
      let MynewInvitations
      if(data.success){
        MynewInvitations = myInvitationsAccess.map(invitation=> (invitation._id === id)? {...invitation,candidateAcceptTheRequestion: status} : invitation)
        setMyInvitationsAccess(MynewInvitations)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getMyInvitationsAccessRequestsForCandidateInFr = async()=>{
    const {data} = await getAllInvitationsByCandidateInFr(user?.email)
    if(data.success){
      setMyInvitationsAccess(data.Items)
    }
  }

  useEffect(()=>{
    getMyInvitationsAccessRequestsForCandidateInFr()
  },[])

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return (
    <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <Card /*className="bg-secondary shadow"*/ >
                <CardBody>
                    {myInvitationsAccess.length == 0 && <h4 className="text-center">Vous n'avez pas de demandes d'accès</h4>}
                    {myInvitationsAccess && myInvitationsAccess.length !== 0 && myInvitationsAccess.map((invitation,i)=>(
                      <Row key={i} className="mb-2 p-2 flex  hover:black hover:border hover:border-black duration-75 hover:z-20 bg-white shadow-lg rounded-sm">
                           <Col lg="8">
                            <div className="flex items-center space-x-3 mb-2">
                              <div>
                                <h3 className="card-t">{invitation?.recruiterUserID?.company}</h3>
                                <span
                                  className="text-sm"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp:
                                      "2" /* number of lines to show */,
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {invitation?.recruiterUserID?.description}
                                </span>
                              </div>
                            </div>
                            <Row>
                              <Col lg="6">
                                <div style={{ display: "inline-flex" }}>
                                  {" "}
                                  <ArchiveIcon
                                    style={{ height: "1.2rem" }}
                                  />{" "}
                                  &nbsp; <span> {invitation?.recruiterUserID?.activity}</span>{" "}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div style={{ display: "inline-flex" }}>
                                  {" "}
                                  <LocationMarkerIcon
                                    style={{ height: "1.2rem" }}
                                  />{" "}
                                  &nbsp; <span> {invitation?.recruiterUserID?.location}</span>{" "}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div style={{ display: "inline-flex" }}>
                                  {" "}
                                  <InboxIcon
                                    style={{ height: "1.2rem" }}
                                  />{" "}
                                  &nbsp; <span> {invitation?.recruiterUserID?.email}</span>{" "}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div style={{ display: "inline-flex" }}>
                                  {" "}
                                  <ClockIcon
                                    style={{ height: "1.2rem" }}
                                  />{" "}
                                  &nbsp; <span> {new Date(invitation.createdAt).toLocaleDateString('en-GB', options)}</span>{" "}
                                </div>
                              </Col>
                              </Row>
                              </Col>
                              <Col lg='4' className='d-flex justify-content-center align-items-center '>
                              <Button disabled={invitation?.candidateAcceptTheRequestion} onClick={()=> EditInterviewAccessStatus(invitation._id,true)} outline color={!invitation?.candidateAcceptTheRequestion ? 'success' : '#D3D3D3'}>Accepter</Button>{' '}
                              <Button disabled={invitation.candidateAcceptTheRequestion === false} onClick={()=> EditInterviewAccessStatus(invitation._id,false)} outline color={(invitation?.candidateAcceptTheRequestion ===false) ? '#D3D3D3' : 'danger' }>Refuser</Button>

                              </Col>
              
                      </Row>
                    ))}
                </CardBody>
                </Card>



          </Col>
    </Row> 
  )
}

export default InFrCandidateInvitationsAccessRequests