import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import TerminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/terminateYourProfileBefore";
import OutFrFreelanceterminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/outFrFreelanceterminateYourProfileBefore";



import { Container, Box, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import { Row } from "reactstrap";

import { H2, H3, H5, Span } from "components/Typography";
import { useHistory, useLocation } from "react-router-dom";
import CandidatMyProfileGenPrezVideo from "components/candidat/CandidatMyProfileGenPrezVideo";
import TableRow from "components/TableRow";
import FlexBox from "components/FlexBox";
import OneJob from "components/recruteur/oneJob";
import OffresOFCF from "components/outFrCandidatSearchCdiFr/offresOFCF";

import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});



function OutFrFreelanceCandidateGeneralInterviews() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
   const history = useHistory();
   const location = useLocation();

   const [alreadyPosted, setAlreadyPosted] = useState(false);
   const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);

   const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  const fetchCandidateCompetencies = () => {
    instance({
      method: "get",
      url: `/candidatesCompetencies/getCompetenciesByUserID/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setCompetenciesAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

    useEffect(() => {
  
      fetchProfile();
      fetchCandidateCompetencies();
    
    }, [location]);

  
  return (
    <>
      {/* <CandidatHorsFrLayoutV2 /> */}
      {/*<CandidatOffres Offres />*/}
      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>
      
      {alreadyPosted && competenciesAlreadyPosted &&
        <FlexBox flexWrap="wrap" justifyContent="center" textAlign="center" alignItems="center">
          <Span alignItems="center" textAlign="center"> Selon votre profil, choisissez le bon entretien vidéo à passer</Span>
          <Span>C'est un auto-test, il n’y a personne qui va vous poser des questions</Span>
          <Span alignItems="center" textAlign="center">Vous allez trouver des questions auxquelles vous devez répondre en vidéo, et vos réponses vont être enregistrées au niveau de notre plateforme</Span>
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[0]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[1]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[2]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[3]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[4]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[5]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[6]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[7]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[8]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[9]} />
          <OffresOFCF jobid_param={config.Jobs_for_outfrSearchPermanentFr[10]} />
        </FlexBox>
      }
</Box>
    </>
  );
};

export default OutFrFreelanceCandidateGeneralInterviews;
