import Tab1 from "views/tab1";
import Tab3 from "views/tab3";
import Tab2 from "views/tab2";

import TabOffresCandidat from "views/tabOffresCandidat";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import RegisterV2 from "views/examples/RegisterV2.js";
import RegisterV3 from "views/examples/RegisterV3.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import ConfirmEmail from "./views/examples/ConfirmEmail";
import EditProfile from "./views/examples/EditProfile";
import ResetPassword from "./views/examples/ResetPassword";
import ConfirmPassword from "./views/examples/ConfirmPassword";
import ResetPasswordSuccess from "./views/examples/ResetPasswordSuccess";
import Tab4 from "views/tab4";
import "../src/components/custom.css"
import TabsRender from "views/tab4"
import {
  FiEdit,
  FiVideo,
  FiBriefcase,
  FiFolderPlus,
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiUsers,
  FiUser,
  FiAward
} from "react-icons/fi";
import React from "react"
import ProfileCandidat from "views/examples/ProfileCandidat";
import CandidatMyProfile from "components/candidat/CandidatMyProfile";
import EditProfileCandidat from "views/examples/EditProfileCandidat";
import Tab2Comp from "views/tab2Comp";
import EmployerAccessProfiles from "components/recruteur/EmployerAccessProfiles";
import Offres from "components/recruteur/jobs";
import HomePage from "components/homePage";
import Competitions from "components/recruteur/competitions";
import Tab2Employer from "views/tab2Employer";
import Tab2CompEmployer from "views/tab2CompEmployer";
import CreateForm from "components/recruteur/CreateForm";
import CompetitionForm from "components/recruteur/CompetitionForm";
var routes = [
  // {
  //   path: "/tab1",
  //   name: "Créer une nouvelle offre d’emploi",
  //   icon: <FiFolderPlus/>,
  //   component: Tab1,
  //   layout: "/admin",
  //   api: false,
  // },
  // {
  //   path: "/tab1",
  //   name: "Créer une offre",
  //   icon: <FiFolderPlus/>,
  //   component: Tab1,
  //   layout: "/candidat",
  //   api: false,
  // },
  {
    path: "/home",
    name: "Accueil",
    icon: "ni ni-shop ",
    component: HomePage,
    layout: "/admin",
    api: false,
  },
  {
    path: "/CreateJob",
    name: "Créer Offre/Compétition",
    icon: "ni ni-fat-add",
    component: CreateForm,
    layout: "/admin",
    api: true,
  },
  {
    path: "/CreateCompetition",
    name: "Créer Offre/Compétition",
    icon: "ni ni-fat-add",
    component: CompetitionForm,
    layout: "/admin",
    api: true,
  },
  {
    path: "/jobs",
    name: "",
    icon: "ni ni-fat-add",
    component: Offres,
    layout: "/admin",
    api: true,
  },
  {
    path: "/competitions",
    name: "",
    icon: "ni ni-fat-add",
    component: Competitions,
    layout: "/admin",
    api: true,
  },
  {
    path: "/tab2",
    name: "Offres d’emploi",
    icon: "ni ni-briefcase-24",
    component: Tab2Employer,
    layout: "/admin",
    api: false,
  },
  {
    path: "/talentsprofiles",
    name: "Candidats",
    icon: "ni ni-trophy",
    component: EmployerAccessProfiles,
    layout: "/admin",
    api: false,
  },
  {
    path: "/tab2comp",
    name: "Compétitions",
    icon: "ni ni-trophy",
    component: Tab2CompEmployer,
    layout: "/admin",
    api: true,
  },
  {
    path: "/home",
    name: "Accueil",
    icon: "ni ni-shop ",
    component: HomePage,
    layout: "/candidat",
    api: false,
  },
  {
  path: "/monprofil",
  name: "Mon profil",
  icon: "ni ni-shop ",
  component: CandidatMyProfile,
  layout: "/candidat",
  api: false,
},
  {
    path: "/tab2",
    name: "Offres d'emploi",
    icon: "ni ni-briefcase-24",
    component: TabOffresCandidat,
    layout: "/candidat",
    api: false,
  },
  {
    path: "/tab2comp",
    name: "Compétitions",
    icon: "ni ni-trophy",
    component: Tab2Comp,
    layout: "/candidat",
    api: true,
  },
  {
    path: "/jobs",
    name: "",
    icon: "ni ni-fat-add",
    component: Offres,
    layout: "/candidat",
    api: true,
  },
  {
    path: "/competitions",
    name: "",
    icon: "ni ni-fat-add",
    component: Competitions,
    layout: "/candidat",
    api: true,
  },
  // {
  //   path: "/tab3",
  //   name: "Interviews",
  //   icon: <FiVideo/>,
  //   component: Tab3,
  //   layout: "/admin",
  //   api: false,
  // },
   
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  //   api: false,
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  //   api: false,
  // },
   {
     path: "/user-profile",
     name: "Mon Profil",
     icon: "ni ni-single-02",
     component: Profile,
     layout: "/admin",
     api: true,
   },
   {
    path: "/user-profile",
    name: "Mon Profil",
    icon: <FiUser/>,
    component: ProfileCandidat,
    layout: "/candidat",
    api: true,
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  //   api: false,
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    api: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/home",
    api: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    api: true,
  },
  {
    path: "/homepage2",
    name: "Register",
    icon: "ni ni-shop text-pink",
    component: HomePage,
    layout: "/home",
    api: true,
  },
    {
      path: "/jobs",
      name: "Register",
      icon: "ni ni-circle-08 text-pink",
      component: Offres,
      layout: "/home",
      api: true,
    },
  {
    path: "/competitions",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Competitions,
    layout: "/home",
    api: true,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: RegisterV3,
    layout: "/home",
    api: true,
  },
  {
    path: "/confirm-email/:id",
    name: "Confirm Email",
    icon: "ni ni-check-bold text-green",
    component: ConfirmEmail,
    layout: "/auth",
    api: true,
  },
   {
     path: "/edit-profile",
     name: "Edit Profile",
     icon: "ni ni-ruler-pencil text-info",
     component: EditProfile,
     layout: "/admin",
     api: true,
   },
   {
    path: "/edit-profile",
    name: "Edit Profile",
    icon: "ni ni-ruler-pencil text-info",
    component: EditProfileCandidat,
    layout: "/candidat",
    api: true,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    icon: "ni ni-folder-17 text-pink",
    component: ResetPassword,
    layout: "/auth",
    api: true,
  },
  {
    path: "/confirm-password/:id",
    name: "Confirm Password",
    icon: "ni ni-folder-17 text-pink",
    component: ConfirmPassword,
    layout: "/auth",
    api: true,
  },
  {
    path: "/reset-success",
    name: "Password Reset Confirmed",
    icon: "ni ni-folder-17 text-pink",
    component: ResetPasswordSuccess,
    layout: "/auth",
    api: false,
  },
];
export default routes;
