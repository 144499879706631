import axios from "axios";
import React, { useEffect, useState } from "react";
import { getOnePlaybookByID } from "network/ApiAxios";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";


//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  CardImg,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";





function PlaybookOneCard( props ) {
  const history = useHistory();
  const [playbook, setPlaybook] = useState({});
  const [jobApplication, setJobApplication] = useState({});
  const [jobID, setJobID] = useState("");
  const [job, setJob] = useState({});
  const [jobApplicationUser, setJobApplicationUser] = useState({});
  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobApplicationCreatedAt, setJobApplicationCreatedAt] = useState("");
  const [jobApplicationUserLocation, setJobApplicationUserLocation] = useState("");




  const getPlaybookByIDFunction = async () => {
    const response = await getOnePlaybookByID(props.playbookID);
    const { data } = response;
    if (data.success) {
      setPlaybook(data.PlaybooksItem);
      // console.log("ggggggggggggg: playbookOneCard: data.success");
      // console.log("ggggggggggggg: playbookOneCard: data.success: data: ", data);
      // console.log("ggggggggggggg: playbookOneCard: data.success: data.PlaybooksItem: ", data.PlaybooksItem);
    } else {

    }
  };







  useEffect(() => {
    getPlaybookByIDFunction();
  }, []);


  return (
    <>
    <Container>
      <Row >
        <Col lg="2">
          <img
            src={`${config.Data_URL}${job.image}`}
            onError={(e) => {
              e.target.src =
                require("assets/img/brand/job_offer_HL_300_225.jpg").default;
            }}
            style={{
              width: "120x",
              height: "120px",

              objectFit: "cover",
            }}
            alt="Responsive image"
          />
        </Col>
        <Col lg="8" >
          <Row className="justify-content-center">
          <Col lg="2">
          </Col> 
          <Col lg="8">
          <div>
              <span className="card-t">
                {playbook.title}{" "}
              </span>
              <span
                className="text-sm"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp:
                    "2" /* number of lines to show */,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {playbook.description}
              </span>
            </div>
          </Col>
          <Col lg="2">
          </Col>
          </Row>

        </Col>
        <Col lg="2" className=" flex items-center">
            <Button
              className="btn-secondary"
              onClick={() =>
                history.push(`/employer/trainings/DisplayOneLearningPathView/63bdd4bb6dbbbbba62030818`)

              }
            >
              Voir plus
            </Button>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default PlaybookOneCard;
