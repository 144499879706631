import Header from "components/Headers/Header";
import Interviews from "components/recruteur/Interviews";
import React from "react";

function tab3() {
  return (
    <>
      <Header />
      <Interviews />
    </>
  );
}

export default tab3;
