import EmployerLayout from 'layouts/EmployerLayout'
import { CreateOrUpdateOffer } from 'network/ApiAxios'
import React, { useEffect } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import {useHistory} from 'react-router-dom'

const SubsciptionSuccess = () => {

    const history = useHistory()

    const user = JSON.parse(localStorage.getItem('user'))

    const EditOfferForRecruiterASPremium = async () => {
        await CreateOrUpdateOffer({ CompanyID: user?.employerCampanyID, Type: 'Premium' })
    }

     useEffect(()=>{
         EditOfferForRecruiterASPremium()
     },[])
    return (
        <>
            <EmployerLayout />
            <Container>
                <Row className='mt-5'>
                    <Col xs="2">
                    </Col>
                    <Col xs="10" className="d-flex flex-column justify-content-center align-items-center">
                        <Container>
                            <Card className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                                color="primary"
                                outline>
                                <CardBody>
                                    <h3> Le paiement a été effectué avec succès,Vous êtes désormais abonnés à <span className='text-primary'>l'offre Premium</span>, Vous pouvez maintenant accéder à tous les profils candidats</h3>
                                    <Row>
                                        <Col sm='2'>
                                        </Col>
                                        <Col sm='4'>
                                            <Button className='mt-4' style={{whiteSpace:'normal'}} color='primary' onClick={()=> history.push('/employer/recruiters/OutFrUsersForRecrutersView')}>Profils Candidats étrangers </Button>
                                        </Col>
                                        <Col sm='4'>
                                            <Button className='mt-4' style={{whiteSpace:'normal'}} color='primary' onClick={()=> history.push('/employer/recruiters/FrUsersForRecrutersView')}>Profils Candidats en France </Button>
                                        </Col>
                                        <Col sm='2'>
                                        </Col>

                                    </Row>


                                </CardBody>
                            </Card>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SubsciptionSuccess