import React, { useEffect, useState } from 'react'
import { LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { getUserById } from 'network/ApiAxios';
import { getProfileFrUserByID } from 'network/ApiAxios';
import { getCompetenciesByCandidate } from 'network/ApiAxios';
import { getProfileProgressForFrCandidate } from 'network/ApiAxios';
import { getCandidateJobApplications } from 'network/ApiAxios';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'
import ProgressBar from './MyProfile/ProgressBar';
import Checklist from './MyProfile/Checklist';
import LocalisationSection from './MyProfile/LocalisationSection';
import FormationSection from './MyProfile/FormationSection';
import CardComp from './MyProfile/CardComp';
import CandidateCompetencies from './CandidateCompetencies';
import ProfilePartie1 from './MyProfile/ProfilePartie1';
import { useTranslation } from 'react-i18next';


const CandidateProfileForRecruiter = ({ id,getProfileCandidate,profileC}) => {
    const [candidateUser, setCandidateUser] = useState(null);
    const [
        candidateProgressProfile,
        setCandidateProgressProfile,
    ] = useState(null);
    const [competencies, setCompetencies] = useState(null);
    const [jobApplication, setJobApplication] = useState(null)

    const user = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();



    const getUser = async (idUSer) => {
        try {
            const { data } =  await getUserById(idUSer)
            setCandidateUser(data)
        } catch (error) {
            console.log(error);
        }
    }


    const getCandidateCompetencies = async () => {
        try {
            //const { data } = await getMyCompetencies(id)
            const { data } = await getCompetenciesByCandidate(id)      
            setCompetencies(data)
        } catch (error) {
            console.log(error);
        }
    }


    const CheckProgressForFrCandidate = async () => {
        try {
            const { data } = await getProfileProgressForFrCandidate(id)
            setCandidateProgressProfile(data)
        } catch (error) {
            console.log(error);
        }
    }


    const fetchData = async () => {
        await getUser(id);
        await getProfileCandidate(id);
        await CheckProgressForFrCandidate();
        await getCandidateCompetencies();
    };

    useEffect(() => {
        fetchData()
    }, [id])


    return (
        <div
            className="container"
        >
            <Row>
                <Col sm='4'>
                        {/* Localisation et déplacement */}
                        <LocalisationSection profileC={profileC} candidateUser={candidateUser} getUser={getUser} getProfileCandidate={getProfileCandidate} user={user} t={t} />
                        <br />
                        <FormationSection profileC={profileC} candidateUser={candidateUser} getProfileCandidate={getProfileCandidate} user={user} t={t} />
                        <br />
                        {/* Certifications */}
                        <CardComp header="Certifications" hidden>
                            {!profileC?.certifications ? (
                                <Alert color="Secondary">
                                    Aucune certification n'a été ajoutée !
                                </Alert>
                            ) : (
                                <ul>
                                    <li>
                                        {profileC?.certifications}
                                        <p
                                            style={{
                                                fontWeight: "light",
                                                fontStyle: "italic",
                                                margin: "0px 10px",
                                                color: "#6c7592",
                                            }}
                                        >
                                            {profileC?.certificationLevel}
                                        </p>
                                    </li>
                                </ul>
                            )}
                        </CardComp>
                        <br />
                </Col>
                <Col sm='8'>
                    {/* FormComp */}
                    <div className="container">
                        <ProfilePartie1 getUser={getUser} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} />
                        <br />

                        <Container>
                            <Card className="shadow">
                                <CardHeader>
                                    <Row className="p-2 bd-highlight">
                                        <Col sm='6'>
                                            <h6 className="heading-small text-muted mb-4">Informations</h6>
                                        </Col>

                                        <Col sm='6'>

                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody className="bg-white">
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email
                                                        </label>
                                                        <Input
                                                            name='email'
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            value={candidateUser?.email || profileC?.email}
                                                            type="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-age">
                                                            Age
                                                        </label>
                                                        <Input
                                                            name='age'
                                                            className="form-control-alternative"
                                                            value={profileC?.age}
                                                            id="input-age"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-gender"
                                                        >
                                                            Gender
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={profileC?.gender}
                                                            id="input-age"
                                                            type="text"
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <label className="px-lg-3">Situation familiale</label>
                                                    <Input
                                                        type='text'
                                                        //options={options_family_Situation}
                                                        value={profileC?.familySituation}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                    </Form>
                                </CardBody>
                            </Card>
                        </Container>


                        <br />

                        <CandidateCompetencies competencies={competencies} user={user} candidateUser={candidateUser} getCandidateCompetencies={getCandidateCompetencies} t={t} />
                        <br />

                    </div>
                    {/* End of FormComp */}
                </Col>
            </Row>

        </div>
    )
}

export default CandidateProfileForRecruiter