import { CreateOrUpdateOffer } from 'network/ApiAxios'
import { fetchOfferByCompany } from 'network/ApiAxios'
import { createUniqueSessionForCheckout } from 'network/ApiAxios'
import { getAllSubscriptionProducts } from 'network/ApiAxios'
import { getGeneralParam } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
//import {useHistory} from 'react-router-dom'

const Subscription = () => {
    const [param, setParam] = useState(0)
    const [companyOffer, setCompanyOffer] = useState(null)
    const [subscription, setSubscription] = useState({})
    const user = JSON.parse(localStorage.getItem('user'))
    //const history = useHistory()

    const getSubsciptionLists = async () => {
        try {
            const { data } = await getAllSubscriptionProducts()
            setSubscription(data.data[0])
        } catch (error) {
            console.log(error);
        }
    }

    const getAccessibleNumberOfPages = async () => {
        try {
            const { data } = await getGeneralParam()
            data && setParam(data[0]?.Parameter)
        } catch (error) {
            console.log(error);
        }
    }

    const getOfferByCompany = async () => {
        try {
            const { data } = await fetchOfferByCompany(user?.employerCampanyID)
            if (data.success) {
                setCompanyOffer(data.CampanyOffer)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const contentBtn = (Type) => { return !companyOffer ? t('BtnSubscription1') : (companyOffer.Type == Type) ? t('BtnSubscription2') : t('BtnSubscription3') }


    useEffect(() => {
        getAccessibleNumberOfPages()
        getOfferByCompany(user?.employerCampanyID)
        getSubsciptionLists()
    }, [])

    const createOrEditFreeOffer = (e) => {
        CreateOrUpdateOffer({ CompanyID: user?.employerCampanyID, Type: e.target.id })
        setCompanyOffer({ ...companyOffer, Type: e.target.id })
    }

    const CreateSession = async(priceID)=>{
        try {
            const {data} = await createUniqueSessionForCheckout({email:user.email,priceId:priceID})
            console.log(data.url);
            //history.push(data.url)
            window.location.href = data.url;

        } catch (error) {
            console.log(error);
        }
    }

    const { t, i18n } = useTranslation();


    return (
        <Container>
            <Row className='d-flex flex-wrap justify-content-around align-items-center mt-5'>
                <Col sm="12" md="6" lg="4">

                    <Card
                        className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                        color="primary"
                        outline
                    >
                        <CardBody>
                            <CardTitle tag="h1">
                                {t('Offer')} Free
                            </CardTitle>

                            <CardText className='mt-3 mb-4'>
                                {t('subscriptionkey1')} {param} {t('subscriptionkey2')}
                            </CardText>

                            <h2 className='mt-3'>0€ /{t('mounth')}</h2>
                            <Button disabled={companyOffer?.Type === 'Free'} onClick={createOrEditFreeOffer} id='Free' className='rounded-pill mt-4' color='primary'>
                                {contentBtn('Free')}
                            </Button>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="12" md="6" lg="4">

                    {subscription && <Card
                        className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                        color="primary"
                        outline
                    >
                        <CardBody>
                            <CardTitle tag="h1">
                                {t('Offer')} {subscription.nickname}
                            </CardTitle>

                            <CardText className='w-400 text-break'>
                                {t('AccessDes')}
                            </CardText>

                            <h2 className='mt-3'>{subscription.unit_amount / 100}€ /{t('mounth')}</h2>
                            <Button disabled={companyOffer?.Type === 'Premium'} onClick={()=>CreateSession(subscription.id)} id='Premium' className='rounded-pill mt-4' color='primary'>
                                {contentBtn('Premium')}
                            </Button>
                        </CardBody>
                    </Card>}
                </Col>

            </Row>
        </Container>
    )
}

export default Subscription