import React, { useEffect, useState } from "react";

function CameraDetect({ setDeviceId, callback, setModalOpen, modalOpen }) {
  const [allowed, setAllowed] = useState("");
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    if (allowed === "granted") {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) =>
          setDevices(devices.filter((device) => device.kind === "videoinput"))
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [allowed]);

  const allowCamera = () => {
    if (navigator.userAgent.indexOf("Firefox")) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then(() => setAllowed("granted"))
        .catch((err) => setAllowed("denied"));
    } else {
      navigator.permissions
        .query({ name: "camera" })
        .then((permissionsObj) => {
          setAllowed(permissionsObj.state);
          if (
            permissionsObj.state === "denied" ||
            permissionsObj.state === "prompt"
          ) {
            navigator.mediaDevices
              .getUserMedia({
                audio: true,
                video: true,
              })
              .then(() => setAllowed("granted"))
              .catch((err) => setAllowed("denied"));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    allowCamera();
  }, []);

  return (
    <div className="text-center">
      {allowed === "denied" && (
        <h3 className="text-red-600">
          You are blocking the camera, Please allow it in your browser settings
          and refresh the page!
        </h3>
      )}
      {(allowed === "prompt" || allowed === "") && (
        <h3>Veuillez autoriser votre caméra</h3>
      )}
      {allowed === "granted" && (
        <select
          onChange={(e) => {setDeviceId(e.target.value);
          callback();
          setModalOpen(!modalOpen);
        }}
          className="border border-gray-400 px-5 py-2"
        >
          <option value="">Veuillez choisir une caméra</option>
          {devices.map((device) => (
            <option value={device.deviceId} key={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

export default CameraDetect;
