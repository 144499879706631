import axios from "axios";
import React, { useCallback, useRef, useState } from "react";
import {
  FilmIcon,
  ChatAltIcon,
  ClockIcon,
  VideoCameraIcon,
  CheckIcon,
  BanIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Webcam from "react-webcam";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Button, 
  Container, 
  Row, 
  Col, 
  GroupAddon,
  FormGroup,
  Input
} from "reactstrap";
import Timer from "components/timer";

import config from "config";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  // config.headers.ContentType = "application/json";
  config.headers.ContentType = "multipart/form-data";
  return config;
});


function RecordVideoAndTextualResponse({
  data,
  interviewId,
  deviceId,
  setQuestionCompleted,
  questionCompleted,
  callback,
  condd,
  setCondd,
  totalQues,
  index,
  question,
  setIndex,
  takesIND,
  setTakesIND,
  unlThinking,
  setUnlThinking,
  unlTakes,
  setUnlTakes,
  setTakesIndexer,
  takesIndexer,
}) {
  const location = useLocation();
  const query = qs.parse(location.search);
  const buttonNameRef = useRef();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState({});
  const [finishCapturing, setFinishCapturing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [thinking, setThinking] = useState(true);

  const [timeOut, setTimeOut] = useState(false);

  const [outOfTakes, setoutOfTakes] = useState(false);
  const [opacity, setOpacity]=useState("0.3")
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRetakeOpen, setModalRetakeOpen] = useState(false);
  const [textualResponse, setTextualResponse] = useState("");
  console.log("id", query.questionId);

  const handleStartCapture = useCallback(() => {
    console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: handleStartCapture: ");


    setOpacity("1");
    // setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(data);
      }
    },
    [setRecordedChunks]
  );

  const handleStopCapture = useCallback(() => {
    setCapturing(false);
    setFinishCapturing(true);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const lastSaveVideo = async () => {
    const blob = new Blob([recordedChunks], {
      type: "video/webm",
    });

    const video = new File([blob], `${localStorage.getItem("candidatId")}--${question._id}`, {
      lastModified: new Date(),
      type: blob.type,
    });
    console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: lastSaveVideo: textualResponse: ", textualResponse);
    console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: lastSaveVideo: interviewId: ", interviewId);
    console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: lastSaveVideo: localStorage.getItem(candidatId): ", localStorage.getItem("candidatId"));

    const formData = new FormData();

    formData.append("video", video);
    formData.append("interview", interviewId);
    formData.append("question", query.questionId);
    formData.append("candidat", localStorage.getItem("candidatId"));
    formData.append("candidat", localStorage.getItem("candidatId"));
    formData.append("textualResponse", textualResponse);
    /*
    instance
    .post(`${config.WS_BASE_URL}jobs/textualResponseInterview/${interviewId}/${query.questionId}/${localStorage.getItem("candidatId")}`, {
      formData
    })
    */
    axios({
      method: "post",
      url: `${config.WS_BASE_URL}jobs/textualResponseInterview/${interviewId}/${query.questionId}/${localStorage.getItem("candidatId")}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    
    setCondd(false);
    callback();
  };
  const retakeCallback = () => {
    setCondd(false);
    callback();
    setTakesIND(takesIND - 1);

    console.log(takesIND);
  };


  useEffect(() => {

    setIndex(index);
    if (question.thinkTime == "Unlimited") {
      setUnlThinking(true);
    } else if (question.thinkTime != "Unlimited") {
      setUnlThinking(false);
    }
    console.log("indexxx2222222", takesIND);
    console.log("indexxxeeeeer", takesIndexer);
    console.log("out of takes",outOfTakes)
   
    if (takesIND <=1 && takesIND>=0) {
      console.log("indexxx", takesIND);
      console.log("outOfTakes", outOfTakes);
      setoutOfTakes(true);
    }
    
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);




  //console.log("unlThininininini", unlThinking);
  return (
    <>

      
      <div className="relative d-flex flex-column align-items-center">
        
        <Row style={{width:"100%", backgroundColor: "#00000075" }}>
          <Col xs="3" style={{ display: "inline-flex"}}>
            <ClockIcon className="h-6 pr-2" color="white" />
              <h4 className="justify-left text-white" >
                Time limit: {question.timeLimit} minutes
              </h4>
          </Col>
          <Col xs="3">
            <h4 className="	text-white" style={{textAlign:"center"}}> Question {index + 1} of {totalQues}:{" "}</h4>
          </Col>
          <Col xs="3" style={{ display: "inline-flex"}}>
            {!timeOut && (
              <h4 className="	text-white" style={{textAlign:"center"}}>
                  {/* <ClockIcon className="h-6 pr-2" /> */}
                  Temps restant:
                  <Timer
                    time={question.timeLimit * 60}
                    value={timeOut}
                    setValue={setTimeOut}
                  />
                </h4>
            )}

          </Col>



          <Col xs="3" style={{ display: "inline-flex"}}>
          <FilmIcon className="h-6 pr-2" color="white" />
          <h4 className=" text-white">            
                {unlTakes
                  ? `Remaining Takes: Unlimited`
                  : `Remaining Takes: ${takesIND-1}`}
              </h4>
          </Col>

        </Row>
        <Row style={{width:"100%" }}>
          <Col xs="3" >
            
          </Col>
          <Col xs="6"><h4 className="	flex items-center justify-center py-3  text-white rounded-t-md" 
          style={{textAlign:"center", background: "linear-gradient(87deg , #11cdef 0, #1171ef 100%)"}}> {question.question}</h4></Col>


          <Col xs="3">
          
          </Col>

        </Row>

        <Row style={{width:"100%" }}>
          <Col xs="3" >
            
          </Col>
          <Col xs="6">
            <FormGroup>
              <label
                className="form-control-label	flex items-center justify-center pt-3" 
                htmlFor="label-response"
              >
                Merci de mettre votre réponse ici:
              </label>

            </FormGroup>
          </Col>



          <Col xs="3">
          
          </Col>

        </Row>
        <Row style={{width:"100%" }}>
          <Col xs="3" >
            
          </Col>
          <Col xs="6">
            <Input 
              className="	flex items-center justify-center text-white rounded-t-md" 
              type="textarea"
              rows="5"
              style={{textAlign:"center", background: "linear-gradient(87deg , #11cdef 0, #1171ef 100%)"}}
              onChange={(e) => setTextualResponse(e.target.value)}
              > 
            </Input>
          </Col>


          <Col xs="3">
          
          </Col>

        </Row>

        <Row 
            //style={{width:"100%" }}
            className="flex items-center justify-center pt-1 pb-3 "
            
            >
          <Col xs="3" >
            
          </Col>
          <Col xs="6">
            <Button
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setIndex(index + 1);
                }}
                // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                // style={{ backgroundColor: "#00000075", height: "50px" }}
                // style={{textAlign:"center"}}
                // className="flex items-center justify-center"
              >
                <p>Sauvegarder votre réponse</p>
              </Button>
          </Col>


          <Col xs="3">
          
          </Col>

        </Row>
      
        {loading && (
          <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
            <ClipLoader color="#f00" />
          </div>
        )}
        <div className="position-relative ">
          <Webcam style={{opacity:opacity}} videoConstraints={{ deviceId: deviceId }} ref={webcamRef} />
          {console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: ")}
          {console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: finishCapturing: ", finishCapturing)}
          {!finishCapturing && handleStartCapture && (
            <>
              <div className="position-absolute top-0 right-0" />

                <VideoCameraIcon style={{ height: "1.5rem" }} />
                {console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: thinking: ", thinking)}
                {console.log("TTTTTTTTTTTTTTTT: RecordVideoAndTextualResponse: unlThinking: ", unlThinking)}
            </>
          )}
          {finishCapturing && (
            <button
              ref={buttonNameRef}
              onClick={handleStopCapture}
              className="btn position-absolute d-flex top-0 right-0  text-red px-3 py-2"
              style={{ backgroundColor: "#00000075", height: "50px" }}
            >
              <BanIcon style={{ height: "1.5rem" }} />
              {timeOut && !finishCapturing && handleStopCapture()}
              <p>Stop</p>
            </button>
          )}
          {finishCapturing && (
            <>
              <button
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setIndex(index + 1);
                }}
                className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                style={{ backgroundColor: "#00000075", height: "50px" }}
              >
                <CheckIcon style={{ height: "1.5rem" }} />
                <p>Save</p>
              </button>
              {!outOfTakes && (
                <button
                  onClick={() => {
                  setModalRetakeOpen(!modalRetakeOpen)
                  }}
                  className="btn position-absolute d-flex top-0 right-0 text-white px-3 py-2"
                  style={{ backgroundColor: "#00000075", height: "50px" }}
                >
                  <RefreshIcon style={{ height: "1.5rem" }} />
                  <p>Retake</p>
                </button>
              )}
            </>
          )}
          {thinking && !capturing && !finishCapturing && !unlThinking && (
            <Card
              style={{
                width: "100%",
                top: "50%",
                height: "50px",
                marginBottom: "-3%",
                backgroundColor: "#00000075",
                alignItems: "center",
              }}
              className=" position-absolute d-flex  px-3 py-2"
            >
              <CardBody
                style={{
                  padding: "0.5rem",
                  color: "white",
                  display: "inline-flex",
                }}
              >
                <ChatAltIcon className="h-6 pr-2" />
                <Timer
                  time={question.thinkTime * 60 + 4}
                  value={thinking}
                  setValue={setThinking}
                />
                &nbsp; avant de commencer l'enregistrement
              </CardBody>
            </Card>
          )}

        </div>
        <Modal
          toggle={() => setModalRetakeOpen(!modalRetakeOpen)}
          isOpen={modalRetakeOpen}
        
        >
          <div className=" modal-header">
            <h4 className=" modal-title" id="exampleModalLabel">
            Êtes-vous sûr de vouloir reprendre la question ?
            </h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => {
                setModalRetakeOpen(!modalRetakeOpen);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setModalRetakeOpen(!modalRetakeOpen);
              }}
            >
              Annuler
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                retakeCallback();
              }}
            >
              Confirmer 2
            </Button>
          </ModalFooter>
        </Modal>          
        <Modal
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
          onClosed={() => {
            setIndex(index - 1);
          }}
        >
          <div className=" modal-header">
            <h4 className=" modal-title" id="exampleModalLabel">
            Soumettre et passer à la question suivante
            </h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              Annuler
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                lastSaveVideo();
                setTakesIndexer(-1);
              }}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default RecordVideoAndTextualResponse;
