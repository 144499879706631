import React, { useRef, useState } from 'react'
import { Button, Card, CardBody, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { EditUserCV } from 'network/ApiAxios';
import { FaFileUpload } from 'react-icons/fa';
import AlertMessage from 'components/styles/AlertMessage';


const EditCvModal = ({ candidateUser, getUser, t }) => {
  const [modal, setModal] = useState(false);
  const [CV, setCV] = useState(null);
  const [msg, setMsg] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'))
  const fileInputRef = useRef(null)

  const handleImageClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const toggle = () => setModal(!modal);

  const handleFileChange = (e) => {
    setCV(e.target.files[0]);
  }

  const EditCvUser = async () => {
    const formData = new FormData();
    formData.append("CV", CV)
    const { data } = await EditUserCV(candidateUser._id, formData);
    if (data.success) {
      setMsg(data.msg)
    }
    await getUser(user._id)
    setTimeout(() => {
      toggle()
    }, 500)
  }

  return (
    <>
      {((candidateUser?._id === user?._id) && user) && <Container>
        <svg onClick={toggle} style={{ height: '1.2rem', color: '#5e72e4', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
          <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
          <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
        </svg>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{t('EditLocationTitle')}</ModalHeader>
          {msg && <div className='m-1'>
            <AlertMessage message={msg} />
          </div>}
          <ModalBody>
            <Card>
              <CardBody className='bg-secondary'>
                <Row className="justify-content-center">
                  <Label for="fileInput" style={{ cursor: 'pointer' }} onClick={handleImageClick}>
                    <FaFileUpload size={40} />
                    Ajouter un CV pdf
                  </Label>
                  <Input
                    type="file"
                    id="fileInput"
                    name="CV"
                    accept="pdf"
                    innerRef={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </Row>
                {CV && <h4 style={{ textAlign: 'center' }}>{CV?.name}</h4>}
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={EditCvUser}
              disabled={!CV}
            >
              {t('save')}
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>

      </Container>}
    </>
  )
}

export default EditCvModal