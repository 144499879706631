import React, { useEffect, useState } from 'react'
import { LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { getUserById } from 'network/ApiAxios';
import { getProfileFrUserByID } from 'network/ApiAxios';
import { getCompetenciesByCandidate } from 'network/ApiAxios';
import { getProfileProgressForFrCandidate } from 'network/ApiAxios';
import { getCandidateJobApplications } from 'network/ApiAxios';
import { Alert, Card, Col, Container, Row, UncontrolledAlert } from 'reactstrap'
import ProgressBar from './MyProfile/ProgressBar';
import Checklist from './MyProfile/Checklist';
import LocalisationSection from './MyProfile/LocalisationSection';
import FormationSection from './MyProfile/FormationSection';
import CardComp from './MyProfile/CardComp';
import FormComp from './MyProfile/FormComp';
import config from 'config';
import { fetchGeneralParamByLabel } from 'network/ApiAxios';
import ChecklistForApply from './MyProfile/CheckListForApply';
import { useTranslation } from 'react-i18next';
import ProfileImageSection from './MyProfile/ProfileImageSection';
import EditCvModal from './MyProfile/EditCvModal';



const CandidateProfileComp = ({ id }) => {
    const [candidateUser, setCandidateUser] = useState(null);
    const [profileC, setProfileC] = useState(null);
    const [
        candidateProgressProfile,
        setCandidateProgressProfile,
    ] = useState(null);
    const [competencies, setCompetencies] = useState(null);
    const [jobApplication, setJobApplication] = useState(null)
    const [isShown, setIsShown] = useState(false)
    const [generalParamId, setGeneralParamId] = useState(null)

    const user = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();


    /* const CalcVal = () => {
        return (
            candidateUser &&
            candidateProgressProfile &&
            (candidateProgressProfile.profileExistence && 25) +
            (candidateProgressProfile.competenciesExistence && 25) +
            (candidateProgressProfile.interviewPassed && 25) +
            (generalPresentationApplication && generalPresentationApplication?.videos.length && 25)
        );
    }; */


    const getUser = async (idUSer) => {
        try {
            const { data } = await getUserById(idUSer)
            setCandidateUser(data)
        } catch (error) {
            console.log(error);
        }
    }


    const getProfileFrCandidate = async (idUSer) => {
        try {
            const { data } = await getProfileFrUserByID(idUSer)
            setProfileC(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCandidateCompetencies = async () => {
        try {
            //const { data } = await getMyCompetencies(id)
            const { data } = await getCompetenciesByCandidate(id)            //console.log('Competencies',data);
            setCompetencies(data)
        } catch (error) {
            console.log(error);
        }
    }


    const CheckProgressForCandidate = async () => {
        try {
            const { data } = await getProfileProgressForFrCandidate(id)
            setCandidateProgressProfile(data)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchJobApplication = async () => {
        try {
            const { data } = await getCandidateJobApplications(id)
            setJobApplication(data)
            //console.log('jobApp', data);
        } catch (error) {
            console.log(error)
        }
    }

    const GetGeneralPresentationJobId = async () => {
        try {
            const { data } = await fetchGeneralParamByLabel("Présentation générale")
            setGeneralParamId(data[0].Parameter)
            //console.log('id',data[0].Parameter)
        } catch (error) {
            console.log(error)
        }
    }


    const fetchData = () => {
        getUser(id);
        getProfileFrCandidate(id);
        CheckProgressForCandidate();
        getCandidateCompetencies();
        fetchJobApplication()
        GetGeneralPresentationJobId()
    };

    useEffect(() => {
        fetchData()
    }, [])

    const copyToClipboard = link => {
        const textField = document.createElement('textarea');
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();

        try {
            document.execCommand('copy');
            // You can provide some feedback here that the copy was successful
            console.log('Copied to clipboard:', link);
            setIsShown(true)

        } catch (err) {
            console.error('Unable to copy', err);
        } finally {
            document.body.removeChild(textField);
        }
    }

    return (
        <div
            className="container"
        >
            <ProfileImageSection candidateUser={candidateUser} callback={getUser} getProfileCandidate={getProfileFrCandidate} profileC={profileC} t={t} />
            <Row>
                <Col sm='4'>
                    <Card>
                        {
                            user?._id === candidateUser?._id && <>
                                <ProgressBar value={candidateProgressProfile?.ProgressValue || 0}
                                t={t}
                                 />
                                {/* <ChecklistForApply
                                    profileCV={profileC?.CV}
                                    userCV={candidateUser?.CV}
                                    IsAppliedToGeneralPresentation={candidateProgressProfile?.IsAppliedToGeneralPresentation}
                                /> */}
                                <Checklist
                                    candidateProgressProfile={
                                        candidateProgressProfile
                                    }
                                    t={t}
                                />
                            </>
                        }
                        <hr />
                        {/* Localisation et déplacement */}
                        <LocalisationSection profileC={profileC} candidateUser={candidateUser} getUser={getUser} getProfileCandidate={getProfileFrCandidate} user={user} t={t} />
                        <br />
                        {/* 3 Top Technologies */}
                        {/* <CardComp header="3 Top Technologies" hidden>
                                            {" "}
                                            {!profileC?.topTechnologies ? (
                                                <Alert color="secondary">Aucune technologie n'a été ajoutée !</Alert>
                                            ) : (

                                                <ul>
                                                    <li>{profileC.topTechnologies}</li>
                                                </ul>
                                            )}
                                        </CardComp> */}
                        <FormationSection profileC={profileC} candidateUser={candidateUser} getProfileCandidate={getProfileFrCandidate} t={t} />
                        <br />
                        {/* Certifications */}
                        <CardComp header="Certifications" hidden>
                            {!profileC?.certifications ? (
                                <Alert color="Secondary">
                                    Aucune certification n'a été ajoutée !
                                </Alert>
                            ) : (
                                <ul>
                                    <li>
                                        {profileC?.certifications}
                                        <p
                                            style={{
                                                fontWeight: "light",
                                                fontStyle: "italic",
                                                margin: "0px 10px",
                                                color: "#6c7592",
                                            }}
                                        >
                                            {profileC?.certificationLevel}
                                        </p>
                                    </li>
                                </ul>
                            )}
                        </CardComp>
                        <br />
                        {/* CV */}
                        <CardComp header='Votre CV' EditModal={<EditCvModal candidateUser={candidateUser} getUser={getUser} t={t} />}>
                            {candidateUser?.CV || profileC?.CV ? <Link to={`/CvCandidate/${candidateUser?._id}/uploads/${candidateUser?.CV ? candidateUser?.CV?.slice(9) : profileC?.CV?.slice(9)}`}>

                                <div className='d-flex'>
                                    <h2>Mon CV </h2>

                                    <LinkIcon style={{ height: '1.2rem' }} />
                                </div>
                            </Link> :
                                <Alert color='secondary'>Complétez votre profil en mettant un CV</Alert>
                            }

                        </CardComp>
                        <br />
                        {user?._id === candidateUser?._id && <CardComp header='Présentation générale' hidden>
                            {(candidateProgressProfile && candidateProgressProfile.IsAppliedToGeneralPresentation) ?
                                <p>Vous avez déjà créé votre présentation générale audio</p>
                                :
                                <Link to={`/jobs/apply?jobId=${generalParamId}`}>
                                    Créer votre présentation générale en audio
                                </Link>
                            }

                        </CardComp>}
                        <br />
                        <CardComp header='Partager votre profil' hidden>
                            <Row style={{ margin: '0 auto' }}>
                                <a href={candidateUser?.linkedin ? candidateUser.linkedin : (profileC?.linkedin) ? profileC?.linkedin : "https://www.linkedin.com"} target='_blank' rel="noreferrer" >
                                    <svg className='pr-4' style={{ fill: 'blue' }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                                    </svg>
                                </a>
                                <LinkIcon onClick={() => copyToClipboard(`${config.DOMAIN_NAME}/sharedFrProfile/${id}`)} style={{ height: '1.2rem', cursor: 'pointer' }} />
                            </Row>
                            <Row className='mt-3'>
                                {isShown && <UncontrolledAlert color="success">
                                    Lien copié
                                </UncontrolledAlert>}
                            </Row>
                        </CardComp>
                    </Card>
                </Col>
                <Col sm='8'>
                    <FormComp
                        getUser={getUser}
                        getProfileCandidate={getProfileFrCandidate}
                        competencies={competencies}
                        profileC={profileC}
                        candidateUser={candidateUser}
                        candidateProgressProfile={candidateProgressProfile}
                        getCandidateCompetencies={getCandidateCompetencies}
                        jobApplication={jobApplication}
                        t={t}
                    />
                </Col>
            </Row>

        </div>
    )
}

export default CandidateProfileComp