import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, Row, Spinner } from 'reactstrap';
import DisplayOpenAiGPT from 'components/other/DisplayOpenAiGPT';
import { fetchJobAppById } from 'network/ApiAxios';
import { ResetQCMQuestionsCatalogAnalysis } from 'network/ApiAxios';
import OneQuestionCatalogJob from './OneQuestionCatalogJob';

const JobAppScoreByOralQCOpenAiReportV1 = ({ candidatId }) => {

    const [jobApp, setjobApp] = useState(null);

    const [oralQuestionsReport, setOralQuestionsReport] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isRelanced, setIsRelanced] = useState(false)

    const getJobAppById = async () => {
        try {
            const { data } = await fetchJobAppById(candidatId);
            console.log('jobApp', data);
            setjobApp(data)
        } catch (error) {
            console.log(error);
        }
    }

    /* const GetOpenAIOralQuestionsQCReport = async () => {
        setIsLoading(true);
        try {
            const resp = await fetchGeneralParamByLabel('Assitant_id_TechnicalQuestions')
            //console.log('resp.data oral', resp.data)

            const { data } = await getOpenAiOralQCsReportByJobApp(candidatId, resp.data[0].Parameter)
            //console.log('openAI Oral', data)
            if (data.Result) {
                setOralQuestionsReport(data.Result)
            }

            if (data.result?.text?.value) {
                console.log('openAI ORal QCs Report Result', data.result)
                setOralQuestionsReport(data.result.text.value)
            }
            setIsLoading(false)


        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    } */


    useEffect(() => {
        getJobAppById()
    }, [])

    return (
        <Row>
            <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                    <h4 className=" mb-4"> Analyse des questions orales techniques par l'IA </h4>

                    <CardBody>
                        {
                            (jobApp && jobApp.questionCatalog.length) ? jobApp.questionCatalog.filter(QC => /Orales/i.test(QC.title)).map(QC => (
                                <OneQuestionCatalogJob
                                    key={QC._id}
                                    item={QC}
                                    candidatId={candidatId}
                                    callBack={getJobAppById}
                                />)
                            )
                                :
                                null
                        }
                    </CardBody>
                    {jobApp?.OralQuestionsCatalogsAnalysis &&
                        <CardFooter>
                            <DisplayOpenAiGPT Result={jobApp.OralQuestionsCatalogsAnalysis} />
                        </CardFooter>
                    }

                </Card>
            </Col>
        </Row>
    )
}

export default JobAppScoreByOralQCOpenAiReportV1