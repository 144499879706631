import axios from "axios";
import React, { useEffect, useState } from "react";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";
//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function JobApplicationsCardsEmployerV2( props ) {
  const [candidats, setCandidats] = useState([]);
  
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState();
  const [job, setJob] = useState({});
  const [jobApplications, setJobApplications] = useState([]);
  const history = useHistory();
  const location = useLocation();
  // const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [candidat, setCandidat] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDelOpen, setModalDelOpen] = useState(false);
  const [candidatID, setCandidatID] = useState("");
  const [participantId, setParticipantId] = useState("");
  const [candidatModal, setCandidatModal] = useState();
  const [check, setCheck] = useState(1);

  const [createdAt, setCreatedAt] = useState("");
  const [reloadWindow, setReloadWindow] = useState(false);
  

  const job_ID = props.jobID;
  // const job_ID = "627d34086543d2ab235e814a";
  

  const getJob = () => {
    instance.get(`jobs/${job_ID}`).then((resp) => {
      // setParticipants(resp.data.job);
      console.log("00000000000000000000000000 JobApplicationsEmployerV3 getJob: resp.data : ", resp.data);
      setJob(resp.data.job);
    });
  };

  const getJobApplications = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);

    console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    console.log("1111111111111111111111111111 JobApplicationsEmployerV3 job_ID", job_ID);
    instance.get(`jobApplication/getJobApplicationsByJobID/${job_ID}`).then((resp) => {
      console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplications(resp.data);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      console.log("1111111111111111111111111111 JobApplicationsEmployerV3: getJobApplications: resp.data : ", resp.data);
      
    });
  };

  useEffect(() => {
    getJobApplications();
    getJob();
  }, [reloadWindow, props.jobID]);

  return (
    <>
    <Container>
    
        {jobApplications &&
        <>
          { console.log("00000000000000000 JobApplicationsEmployerV3 participants: ", jobApplications) }
          {jobApplications

            .map((participant) => (
              <>
                  {participant && participant.pipeline !== "Validé" && participant.pipeline !== "Rejeté" && participant.pipeline !== "A relancer" && participant.pipeline !== "Sourcing" && participant.pipeline !== "A qualifier" && props.pipeline === "" && (

                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                  >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Validé" && props.pipeline === "Validé" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                    >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Rejeté" && props.pipeline === "Rejeté" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                    >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "Sourcing" && props.pipeline === "Sourcing" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                    >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "A relancer" && props.pipeline === "A relancer" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                    >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
                  {participant && participant.pipeline === "A qualifier" && props.pipeline === "A qualifier" && (
                    <Row
                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                    key={participant._id}
                      onClick={() => {
                        setCandidatID(participant?._id);
                        setModalOpen(!modalOpen);
                        setCandidatModal(participant);
                      }}
                    >
                    <Col lg="2">
                      <img
                        src={`${config.Data_URL}${participant.userID.image}`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                      <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {participant.prenom}{" "}
                            {participant.nom}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                        <Col lg="8">
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{participant.email}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <MailIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp; <span>{job.title}</span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                Date:{participant.createdAt.slice(0, 10)}
                              </span>{" "}
                            </div>
                          </Col>
                          <Col lg="12">
                            <div
                              className="text-sm"
                              style={{ display: "inline-flex" }}
                            >
                              {" "}
                              <LightBulbIcon
                                className=""
                                style={{ height: "1.2rem" }}
                              />{" "}
                              &nbsp;{" "}
                              <span>
                                {" "}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                                Nombre de vidéo: {participant?.videos?.length}
                              </span>{" "}
                            </div>
                          </Col>

                        </Col>
                        <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir la Candidature
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    </Row>
                  )}
              </>   
  
            ))
            
          }
          <Modal
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">
              {job && job.title &&
                <h4 className=" modal-title" id="exampleModalLabel">
                  {job.title}{" "}
                </h4>

              }

              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <ShowApplicationEmployerV4
                candidatId={candidatID}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => 
                  {
                    setModalOpen(!modalOpen);
                    setReloadWindow(!reloadWindow);
                  }
                }
                size="sm"
              >
                Fermer
              </Button>
            </ModalFooter>
          </Modal>


      
      </>
      }
      </Container>
    </>
  );
}

export default JobApplicationsCardsEmployerV2;
