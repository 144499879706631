import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Card, CardBody, CardFooter } from 'reactstrap';
import OneProfileCandidateForRecruiter from './OneProfileCandidateForRecruiter';
import AlertMessage from 'components/styles/AlertMessage';
import { getOpenedOutFrProfilesCandidatesForRecruiters } from 'network/ApiAxios';

const OpenedOutFrCandidatedProfilesForRecruiters = () => {
    const [candidates, setCandidates] = useState([])
    const [perPage] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        // console.log("selectedpage", selectedPage);
        setOffset(selectedPage + selectedPage * 5);

    };


    const getOpenedOutFrCandidatesProfilesForRecruiters = async () => {
        try {
            const { data } = await getOpenedOutFrProfilesCandidatesForRecruiters()
            console.log('data', data);
            if (typeof data === 'string') {
                setCandidates(data);
                setPageCount(0);
            } else {
                setCandidates(data.slice(offset, offset + perPage));
                setPageCount(Math.ceil(data.length / perPage));
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getOpenedOutFrCandidatesProfilesForRecruiters()
    }, [offset])

    return (
        <>
            <Card>
                <CardBody>

                    {typeof (candidates) == 'string' && <AlertMessage message={candidates} />}

                    {Array.isArray(candidates) && candidates && candidates.length !== 0 && candidates.map((candidat, i) => (
                        <OneProfileCandidateForRecruiter key={i} candidate={candidat} />
                    ))}
                </CardBody>
                <CardFooter style={{ margin: '0 auto' }}>

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        //pageRangeDisplayed={limit}
                        pageCount={pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={5}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </CardFooter>
            </Card>
        </>
    )
}

export default OpenedOutFrCandidatedProfilesForRecruiters