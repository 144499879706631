
import React from 'react'
import TrainingDetailsForCandidates from 'components/candidat/TrainingDetailsForCandidates';
import CandidateLayoutV3 from 'layouts/CandidatHorsFrLayoutV2';

const TrainingForCandidatesDetails = ({match}) => {

    const user = JSON.parse(localStorage.getItem('user'))
    const {id} = match.params;

  return (
    <>
              <CandidateLayoutV3 />
              <TrainingDetailsForCandidates 
                id={id}
              />
              
          </>
  
  )
}

export default TrainingForCandidatesDetails