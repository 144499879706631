import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const AuthRoutes = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !(localStorage.getItem("token") && localStorage.getItem("user"))
            ? <Component {...props} /> 
            : (localStorage.getItem("user").type=="employer" 
            ? <Redirect to='/admin/home' /> 
            : <Redirect to='/candidat/tab1' />)
    )} />
);

export default AuthRoutes;
