import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { EditUserParameters, EditProfileFrCandidate } from 'network/ApiAxios';


const EditProfileModal = ({ profileC, candidateUser,getUser,getProfileCandidate,t }) => {
    const [modal, setModal] = useState(false);
    const [EditedProfile, setEditedProfile] = useState({})

    //const [mobility, setMobility] = useState(profileC?.mobility)
    let user = JSON.parse(localStorage.getItem("user"));

   /*  useEffect(() => {
        setMobility(profileC?.mobility)
    }, [profileC]); */

    const HandleChangeInput = (e) => {
        setEditedProfile({ ...EditedProfile, [e.target.name]: e.target.value })
    }
    const toggle = () => setModal(!modal);
    

    const Editing = async() => {

        (EditedProfile.name || EditedProfile.lastName) && await EditUserParameters({ userID: candidateUser?._id, name: EditedProfile?.name, lastName: EditedProfile?.lastName }, candidateUser?._id)
        
        if (EditedProfile.name) { user.name = EditedProfile.name; }
        if (EditedProfile.lastName) { user.lastName = EditedProfile.lastName }
        if (EditedProfile.name || EditedProfile.lastName) { localStorage.setItem("user", JSON.stringify(user)); }
        
        
        await EditProfileFrCandidate(EditedProfile, candidateUser?._id)
        
       await getUser(user._id)
       await getProfileCandidate(user._id)
        toggle()
       
        
    }

    return (
        <div className="container">
            <Button className='d-flex' style={{ float: 'right',backgroundColor:'#5e72e4',color:'white',whiteSpace:'normal' }} onClick={toggle}>
                <svg style={{ height: '1.2rem' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                    <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                </svg></Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>changer</ModalHeader>
                <ModalBody>
                    <Card className="shadow">
                        <CardBody className="bg-white">
                            <Form>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                            >
                                                {t('firstName')}
                                            </label>
                                            <Input
                                                name='name'
                                                className="form-control-alternative"
                                                id="input-username"
                                                defaultValue={candidateUser?.name}
                                                onChange={HandleChangeInput}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-lastname"
                                            >
                                                {t('lastName')}
                                            </label>
                                            <Input
                                                name='lastName'
                                                className="form-control-alternative"
                                                id="input-username"
                                                defaultValue={candidateUser?.lastName}
                                                onChange={HandleChangeInput}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                               {/*  <label className="px-lg-3">Mobilité</label>
                                <Select
                                    className="w-full "
                                    options={options_mobility}
                                    onChange={(selectedOpt) => setMobility(selectedOpt.value)}
                                //defaultInputValue={profileC?.mobility}
                                /> */}
                                {/* <label className="px-lg-3">Quel est le profil qui vous corresponde le mieux?</label>
                  <Select  
                    className="w-full "
                    options={options_profile_categories} 
                    defaultValue={profileC?.title}
                  /> */}
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        {t('expertise')}
                                    </label>
                                    <Input
                                        name='expertiseField'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.expertiseField}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        {t('ProfileTitle')}
                                    </label>
                                    <Input
                                        name='title'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.title}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        {t('ProfileCategory')}
                                    </label>
                                    <Input
                                        name='desiredProject'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.desiredProject}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        {t('Experience')}
                                    </label>
                                    <Input
                                        name='experience'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.experience}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        {t('NbCDI')}
                                    </label>
                                    <Input
                                        name='permanentContractExperience'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.permanentContractExperience}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup>
                                {/* <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        votre Ecole/Université/Institut
                                    </label>
                                    <Input
                                        name='university'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.university}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup> */}
                               {/*  <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input"
                                    >
                                        Diplôme
                                    </label>
                                    <Input
                                        name='diploma'
                                        className="form-control-alternative"
                                        id="input"
                                        defaultValue={profileC?.diploma}
                                        type="text"
                                        onChange={HandleChangeInput}
                                    />
                                </FormGroup> */}

                            </Form>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={Editing}>
                        {t('save')}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

export default EditProfileModal