import React from "react";
import SidebarCandidateV3 from "components/Sidebar/SidebarCandidateV3";
import Header from "components/Headers/Header";

const CandidateLayoutV2 = () => {
  

    return (
      <>
        <SidebarCandidateV3/>
        {/* <CandidatOutFrNavbar/> */}
        <Header/>
      </>
    );
  
}

export default CandidateLayoutV2;
