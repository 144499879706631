import React from "react";

import SidebarCandidateV5 from "components/Sidebar/SidebarCandidateV5";
import Header from "components/Headers/Header";

const CandidateLayoutV3 = () => {
  

    return (
      <>
        <SidebarCandidateV5/>
        {/* <CandidatOutFrNavbar/> */}
        <Header/>
      </>
    );
  
}

export default CandidateLayoutV3;
