import {frUserManagementNotesTypes} from "options";


import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import Select from "react-select";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function AddFrUserManagementNotesWithChoicesWithCallback(
  {
    candidateUserID,
    fetchUserNotes,
  }
) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  

  const [addThisNote, setAddThisNote] = useState("");
  // const [newNoteType, setNewNoteType] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  
  
  const user = JSON.parse(localStorage.getItem("user"));




const createUserNote = async () => {
  let note = addThisNote;
  let recruiterUserID = user._id;


  // console.log("addFrUserManagementNotesWithChoices: createUserNote: step 0: ");
  // console.log("addFrUserManagementNotesWithChoices: createUserNote: step 5 addThisNote: ", addThisNote);
  
  instance.post("/userManagementNotes/createNote", 
  {
    candidateUserID,
    recruiterUserID,
    note,
  }
  ).then((resp) => {
    // console.log("addFrUserManagementNotesWithChoices: createUserNote: step 2:");
    // console.log("addFrUserManagementNotesWithChoices: createUserNote: step 3 : resp: ", resp);
    // console.log("addFrUserManagementNotesWithChoices: createUserNote: step 4 : resp.data: ", resp.data);
    fetchUserNotes();  
  });
};


   useEffect(() => {
  }, [newAddedNote]);



  return (
    <>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form>
                  <FormGroup>
                    <label className="px-lg-3">Ajouter une remarque</label>
                   <Select className="w-full"
                          options={frUserManagementNotesTypes}   
                          // value={applicationPipeline} 
                          value = {
                            frUserManagementNotesTypes.filter(option => 
                              option.label === addThisNote)
                         }
                                          
                          onChange={(e) => {
                            // setNewNoteType(e.label);
                            setAddThisNote(e.label);
                            }} />
                  </FormGroup>
                  <FormGroup>
                    <Input
                        name="addThisNoteType"
                        // label="Nom" 
                        // placeholder="Email" 
                        className="w-full " 
                        type="textarea"
                        rows="1"
                        // onBlur={handleBlur} 
                        // value={email}
                        // onChange={handleChange} 
                        // onChange={e => setAddThisNote(e.target.value)}
                        value={addThisNote || ""}
                        // error={!!touched.titre && !!errors.titre} 
                        // helperText={touched.titre && errors.titre} 
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                      <Button 
                      className="mt-2"
                      variant="contained" 
                      color="primary" 
                      // type="submit" 
                      // sx={{ mt: "25px" }}
                      onClick={() => { 
                        // console.log("bouton cliqué 0000000000000000000000 ")
          
                        createUserNote();
                        setNewAddedNote(newAddedNote + 1 );
                        setAddThisNote("");
                      }}
                      >
                        Enregistrer
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
     
    </>
  );
}

export default AddFrUserManagementNotesWithChoicesWithCallback;
