import React, { useState, useEffect } from "react";
import { ArrowDownIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { useLocation, useHistory } from "react-router-dom";
import { createOnePlaybook } from "network/ApiAxios";
import qs from "query-string";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import config from "config";
import Header from "components/Headers/Header.js";

function CreateLearningPath () {
  const locationQuery = useLocation();
  const history = useHistory();
  const query = qs.parse(locationQuery.search);

  const [title, setTitle] = useState("");
  const [description, setDiscription] = useState("");


  
  const [errors, setErrors] = useState({
    title: "",
    description: "",
  });
  const [userID, setUserID] = useState();
  const [creatorID, setCreatorID] = useState();
  
  const [step_1_Description, set_step_1_Description] = useState("");
  const [step_1_URL, set_step_1_URL] = useState("");
  const [step_2_Description, set_step_2_Description] = useState("");
  const [step_2_URL, set_step_2_URL] = useState("");
  const [step_3_Description, set_step_3_Description] = useState("");
  const [step_3_URL, set_step_3_URL] = useState("");


  const [error, setError] = useState();
  useEffect(() => {
    setUserID(JSON.parse(localStorage.getItem("user"))._id);
    setCreatorID(JSON.parse(localStorage.getItem("user"))._id);
  }, []);


  const handleJobValidation = () => {
    let isValid = true;

    if (!title) {
      isValid = false;
      setError("impossible de procéder avec un Titre de l'offre vide ");
    }
    
   return isValid;
  };
  const createPlaybookFunction = async () => {
    
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: ");
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: handleJobValidation(): ", handleJobValidation());
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: creatorID: ", creatorID);
    if (handleJobValidation()){
      // console.log("pppppppppppppp: learningPath: createPlaybookFunction: title: ", title);
      // console.log("pppppppppppppp: learningPath: createPlaybookFunction: description: ", description);
      const response_Playbook_Creation = await createOnePlaybook(title, description, creatorID, step_1_Description, step_1_URL, step_2_Description, step_2_URL, step_3_Description, step_3_URL);
      // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: ");
      // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: ", response_Playbook_Creation);
      const { data } = response_Playbook_Creation;
      if (data.success) {
        // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: success");
        // resetForm()
        history.push(`/employer/trainings/YourLearningPathsView`);
      } else {
        // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: failure");
      }
    }
  };

  const onSubmitForm = (e) => {
    
        e.preventDefault();
        {/*
          if (title !== "" && description !== "" && location !== "") {
            history.push(`${locationQuery.pathname}?status=true`);
          }
        */}

  };



  const resetForm = (e) => {
    e.preventDefault();
    setTitle("");
    setDiscription("");
  };

  return (
    <>
 
        <Container fluid className="mt-4">
          <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
            <Form onSubmit={onSubmitForm}>
              <div className="form-group">
                <Label>Titre du parcours de formation*</Label>
                <Input
                  value={title}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        title: "¨Veuillez attribuer un titre",
                      });
                    } else {
                      setErrors({ ...errors, title: "" });
                    }
                    setTitle(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.title ? "border-danger" : null
                  }`}
                  // placeholder="Example: accountant"
                />
                {errors.title && <p className="text-red">{errors.title}</p>}
              </div>


              <div className="form-group">
                <Label>Description*</Label>
                <p>Une description détaillée </p>
                <textarea
                  value={description}
                  className={`form-control ${
                    errors.description ? "border-danger" : null
                  }`}
                  rows="5"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        description: "¨Please provide a description",
                      });
                    } else {
                      setErrors({ ...errors, description: "" });
                    }
                    setDiscription(e.target.value);
                  }}
                />
                {errors.description && (
                  <p className="text-red">{errors.description}</p>
                )}
              </div>


              <div className="form-group">
                <Label>Etape 1: Description</Label>
                <Input
                  value={step_1_Description}
                  onChange={(e) => {
                    set_step_1_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 1: URL</Label>
                <Input
                  value={step_1_URL}
                  onChange={(e) => {
                    set_step_1_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>

              <div className="form-group">
                <Label>Etape 2: Description</Label>
                <Input
                  value={step_2_Description}
                  onChange={(e) => {
                    set_step_2_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 2: URL</Label>
                <Input
                  value={step_2_URL}
                  onChange={(e) => {
                    set_step_2_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>


              <div className="form-group">
                <Label>Etape 3: Description</Label>
                <Input
                  value={step_3_Description}
                  onChange={(e) => {
                    set_step_3_Description(e.target.value);
                  }}
                  type="textarea"
                  rows="5"
                  // placeholder="Example: accountant"
                />
              </div>
              <div className="form-group">
                <Label>Etape 3: URL</Label>
                <Input
                  value={step_3_URL}
                  onChange={(e) => {
                    set_step_3_URL(e.target.value);
                  }}
                  type="text"
                  // placeholder="Example: accountant"
                />
              </div>
            </Form>

            {errors.active && <p className="text-red">{errors.active}</p>}
            {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
            <div className="flex justify-center mt-4 space-x-2">
              <Button
                type="button"
                onClick={createPlaybookFunction}
                className="px-5 py-2  bg-green-400 text-white  hover:border-green-500 hover:border-[1px]"
                color="primary"
              >
                Créer le parcours de formation
              </Button>
            </div>
          </Card>
        </Container>


    
    </>
  );
}

export default CreateLearningPath;
