import React, { useEffect, useState } from "react";
import Header from "../Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import { getCompanyUsersForTraningsTracking } from "../../network/ApiAxios";
import EditUser from "components/employer/editUser";

const UsersTableForTrainingsTracking = () => {
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userID, setUserID] = useState("");

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

  
  useEffect(() => {
    const runAsync = async () => {
      // console.log("11111111111111: UsersTableForTrainingsTracking: runAsync: ");
      // console.log("11111111111111: UsersTableForTrainingsTracking: user: ", user);
      // console.log("11111111111111: UsersTableForTrainingsTracking: user.employerCampanyID: ", user.employerCampanyID);
      if ((user.employerCampanyID !== null) && (user.employerCampanyID !== ""))
      {
        const response = await getCompanyUsersForTraningsTracking(user.employerCampanyID);
        const { data } = response;
        // console.log("11111111111111: UsersTableForTrainingsTracking: ");
        // console.log("11111111111111: UsersTableForTrainingsTracking: data: ", data);
        if (data && data.length && data.length !== null && data.length !== 0) {
          setUsers(data);
          // console.log("users table: users", users);
        }
  
      }
      
    };
    runAsync();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Users</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Rôle</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.email}>
                      <th scope="row">{user.name}</th>
                      <td>{user.email}</td>
                      <td>{user.accessLevel ? user.accessLevel : "Standard"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  );
};

export default UsersTableForTrainingsTracking;
