import SharedProfileCandidateComp from 'components/candidat/SharefProfileCandidateComp'
import PageSpace from 'components/styles/pageSpace'
import HomepageLayout from 'layouts/HomepageLayout'
import React from 'react'

const FrSharedProfile = ({match}) => {
    const {id} = match.params
    return (
      <>
      <HomepageLayout />
      <PageSpace />
      <SharedProfileCandidateComp id={id} location={"France"} />
      </>
    )
}

export default FrSharedProfile