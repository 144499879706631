import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import HomeNavbar from "components/Navbars/HomeNavbar";
import AnonymousSidebar from "components/Sidebar/AnonymousSidebar";
import Header from "components/Headers/Header";


const HomepageV2Layout = () => {
  


    return (
      <>
        <AnonymousSidebar/>
        <Header/>
      </>
    );
  }

export default HomepageV2Layout;
