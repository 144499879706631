import {

    XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";

import {
    Container,
    Row,
    Col,
    Button,
    Input,
    Modal,
    ModalBody,
    Table,

} from "reactstrap";
import "reactjs-popup/dist/index.css";
import config from "config";
import EditJobAcceptanceCriteria from "./editJobAcceptanceCriteria";
import ConfigureSelect from "components/other/ConfigureSelect";
import ConfigureAcceptianceCriteria from "./ConfigureAcceptianceCriteria";
import { useTranslation } from "react-i18next";



const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});

function AcceptanceCriteria({ jobId }) {
    const { t, i18n } = useTranslation();

    const [AcceptanceCriteriaToModify, setAcceptanceCriteriaToModify] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [questionAddingModalOpen, setQuestionAddingModalOpen] = useState(false);

    const [JobAcceptanceCriteria, setAcceptanceCriteria] = useState("");

    const [description, setDescription] = useState("");
    const [isUpdated, setIsUpdated] = useState(false)


    useEffect(() => {
        GetAcceptanceCriteriaByjobID();
    }, [isUpdated]);

    const AddAcceptanceCriteria = async () => {
        const response = await instance.post(`JobAcceptanceCriteria/createNewAcceptanceCriteria`, {
            description: description,
            jobID: jobId
        })
        const { data } = response;
        if (data.success) {
            console.log(data);
            window.location.reload();

        } else {
            console.log("err");

        }
    }
    const GetAcceptanceCriteriaByjobID = async () => {
        const response = await instance.get(`JobAcceptanceCriteria/getAcceptanceCriteriaByjobID/${jobId}`)
        const { data } = response;
        if (data.success) {
            setAcceptanceCriteria(response.data.AcceptanceCriteria);

            console.log(data);
        } else {
            console.log("err");

        }
    }


    const removeAcceptanceCriteria = async (ac) => {


        await instance
            .delete(`JobAcceptanceCriteria/DeleteAcceptanceCriteria/${ac._id}`)

            .then((res) => {
                console.log("Deletion successful");
                window.location.reload();


            })
            .catch((err) => console.log(err));
    };


    return (
        <Container>
            <Row className="mb-4 mt-4">
                <Col lg="5" className="d-flex align-items-center">
                    <h3>{t('AccCriteriaKey1')} {t('AcceptanceCriteria')}</h3>
                </Col>
                <Col lg="5" className="d-flex align-items-center">
                    <Input value={description}
                        onChange={e => setDescription(e.target.value)} />
                </Col>
            </Row>

            <Container>
                <Row className="justify-content-center">
                    <Col lg="3">
                        <Button style={{
                            backgroundColor: "#404954",
                            borderColor: "#404954",
                            color: "white",
                        }} onClick={AddAcceptanceCriteria}>{t('OutFrSidebarKey14')}</Button>
                    </Col>
                </Row>
            </Container>
            <Table className="mt-5">
                {!JobAcceptanceCriteria && (
                    <h3>{t('AccCriteriaKey2')}!</h3>
                )}
                <thead className="justify-content-center">
                    <tr >
                        <th className="text-center"><h4>{t('AccCriteriaKey3')}</h4></th>
                        {/* <th><ConfigureAcceptianceCriteria
                         JobAcceptanceCriteria={JobAcceptanceCriteria} 
                         jobId={jobId}
                         />
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {JobAcceptanceCriteria &&
                        JobAcceptanceCriteria.map((AcceptanceCriteria) => (
                            <div
                                key={AcceptanceCriteria._id}
                                className="flex items-center py-2 border-t border-b first:border-t-0 last:border-b-0 justify-between text-black"
                            >
                                <li>{AcceptanceCriteria.description}</li>

                                <div className="flex items-center space-x-2 text-gray-500">
                                    <Button
                                        style={{
                                            backgroundColor: "#404954",
                                            borderColor: "#404954",
                                            color: "white",
                                        }}
                                        className=""
                                        color=""
                                        type="button"
                                        size="sm"
                                        onClick={() => {
                                            setAcceptanceCriteriaToModify(AcceptanceCriteria._id);
                                            setModalOpen(!modalOpen);
                                        }}
                                    >
                                        {t('OfferDesKey4')}
                                    </Button>


                                    <XIcon
                                        className="h-6 cursor-pointer text-red-600"
                                        onClick={removeAcceptanceCriteria.bind(this, AcceptanceCriteria)}
                                    />
                                </div>
                            </div>
                        ))}
                </tbody>

                <Modal
                    toggle={() => setModalOpen(!modalOpen)}
                    isOpen={modalOpen}
                    size="lg">


                    <ModalBody>
                        <EditJobAcceptanceCriteria
                            AcceptanceCriteriaId={AcceptanceCriteriaToModify}
                            jobID={jobId}
                        />
                    </ModalBody>

                </Modal>
            <Table className='mt-5'>
            <thead className="justify-content-center">
                    <tr >
                        <th className="text-center"><h4>{t('AccCriteriaKey4')}</h4></th>
                    </tr>
                </thead>
            </Table>

            </Table>
            {/* Config auto */}
            <ConfigureAcceptianceCriteria
                JobAcceptanceCriteria={JobAcceptanceCriteria}
                jobId={jobId}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                t={t}
            />



        </Container>

    );
}

export default AcceptanceCriteria;
