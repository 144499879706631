import React from "react";
import HomepageLayout from "layouts/HomepageLayout";
import { Card, CardBody, Col } from "reactstrap";
import RegisterEmployer from "components/other/RegisterEmployer";


const SignUpEmployerView = () => {



    return (
        <>
            <HomepageLayout />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 50,
                }}

            >
                <Col lg="8" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            {/* Recruteur */}
                            <RegisterEmployer />
                        </CardBody>
                    </Card>
                </Col>
            </div>


        </>
    );
};

export default SignUpEmployerView;
