import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import OutFrFreelanceCandidateGeneralInterviews from "components/outFrCandidatSearchCdiFr/outFrFreelanceCandidateGeneralInterviews";
import { Container } from "reactstrap";
import React from "react";




function OutFrFreelanceGeneralInterviewsView() {


  
  return (
    <>
      <CandidateLayoutV3 />

      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <h2>Projet: Je suis hors de la France et je cherche un freelance en France</h2>
          <h3>Passer au moins un entretien</h3>
      </Container>    

      <OutFrFreelanceCandidateGeneralInterviews />

      
      
    </>
  );
};

export default OutFrFreelanceGeneralInterviewsView;
