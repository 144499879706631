import { ColorSwatchIcon } from '@heroicons/react/outline';
import { getopenAiVideoEvaluation } from 'network/ApiAxios';
import { fetchGeneralParamByLabel } from 'network/ApiAxios'
import React, { useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'reactstrap'
import {Accordion,AccordionSummary,AccordionDetails,Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const EvaluateCandidateVideoResponseForRecruiter = ({ currentQuestion, currentQuestionType, videoUrl, videoId, openAIResponse ,setOpenAIResponse}) => {
    const [isloading, setIsLoading] = useState(false);
    console.log('openAIResponse', openAIResponse)

    const EvaluateCandidateVideoResponse = async () => {
        setIsLoading(true);
        try {
            const { data } = await fetchGeneralParamByLabel('Assitant_id_verify_video');
            //console.log('body open AI', { question: currentQuestion, videoPath: videoUrl, videoId })
            const resp = await getopenAiVideoEvaluation({ question: currentQuestion, videoPath: videoUrl, videoId }, data[0].Parameter)
            resp.data && setOpenAIResponse(resp.data?.text?.value)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    return (
        <Container>
            {isloading ? <div className='w-full d-flex justify-content-center'>
                <Spinner
                    color="primary"
                    type="grow"
                >
                    Loading...
                </Spinner>
            </div>
                :
                currentQuestionType === "Video" &&
                <>
                    <Row className='w-full'>
                        <Col sm='6'>
                        </Col>
                        <Col sm='6'>
                            <Button
                                disabled={openAIResponse}
                                hidden={openAIResponse}
                                onClick={EvaluateCandidateVideoResponse}
                            //color='light' 
                            //style={{ float: 'right' }}
                            >
                                Evaluer la vidéo par IA
                            </Button>
                        </Col>

                    </Row>
                    {openAIResponse && <Row>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Voir la réponse</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {openAIResponse}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Row>}
                </>
            }

        </Container>
    )
}

export default EvaluateCandidateVideoResponseForRecruiter