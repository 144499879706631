import Header from 'components/Headers/Header'
import HomeNavbar from 'components/Navbars/HomeNavbar'
import SearchTalent from 'components/other/SearchTalent'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import qs from "query-string";
import { useLocation,useHistory } from 'react-router-dom'
import { getTalentedCandidatesAccordingToProfileTypeAndLocation } from 'network/ApiAxios'
import OneCardForTalentedCandidateForEmployer from 'components/employer/OneCardForTalentedCandidateForEmployer'
import ReactPaginate from 'react-paginate'
import { getTalentedCandidatesAccordingToProfileTypeAndLocationNumber } from 'network/ApiAxios'

const TalentedCandidatesList = () => {
    const [pageNb, setPageNb] = useState(1);
    const [limit, setLimit] = useState(24);
    const [users, setUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [usersNb, setUsersNb] = useState(null)
    
    const history = useHistory();
    const location = useLocation();
    const query = qs.parse(location.search);
    console.log('query', query)
    const ProfileType = query?.profileType;
    const Location = query?.location;

    const fetchTalentedCandidatesAccordingToProfileTypeAndLocationNumber = async(ProfileType, Location)=>{
        try {
            const { data } = await getTalentedCandidatesAccordingToProfileTypeAndLocationNumber(ProfileType, Location)
            setUsersNb(data.cnt)
            setPageCount(Math.ceil(data.cnt / limit))
        } catch (error) {
            console.log(error)
        }
    }

    const HandleClick= (ProfileType, Location)=>{
        setPageNb(1)
        fetchTalentedCandidatesAccordingToProfileTypeAndLocationNumber(ProfileType, Location);
        fetchTalentedCandidatesAccordingToProfileTypeAndLocation(ProfileType, Location)
        history.push(`/home/talentedCandidatesLists?profileType=${ProfileType}&location=${Location}`)
    }

    const fetchTalentedCandidatesAccordingToProfileTypeAndLocation = async (ProfileType, Location) => {
        try {
            const { data } = await getTalentedCandidatesAccordingToProfileTypeAndLocation(pageNb, limit, ProfileType, Location)
            setUsers(data)
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageClick = (e) => {
        setPageNb(e.selected + 1);
    };

    useEffect(()=>{
        fetchTalentedCandidatesAccordingToProfileTypeAndLocationNumber(ProfileType, Location)
    },[])

    useEffect(() => {
        fetchTalentedCandidatesAccordingToProfileTypeAndLocation(ProfileType, Location)
    }, [pageNb])


    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <div className="main-content">
                <HomeNavbar />
                <div className="homeHeader bg-grad pb-1 pt-4 pt-md-1">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <Header />
                {/* Page content */}
                <SearchTalent
                    ProfileType={ProfileType}
                    Location={Location}
                    onClick={HandleClick}
                />
                <Row className='justify-content-center mt-5'>
                    <Card className='bg-transparent shadow w-75'>
                        <CardHeader>
                            <h2>{usersNb} candidats trouvés </h2>
                        </CardHeader>
                        <CardBody>
                            <div /*className='d-flex justify-content-center flex-wrap'*/ style={{gap:'20px', display:'grid', gridTemplateColumns: "auto auto auto"}}>
                            {users?.map((user,i) => <OneCardForTalentedCandidateForEmployer
                                //user={user}
                                key={i}
                                id={user._id}
                                location={user?.location}
                            />)}
                            </div>
                        </CardBody>
                        <CardFooter className="d-flex justify-content-center">
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </CardFooter>
                    </Card>


                </Row>
            </div>
        </div>
    )
}

export default TalentedCandidatesList