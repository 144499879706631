import MyAcceptedAccessRequestsDemands from 'components/employer/MyAcceptedAccessRequestsDemands'
import EmployerLayout from 'layouts/EmployerLayout'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const MyAcceptedAccessRequests = () => {
  return (
    <>
    <EmployerLayout />
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
          <MyAcceptedAccessRequestsDemands/>
          </Col>
        </Row>
      </Container> 
    </>
  )
}

export default MyAcceptedAccessRequests