import React, { useState } from "react";

// reactstrap components
import {
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { createTrainingEmployeeByEmailAddress } from "../../network/ApiAxios";
import config from "config";
import Select from "react-select";
const AddUserForTrainingsTracking = () => {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [accountConfirmation, setAccountConfirmation]= useState(true)
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);

  const addUser = async () => {
    if (!(email )) {
      setError(
        "Make sure to fill all the inputs and agree to the privacy policy"
      );
      return;
    }


    const response = await createTrainingEmployeeByEmailAddress(email, user.employerCampanyID);
    const { data } = response;
    if (!data.success) {
      setError(data.msg);
      return;
    }
    // if (config.DEMO) {
    //   setToastMessage(
    //     "This is a demo, so we will not send you an email. Instead, click on this link to verify your account:"
    //   );
    //   setUserID(data.userID);
    // }
    window.location.reload()
    setError("");
    setEmail("");
    setShowToast(true);
  };

  return (
    <>
      
      <Col lg="6" md="8" className="mx-auto mb-4 mt-4">
        <Card className="bg-secondary shadow border-0">
        <div className="flex flex-col  py-3 mx-5 mt-4 ">
              <h3>Suivre les formation de ce collaborateur: </h3>
            </div>  
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">

              <FormGroup>
              <Label>Email</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>



              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
             
              <div className="text-center">
                <Button
                  onClick={addUser}
                  className="mt-4"
                  style={{backgroundColor:"#404954", borderColor:"#404954", color:"white"}}
                  type="button"
                >
                  Ajouter cet utilisateur
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default AddUserForTrainingsTracking;
