import EmployerLayout from "layouts/EmployerLayout";
import EmployerAccessProfiles from "components/recruteur/EmployerAccessProfiles";
import React from "react";
import { Box } from "@mui/material";

function TalentsProfiles() {
  return (
    <>
      <EmployerLayout />
      <Box 
            sx={
                { 
                ml: 32,
                p: "30px",
                mb: 10
                }
                }
            display="flex"
            alignItems="center"
            justifyContent="center"
            >
        <EmployerAccessProfiles />
      </Box>
      
    </>
  );
}

export default TalentsProfiles;