import { fetchActiveCodingQ } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row, Spinner, Table } from 'reactstrap'
import OneCodingQuestion from './OneCodingQuestion'
import { AppendNewCodingQuestionToJob } from 'network/ApiAxios'

const AddCodingQuestion = ({ jobID, toggle, fct, questions }) => {
    const [activeCodingQ, setActiveCodingQ] = useState([])
    const [appendedCQToJob, setAppendedCQToJob] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getActiveCodingQuestions = async () => {
        try {
            const { data } = await fetchActiveCodingQ()
            setActiveCodingQ(data)
        } catch (error) {
            console.log(error)
        }
    }

    const AppendOneCQToJob = (CQ) => {
        appendedCQToJob.push(CQ)
        //console.log('appendedCQToJob',appendedCQToJob)
    }

    const AddNewCQToJob = async () => {
        setIsLoading(true)
        try {
            await AppendNewCodingQuestionToJob(jobID, { newQuestionIDs: appendedCQToJob })
            await fct()
            toggle()
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getActiveCodingQuestions()
    }, [])
    return (
        <Container>
            <Row>
                <Card className="bg-transparent w-full">
                    <CardHeader className="bg-transparent  w-full">
                        liste des questions de type code
                    </CardHeader>
                    <CardBody className='p-3 bg-body rounded shadow border-0 d-flex flex-column justify-content-center align-items-center '>
                        <Table responsive
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        titre de la question
                                    </th>
                                    <th>
                                        Domaine
                                    </th>
                                    <th>
                                        Niveau
                                    </th>
                                    <th>
                                        Durée
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {activeCodingQ?.map((item, i) => <OneCodingQuestion item={item} key={i} AppendOneCQToJob={AppendOneCQToJob} questions={questions} />)}
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter className="bg-transparent  w-full d-flex justify-content-center">
                        <Button onClick={AddNewCQToJob} color='primary'>Ajouter {isLoading && <Spinner color="secondary">
                            Loading...
                        </Spinner>}</Button>
                    </CardFooter>
                </Card>
            </Row>

        </Container>
    )
}

export default AddCodingQuestion