import React from "react";
import { useHistory, Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  NavItem,
  NavLink,
} from "reactstrap";
import { logout } from "../../network/ApiAxios";
import config from "config";
const AdminNavbar = (props) => {
  const history = useHistory();
  let username = JSON.parse(localStorage.getItem("user")).name;
  const user = JSON.parse(localStorage.getItem("user"));
  const logOut = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await logout(token);
      const { data } = response;
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        props.history.push("/auth/login");
      }
    }
  };

  return (
    <>
      <Navbar
        className="navbar-top  navbar-light "
        expand="md"
        id="navbar-main"
      >
        <Container fluid>
          <div
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {/* <img
              src={
                require("assets/img/brand/2022-01-04-ConvertImage.png").default
              }
              style={{
                width: "80px",
                height: "40px",

                objectFit: "cover",
              }}
              alt="Responsive image"
            /> */}
          </div>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <NavItem>
              <NavLink
                className="mb-0 nav-link cursor-pointer mr-3"
                href="#pablo"
                // activeClassName="active"
                onClick={() => {
                  if (user.role === "employer") {
                    history.push("/admin/jobs");
                  } else {
                    history.push("/candidat/jobs");
                  }
                }}
              >
                Offres
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {user.role == "employer" ? (
                      user.image ? (
                        <img
                          alt="..."
                          src={`${config.Data_URL}${user.image}`}
                        />
                      ) : (
                        <img
                          alt="..."
                          src={require("assets/img/brand/default.png").default}
                        />
                      )
                    ) : user.image ? (
                      <img alt="..." src={`${config.Data_URL}${user.image}`} />
                    ) : (
                      <img
                        alt="..."
                        src={require("assets/img/brand/default.png").default}
                      />
                    )}
                  </span>
                  <Media className="ml-0 d-none d-lg-block">
                    <span className="mb-0 nav-link">{username}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow">
                {/* <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Welcome!</h6>
                                </DropdownItem> */}
                <DropdownItem
                  to={
                    JSON.parse(localStorage.getItem("user")).role == "employer"
                      ? "/admin/user-profile"
                      : "/candidat/user-profile"
                  }
                  tag={Link}
                >
                  <i className="ni ni-single-02" />
                  <span>Profil</span>
                </DropdownItem>
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-settings-gear-65"/>
                                    <span>Settings</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-calendar-grid-58"/>
                                    <span>Activity</span>
                                </DropdownItem>
                                <DropdownItem to="/admin/user-profile" tag={Link}>
                                    <i className="ni ni-support-16"/>
                                    <span>Support</span>
                                </DropdownItem> */}
                {/* <DropdownItem divider/> */}
                <DropdownItem href="#pablo" onClick={logOut}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
