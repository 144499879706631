import React from 'react'
import { Alert } from 'reactstrap'

const AlertMessage = ({message}) => {
  return (
  
    <Alert className='text-center' color='light'>{message}</Alert>
  )
}

export default AlertMessage