import React, { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import { getCompanyUsers } from "../../network/ApiAxios";
import EditUser from "components/employer/editUser";
import { useTranslation } from "react-i18next";

const UsersTable = () => {
  const { t, i18n } = useTranslation();

  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userID, setUserID] = useState("");

  const toggle = () => setModalOpen(!modalOpen);

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

   const runAsync = async () => {
    const response = await getCompanyUsers(user.company);
    const { data } = response;
    // console.log("users table: user.company", user.company);
    // console.log("users table: data", data);
    // console.log("users table: data.users", data.users);
    if (data.success) {
      setUsers(data.users);
      // console.log("users table: users", users);
    }
  };

  
  useEffect(() => {
    runAsync();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{t('User')}s</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('lastName')}</th>
                    <th scope="col">Email</th>
                    <th scope="col">{t('USERManagementKEY3')}</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.email}>
                      <th scope="row">{user.name}</th>
                      <td>{user.email}</td>
                      <td>{user.accessLevel ? user.accessLevel : "Standard"}</td>
                      <td>
                        <Button
                          style={{
                            backgroundColor: "#404954",
                            borderColor: "#404954",
                            color: "white",
                          }}
                          className=""
                          color=""
                          type="button"
                          size="sm"
                          onClick={() => {
                            setUserID(user._id);  
                            setModalOpen(!modalOpen);
                        }}
                        >
                          {t('OfferDesKey4')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Modal 
        toggle={() => setModalOpen(!modalOpen)} 
        isOpen={modalOpen}
        size="lg">
          <div className=" modal-header">
            <h4 className=" modal-title" id="exampleModalLabel">
              {t('OfferDesKey4')} {t('User')}
            </h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
           <ModalBody>
             <EditUser userID={userID} toggle={toggle} runAsync={runAsync} />
             </ModalBody>               
          
        </Modal>
      </Container>
    </>
  );
};

export default UsersTable;
