import PageTitleStyle from 'components/styles/PageTitleStyle';
import PageSpace from 'components/styles/pageSpace';
import { fetchOneTraining } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import OneSectionForTraining from './OneSectionForTraining';

const TrainingDetailsForCandidates = ({ id }) => {
    const [oneTraining, setOneTraining] = useState({});

    const GetOneTraining = async () => {
        try {
            const { data } = await fetchOneTraining(id)
            data && setOneTraining(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetOneTraining()
    }, [])
    return (

        <Container>
            <PageSpace />
            <PageTitleStyle
                title={oneTraining?.label}
            >

            </PageTitleStyle>

            <Row className="justify-content-center border-0 py-2" style={{ width: "100%" }}>
                <Col xs="2" >
                </Col>
                <Col xs="10">
                    <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
                        <CardBody>
                            {oneTraining?.sections?.map(section=> <OneSectionForTraining
                                key={section._id}
                                section={section}
                            
                            />)}

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default TrainingDetailsForCandidates