import React from "react";

// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";
import {useHistory} from "react-router-dom";
import Header from "./Header";

const UserHeader = () => {

    const username = JSON.parse(localStorage.getItem("user")).name;
    const history = useHistory();

    return (
        <>
        <Header/>
            <div 
                className=" pb-8 pt-5 pt-lg-6 d-flex align-items-center"
                style={{
                    minHeight: "300px",
                    backgroundImage:
                        "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top"
                }}
            >
                {/* Mask */}
                {/* <span className="mask bg-gradient-default opacity-8"/> */}
                {/* Header container */}
                <Container className="d-flex align-items-center" fluid>
                    <Row  style={{marginLeft: ""}}>
                        <Col lg="7" md="50">
                            <h1 className="2 page   page-t"> {username}</h1>
                            
                            <Button
                                // style={{
                                //     backgroundColor: "#404954",
                                //     color: "white",
                                //   }}
                                className="btn-secondaryG"
                                onClick={() => history.push('/admin/edit-profile')}
                            >
                                Modifier le profil
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default UserHeader;
