
import PageSpace from "components/styles/pageSpace";
import CvForFrCandidateUser from "components/employer/cvForFrCandidateUser";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
    Container,
    Button,
    Col,
    Card,
    CardImg,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    CardHeader,
    Table,
    CardBody,
    Form,
    Input,
} from "reactstrap";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
import SendInvitationForOneQualifiedCandidate from "components/employer/SendInvitationForOneQualifiedCandidate";
import CandidateGeneralPresentation from "components/employer/CandidateGeneralPresentation";
import { MicrophoneIcon } from "@heroicons/react/solid";
import CandidateProfileForRecruiter from "components/candidat/CandidateProfileForRecruiter";
import { getProfileFrUserByID } from "network/ApiAxios";
import { useTranslation } from "react-i18next";
import JobApplicationTitle from "components/employer/jobApplicationTitle";
import { Grid } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});
function UserProfileForRecruters(props) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
    // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
    const [candidateUser, setCandidateUser] = useState({});
    //const [videos, setVideos] = useState(null);
    //const [candidat, setCandidat] = useState("");
    //const [hasDrive, setHasdrive] = useState(false);
    //const [applicationPipeline, setApplicationPipeline] = useState("");

    const [openTab, setOpenTab] = useState(1);

    const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
    //const [jobTitle, setJobTitle] = useState("");
    //const [job, setJob] = useState({});
    const [profileC, setProfileC] = useState(null)

    const user = JSON.parse(localStorage.getItem("user"));


    const fetchAllJobApplicationsForThisUser = async () => {
        instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
            if (resp.data.length) {
                setAllJobApplicationsForThisUser(resp.data);
            }
        });
    };




    const getCandidateUser = () => {
        instance.get(`users/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
            // setParticipants(resp.data.job);
            // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ");
            // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ", resp.data);
            setCandidateUser(resp.data);
        });


    };

    const getProfileCandidate = async () => {
        try {
            const { data } = await getProfileFrUserByID(props.candidateUserID);
            setProfileC(data)
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getCandidateUser();
        getProfileCandidate()
        fetchAllJobApplicationsForThisUser();

    }, []);


    return (
        <>
            <div
                className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
                style={{ marginTop: "" }}
            >
                <div className="flex items-center  ">
                    <CardImg
                        variant=""
                        className="rounded-circle"
                        style={{
                            width: "80px",
                            height: "80px",
                            alignSelf: "center",
                            objectFit: "cover",
                        }}
                        alt="Responsive image"
                        src={"assets/img/brand/500.png"}
                        onError={(e) => {
                            e.target.src =
                                // require("assets/img/brand/500.png").default;
                                require("assets/img/brand/500.png").default;

                        }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="pt-2 font-bold    ">
                        <span
                            className="pt-2 left-0 font-bold "
                            style={{ display: "inline-flex" }}
                        >
                            {t('firstName')}:&nbsp;<h4> {candidateUser.name}</h4>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            className="pt-2 font-bold   "
                            style={{ display: "inline-flex" }}
                        >
                            {t('lastName')}:&nbsp; <h4>{candidateUser.lastName} </h4>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            className="pt-2 right-0 font-bold   "
                            style={{ display: "inline-flex" }}
                        >
                            Email:&nbsp; <h4>{candidateUser.email}</h4>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                            className="pt-2 right-0 font-bold   "
                            style={{ display: "inline-flex" }}
                        >
                            {t('Country')}:&nbsp; <h4>{candidateUser.location}</h4>
                        </span>
                    </div>
                </div>
                <Row>
                    <Col className="order-xl-1">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Table className="GatTable">
                                    <tbody>
                                        <tr>

                                            <>
                                                <td
                                                    className={
                                                        (openTab === 1)
                                                            ? "tdActive "
                                                            : "td "
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(1);
                                                    }}
                                                    data-toggle="tab"
                                                    href="#link1"
                                                    role="tablist"
                                                    //onClick={() => history.push('/admin/edit-profile')}
                                                    size="sm"
                                                >
                                                    <i className="ni ni-single-copy-04" aria-hidden="true" />{" "}
                                                    CV
                                                </td>

                                                <td
                                                    className={
                                                        (openTab === 2)
                                                            ? "tdActive "
                                                            : "td "
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(2);
                                                    }}
                                                    data-toggle="tab"
                                                    href="#link2"
                                                    role="tablist"
                                                    size="sm"
                                                >
                                                    <i className="fa fa-users" aria-hidden="true" />{" "}
                                                    {t('Profile')}
                                                </td>

                                                <td
                                                    className={
                                                        (openTab === 3)
                                                            ? "tdActive "
                                                            : "td "
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(3);
                                                    }}
                                                    data-toggle="tab"
                                                    href="#link3"
                                                    role="tablist"
                                                    size="sm"
                                                >
                                                    <div className="row">
                                                        <MicrophoneIcon style={{ height: '1.2rem' }} />{" "}
                                                        {t('GeneralPresentation')}
                                                    </div>
                                                </td>
                                                <td
                                                    className={
                                                        (openTab === 4)
                                                            ? "tdActive "
                                                            : "td "
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(4);
                                                    }}
                                                    data-toggle="tab"
                                                    href="#link4"
                                                    role="tablist"
                                                    size="sm"
                                                >
                                                    <i className="ni ni-briefcase-24" />{" "}
                                                    {t('Interview')}s
                                                </td>

                                            </>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardHeader>
                            {openTab === 1 &&
                                <Container fluid>
                                    <CardBody
                                        className={openTab === 1 ? "block" : "hidden"}
                                        id="link1"
                                    >
                                        <CvForFrCandidateUser
                                            candidateUserID={props.candidateUserID}
                                        />

                                    </CardBody>

                                </Container>

                            }

                            {openTab === 2 &&
                                <CardBody
                                    className={openTab === 2 ? "block" : "hidden"}
                                    id="link2"
                                >
                                    <CandidateProfileForRecruiter id={props.candidateUserID} profileC={profileC} getProfileCandidate={getProfileCandidate} user={user} />
                                </CardBody>
                            }
                            {openTab === 3 &&
                                <CardBody
                                    className={openTab === 3 ? "block" : "hidden"}
                                    id="link3"
                                >
                                    <CandidateGeneralPresentation candidateUserID={props.candidateUserID} />
                                </CardBody>
                            }

                            {openTab === 4 &&
                                <Row
                                    className={openTab === 4 ? "block" : "hidden"}
                                    id="link4"
                                >

                                    <PageSpace />
                                    <Row className="pl-lg-4">
                                        <Col className='pb-3'>
                                            <form>
                                                <h4 className=" mb-4">{t('Interview')}s</h4>
                                                <Grid item xs={12}>
                                                    <label className="px-lg-3">{t('NoteKEY7')}:</label>

                                                </Grid>

                                                {allJobApplicationsForThisUser &&
                                                    allJobApplicationsForThisUser.map((oneJobApplication) => (

                                                        <JobApplicationTitle
                                                            oneJobApplicationID={oneJobApplication._id}
                                                            jobID={oneJobApplication.jobID}
                                                            key={oneJobApplication._id}
                                                        />

                                                    ))}


                                            </form>
                                        </Col>

                                    </Row>


                                </Row>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default UserProfileForRecruters;
