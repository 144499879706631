import {

  Container,
  Row,

} from "reactstrap";

function PageSpace(props) {
  

  return (
    <>

    <Container fluid>
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
    </Container>





      

    </>
  );
}

export default PageSpace;
