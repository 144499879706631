import axios from "axios";
import React, { useEffect, useState } from "react";
// import ShowCandidat from "components/recruteur/ShowCandidat";


//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});





function JobApplicationOneCardEmployerV2( props ) {
  const [jobApplication, setJobApplication] = useState({});
  const [jobID, setJobID] = useState("");
  const [job, setJob] = useState({});
  const [jobApplicationUser, setJobApplicationUser] = useState({});
  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobApplicationCreatedAt, setJobApplicationCreatedAt] = useState("");
  const [jobApplicationUserLocation, setJobApplicationUserLocation] = useState("");


  const getJobApplication = () => {

    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdminV2 getJobApplication");
    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdminV2 getJobApplication job_Application_ID", job_Application_ID);
    instance.get(`jobApplication/getJobApplicationById/${props.job_Application_ID}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setJobApplication(resp.data);
      setJobID(resp.data.jobID);
      setJobApplicationCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 resp.data: ", resp.data);
      // console.log("1111111111111111111111111111 jobApplication: ", jobApplication);
      // console.log("1111111111111111111111111111 jobID: ", jobID);
  
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin: getJobApplication: resp.data : ", resp.data);
      
    });
  };

  const getJob = () => {
    if (jobID !== null && jobID !== "")
    {
      instance.get(`jobs/${jobID}`).then((resp) => {
        // setParticipants(resp.data.job);
        // console.log("00000000000000000000000000 JobApplicationsEmployerV3 getJob: resp.data : ", resp.data);
        setJob(resp.data.job);
      });
    }

  };

  const getJobApplicationUser = () => {
    // console.log("00000000000000000000000000 getJobApplicationUser  : ");
    // console.log("00000000000000000000000000 getJobApplicationUser  : jobApplicationUserID: ", jobApplicationUserID);
    // console.log("00000000000000000000000000 getJobApplicationUser : URL: ", `users/getprofilebyuserid/${jobApplicationUserID}`);
    if (jobApplicationUserID !== null && jobApplicationUserID !== "") 
    {
      instance.get(`users/getprofilebyuserid/${jobApplicationUserID}`).then((resp) => {
        // setParticipants(resp.data.job);
        // console.log("00000000000000000000000000 getJobApplicationUser: resp.data : ", resp.data);
        // console.log("00000000000000000000000000 getJobApplicationUser : resp.data.location : ", resp.data.location);
        setJobApplicationUser(resp.data);
        
        // setJobApplicationUserLocation(resp.data.location);
      });
    }

  };



  useEffect(() => {
    getJobApplication();
    getJob();
    getJobApplicationUser();
  }, [jobID, jobApplicationUserID]);


  return (
    <>
    <Container>
    
    <Row>
    <Col lg="3">
      <img
        src={`${config.Data_URL}${jobApplicationUser.userIDImage}`}
        className="rounded-circle"
        onError={(e) => {
          e.target.src =
            require("assets/img/brand/500.png").default;
        }}
        style={{
          width: "100%",
          height: "100%",

          //objectFit: "cover",
        }}
        //alt="Responsive image"
      />
    </Col>
    <Col lg="9">
      <Col lg="12" className="mb-2">
        <div>
          <span className="card-t">
            {jobApplication.prenom}{" "}
            {jobApplication.nom}{" "}
          </span>
        </div>
      </Col>
      <Row>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <MailIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp; <span>{jobApplication.email}</span>{" "}
            </div>
          </Col>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <MailIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp; <span>{job.title}</span>{" "}
            </div>
          </Col>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <LightBulbIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp;{" "}
              <span>
                {" "}
                Date:{jobApplicationCreatedAt.slice(0, 10)}
              </span>{" "}
            </div>
          </Col>
          <Col lg="12">
            <div
              className="text-sm"
              style={{ display: "inline-flex" }}
            >
              {" "}
              <LightBulbIcon
                className=""
                style={{ height: "1.2rem" }}
              />{" "}
              &nbsp;{" "}
              <span>
                {" "}
                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3") */}
                {/* console.log("55555555555555555555555555 jobApplicationsEmployerV3: participant?.interviewCandidat", participant?.interviewCandidat?.videos?.length) */}
                Nombre de vidéo: {jobApplication?.videos?.length}
              </span>{" "}
            </div>
          </Col>

      </Row>
    </Col>
    </Row>

      
      </Container>
    </>
  );
}

export default JobApplicationOneCardEmployerV2;
