import { BriefcaseIcon } from "@heroicons/react/outline";
import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";
import config from "config"
function CardJob({ job, goToJob }) {
  return (
    <Card
      className=" px-2 py-3   hover:scale-105 cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
      onClick={goToJob}
    >
       <Row>
      <Col lg="4">
            <img
              src={`${config.Data_URL}${job.image}`}
              onError={(e) => {
                e.target.src = require("assets/img/brand/500.png").default;
              }}
              style={{
                width: "200px",
                height: "200px",
                
                objectFit: "cover",
              }}
              alt="Responsive image"
            />
          </Col>
          <Col  lg="8">
      <div className="flex items-center space-x-3 mb-2">
        
        <div>
          <h3>{job.title}</h3>
          <span className="text-sm">{job.location}</span>
        </div>
      </div>
      <span>Manager: </span>

      <span>
        Status:{" "}
        <span
          className={`${
            job.status === "inactive" ? "text-red-600" : "text-green-600"
          }`}
        >
          {job.status}
        </span>
      </span>
      <span>
        Updated on:{" "}
        {`${job.updatedAt.slice(0, 10)} ${job.updatedAt.slice(11, 19)}`}
      </span>
      </Col>
      </Row>
    </Card>
  );
}

export default CardJob;
