import React, { useEffect, useState } from "react";
import {useHistory} from 'react-router-dom'
import axios from "axios";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  ChatAltIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

// reactstrap components
import {
  Container,
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import config from "config";
import Select from "react-select";
import { getAllActiveQuestionCatalogs } from "network/ApiAxios";
import OneQuestionCatalog from "./OneQuestionCatalog";
import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/outline";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function AddJobQuestionCatalog(props) {
  const [activequestionCatalogs, setActivequestionCatalogs] = useState([])

  const FetchAllActiveQuestionsCatalogs = async () => {
    const { data } = await getAllActiveQuestionCatalogs()
    //const blank = { label: '', value: null }
    setActivequestionCatalogs(data.map(el => el = { label: el.label, value: el.jobID }))
    //console.log('Qcatalogs', [...data.map(el => el = { label: el.label, value: el.jobID }), blank]);
  }

  const [job, setJob] = useState({});
  const [newQuestionCatalog1, setNewQuestionCatalog1] = useState("");
  const [newQuestionCatalog2, setNewQuestionCatalog2] = useState("");
  const [newQuestionCatalog3, setNewQuestionCatalog3] = useState("");
  const [newQuestionCatalog4, setNewQuestionCatalog4] = useState("");
  const [newQuestionCatalog5, setNewQuestionCatalog5] = useState("");
  const [newQuestionCatalog6, setNewQuestionCatalog6] = useState("");
  const [newQuestionCatalog7, setNewQuestionCatalog7] = useState("");

  const [msg, setMsg] = useState(null)

  const { t, i18n } = useTranslation();
  const history = useHistory()


  const updateQuestionCatalog = (fieldName, QC) => {
    setMsg(null)
    instance.put(`jobs/updateJobOneField/${props.jobID}/${fieldName}`, { "fieldValue": QC }).then((resp) => {
      if (resp.data.success) {
        setMsg(resp.data.message)
      }
    });
    //window.location.reload();

  };

  const fetchJobData = () => {
    instance
      .get(`jobs/${props.jobID}`)
      .then((res) => {
        setJob(res.data.job);
        if (res.data.job.questionCatalog1 || res.data.job.questionCatalog1 === null ) {
          setNewQuestionCatalog1(res.data.job.questionCatalog1)
        }
        if (res.data.job.questionCatalog2 || res.data.job.questionCatalog2 === null) {
          setNewQuestionCatalog2(res.data.job.questionCatalog2)
        }
        if (res.data.job.questionCatalog3 || res.data.job.questionCatalog3 === null) {
          setNewQuestionCatalog3(res.data.job.questionCatalog3)
        }
        if (res.data.job.questionCatalog4 || res.data.job.questionCatalog4 === null) {
          setNewQuestionCatalog4(res.data.job.questionCatalog4)
        }
        if (res.data.job.questionCatalog5 || res.data.job.questionCatalog5 === null) {
          setNewQuestionCatalog5(res.data.job.questionCatalog5)
        }
        if (res.data.job.questionCatalog6 || res.data.job.questionCatalog6 === null) {
          setNewQuestionCatalog6(res.data.job.questionCatalog6)
        }
        if (res.data.job.questionCatalog7 || res.data.job.questionCatalog7 === null) {
          setNewQuestionCatalog7(res.data.job.questionCatalog7)
        }
      })
      .catch((err) => console.log(err));
  };



  useEffect(() => {
    fetchJobData();
    FetchAllActiveQuestionsCatalogs();

  }, []);


  return (
    <>
      <Container style={{ width: "100%" }}>
        {activequestionCatalogs && <>
            
            <OneQuestionCatalog
                activequestionCatalogs={activequestionCatalogs}
                QC={newQuestionCatalog1}
                onChange={(e) => {
                  setNewQuestionCatalog1(e.value);
                  updateQuestionCatalog("questionCatalog1", e.value);
                }}
                idx={1}
                msg={msg}
                removeQC={()=>{
                  updateQuestionCatalog("questionCatalog1", null);
                  fetchJobData()
                }}
              />


          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog2}
            onChange={(e) => {
              setNewQuestionCatalog2(e.value);
              updateQuestionCatalog("questionCatalog2", e.value)
            }}
            msg={msg}
            idx={2}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog2", null);
              fetchJobData()
            }}
          />

          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog3}
            onChange={(e) => {
              setNewQuestionCatalog3(e.value);
              updateQuestionCatalog("questionCatalog3", e.value);
            }}
            msg={msg}
            idx={3}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog3", null);
              fetchJobData()
            }}
          />

          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog4}
            onChange={(e) => {
              setNewQuestionCatalog4(e.value);
              updateQuestionCatalog("questionCatalog4", e.value)
            }}
            msg={msg}
            idx={4}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog4", null);
              fetchJobData()
            }}
          />

          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog5}
            onChange={(e) => {
              setNewQuestionCatalog5(e.value);
              updateQuestionCatalog("questionCatalog5", e.value)
            }}
            msg={msg}
            idx={5}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog5", null);
              fetchJobData()
            }}
          />

          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog6}
            onChange={(e) => {
              setNewQuestionCatalog6(e.value);
              updateQuestionCatalog("questionCatalog6", e.value)
            }}
            msg={msg}
            idx={6}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog6", null);
              fetchJobData()
            }}
          />

          <OneQuestionCatalog
            activequestionCatalogs={activequestionCatalogs}
            QC={newQuestionCatalog7}
            onChange={(e) => {
              setNewQuestionCatalog7(e.value);
              updateQuestionCatalog("questionCatalog7", e.value)
            }}
            msg={msg}
            idx={7}
            removeQC={()=>{
              updateQuestionCatalog("questionCatalog7", null);
              fetchJobData()
            }}
          />
        </>

        }

        <Row>
          {msg && <Alert className='text-center' color='success'>{msg}</Alert>}
        </Row>
        {/* <Row className='w-full justify-content-center'>
          <Button
            style={{
              backgroundColor: "#404954",
              borderColor: "#404954",
              color: "white",
            }}
            onClick={HandleClose}>
            {t('Close')}
          </Button>
        </Row> */}


      </Container>
    </>
  );
};

export default AddJobQuestionCatalog;
