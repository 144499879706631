import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Row,
  Button, 
  Col, 
  Card, 
  CardImg, 
  FormGroup, 
  InputGroup, 
  InputGroupAddon, 
  CardHeader, 
  Table, 
  CardBody, 
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import config from "config";






const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function FormForFrCandidate( props) {
  const history = useHistory();

  

  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormWhatsappNb, setJobApplicationFormWhatsappNb] = useState("");
  const [jobApplicationFormWhatsappNbFiltered, setJobApplicationFormWhatsappNbFiltered] = useState("");


  
  
  const user = JSON.parse(localStorage.getItem("user"));






  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("FormForFrCandidate: fetchForm: props.candidateUserID: ", props.candidateUserID);
    
    instance.get(`/profiles/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormWhatsappNb(resp.data.whatsappnumber);
      let input = resp.data.whatsappnumber;
      let input_filtered = input.replace(/[|&;$%@"<>()+,]/g, "");
      setJobApplicationFormWhatsappNbFiltered(input_filtered);
      console.log("33333333333333333333: fetchForm: input_filtered", input_filtered);
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};








   useEffect(() => {
    fetchForm();
  
  }, []);







  return (
    <>
        <Row>

                {jobApplicationForm &&
                  <Form>
                    <h4 className=" mb-4">Formulaire pour ce candidat:</h4>
                    {jobApplicationForm.firstname &&
                      <p className=" mb-4">Prénom: {jobApplicationForm.firstname}</p>
                    }
                    {jobApplicationForm.lastname &&
                      <p className=" mb-4">Nom: {jobApplicationForm.lastname}</p>
                    }
                    {jobApplicationForm.gender &&
                      <p className=" mb-4">Genre: {jobApplicationForm.gender}</p>
                    }
                    {jobApplicationForm.familySituation &&
                      <p className=" mb-4">Situation familiale: {jobApplicationForm.familySituation}</p>
                    }
                    {jobApplicationForm.age &&
                      <p className=" mb-4">Age: {jobApplicationForm.age}</p>
                    }
                    {jobApplicationForm.email &&
                      <p className=" mb-4">Email: {jobApplicationForm.email}</p>
                    }
                    {jobApplicationForm.whatsappnumber &&
                    <>
                        <p className=" mb-4">Whatsapp: {jobApplicationForm.whatsappnumber}</p>
                        <Button
                          type="button"
                          className="mb-4 shadow-xl text-white"
                          style={{backgroundColor:"#11a0ef"}}
                          href={`https://wa.me/${jobApplicationFormWhatsappNbFiltered}`}
                          target="blank"
      
                          // onClick={() => setModalOpen(!modalOpen)}
                        >
                          URL sur Whatsapp
      
                        </Button>
                      </>
                    }

                    {jobApplicationForm.linkedin &&
                    <>
                      <p className=" mb-4">Linkedin: {jobApplicationForm.linkedin}</p>
                      
                        <Button
                        type="button"
                        className="mb-4 shadow-xl text-white"
                        style={{backgroundColor:"#11a0ef"}}
                        href={jobApplicationForm.linkedin}
                        target="blank"

                        // onClick={() => setModalOpen(!modalOpen)}
                      >
                        URL du profil Linkedin:

                      </Button>
                    </>
                    }
                    {!jobApplicationForm.linkedin &&
                      <p>Ce candidat n'a pas rensigné son profil Linkedin</p>
                    }

                    {jobApplicationForm.bacObtained &&
                      <p className=" mb-4">Bac Obtenu? {jobApplicationForm.bacObtained}</p>
                    }
                    {jobApplicationForm.diploma &&
                      <p className=" mb-4">Diplome: {jobApplicationForm.diploma}</p>
                    }
                    {jobApplicationForm.expertiseField &&
                      <p className=" mb-4">Domaine d'expertise: {jobApplicationForm.expertiseField}</p>
                    }
                    {jobApplicationForm.technicalProfileOrNot &&
                      <p className=" mb-4">Profil technique: {jobApplicationForm.technicalProfileOrNot}</p>
                    }
                    {jobApplicationForm.profileType &&
                      <p className=" mb-4">Type de profil: {jobApplicationForm.profileType}</p>
                    }
                    {jobApplicationForm.desiredProject &&
                      <p className=" mb-4">Projet désiré: {jobApplicationForm.desiredProject}</p>
                    }
                    {jobApplicationForm.experience &&
                      <p className=" mb-4">Expérience: {jobApplicationForm.experience}</p>
                    }
                    {jobApplicationForm.permanentContractExperience &&
                      <p className=" mb-4">Expérience en CDI: {jobApplicationForm.permanentContractExperience}</p>
                    }
                    {jobApplicationForm.topTechnologies &&
                      <p className=" mb-4">Top 3 des technologies: {jobApplicationForm.topTechnologies}</p>
                    }
                    {jobApplicationForm.certifications &&
                      <p className=" mb-4">Certifications: {jobApplicationForm.certifications}</p>
                    }
                    {jobApplicationForm.certificationLevel &&
                      <p className=" mb-4">Niveau de certification: {jobApplicationForm.certificationLevel}</p>
                    }
                    {jobApplicationForm.actualContract &&
                      <p className=" mb-4">Contrat actuel: {jobApplicationForm.actualContract}</p>
                    }
                    {jobApplicationForm.mobility &&
                      <p className=" mb-4">Mobilité: {jobApplicationForm.mobility}</p>
                    }
                    {jobApplicationForm.baf &&
                      <p className=" mb-4">Est ce que vous ne voulez pas travaillé dans le secteur Banques, Assurance et Finances: {jobApplicationForm.baf}</p>
                    }
                    {jobApplicationForm.title &&
                      <p className=" mb-4">Titre: {jobApplicationForm.title}</p>
                    }
                    {jobApplicationForm.village &&
                     <p className=" mb-4">Ville: : {jobApplicationForm.village}</p>
                    }
                    {jobApplicationForm.country &&
                      <p className=" mb-4">Pays: {jobApplicationForm.country}</p>
                    }
                    {jobApplicationForm.studyLevel &&
                      <p className=" mb-4">Niveau d'étude: {jobApplicationForm.studyLevel}</p>
                    }
                    {jobApplicationForm.university &&
                      <p className=" mb-4">Université: {jobApplicationForm.university}</p>
                    }
                    {jobApplicationForm.phonenumber &&
                      <p className=" mb-4">Téléphone: {jobApplicationForm.phonenumber}</p>
                    }
                    {jobApplicationForm.date &&
                      <p className=" mb-4">Date: {jobApplicationForm.date}</p>
                    }
                    
                  </Form>
                }
                {!jobApplicationForm &&
                  <p>Ce candidat n'a pas rempli son formulaire.</p>
                }
 
        </Row>
      
    </>
  );
}

export default FormForFrCandidate;
