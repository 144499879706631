import Header from 'components/Headers/Header'
import HomeNavbar from 'components/Navbars/HomeNavbar'
import JobsWithPayOrTJMCondition from 'components/candidat/JobsWithPayOrTJMCondition'
import { Card, Col, Container, Row } from 'reactstrap'

const JobOffersWithPayMoreThen50KView = () => {
    

    return (
        <div style={{width:'100%', overflow:'hidden'}}>
            <div className="main-content">
                <HomeNavbar /> 
                <div className="homeHeader bg-grad pb-1 pt-4 pt-md-1">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <Header />
                {/* Page content */}
                <JobsWithPayOrTJMCondition />
            </div>
        </div>
    )
}

export default JobOffersWithPayMoreThen50KView

