import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AcceptanceCriteriaOptions } from 'options';
import SelectOptionQuestion from './SelectOptionQuestion';
import { ConfigureOptionsQuestions } from 'network/ApiAxios';
import { UpdateConfiguredOptionsQuestions } from 'network/ApiAxios';

const ConfigureQuestionOptionsModal = ({ qs, AcceptanceCriteria, isUpdated, setIsUpdated,t }) => {
    const [modal, setModal] = useState(false);
    const [configuredOptions, setConfiguredOptions] = useState([])
    const [selectedValue1, setSelectedValue1] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 1")?.Status))
    );
    const [selectedValue2, setSelectedValue2] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 2")?.Status))
    )
    const [selectedValue3, setSelectedValue3] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 3")?.Status))
    )
    const [selectedValue4, setSelectedValue4] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 4")?.Status))
    )
    const [selectedValue5, setSelectedValue5] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 5")?.Status))
    )
    const [selectedValue6, setSelectedValue6] = useState(AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 6")?.Status))
    )
    const [error, setError] = useState(null)
    const toggle = () => setModal(!modal);

    const UpdateOrAddOption = (opt, optionNb) => {
        const foundIndex = configuredOptions.findIndex(opt => opt.option === optionNb)
        console.log("Index", foundIndex)
        //console.log('opt value', opt.value)
        if (foundIndex == -1) {
            configuredOptions.push({ option: optionNb, Status: opt.value, id: Math.random() })
            //setConfiguredOptions(newConfigOpts)
        }
        else {
            configuredOptions[foundIndex] = { ...configuredOptions[foundIndex], Status: opt.value }
            //console.log('setConfiguredOptions', configuredOptions)
        }
        console.log("configuredOptions", configuredOptions)
        setConfiguredOptions(configuredOptions)
    }

    const HandleSelectChangeOpt1 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue1(opt);

    }
    const HandleSelectChangeOpt2 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue2(opt);

    }
    const HandleSelectChangeOpt3 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue3(opt);

    }
    const HandleSelectChangeOpt4 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue4(opt);

    }
    const HandleSelectChangeOpt5 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue5(opt);

    }
    const HandleSelectChangeOpt6 = (opt, optionNb) => {
        UpdateOrAddOption(opt, optionNb)
        setSelectedValue6(opt);

    }

    const HandleConfigurationValidation = () => {
        let isValid = true;

        if (!selectedValue1 && qs.QCMOption1) {
            isValid = false;
            setError("Le champ d'option 1 est vide !")
            return isValid
        }
        if (!selectedValue2 && qs.QCMOption2) {
            isValid = false;
            setError("Le champ d'option 2 est vide !")
            return isValid
        }
        if (!selectedValue3 && qs.QCMOption3) {
            isValid = false;
            setError("Le champ d'option 3 est vide !")
            return isValid

        } 
        if (!selectedValue4 && qs.QCMOption4) {
            isValid = false;
            setError("Le champ d'option 4 est vide !")
            return isValid

        } 
        if (!selectedValue5 && qs.QCMOption5) {
            isValid = false;
            setError("Le champ d'option 5 est vide !")
            return isValid
        } 
        if (!selectedValue6 && qs.QCMOption6) {
            isValid = false;
            setError("Le champ d'option 6 est vide !")
            return isValid
        }

        console.log('isValid', isValid)
        return isValid
    }

    const OptionsQuestionsConfiguration = async () => {
        setError(null)
        try {
            console.log('configuredOptions', configuredOptions)
            console.log('configuredOptions.every(opt => opt._id)',configuredOptions.every(opt => opt._id))
            if (configuredOptions.length > 0 && configuredOptions.every(opt => opt._id)) {
                //console.log('All options have _id');
                await UpdateConfiguredOptionsQuestions(AcceptanceCriteria._id, { newOptionsQs: configuredOptions })
                toggle()

            }
            else {
                //console.log('Some options do not have _id');

                if ( HandleConfigurationValidation() ) {
                    await ConfigureOptionsQuestions(AcceptanceCriteria._id, { newOptionsQs: configuredOptions })
                    toggle()
                }
            }
            setIsUpdated(!isUpdated)

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        setConfiguredOptions(AcceptanceCriteria?.ConfiguredOptions)
        //console.log('configOpts',AcceptanceCriteria?.ConfiguredOptions)
        //console.log('pfff', AcceptanceCriteria?.ConfiguredOptions.find(ConfigOpt => ConfigOpt?.option === "Option 1")?.Status)
    }, [AcceptanceCriteria?.ConfiguredOptions])


    return (
        <>
            <Button
                isOpen={modal}
                style={{
                    backgroundColor: "#404954",
                    borderColor: "#404954",
                    color: "white",
                    whiteSpace: 'wrap'
                }}
                className="sm"
                onClick={toggle}
            >
                {t('ConfigOpts')}
            </Button>
            <Modal isOpen={modal} toggle={toggle} className="w-full">
                <ModalHeader toggle={toggle}>Question : {qs.question}</ModalHeader>
                <ModalBody className='w-full'>
                    {qs.QCMOption1 && <div className='w-full d-flex mt-2 mb-2'>

                        <p className='w-full'>{qs.QCMOption1}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder={`${t('Configure')}...`}
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt1(opt, 'Option 1')}
                            //defaultValue={AcceptanceCriteriaOptions.find(opt => opt.value === (AcceptanceCriteria?.ConfiguredOptions?.find(ConfigOpt => ConfigOpt?.option === "Option 1")?.Status))}
                            value={selectedValue1}
                        />
                    </div>}
                    {qs.QCMOption2 && <div className='w-full d-flex mt-2 mb-2'>

                        <p className='w-full'>{qs.QCMOption2}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder="Configurer..."
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt2(opt, 'Option 2')}
                            value={selectedValue2}

                        />
                    </div>}
                    {qs.QCMOption3 && <div className='w-full d-flex mt-2 mb-2'>

                        <p className='w-full'>{qs.QCMOption3}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder="Configurer..."
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt3(opt, 'Option 3')}
                            value={selectedValue3}

                        />
                    </div>}
                    {qs.QCMOption4 && <div className='w-full d-flex mt-2 mb-2'>

                        <p className='w-full'>{qs.QCMOption4}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder="Configurer..."
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt4(opt, 'Option 4')}
                            value={selectedValue4}
                        />
                    </div>}
                    {qs.QCMOption5 && <div className='w-full d-flex'>

                        <p className='w-full'>{qs.QCMOption5}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder="Configurer..."
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt5(opt, 'Option 5')}
                            value={selectedValue5}
                        />
                    </div>}
                    {qs.QCMOption6 && <div className='w-full d-flex'>

                        <p className='w-full'>{qs.QCMOption6}</p>
                        <SelectOptionQuestion
                            className='w-75'
                            placeholder="Configurer..."
                            options={AcceptanceCriteriaOptions}
                            onChange={(opt) => HandleSelectChangeOpt6(opt, 'Option 6')}
                            value={selectedValue6}

                        />
                    </div>}
                    {error && <Row className='m-2'>
                        <p className="text-red">{error}</p>

                    </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={OptionsQuestionsConfiguration}>
                        {t('save')} configuration
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfigureQuestionOptionsModal