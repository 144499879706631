import React from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap'
import './CSS/Profile.css'
import EditProfileModal from './EditProfileModal'
import {useHistory} from 'react-router-dom'

const ProfilePartie1 = ({ candidateUser, profileC, getUser, getProfileCandidate, t, isSharedProfile }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));

  const personaLInfo = candidateUser?.ParsedDataCV?.personal_infos;
  const TotalExp = candidateUser?.ParsedDataCV?.work_experience?.total_years_experience;

  const HandleClick = (e)=>{
    e.preventDefault();
    history.push('/homepage/register')
  }

  return (
    <Container>

      <Row /*className="d-flex justify-content-between p-2 bd-highlight"*/>
        <Col sm='8' className='pt-2'>
          <h1 className='text-black mb-4'>{candidateUser?.name} {candidateUser?.lastName}</h1>
          <h3 className='text-black'>{isSharedProfile ? personaLInfo?.current_profession : profileC?.title}</h3>
        </Col>
        <Col sm='4'>
          {(user?._id == candidateUser?._id && user && profileC) && <EditProfileModal getUser={getUser} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} />}
          {( !user && (user === null || user === undefined) && candidateUser?._id !== user?._id) && <Button className='mt-1' color='primary' onClick={HandleClick}>Proposez une offre</Button>}
        </Col>
      </Row>


      {/* <Row className='mt-4'>
        <Col sm='3'>
              </Col>
        <Col sm='3'>
          <Badge
            className='p-3'
            color="primary"
            pill
            style={{ whiteSpace: 'normal' }}
          >
            {profileC?.profileType}
          </Badge>
        </Col>
        <Col sm='3'>
          <Badge
            className='p-3'
            color="primary"
            pill
            style={{ whiteSpace: 'normal' }}
          >
            {profileC?.expertiseField}
          </Badge>
        </Col>
        <Col sm='3'>
          <Badge
            className='p-3'
            color="primary"
            pill
            style={{ whiteSpace: 'normal' }}
          >
            {profileC?.desiredProject}
          </Badge>
        </Col>
      </Row> */}

      {((isSharedProfile && TotalExp) || profileC?.experience) && <Row className='mt-4 justify-content-start pl-3'>
           <div className='d-flex flex-column align-items-around p-1' style={{ border: '1px solid #e0ded9',width:"188px", backgroundColor:"#FCFCFC"}}>
              <h5 className='font-weight-bold pr-2 text-black mb-2'>Experience</h5>
              <h2 className='text-black'> {(isSharedProfile && TotalExp) ? TotalExp : profileC?.experience?.replace('ans',' ')} an(s)</h2>

            </div>

        {/*profileC?.permanentContractExperience && <>
            <div className='d-flex flex-column align-items-around p-1' style={{ border: '1px solid #e0ded9',width:"188px",backgroundColor:"#FCFCFC"}}>
              <h5 className='font-weight-bold pr-2 text-black mb-2'>CDI </h5>
              <h2 className='text-black'>{profileC.permanentContractExperience} ans</h2>

            </div>
    </>*/}
      </Row>}

    </Container>
  )
}

export default ProfilePartie1