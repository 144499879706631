import React, { useEffect, useState } from 'react'
import { CheckIcon, ExclamationCircleIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { fetchGeneralParamByLabel } from 'network/ApiAxios';



const ChecklistForApply = ({ IsAppliedToGeneralPresentation, profileCV, userCV,isOpen }) => {
    //const {profileExistence,competenciesExistence,interviewPassed} = candidateProgressProfile 
    const [isShown, setIsShown] = useState(isOpen);
    const [generalParamId, setGeneralParamId] = useState(null)
    console.log("IsAppliedToGeneralPresentation",IsAppliedToGeneralPresentation)

    const toggleShow = () => setIsShown((prevState) => !prevState);
    const style1 = { marginRight: '20px', fontSize: '30px', color: 'green', height: "2rem" }
    const style2 = { marginRight: '20px', fontSize: '30px', color: 'yellow', height: "2rem" }

    const CVExistence = () => {
        let isCvExisting = false;
        if (profileCV || userCV) {
            isCvExisting = true;
        }
        return isCvExisting
    }

    const GetGeneralPresentationJobId = async () => {
        try {
            const { data } = await fetchGeneralParamByLabel("Présentation générale")
            setGeneralParamId(data[0].Parameter)
            //console.log('id',data[0].Parameter)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        CVExistence()
        console.log('CVExistence()', CVExistence())
    }, [profileCV, userCV])

    useEffect(() => {
        GetGeneralPresentationJobId()
    }, [IsAppliedToGeneralPresentation])

    return (
        <div>
            <div onClick={toggleShow} className='d-flex align-items-center justify-content-center'>
                <span style={{ fontWeight: 'bold',cursor:'pointer' }}>La checklist pour pouvoir postuler</span>
                {isShown ? <ChevronUpIcon style={{ height: "2rem",cursor:'pointer' }} /> : <ChevronDownIcon style={{ height: "2rem",cursor:'pointer' }} />}
            </div>


            {isShown && <ul style={{ listStyle: 'none' }}>
                <div className='d-flex align-items-center'>
                    {CVExistence() ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
                    <li style={{ display: 'inline' }}> {CVExistence() ? "CV déjà ajouté à votre profil" :
                        <a target='_blanck' href="/infrancecandidate/createMyprofile">
                            Cliquer ici pour ajouter votre CV 
                        </a>}</li>

                </div>

                <div className='d-flex align-items-center'>
                    {IsAppliedToGeneralPresentation ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
                    <li style={{ display: 'inline' }}>{IsAppliedToGeneralPresentation ? "Présentation générale déjà ajoutée à votre profil"
                        :
                        generalParamId && <a target='_blanck' href={`/jobs/apply?jobId=${generalParamId}`}>Créer votre présentation générale</a>
                    }</li>

                </div>
            </ul>}
        </div>
    )
}

export default ChecklistForApply