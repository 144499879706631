import axios from "axios";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import JobCandidats from "components/recruteur/JobCandidats";
import LandingPageOnev2 from "components/landingPage/landingPageOnev2";
import LandingPageCompletedCandidatures from "components/landingPage/landingPageCompletedCandidatures";
//import LandingPageCandidatures from "components/landingPage/landingPageCandidatures";
import HomeNavbar from "components/Navbars/HomeNavbar";
import Header from "components/Headers/Header";
import "components/app.css";


import config from "config";
import LoginPostul from "components/loginPostul";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function Landing_Page_Layout_One() {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }




  return (
    <>
        <div className="main-content">
          <HomeNavbar />
          <div className="homeHeader bg-grad pb-1 pt-4 pt-md-1">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                   
                  </Col>
                </Row>
              </div>
            </Container>
            
          </div>

        <Header />                 
          {/* Page content */}
        </div>
        <Container
        
        style={{
          backgroundColor: "#f7fafc",
           //objectFit: "cover",
          justifyContent:'center',
          alignItems:'center',
          width: "100%",
          height: "75%",
          paddingRight: 0,
          paddingLeft: 0,
      }}
        >
          
        <Row>
          <Col>
           <div
            className="landingPageStyle"
           style={{
                backgroundColor: "#f7fafc",
                 //objectFit: "cover",
                justifyContent:'center',
                alignItems:'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
            }}
           
           
           >
            <h1>La plateforme qui booste votre RH</h1>
            <br></br>
            <br></br>
            <p>Filtrez les candidats 5 fois plus rapidement avec les interviews en Vidéo</p>
            <p>Éliminez les problèmes de planification des entretiens</p>
            <p>Obtenez plus d'informations sur les candidats en moins de temps</p>
            <p>Mener des entretiens à distance</p>

            
           </div>
          </Col>
          <Col>
                      <img
                        src={`assets/img/brand/video-interview-700x467.jpg`}
                        //className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/video-interview-700x467.jpg").default;
                        }}
                        style={{
                          width: "100%",
                          height: "75%",
                          paddingRight: 0,
                          paddingLeft: 0,
                          

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
         </Col>
        </Row>
      </Container>
      <Container className="landingPageStyle">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
        <div >
          <h2>Les dernières candidatures en Vidéo</h2>
        </div>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
                <div className="pl-lg-4">
                  <Row>
                  {/*LandingPageCompletedCandidatures LandingPageOnev2 */}
                  <LandingPageCompletedCandidatures jobId="61dd821a1b81b1f6c375e088" /> 
                  </Row>
                </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="landingPageStyle">
      <Col>
       <div>
          <h2>Arrêtez de perdre trop de temps avec le recrutement</h2>
          <p
            style={{
              paddingBottom: "20px",
            }}
          
          >Suppimez les tâches manuelles de recrutement qui vous font perdre des candidats</p>
          {' '}
          <span> </span>
      </div>
      </Col>
      <Row
        style={{
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      
      >
      <Col><h3>Rapide</h3></Col>
      <Col><h3>Pas de RDV</h3></Col>
      <Col><h3>Direct</h3></Col>
      </Row>
      <Row
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
      >
      <Col><h4>Créez l'Interview avec des questions de préselection</h4></Col>
      <Col><h4>Le candidat passe l'entretien Vidéo chez lui</h4></Col>
      <Col><h4>Evaluez les candidatures via les vidéos des candidats</h4></Col>
      </Row>
      </Container>
      <Container className="landingPageStyle">
      <Col className="class-col-2">
       <div>
          <h2>Recruter Rapidement</h2>
          <h2>

          <Button
          color="primary"
          variant="primary"
          size="lg"
          justifyContent="center"
          href={`config.DOMAIN_NAME + '/home/register/'`}
          //   target="blank"
          >
          Créez votre compte
        </Button>

          </h2>



      </div>
      </Col>
      </Container>
    </>
  );
}

export default Landing_Page_Layout_One;
