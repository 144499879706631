import { DeleteCompetency } from 'network/ApiAxios';
import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';

const DeleteOneCompetency = ({ user, candidateUser, competencyID, getCandidateCompetencies, compentency }) => {

    const [modal, setModal] = useState(false);

    const deletingOneCOmpetency = async () => {
        try {
            await DeleteCompetency(competencyID, compentency._id)
            toggle()
            await getCandidateCompetencies()
        } catch (error) {
            console.log(error);
        }
    }

    const toggle = () => setModal(!modal);
    return (
        <Container>
            {(user?._id === candidateUser?._id && user) &&
                <svg onClick={toggle} style={{ height: '1.2rem', color: 'red',cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg>

            }
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Supprimer cette compétence</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm='12'>
                            <p>Êtes-vous sûr de supprimer cette compétence ?</p>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={deletingOneCOmpetency}>
                        Supprimer
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>

        </Container>
    )
}

export default DeleteOneCompetency