import { fetchQuestions } from 'network/ApiAxios';
import { AppendNewCodingQuestionToJob } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row, Spinner, Table } from 'reactstrap'
import OneCodingQuestion from './OneCodingQuestion';
import EditJobQuestion from './editJobQuestion';

const AddJobQuestionsFromDB = ({ jobID, questions, toggle, fct }) => {
    const [Allquestions, setAllQuestions] = useState([])
    const [appendedQuestionToJob, setAppendedQuestionToJob] = useState([]);
    const [appendedTemplateQuestion, setAppendedTemplateQuestion] = useState(null)
    const [pageNumber, setPageNumber] = useState(1);
    const [pagination, setPagination] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isQuestionCharged, setIsQuestionCharged] = useState(false);

    const getQuestions = async () => {
        try {
            const { data } = await fetchQuestions(pageNumber, pagination);
            setPageCount(data.totalQuestions / pagination)
            setAllQuestions(data.questions)
        } catch (error) {
            console.log(error)
        }
    }

    const AppendOneCQToJob = (id) => {
        //appendedQuestionToJob.push(CQ)
        setAppendedTemplateQuestion(id)
        //console.log('appendedQuestionToJob',appendedQuestionToJob)
    }

    /* const AddNewCQToJob = async () => {
        setIsLoading(true)
        try {
            await AppendNewCodingQuestionToJob(jobID, { newQuestionIDs: appendedQuestionToJob })
            await fct()
            toggle()
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    } */

    const HandleClick = (id) => {
        setIsQuestionCharged(true)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPageNumber(selectedPage + 1);
    };

    useEffect(() => {
        getQuestions()
    }, [pageNumber])

    return (
        <Container className='w-full'>
            {!isQuestionCharged ? <Row>
                <Card className="bg-transparent w-full">
                    <CardHeader className="bg-transparent  w-full">
                        Liste des questions
                    </CardHeader>
                    <CardBody className='p-3 bg-body rounded shadow border-0 d-flex flex-column justify-content-center align-items-center '>
                        <Table responsive
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        Question
                                    </th>
                                    <th>
                                        Type
                                    </th>
                                    <th>
                                        Durée
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {Allquestions?.map((item, i) => <OneCodingQuestion item={item} key={item._id} questions={questions} AppendOneCQToJob={AppendOneCQToJob} />)}
                            </tbody>
                        </Table>
                        <Row>
                            <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                //forcePage = {pageNumber}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </Row>
                    </CardBody>
                    <CardFooter className="bg-transparent  w-full d-flex justify-content-center">
                        <Button
                            onClick={HandleClick}
                            style={{
                                backgroundColor: "#404954",
                                borderColor: "#404954",
                                color: "white",
                              }}
                            size='sm'
                        >
                            Charger la question {isLoading && <Spinner color="secondary">
                                Loading...
                            </Spinner>}</Button>
                    </CardFooter>
                </Card>
            </Row>
                :
                <Row>
                    <EditJobQuestion
                        questionID={appendedTemplateQuestion}
                        jobID={jobID}
                        toggle={toggle}
                        fct={fct}
                        appendFromDB
                    />
                </Row>}
        </Container>
    )
}

export default AddJobQuestionsFromDB