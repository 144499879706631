import axios from "axios";
import React, { useEffect, useState } from "react";
import { getOnePlaybookByID } from "network/ApiAxios";
import { sendInvitationEmailForJobApp } from "network/ApiAxios";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";


//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { SearchIcon } from "@heroicons/react/outline";
import {
  Button,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,

} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";
import { useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function SendEmailInvitForOneJobApp( props ) {
  const { t, i18n } = useTranslation();
  // const [candidateEmail, setCandidateEmail] = useState("");
  const history = useHistory();
  const [jobApplication, setJobApplication] = useState({});
  const [invitationSent, setInvitationSent] = useState(false);
  const [jobAppEmail, setJobAppEmail] = useState("");
  

  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }




  const sendInvitationEmailForJobAppFunction = async () => {
    
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: ");
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: handleJobValidation(): ", handleJobValidation());
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: creatorID: ", creatorID);

     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: title: ", title);
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: description: ", description);
     const response = await sendInvitationEmailForJobApp(user.email, jobAppEmail, props.jobID);
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: ");
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: ", response_Playbook_Creation);
     const { data } = response;
     if (data.success) {
      setInvitationSent(true);
       // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: success");
       // resetForm()
       // history.push(`/employer/trainings/YourLearningPathsView`);
     } else {
       // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: failure");
     }
   
 };

 const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.jobAppID}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);


  })
  .catch((err) => {
    
  });
};
const getjobAppUser = () => {
  instance.get(`users/getprofilebyuserid/${props.jobApplicationUserID}`).then((resp) => {
    // console.log("function editUser : resp: ", resp);
    setJobAppEmail(resp.data.email);

  });
};


  useEffect(() => {
    fetchJobApplication();
    getjobAppUser();
  }, []);


  return (
    <>
    <Container>
      <Row >
        <Col lg="8" >
          <FormGroup>
            <label
                className="form-control-label"
                htmlFor="input-username"
            >
                {t('candidateEmail')}:
            </label>
            <Input
                className="form-control-alternative"
                value={jobAppEmail}
                id="input-Email"
                placeholder="Email"
                // onChange={(e) => setCandidateEmail(e.target.value)}
                type="text"
            />
          </FormGroup>
        </Col>
        <Col lg="2">
            <label
                    className="form-control-label"
                    htmlFor="input-button-send"
                >
                
            </label>
            <Button
              className="btn-secondary"
              onClick={sendInvitationEmailForJobAppFunction}
            >
              {t('SendInvitationByEmail')}
            </Button>
        </Col>
      </Row>
      {invitationSent &&
        <Row>
          <Col lg="6">
          <Label
            className="form-control-label"
            htmlFor="input-button-send"
          >
            {t('EmailSentOk')}!
          </Label>
          </Col>
        </Row>
      }
    </Container>
    </>
  );
}

export default SendEmailInvitForOneJobApp;
