import React from "react";
import CandidateLayoutV2 from "layouts/CandidateLayoutV2";
import CandidatMyProfile from "components/candidat/CandidatMyProfile";
import { Box } from "@mui/material";


function CandidateMyProfile () {
    return (
      <>
        <CandidateLayoutV2 />
        <div className="flex flex-col items-center space-y-5 py-3 ">
            <h3>Mon profil: Présentation Générale</h3>
        </div>
        <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>
        <CandidatMyProfile />
        </Box>


      </>
    );
  
}

export default CandidateMyProfile;
