import React, { useState } from "react";

// reactstrap components
import {
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerUserCompany } from "../../network/ApiAxios";
import config from "config";
import Select from "react-select";
import { useTranslation } from "react-i18next";
const CreateUser = () => {
  const { t, i18n } = useTranslation();

  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
   let role = "employer";
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [accountConfirmation, setAccountConfirmation]= useState(true)
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);

  const registerUser = async () => {
    if (!(firstname && lastname && email && role && password && confirmPassword )) {
      setError(
        "Make sure to fill all the inputs and agree to the privacy policy"
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (user.employerCampanyID)
    {
      const response = await registerUserCompany(firstname, lastname, email, user.company, password, role, user.employerCampanyID, accessLevel);
      const { data } = response;
      if (!data.success) {
        setError(data.msg);
        return;
      }
    }
    
    
    // if (config.DEMO) {
    //   setToastMessage(
    //     "This is a demo, so we will not send you an email. Instead, click on this link to verify your account:"
    //   );
    //   setUserID(data.userID);
    // }
    window.location.reload()
    setError("");
    setFirstname("");
    setLastname("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setAccessLevel("");
    setShowToast(true);
  };

  return (
    <>
      
      <Col lg="6" md="8" className="mx-auto mb-4 mt-4">
        <Card className="bg-secondary shadow border-0">
        <div className="flex flex-col  py-3 mx-5 mt-4 ">
              <h3>{t('USERManagementKEY2')}: </h3>
            </div>  
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup>
              <Label>{t('firstName')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('firstName')}
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label>{t('lastName')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('lastName')}
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label>Email</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label>{t('USERManagementKEY3')}</Label>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    
                  </InputGroupAddon>
                  
                  <Select
                  className={`w-full `}
                  options={[
                    { value: "Administrator", label: "Administrator" },
                    { value: "Standard", label: "Standard" },
                    
                  ]}
                  onChange={(e) => {
                    console.log("eeee",e.value)
                    setAccessLevel(e.value);
                    
                  }}
                />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label>{t('password')}</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
              <Label>{t('PasswordConfirmation')}</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
             
              <div className="text-center">
                <Button
                  onClick={registerUser}
                  className="mt-4"
                  style={{backgroundColor:"#404954", borderColor:"#404954", color:"white"}}
                  type="button"
                >
                  {t('add')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CreateUser;
