import { getUserByEmail } from 'network/ApiAxios'
import { GetAllAcceptedInvitationsAccessByCompany } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Alert, Card, CardBody, CardFooter } from 'reactstrap'
import OneAcceptedInterviewAccess from './OneAcceptedInterviewAccess'
import { getProfilesCandidatesthatacceptInterviewDemands } from 'network/ApiAxios'
import ReactPaginate from 'react-paginate'
import AlertMessage from 'components/styles/AlertMessage'

const MyAcceptedAccessRequestsDemands = () => {
  const [myAcceptedInvitationRequests, setMyAcceptedInvitationRequests] = useState([])
  const [candidates, setCandidates] = useState([])
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);


  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }

  const getProfilesWithInterviewPassedAndAcceptInterviewAccessDemand = async () => {
    const { data } = await getProfilesCandidatesthatacceptInterviewDemands(user?.employerCampanyID)
    setCandidates(data.slice(offset, offset + perPage));
    setPageCount(Math.ceil(data.length / perPage));
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("selectedpage", selectedPage);
    setOffset(selectedPage + selectedPage * 5);

  };



  const fetchAcceptedInvitationsAccessRequestByCompany = async () => {
    try {
      const { data } = await GetAllAcceptedInvitationsAccessByCompany(user?.employerCampanyID)
      if (data.success) {
        setMyAcceptedInvitationRequests(data.invitations)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getProfilesWithInterviewPassedAndAcceptInterviewAccessDemand()
    fetchAcceptedInvitationsAccessRequestByCompany()
  }, [])

  useEffect(() => {
    getProfilesWithInterviewPassedAndAcceptInterviewAccessDemand()
  }, [offset])


  return (
    <>
      <Card>
        <CardBody>
          {candidates.length == 0 && <AlertMessage message={"Vous n'avez pas de demandes d'accès acceptées dont le candidat a passé des entretiens"} />
          }

          {candidates && candidates.length !== 0 && candidates.map((candidat, i) => (
            <OneAcceptedInterviewAccess key={i} candidate={candidat} myAcceptedInvitationRequests={myAcceptedInvitationRequests} />
          ))}
        </CardBody>
        <CardFooter style={{ margin: '0 auto' }}>

          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            //pageRangeDisplayed={limit}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={5}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </CardFooter>
      </Card>
    </>
  )
}

export default MyAcceptedAccessRequestsDemands