import React, { useRef, useState } from 'react'
import { Card, Col, Input, Row, Tooltip } from 'reactstrap'
import ProfileImgDefault from "../../../assets/img/brand/500.png";
import avatarImg3 from '../../../assets/img/brand/profileAvatar2.webp';

import { EditProfileImage } from 'network/ApiAxios';
import config from 'config';
import ProfilePartie1 from './ProfilePartie1';

const ProfileImageSection = ({ candidateUser, isSharedProfile, callback,getProfileCandidate,profileC, t }) => {
    const fileInputRef = useRef(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const handleImageClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const formData = new FormData();
        formData.append("image", e.target.files[0])
        if (e.target.files[0]) {
            await EditProfileImage(candidateUser?._id, formData);
            await callback(candidateUser?._id)
        }
    }

    const avatar = {
        height: "200px",
        Width: "200px",
        borderRadius: "50%",
        objectFit: "cover",
        cursor: 'pointer'
    }
    return (
        <Row>
            <Col sm='4'>
                {isSharedProfile ? <div className='d-flex justify-content-center align-items-center m-2'>
                    <img
                        style={avatar}
                        src={candidateUser?.image ? `${config.CVs_URL}${candidateUser.image}` : candidateUser?.cv_photo ? `${config.CVs_URL}${candidateUser.cv_photo}` : avatarImg3}
                        alt='imageProfile'
                    />
                </div>
                    :
                    <div className='d-flex justify-content-center align-items-center m-2'>
                        <img
                            for="fileInput"
                            style={avatar}
                            src={candidateUser?.image ? `${config.CVs_URL}${candidateUser.image}` : candidateUser?.cv_photo ? `${config.CVs_URL}${candidateUser.cv_photo}` : ProfileImgDefault}
                            alt='imageProfile'
                            onClick={handleImageClick}
                            id="TooltipExample"
                        />

                        <Input
                            type="file"
                            id="fileInput"
                            name="image"
                            accept='image/*'
                            innerRef={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <Tooltip
                            isOpen={tooltipOpen}
                            target="TooltipExample"
                            toggle={toggle}
                        >
                            Modifier la photo de profil
                        </Tooltip>
                    </div>}
            </Col>
            <Col sm='8'>
            <ProfilePartie1 getUser={callback} getProfileCandidate={getProfileCandidate} profileC={profileC} candidateUser={candidateUser} t={t} isSharedProfile={isSharedProfile} />
            </Col>
        </Row>
    )
}

export default ProfileImageSection