import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { H2, H3 } from "components/Typography";

import { Box, Container, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import { Formik } from "formik";

import { useHistory, useLocation } from "react-router-dom";

import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});




function OutFrProjectsView() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
   const history = useHistory();
   const location = useLocation();
   const [profile, setProfile] = useState({});
   const [title, setTitle] = useState("");
   const [ville, setVille] = useState("");
   const [university, setUniversity] = useState("");
   const [studyLevel, setStudyLevel] = useState("");
    

   

   
    

   const fetchProfile = () => {
  
    const formData = new FormData();
    formData.append("userID", user._id);

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setProfile(resp.data);
      setTitle(resp.data.title);
      setVille(resp.data.ville);
      setUniversity(resp.data.university);
      setStudyLevel(resp.data.studyLevel);
      // console.log("user id: ", user._id);
      // console.log("111111111111111: resp.data: ", resp.data);
      // console.log("profile: ", profile);
      });

    
  };

useEffect(() => {
  
  // fetchProfile();


}, [location]);


  
  return (
    <>
      <CandidateLayoutV3 />
      {/*<CandidatOffres Offres />*/}
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
          <H2>Projet: Je suis hors de la France et je cherche un CDI en France</H2>
          <H3>Mon profil</H3>
      </Container>

      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>

        <Formik 
          
          >
          {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <label className="px-lg-3">Projets</label>
                  <TextField name="projets" 
                    //label="Quel est votre Ecole/Université/Institut?" 
                    //placeholder="Quel est votre Ecole/Université/Institut?" 
                    fullWidth 
                    // onBlur={handleBlur} 
                    // value={profile.studyLevel}
                    value={user.projets}
                    //onChange={e => setUniversity(e.target.value)}
                    //error={!!touched.university && !!errors.university} 
                    //helperText={touched.university && errors.university} 
                  />
                </Grid>
              </Grid>
             {/*} <Button 
              variant="contained" 
              color="primary" 
              type="submit" 
              sx={{ mt: "25px" }}
              onClick={() => { }}
              >
                Modifier votre fiche d'inscription
              </Button> */}
            </form>}
        </Formik>
      </Box>
      
    </>
  );
};

export default OutFrProjectsView;
