import { getActiveProfileTypes } from 'network/ApiAxios';
import { options_Pays } from 'options'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap'
import Select from 'react-select';


const SearchTalent = ({ ProfileType, Location, onClick }) => {
    const [activeProfileTypes, setActiveProfileTypes] = useState([]);
    const [pays, setPays] = useState([]);
    const [profileType, setProfileType] = useState(ProfileType || null);
    const [location, setLocation] = useState(Location || null);
    const [error, setError] = useState(null);

    const fetchActiveProfileTypes = async () => {
        try {
            const { data } = await getActiveProfileTypes()
            data && setActiveProfileTypes(data.map(item => ({ value: item.jobID, label: item.name })))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchActiveProfileTypes()
    }, [])

    const HandleSelectChange = (option, setFunc) => {
        setFunc(option.value)
    }

    const customStyles = {
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menu: provided => ({ ...provided, position: 'absolute' }),
    };

    const SelectValidation = () => {
        let isValid = true;
        if (!profileType) {
            isValid = false;
            setError("Indiquez un métier ou une compétence (ex: web designer, java maven spring, iOS developer, etc.)")
        }
        if (!location) {
            isValid = false;
            setError("Indiquez le lieu")
        }
        return isValid
    }

    const HandleClick = () => {
        setError(null)
        if (SelectValidation()) {
            onClick(profileType, location)
        }
    }

    return (
        <Card className="bg-transparent shadow p-2">
            <CardBody>
                <Row className='justify-content-center'>
                    {/* <Col sm='2'></Col> */}
                    <Col sm='4'>
                        <Select
                            options={activeProfileTypes}
                            placeholder='Exemple : développeur web'
                            className='w-full'
                            onChange={(opt) => HandleSelectChange(opt, setProfileType)}
                            value={activeProfileTypes.find(opt => opt.value === profileType)}
                            styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </Col>
                    <Col sm='5'>
                        <div className='d-flex'>
                            <Select
                                placeholder="Pays ou ville ou région"
                                className="w-full"
                                options={options_Pays}
                                onChange={(opt) => HandleSelectChange(opt, setLocation)}
                                value={options_Pays.find(opt => opt.value === location)}
                                styles={{ ...customStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                            />
                            <Button className='ml-4' onClick={HandleClick}> Trouvez un talent</Button>

                        </div>

                    </Col>
                    {/* <Col sm='2'>
                    </Col> */}

                </Row>
            </CardBody>
            <CardFooter className='d-flex justify-content-center'>
                {error && (
                    <div className="text-muted font-italic">
                        <small>
                            <span className="text-red font-weight-700">{error}</span>
                        </small>
                    </div>
                )}
            </CardFooter>
        </Card>
    )
}

export default SearchTalent