import axios from "axios";
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";
import FillYourCompetencies from "components/candidat/FillYourCompetencies";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";



import Select from "react-select";

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

import React, { useEffect, useState } from "react";
import config from "config";
import { options_profile_categories, options_CCNP, options_CCNA_NetSec, options_Cisco_NetSec, options_Competency_degree, options_profile_change_SR } from 'options';
import 'react-phone-number-input/style.css';



const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});



function InFranceCandidateCompetencies() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);



  const fetchCandidateCompetencies = () => {
  

    instance({
      method: "get",
      url: `/candidatesCompetencies/getCompetenciesByUserID/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setCompetenciesAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

    useEffect(() => {
  
      fetchCandidateCompetencies();
    
    }, [location]);

  
  return (
    <>
      <CandidateLayoutV3 />
      <PageSpace />
      <PageTitleStyle
        title="Mes compétences"
      >
        
      </PageTitleStyle>

      <Container>
      {competenciesAlreadyPosted &&
        <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="3" >
          </Col>
          <Col xs="9">
            <>
              <label className="px-lg-3">Votre tableau de compétences est déjà réalisé</label>
            </>
            
          </Col>

        </Row>
      }
      {!competenciesAlreadyPosted &&
        <Row>
          <Col xs="3" >
          </Col>
          <Col xs="9">
            <FillYourCompetencies
              CandidateType = "InFranceCandidate"
            />
            
          </Col>

        </Row>
      }
      </Container>
    



    </>
  );
}

export default InFranceCandidateCompetencies;
