import axios from "axios";
import JobCardsHomepage from "components/homepage/jobCardsHomepage";
import PageSpace from "components/styles/pageSpace";
import JobCardsHomepageV2 from "components/homepage/jobCardsHomepageV2";
import HomepageLayout from "layouts/HomepageLayout";
import StopWasteTime from "components/homepage/stopWasteTime";
import SimplifyTheRecuitement from "components/homepage/simplifyTheRecuitement";
import FindBestCandidates from "components/homepage/findBestCandidates";
import HomeNavbar from "components/Navbars/HomeNavbar";
import HomeHeader from "components/Headers/HomeHeader";
import HomeHeaderV2 from "components/Headers/HomeHeaderV2";
import CallToActionForEmployers from "components/homepage/callToActionForEmployers";



import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';



function HomepageViewV2() {

  const { t, i18n } = useTranslation();
  const history = useHistory()

  const HandlePageRedirection = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.role === "employer") {
      history.push("/employer/home")

    }

    if (user && (user.role === "candidat")) {
      history.push("/candidate/home");
    }



  }

  useEffect(() => {
    HandlePageRedirection()
  }, [])



  return (
    <>
      {/*
      <HomepageLayout />
      <HomeNavbar />
      
      
    */}



      <HomeHeaderV2 />
      <PageSpace />
      <PageSpace />
      <PageSpace />
      <CallToActionForEmployers />

      <Container fluid>
        <Row>
          <Col>
            <div
              className="homePageStyle"
              style={{
                //backgroundColor: "#f7fafc",
                //objectFit: "cover",
                justifyContent: 'center',
                alignItems: 'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
              }}


            >
              <h2>{t("title2")}</h2>
            </div>
          </Col>
        </Row>
      </Container>
      {/*
          <JobCardsHomepage />

          */}

      <JobCardsHomepageV2 />



      {/* <div className="mt-3 mb-3" >
              <img
              alt="..."
              className="shadow-lg"
                src={require("assets/img/brand/hire.jpg").default}
                style={{width:"100%"}}
              >
                
              </img>
            </div> */}




      {/* <StopWasteTime /> */}

      {/* <FindBestCandidates /> */}

      {/* <SimplifyTheRecuitement /> */}



    </>
  );
}

export default HomepageViewV2;
