import { fetchJobAppForGeneralPresentation } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import JobAppVideos from './jobAppVideos'

const CandidateGeneralPresentation = ({candidateUserID}) => {
    const [generalPresentationApplication,setGeneralPresentationApplication] = useState(null)
    const [msg, setMsg] = useState(null)


    const GetJobAppForGeneralPresentation = async()=>{
        try {
            const {data} = await fetchJobAppForGeneralPresentation(candidateUserID)
            data && setGeneralPresentationApplication(data)
        } catch (error) {
            //console.log(error.response.data?.msg)
            setMsg(error.response.data?.msg)
        }
    }

    useEffect(()=>{
        GetJobAppForGeneralPresentation()
    },[])

    return (
        <>
                    {!msg && generalPresentationApplication ? <JobAppVideos
                      jobAppID = {generalPresentationApplication._id}
                    /> 
                :
                <h3>{msg}</h3>
                }
        </>
    )
}

export default CandidateGeneralPresentation