import config from "config";
import { FetchMyJobs } from "network/ApiAxios";
import { saveInvitationRequest } from "network/ApiAxios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Tooltip,
} from "reactstrap";
import SendEmailInvitationForOneJob from "./sendEmailInvitationForOneJob";
import PageSpace from "components/styles/pageSpace";
import Select from 'react-select'
import { useTranslation } from "react-i18next";

const SendInvitationForOneQualifiedCandidate = ({ candidateEmail }) => {
  const { t, i18n } = useTranslation();
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }
  const [msg, setMsg] = useState(false);
  const [invitation, setInvitation] = useState({
    candidateUserEmail: candidateEmail,
    recruiterUserID: user?._id,
    recruiterEmail: user?.email,
    recruiterCompanyID: user?.employerCampanyID,
    companyName: user?.company,
  });

  const [jobsOptions, setJobsOptions] = useState([])
  const [selectedJob, setSelectedJob] = useState(null)

  const SendInvitationForQualifiedCandidateandSave = async () => {
    const { data } = await saveInvitationRequest(invitation);
    if (data.success) {
      setMsg(data.msg);
    }
  }

  const GetMyJobs = async () => {
    try {
      const { data } = await FetchMyJobs({ userID: user._id })
      if (data.length) {
        const jobsForOpts = data.map(job => job = { label: job.title, value: job._id })
        setJobsOptions(jobsForOpts)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    GetMyJobs()
  }, [])

  return (
    <Container>
      <Row>
        <h2 className="mt-2 px-lg-4">
          {t('DemandPassInterview')}
        </h2>

        <Col lg="8">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-username">
              {t('candidateEmail')}:
            </label>
            <Input
              className="form-control-alternative"
              value={candidateEmail}
              id="input-Email"
              placeholder="Email"
              type="text"
              disabled
            />
          </FormGroup>
        </Col>
        <Col lg="2">
          <label
            className="form-control-label"
            htmlFor="input-button-send"
          ></label>
          <Button
            className="btn-secondary"
            onClick={SendInvitationForQualifiedCandidateandSave}
          >
            {t('SendInvitationByEmail')}
          </Button>
        </Col>
      </Row>

      <label className="form-control-label font-bold" htmlFor="input-username">
        {t('EmailObject')} :
      </label>
      <span className="ml-1 font-bold">Invitation pour passer l'entretien</span>
      <br/>
      <label className="form-control-label font-bold" htmlFor="input-username">
        {t('EmailBody')} :
      </label>
        <Input
          className='form-control-lg w-75'
          type="textarea"
          rows="5"
          readOnly
          value={`${t('Greeting')}, \n\n` +
            `${t('Company')} ${user?.company} ${t('InterestedProfile')}. \n\n` +
            `${t('BodyEmailkey1')} : ${config.DOMAIN_NAME}/home/login \n\n` +
            `${t('BodyEmailkey2')} : ${config.DOMAIN_NAME}/homepage/register \n\n` +
            `${t('BodyEmailkey3')} : ${config.DOMAIN_NAME}/Infrapplypermanentfr/preferences  \n\n` +
            `${t('BodyEmailkey4')}. \n\n` +
            `${t('BodyEmailkey5')}, \n\n` +
            `Hirelands \n`}
        />
      {msg && (
        <Row>
          <Col lg="6">
            <Alert
              className='p-2'
              color="success"
              //className="form-control-label"
              htmlFor="input-button-send"
            >
              {msg}
            </Alert>
          </Col>
        </Row>
      )}
      <PageSpace />

      <>
        <h2 className="mt-2 px-lg-4">
          {t('ApplyAsk')}
        </h2>

        <Row className='m-4 d-flex justify-content-center'>
          <Select
            className='w-75'
            options={jobsOptions}
            placeholder={t('SelectJobLabel')}
            onChange={(job) => setSelectedJob(job.value)}
            menuPlacement="top"
          />

        </Row>

        {selectedJob &&
          <SendEmailInvitationForOneJob jobID={selectedJob} candidateEmail={candidateEmail} />
        }
      </>
    </Container>
  );
};

export default SendInvitationForOneQualifiedCandidate;
