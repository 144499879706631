import { useState } from 'react';
import Select from 'react-select'


const AcceptanceCriteriaSelect = ({ criteriaId, responseText, AcceptanceCriteriaOptions,instance,candidatId,t }) => {
    //console.log('responseText',responseText)
    const [selectedOption , setSelectedOption] = useState(null)
  

      const CreateOrUpdateAcceptanceCriteriaResponse = (SelectedOpt, criteriaId) => {
        instance.post("JobAcceptanceCriteria/createORUpdateResponseAcceptanceCriteria", {
          JobAppId: candidatId,
          AcceptanceCriteriaID: criteriaId,
          status: SelectedOpt.value,
        });
      };

      const handleSelectChange = (selectedOpt) => {
        setSelectedOption(selectedOpt.value)
        CreateOrUpdateAcceptanceCriteriaResponse(selectedOpt, criteriaId);
      };
  
    return (
      <Select
        name={criteriaId}
        className='w-full'
        value={AcceptanceCriteriaOptions.find(selectedOpt => selectedOpt.value === ((selectedOption !== null) ? selectedOption : responseText))}
        options={AcceptanceCriteriaOptions}
        placeholder={`${t('Select')}...`}
        onChange={handleSelectChange}
      />
    );
  };

  export default AcceptanceCriteriaSelect