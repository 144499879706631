import React, { useEffect, useState } from 'react'
import { LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { getUserById } from 'network/ApiAxios';
import { getProfileFrUserByID } from 'network/ApiAxios';
import { getCompetenciesByCandidate } from 'network/ApiAxios';
import { getProfileProgressForFrCandidate } from 'network/ApiAxios';
import { getCandidateJobApplications } from 'network/ApiAxios';
import { Alert, Badge, Button, Card, CardHeader, Col, Container, Label, Row, Table } from 'reactstrap'
import ProgressBar from './MyProfile/ProgressBar';
import Checklist from './MyProfile/Checklist';
import LocalisationSection from './MyProfile/LocalisationSection';
import FormationSection from './MyProfile/FormationSection';
import CardComp from './MyProfile/CardComp';
import FormComp from './MyProfile/FormComp';
import config from 'config';
import { fetchGeneralParamByLabel } from 'network/ApiAxios';
import ChecklistForApply from './MyProfile/CheckListForApply';
import { useTranslation } from 'react-i18next';
import { ParseCVCandidate } from 'network/ApiAxios';
import FormationSectionSP from './SharedProfile/FormationSectionSP';
import FormCompSP from './SharedProfile/FormCompSP';
import ProfileImageSection from './MyProfile/ProfileImageSection';
import JobAppAudiosSF from './SharedProfile/JobAppAudiosSF';
import JobAppGeneralAnalysis from 'components/employer/jobAppScoreReport';
import JobAppScoreReport from 'components/employer/jobAppGeneralAnalysis';
import JobAppScoreByQuestionCatalogReport from 'components/employer/jobAppScoreByQuestionCatalogReport';
import JobAppScoreByOralQCOpenAiReport from 'components/employer/JobAppScoreByOralQCOpenAiReport';
import CodeQuestionAnalysisByJobApp from 'components/employer/CodeQuestionAnalysisByJobApp';
import JobAcceptanceCriteria from 'components/employer/JobAcceptanceCriteria';
import OpenAiCvCandidateAnalysisReport from 'components/employer/openAiCvCandidateAnalysisReport';
import { getOneFakeUser } from 'network/ApiAxios';
import { FetchLastPostedCV } from 'network/ApiAxios';
import JobAppScoreByOralQCOpenAiReportV1 from 'components/employer/JobAppScoreByOralQCOpenAiReportV1';
import SharedProfileImgSection from './SharedProfile/SharedProfileImgSection';



const SharedProfileCandidateComp = ({ id, location }) => {
    const [candidateUser, setCandidateUser] = useState(null);
    const [profileC, setProfileC] = useState(null);
    const [
        candidateProgressProfile,
        setCandidateProgressProfile,
    ] = useState(null);
    const [competencies, setCompetencies] = useState(null);
    const [jobApplication, setJobApplication] = useState(null)
    const [generalParamId, setGeneralParamId] = useState(null)
    const [openTab, setOpenTab] = useState(1);
    const [jobAppCV, setJobAppCV] = useState(null);

    const user = JSON.parse(localStorage.getItem('user'))
    const { t, i18n } = useTranslation();



    const getUser = async (idUSer) => {
        try {
            const { data } = await getUserById(idUSer)
            if (data) {
                setCandidateUser(data);
            }
        } catch (error) {
            console.log(error);
            const { data } = await getOneFakeUser(idUSer)
            setCandidateUser(data)
        }
    }


    const getProfileCandidate = async (idUSer) => {
        try {
            const { data } = await getProfileFrUserByID(idUSer)
            //const { data } = await getProfileFrUserByID(idUSer)
            setProfileC(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCandidateCompetencies = async () => {
        try {
            //const { data } = await getMyCompetencies(id)
            const { data } = await getCompetenciesByCandidate(id)            //console.log('Competencies',data);
            setCompetencies(data)
        } catch (error) {
            console.log(error);
        }
    }


    const CheckProgressForFrCandidate = async () => {
        try {
            const { data } = await getProfileProgressForFrCandidate(id)
            setCandidateProgressProfile(data)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchJobApplication = async () => {
        try {
            const { data } = await getCandidateJobApplications(id)
            setJobApplication(data)
            //console.log('jobApp', data);
        } catch (error) {
            console.log(error)
        }
    }

    const GetGeneralPresentationJobId = async () => {
        try {
            const { data } = await fetchGeneralParamByLabel("Présentation générale")
            setGeneralParamId(data[0].Parameter)
            //console.log('id',data[0].Parameter)
        } catch (error) {
            console.log(error)
        }
    }

    const GetLastPostedCV = async () => {
        try {
            const { data } = await FetchLastPostedCV(id);
            data && setJobAppCV(data);
            //console.log('jobAppCV', data)
        } catch (error) {
            console.log(error)
        }
    }


    const fetchData = () => {
        getUser(id);
        getProfileCandidate(id);
        CheckProgressForFrCandidate();
        getCandidateCompetencies();
        fetchJobApplication()
        GetGeneralPresentationJobId()
        GetLastPostedCV()
    };

    useEffect(() => {
        fetchData()
    }, [])

    const CompetenciesFromParsedCV = { competencies: candidateUser?.ParsedDataCV?.competencies }

    const jobAppId = localStorage.getItem('jobAppId');

    //const jobAppDetail = jobApplication?.find(jobApp => jobApp._id === jobAppId )
    //console.log('jobAppDetail',jobAppDetail)

    return (
        <div
            className="container"
        >
            {/* <ProfileImageSection candidateUser={candidateUser} isSharedProfile t={t} profileC={profileC} /> */}
            <SharedProfileImgSection candidateUser={candidateUser} />
            <br />
            <Row className="bg-white p-1 mt-1 mb-3 justify-content-center">

                <span
                    className={`${openTab === 1 ? "active " : ""} tab`}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                    }}
                    data-toggle="tab"
                    role="tablist"
                >
                    {/* <i className="fa fa-book" aria-hidden="true" />{" "} */}
                    <span className="tab-icon">🏠</span>
                    Entretien

                </span>



                <span
                    className={`${openTab === 2 ? "active " : ""} tab`}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                    }}
                    data-toggle="tab"
                    role="tablist"
                >
                    {/* <i className="fa fa-book" aria-hidden="true" />{" "} */}
                    <span className="tab-icon">📄</span>
                    CV

                </span>

                <span
                    className={`${openTab === 3 ? "active " : ""} tab`}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                    }}
                    data-toggle="tab"
                    role="tablist"
                >
                    {/* <i className="fa fa-book" aria-hidden="true" />{" "} */}
                    <span className="tab-icon">📊</span>
                    Analyse

                </span>

                <span
                    className={`${openTab === 4 ? "active " : ""} tab`}
                    onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(4);
                    }}
                    role="tablist"
                    data-toggle="tab"
                >
                    {/* <i className="fa fa-user" aria-hidden="true" />{" "} */}
                    <span className="tab-icon">👤</span>
                    Profil
                </span>


            </Row>
            
            {openTab === 1 && <JobAppAudiosSF id={id} />}


            {openTab === 2 && <Row>
                <Col>
                    {(candidateUser?.CV || profileC?.CV || jobAppCV) ? <iframe
                        title='pdf viewer'
                        src={config.Environment === "Dev" ?
                            "https://app.hirelands.com/uploads/ad6b66a4-a44d-47e1-b008-7653284fb7ea.pdf"
                            :
                            candidateUser?.CV ? `${config.CVs_URL}${candidateUser?.CV}` : profileC?.CV ? `${config.CVs_URL}${profileC?.CV}` : `${config.CVs_URL}${jobAppCV}`}
                        frameBorder="0"
                        style={{ width: "100%", height: "100vh", border: "none" }}
                    //sandbox="allow-same-origin allow-scripts allow-modals"
                    >

                    </iframe> :
                        <Label>Le candidat n'a pas mis de CV</Label>}
                </Col>
            </Row>}
            {openTab === 3 && <Container>
                {
                    jobAppId ? <>
                        {/* <JobAppGeneralAnalysis
                            candidatId={jobAppId}
                        />

                        <JobAppScoreReport
                            candidatId={jobAppId}
                        /> */}

                        <JobAppScoreByQuestionCatalogReport candidatId={jobAppId} />

                        <JobAppScoreByOralQCOpenAiReportV1 candidatId={jobAppId} />

                        <CodeQuestionAnalysisByJobApp candidatId={jobAppId} />

                        <OpenAiCvCandidateAnalysisReport candidatId={jobAppId} />

                        {/*jobAppDetail && <JobAcceptanceCriteria
                            jobID={jobAppDetail?.jobID}
                            candidatId={jobAppId}
                />*/}
                    </>
                        :
                        <Row>
                            Aucune candidature trouvée!
                        </Row>}

            </Container>}

            {openTab === 4 && <Row>
                <Col sm='4'>
                    <Card>
                        {/* Localisation et déplacement */}
                        <LocalisationSection profileC={profileC} candidateUser={candidateUser} getUser={getUser} getProfileCandidate={getProfileCandidate} user={user} t={t} isSharedProfile />
                        <br />
                        {/* 3 Top Technologies */}
                        {/* <CardComp header="3 Top Technologies" hidden>
                                            {" "}
                                            {!profileC?.topTechnologies ? (
                                                <Alert color="secondary">Aucune technologie n'a été ajoutée !</Alert>
                                            ) : (

                                                <ul>
                                                    <li>{profileC.topTechnologies}</li>
                                                </ul>
                                            )}
                                        </CardComp> */}

                        {/* Certifications */}
                        {(profileC?.certifications || candidateUser?.ParsedDataCV?.certifications?.length) ? <CardComp header="Certifications" hidden>
                            {candidateUser?.ParsedDataCV?.certifications?.length ?
                                candidateUser?.ParsedDataCV?.certifications?.map((certificate, i) => {
                                    <Badge
                                        key={i}
                                        className='p-3'
                                        color="primary"
                                        pill
                                    >
                                        {certificate?.name}
                                    </Badge>
                                })
                                :
                                !profileC?.certifications ? (
                                    <Alert color="Secondary">
                                        Aucune certification n'a été ajoutée !
                                    </Alert>
                                ) : (
                                    <ul>
                                        <li>
                                            {profileC?.certifications}
                                            <p
                                                style={{
                                                    fontWeight: "light",
                                                    fontStyle: "italic",
                                                    margin: "0px 10px",
                                                    color: "#6c7592",
                                                }}
                                            >
                                                {profileC?.certificationLevel}
                                            </p>
                                        </li>
                                    </ul>
                                )}
                        </CardComp>
                            : null}
                        <br />

                        {/* user?._id === candidateUser?._id && <CardComp header='Présentation générale'>
                            {(candidateProgressProfile && candidateProgressProfile.IsAppliedToGeneralPresentation) ?
                                <p>Vous avez déjà créé votre présentation générale audio</p>
                                :
                                <Link to={`/jobs/apply?jobId=${generalParamId}`}>
                                    Créer votre présentation générale en audio
                                </Link>
                            }

                        </CardComp> */}

                        {candidateUser?.ParsedDataCV?.languages.length ?
                            <CardComp header="Langues" hidden>
                                <div style={{ display: 'Grid', gridTemplateColumns: "auto auto", gap: "20px" }}>


                                    {candidateUser?.ParsedDataCV?.languages?.map((lang, i) =>
                                        <ul key={i}>
                                            <li>
                                                {lang?.name}
                                                <p
                                                    style={{
                                                        fontWeight: "light",
                                                        fontStyle: "italic",
                                                        margin: "0px 10px",
                                                        color: "#6c7592",
                                                    }}
                                                >
                                                    {lang?.code}
                                                </p>
                                            </li>
                                        </ul>
                                    )}
                                </div>

                            </CardComp>
                            : null}
                        <br />
                        {candidateUser?.ParsedDataCV?.interests.length ?
                            <CardComp header="Intérêts" hidden>

                                {candidateUser?.ParsedDataCV?.interests?.map((interest, i) =>
                                    <ul key={i}>
                                        <li>
                                            {interest?.name}
                                        </li>
                                    </ul>
                                )}

                            </CardComp>
                            : null}
                        <br />
                    </Card>
                </Col>
                <Col sm='8'>
                    <FormCompSP
                        getUser={getUser}
                        getProfileCandidate={getProfileCandidate}
                        competencies={competencies?.competencies.length ? competencies : CompetenciesFromParsedCV}
                        profileC={profileC}
                        candidateUser={candidateUser}
                        candidateProgressProfile={candidateProgressProfile}
                        getCandidateCompetencies={getCandidateCompetencies}
                        jobApplication={jobApplication}
                        t={t}
                    />
                </Col>
            </Row>}

        </div>
    )
}

export default SharedProfileCandidateComp