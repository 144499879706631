import React, { useState } from 'react'
import { Button, Container, Row } from 'reactstrap';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import { Document, Page, pdfjs } from "react-pdf";
import config from 'config';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const DisplayCVCandidate = ({match}) => {
    const {pathnameCV} = match.params
    console.log(match.params);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    const changePage = (numb) => {
      setPageNumber((prevPageNumber) => prevPageNumber + numb);
    };
    
    const width = useWindowWidth();
  
  return (
    <Container fluid>
      <center>
        <p
          style={{
            fontSize: "15px",
            lineHeight: "24px",
            fontWeight: 400,
            color: "#696969",
            margin: "10px",
          }}
        >
          Page <span className="fw-bold" >{pageNumber}</span> of{" "}
          <span className="fw-bold">{numPages}</span>
        </p>
        {pageNumber > 1 && (
          <Button
            onClick={() => changePage(-1)}
            color="light"
            style={{ marginRight: "10px" }}
          >
            Précédent
          </Button>
        )}
        {pageNumber < numPages && (
          <Button onClick={() => changePage(+1)} color="light">
            Suivant
          </Button>
        )}
        <Row className="w-75">
          <hr className="my-2" />
        </Row>
        {config.Environment !== "Dev" && pathnameCV && <Row>
          <Document 
            file={`${config.Data_URL}uploads/${pathnameCV}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<h3>please wait...</h3>}
          >
            <Page width={0.8*width} pageNumber={pageNumber} />
          </Document>
         
          
        </Row>}

        {config.Environment == "Dev" && pathnameCV && <Row>
        <img
                src={"assets/img/brand/500.png"}
                onError={(e) => {
                  e.target.src =
                    require("../../assets/img/brand/500.png").default;
                }}
            />
        </Row>}
        <Row className="w-75">
          <hr className="my-2" />
        </Row>
      </center>
    </Container>

  )
}

export default DisplayCVCandidate