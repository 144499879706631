import axios from "axios";
import React, { useState, useEffect } from "react";
import ShowInterviewVideoAndScreen from "components/interview/ShowInterviewVideoAndScreen";
import ShowInterviewVideoAndTextualResponse from "components/interview/ShowInterviewVideoAndTextualResponse";
import ShowInterviewV2 from "components/interview/ShowInterviewV2";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { options_interview_method } from "options";
import ShowInterviewInFranceCandidate from "components/interview/ShowInterviewInFranceCandidate";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import config from "config";
// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function InterviewFormV5({ interview, interviewId }) {
  let user = JSON.parse(localStorage.getItem("user"));
  let userID = user._id;
  let jobID = interview?.job?._id;
  let application_method = interview?.job?.application_method || null;
  //console.log('application_method',application_method)
  //console.log('job', interview)

  const [interviewMethod, setInterviewMethod] = useState("");
  let questionCatalog1jobID = interview?.job?.questionCatalog1;
  let questionCatalog2jobID = interview?.job?.questionCatalog2;
  let questionCatalog3jobID = interview?.job?.questionCatalog3;
  let questionCatalog4jobID = interview?.job?.questionCatalog4;
  let questionCatalog5jobID = interview?.job?.questionCatalog5;
  let questionCatalog6jobID = interview?.job?.questionCatalog6;
  let questionCatalog7jobID = interview?.job?.questionCatalog7;


  //console.log('QC1',questionCatalog1jobID);
  //console.log('QC2',questionCatalog2jobID);
  //console.log('QC3',questionCatalog3jobID);


  const [allInterviewQuestions, setAllInterviewQuestions] = useState([]);
  const [prenom, setPrenom] = useState(user.lastName);
  const [nom, setNom] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [telephone, setTelephone] = useState("");
  const [CV, setCV] = useState("");
  const [start, setStart] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allQuestionsLoading, setAllQuestionsLoading] = useState(false);
  const [method, setMethod] = useState(null);

  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }


  const fetchQuestionCatalogQuestions = () => {
    //instance.get(`/jobs/${id}`).then((resp) => {

    if (interview.job && interview.job.questions) {
      // setAllInterviewQuestions({...allInterviewQuestions,...interview.job.questions});
      // setAllInterviewQuestions([...allInterviewQuestions,...interview.job.questions]);
      let response_brut_0;
      response_brut_0 = interview.job.questions;
      for (var i = 0; i < (response_brut_0).length; i++) {
        // setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_0[i]]);
        allInterviewQuestions.push(response_brut_0[i]);
      }

    }

    if (questionCatalog1jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog1jobID}`).then((resp) => {

        // console.log("aaaaaaaaaaaaaa function fetchJobs fichier offres OFCF: resp.data eq : ", resp.data);
        // jobs_array.concat(resp.data);
        // setJobs(jobs.concat(resp.data));
        //setJobs(jobs => jobs.concat(resp.data));
        //setData(data  => data.concat(resp.data));
        // console.log(resp.data);



        let response_brut_1;
        console.log("condition regex 1", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }

        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }

        // setAllInterviewQuestions([...allInterviewQuestions,...resp.data.questions]);
        // setAllInterviewQuestions({...allInterviewQuestions,...resp.data.questions});



        //job = resp.data.job;
        // console.log(job);
      });
    }
    if (questionCatalog2jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog2jobID}`).then((resp) => {

        // console.log("aaaaaaaaaaaaaa function fetchJobs fichier offres OFCF: resp.data eq : ", resp.data);
        // jobs_array.concat(resp.data);
        // setJobs(jobs.concat(resp.data));
        //setJobs(jobs => jobs.concat(resp.data));
        //setData(data  => data.concat(resp.data));
        // console.log(resp.data);



        let response_brut_1;
        console.log("condition regex 2", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }

        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }

        // setAllInterviewQuestions([...allInterviewQuestions,...resp.data.questions]);
        // setAllInterviewQuestions({...allInterviewQuestions,...resp.data.questions});



        //job = resp.data.job;
        // console.log(job);
      });
    }
    if (questionCatalog3jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog3jobID}`).then((resp) => {

        // console.log("aaaaaaaaaaaaaa function fetchJobs fichier offres OFCF: resp.data eq : ", resp.data);
        // jobs_array.concat(resp.data);
        // setJobs(jobs.concat(resp.data));
        //setJobs(jobs => jobs.concat(resp.data));
        //setData(data  => data.concat(resp.data));
        // console.log(resp.data);



        let response_brut_1;

        console.log("condition regex 3", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }
        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }

        // setAllInterviewQuestions([...allInterviewQuestions,...resp.data.questions]);
        // setAllInterviewQuestions({...allInterviewQuestions,...resp.data.questions});



        //job = resp.data.job;
        // console.log(job);
      });
    }

    if (questionCatalog4jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog4jobID}`).then((resp) => {

        let response_brut_1;

        console.log("condition regex 4", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }
        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }
      });
    }

    if (questionCatalog5jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog5jobID}`).then((resp) => {

        let response_brut_1;

        console.log("condition regex 5", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }
        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }
      });
    }

    if (questionCatalog6jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog6jobID}`).then((resp) => {

        let response_brut_1;

        console.log("condition regex 6", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }
        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }
      });
    }

    if (questionCatalog7jobID) {

      instance.get(`/jobs/getjobbyid/${questionCatalog7jobID}`).then((resp) => {

        let response_brut_1;

        console.log("condition regex 7", /Coding/i.test(resp.data.title))

        if(/Coding/i.test(resp.data.title)){
          response_brut_1 = resp.data.questions;
        }
        else {
          response_brut_1 = getMultipleRandom(resp.data.questions, 12);
        }
        // console.log(getMultipleRandom(response_brut_1, 2));
        for (var i = 0; i < (response_brut_1).length; i++) {
          setAllInterviewQuestions(allInterviewQuestions => [...allInterviewQuestions, response_brut_1[i]]);
        }
      });
    }

    //add IA Questions generated from job Description
    if(interview?.job?.Add_IAquestions_from_jobDescription && interview?.job?.IAquestions_from_jobDescription.length ){
       const IaQuestions = interview?.job?.IAquestions_from_jobDescription;
       //console.log('IaQuestions_JobDes', IaQuestions);
       setAllInterviewQuestions(allInterviewQuestions =>[...allInterviewQuestions, ...IaQuestions ])
    }
    
    //add IA Questions generated from CV Candidate
    const IAQuestionsFromCVCandidate = interview?.job?.IAquestions_from_CV_Candidate?.find(q => q.candidate === user._id);
    //console.log('IAQuestionsFromCVCandidate', IAQuestionsFromCVCandidate)

    if(interview?.job?.IAquestions_from_CV_Candidate && interview?.job?.IAquestions_from_CV_CandidateCount && IAQuestionsFromCVCandidate){
      const IaQuestions = IAQuestionsFromCVCandidate.questions;
      setAllInterviewQuestions(allInterviewQuestions =>[...allInterviewQuestions, ...IaQuestions ])
   }

    //console.log('allInterviewQuestions', allInterviewQuestions);

    setAllQuestionsLoading(true);


  };

  const handleSubmitForm = (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("userID", userID);
    formData.append("jobID", jobID);
    formData.append("nom", nom);
    formData.append("prenom", prenom);
    formData.append("email", email);
    formData.append("telephone", telephone);
    formData.append("CV", CV);

    let QCs = [];


    questionCatalog1jobID && QCs.push(questionCatalog1jobID)
    questionCatalog2jobID && QCs.push(questionCatalog2jobID)
    questionCatalog3jobID && QCs.push(questionCatalog3jobID)
    questionCatalog4jobID && QCs.push(questionCatalog4jobID)
    questionCatalog5jobID && QCs.push(questionCatalog5jobID)
    questionCatalog6jobID && QCs.push(questionCatalog6jobID)
    questionCatalog7jobID && QCs.push(questionCatalog7jobID)

    console.log("QCs", QCs)

    formData.append("questionCatalog", JSON.stringify(QCs));



    // console.log("22222222222222222222222: handleSubmitForm: ");
    // console.log("22222222222222222222222: handleSubmitForm: CV: ", CV);
    // console.log("22222222222222222222222: handleSubmitForm: nom: ", nom);


    instance.post(`/jobs/createJobAppForCandidateV4/${interviewId}`, formData).then((res) => {
      // console.log("1111111111111111111111");
      // console.log("1111111111111111111111: res: ", res);
      if (res.data) {
        instance.put("/jobs/candidatID", { jobId: interview.job._id, userID: user._id, candidat: res.data._id }).then((response) => {
          localStorage.setItem("participationId", response.data.participation._id);
          // console.log("0000000000000000000 put /jobs/candidatID: response.data", response.data);
          // console.log("0000000000000000000 put /jobs/candidatID: response.data.participation.interviewCandidat", response.data.participation.interviewCandidat);
          // console.log("0000000000000000000 put /jobs/candidatID: response.data.participation.CV", response.data.participation.CV);

          {/*
          
              instance.put(`/jobApplication/updateJobApplicationOneField/${res.data.participation.interviewCandidat}/CV`, {fieldValue: res.data.participation.CV }).then((res)=>{
                localStorage.setItem("participationId", res.data.participation._id);
              });

            */}

        });

        localStorage.setItem("candidatId", res.data._id);
        localStorage.setItem("candName", res.data.nom);
        localStorage.setItem("candLastName", res.data.prenom);
        
        //console.log('Add_IAquestions_from_CV_Candidate',interview?.job?.Add_IAquestions_from_CV_Candidate)
        //console.log('IAquestions_from_CV_CandidateCount',interview?.job?.IAquestions_from_CV_CandidateCount)
        const IAQuestionsFromCVCandidate = interview?.job?.IAquestions_from_CV_Candidate?.find(q => q.candidate === user._id);
        //console.log('IAQuestionsFromCVCandidate',IAQuestionsFromCVCandidate)

        if (interview?.job?.Add_IAquestions_from_CV_Candidate && interview?.job?.IAquestions_from_CV_CandidateCount && !IAQuestionsFromCVCandidate?.questions?.length) {
          //Create IA questions related to CV Candidate
          instance.get(`openAI/GenerateQuestionsFomCvCandidate/${res.data._id}/${interview.job.IAquestions_from_CV_CandidateCount}`).then(response => {
            console.log('QGPT created')
            setLoading(false);
            setStart(true);
          })
            .catch((error) => {
              console.log(error)
              setStart(true);
            })
        }
        
        else {
          setStart(true);
        }
        setError(false);
        //setStart(true);

      }
    })
      .catch((err) => {
        if (err) {
          setError(true);
        }
      });
    // setStart(true);
  };


  useEffect(() => {
    fetchQuestionCatalogQuestions();

  }, [loading]);

  /* useEffect(()=>{
    setMethod(interview)
  },[
    interview
  ]) */

  return (
    <>
      {!start && application_method && application_method === "only video is allowed" && (
        <Col lg="5" md="7" className="mt-4 mx-auto">
          <h3 className="text-center text-danger">
            <span className="font-bold">NOTEZ BIEN:</span> Une fois que vous appuyez sur "Commencer l'entretien",
            ne rafraîchissez pas la page et ne la quittez pas avant d'avoir terminé l'entretien.
          </h3>
          <Card className="bg-secondary shadow border-0">
            <CardBody>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nom
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={nom}
                        id="input-username"
                        placeholder="Username"
                        // onChange={(e) => setName(e.target.value)}
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Prénom
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={prenom}
                        id="input-username"
                        placeholder="Username"
                        // onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Email
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        value={email}
                        // onChange={(e) => setAdress(e.target.value)}
                        type="email"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Telephone
                      </label>
                      <Input
                        className="form-control-alternative"

                        placeholder="Telephone"
                        onChange={(e) => setTelephone(e.target.value)}
                        type="number"

                      />
                    </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Votre CV
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="file"
                        accept=".pdf"
                        onChange={(e) =>
                          setCV(e.target.files[0])
                        }
                      // CVinput
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  {(loading && !error) && (
                    <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
                      <ClipLoader color="#f00" />
                    </div>
                  )}
                  {(!loading || error) && (
                    <Button
                      type="submit"
                      color="primary"
                      className="px-5 py-2  text-white"
                      style={{ backgroundColor: "#11a0ef" }}
                    >
                      Commencer l'entretien
                    </Button>
                  )
                  }

                </Row>
                {error && (
                  <p className="text-red-600 text-center">
                    Informations d'identification erronée !
                  </p>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}
      {!start && (!application_method || (application_method && application_method === "candidate can choose")) && (
        <Col lg="5" md="7" className="mt-4 mx-auto">
          <h3 className="text-center">
            Vous allez trouver des questions auxquelles vous allez répondre en vidéo. Vos réponses vont être envoyées au recruteur qui vous contactera rapidement pour la suite.
          </h3>
          <Card className="bg-secondary shadow border-0">
            <CardBody>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Nom
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={nom}
                        id="input-username"
                        placeholder="Username"
                        // onChange={(e) => setName(e.target.value)}
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-username"
                      >
                        Prénom
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={prenom}
                        id="input-username"
                        placeholder="Username"
                        // onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Email
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        value={email}
                        // onChange={(e) => setAdress(e.target.value)}
                        type="email"
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Telephone
                      </label>
                      <Input
                        className="form-control-alternative"

                        placeholder="Telephone"
                        onChange={(e) => setTelephone(e.target.value)}
                        type="number"

                      />
                    </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        Votre CV
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="file"
                        accept=".pdf"
                        onChange={(e) =>
                          setCV(e.target.files[0])
                        }
                      // CVinput
                      />
                      {/* </div> */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <label className="px-lg-3">Comment vous voulez passer cet auto entretien?</label>
                      <Select
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        // placeholder="profile_categories" 
                        className="w-full "
                        options={options_interview_method}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setInterviewMethod(e.value) }}
                      // value={values.NiveauDiplome || ""} 
                      //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                      //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-md-center">
                  <Button
                    type="submit"
                    color="primary"
                    className="px-5 py-2  text-white"
                    // onClick={setLoading(false)}
                    style={{ backgroundColor: "#11a0ef" }}
                  >
                    Commencer l'entretien
                  </Button>
                  {/*
                {loading &&
                  <Button
                  type="submit"
                  color="primary"
                  className="px-5 py-2  text-white"
                  // onClick={setLoading(false)}
                  style={{backgroundColor:"#11a0ef"}}
                >
                  Commencer l'entretien
                </Button>

                }
                {!loading && (
                  <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
                    <div style={{ display: "block" }}>
                      <h3 className=" text-white text-center">
                        Veuillez patienter ...
                      </h3>
                      <div style={{ transform: "translate(180%)", width: "20%" }}>
                        <ChangingProgressProvider
                          values={[
                            3, 6, 9, 12, 15, 18, 21, 25, 28, 31, 34, 38, 42, 45, 48,
                            51, 55, 59, 62, 64, 67, 70, 73, 76, 79, 82, 85, 90, 95,
                            99,
                          ]}
                        >
                          {(percentage) => (
                            <CircularProgressbar
                              value={percentage}
                              text={`${percentage}%`}
                              styles={buildStyles({
                                pathTransitionDuration: 1,
                              })}
                            />
                          )}
                        </ChangingProgressProvider>
                      </div>
                      <h4 className="text-white text-center">
                        Ne fermez pas la page, cela peut prendre un certain temps!
                      </h4>
                    </div>
                  </div>
                )}
        */}
                </Row>
                {error && (
                  <p className="text-red-600 text-center">
                    Informations d'identification erronée !
                  </p>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      )}
      {start && (application_method === "only video is allowed") && user.email !== "etranger111@gmail.com" && user.email !== "etranger262@gmail.com" && (
        <>
          {/* console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : allInterviewQuestions: ", allInterviewQuestions) */}

          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : Return: ") */}
          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : Return: interview.job.questions: ", interview.job.questions) */}
          <ShowInterviewV2
            interviewId={interviewId}
            interview={interview}
            // questions={interview.job.questions}
            questions={allInterviewQuestions}
            data={{ nom, prenom, email, telephone }}
          />
          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : Return: interview.job: ", interview.job) */}

          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : Return: interview.job._id: ", interview.job._id) */}

          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: : Return: interview.job.questionCatalog1: ", interview.job.questionCatalog1) */}


        </>
      )}
      {start && user.location !== "France" && user.email === "etranger111@gmail.com" && (
        <>
          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: start && user.emailetranger111@gmail.com") */}
          <ShowInterviewVideoAndScreen
            interviewId={interviewId}
            interview={interview}
            questions={interview.job.questions}
            data={{ nom, prenom, email, telephone }}
          />
        </>
      )}
      {start && user.location !== "France" && user.email === "etranger262@gmail.com" && (
        <>
          {/*console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa: start && etranger262@gmail.com") */}
          <ShowInterviewVideoAndTextualResponse
            interviewId={interviewId}
            interview={interview}
            questions={interview.job.questions}
            data={{ nom, prenom, email, telephone }}
          />
        </>
      )}
      {start && (!application_method || application_method === "candidate can choose") && (
        <ShowInterviewInFranceCandidate
          interviewId={interviewId}
          interview={interview}
          questions={allInterviewQuestions}
          data={{ nom, prenom, email, telephone }}
          interviewMethod={interviewMethod}
        />
      )}
    </>
  );
}

export default InterviewFormV5;
