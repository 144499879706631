import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/outline';

const OneQuestionCatalog = ({ activequestionCatalogs, QC, onChange, idx,removeQC }) => {
  const { t, i18n } = useTranslation();
  const [currentQC, setCurrentQC] = useState(null);

  useEffect(()=>{
    setCurrentQC(QC)
  },[QC])


  const isQCFound = activequestionCatalogs.find(option =>option.value === currentQC);
  
  return (
    <Row className="mb-4 w-full">
      <Col lg="8">


        {/* <Row className="font-semibold text-lg text-black">
      <h4 >{t('QuestionBlock')} {idx} :</h4>
    </Row> */}
        <Row>
          <h4 className='mt-2' >{t('QuestionBlock')} {idx} :</h4>
          <Select
            className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
            options={activequestionCatalogs}
            value={isQCFound || null}
            onChange={onChange}
          />

          {isQCFound && <XIcon
            className="h-6 cursor-pointer text-red-600 mt-2"
            onClick={removeQC}
          />}


        </Row>


      </Col>
    </Row>

  )
}

export default OneQuestionCatalog