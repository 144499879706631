import { PencilIcon } from '@heroicons/react/solid'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, Button, Input } from 'reactstrap'

const OneCodingQuestion = ({ item, AppendOneCQToJob, questions }) => {
    const isAlreadyChoosen = questions.find(qs => qs._id === item._id)
    //console.log('isAlreadyChoosen', isAlreadyChoosen)
    return (
        <>
            <tr>
                {item?.type === 'Code' ? <>
                    <td>
                        <Input disabled={isAlreadyChoosen} checked={isAlreadyChoosen} onChange={() => AppendOneCQToJob(item._id)} type="checkbox" />
                    </td>
                    <th scope="row">
                        {item.title}
                    </th>
                    <td>
                        {item.Domain}
                    </td>
                    <td>
                        {item.Level}
                    </td>
                </>
                    :
                    <>
                        <td>
                            <Input disabled={isAlreadyChoosen} checked={isAlreadyChoosen} onChange={() => AppendOneCQToJob(item._id)} type="radio" />
                        </td>
                        <th scope="row">
                            {item.question.length > 45 ? `${item.question.slice(0, 45)}...` : item.question}
                        </th>
                        <td>
                            <Badge color='primary'>{item.type}</Badge>
                        </td>
                    </>
                }
                <td>
                    {item.timeLimit}
                </td>
            </tr>
        </>
    )
}

export default OneCodingQuestion