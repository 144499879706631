import axios from "axios";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import JobCandidats from "components/recruteur/JobCandidats";
import OneJob from "components/recruteur/oneJob";


import CandidatMyProfileGenPrezVideo from "components/candidat/CandidatMyProfileGenPrezVideo";
import CandidatShowMyProfileGenPrezVideo from "components/candidat/CandidatShowMyProfileGenPrezVideo";


//import LandingPageCandidatures from "components/landingPage/landingPageCandidatures";
import HomeNavbar from "components/Navbars/HomeNavbar";
import Header from "components/Headers/Header";
import "components/app.css";


import config from "config";
import LoginPostul from "components/loginPostul";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function CandidatMyProfile() {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }




  return (
    <>
    <Header />
      <Container className="landingPageStyle">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
                <div className="pl-lg-4">
                  <Row>
                    {/*OneJob CandidatMyProfileGenPrezVideo */}
                  <CandidatMyProfileGenPrezVideo jobId={config.General_Presentation_Job_ID} />
                  </Row>
                </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
                <div className="pl-lg-4">
                  <Row>
                    {/*<CandidatShowMyProfileGenPrezVideo /> */}
                  <CandidatShowMyProfileGenPrezVideo />
                  </Row>
                </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CandidatMyProfile;
