
import { fetchOneTraining } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Label, Row } from 'reactstrap'
import { useHistory } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useCustomTranslation from 'components/other/useCustomTranslation';
import { MdOndemandVideo } from "react-icons/md";


const TrainingOverViewComp = ({ id }) => {
    const history = useHistory();
    const [oneTraining, setOneTraining] = useState({});
    const { t } = useCustomTranslation();

    const GetOneTraining = async () => {
        try {
            const { data } = await fetchOneTraining(id)
            data && setOneTraining(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetOneTraining()
    }, [])

    const VideosNb = oneTraining?.sections?.filter(sect => sect.video)?.length;
    const SectionsNb = oneTraining?.sections?.length;

    return (

        <Container className='d-flex justify-content-center'>

            <Row className="justify-content-center border-0 py-2" style={{ width: "100%" }}>
                <Col xs="2" >
                </Col>
                <Col xs="10">
                    <Row className='bg-secondary w-100 mt-2 mb-5 p-4'>
                        <Col sm='10'>
                            <h1 className='ml-5'>{oneTraining?.label}</h1>
                            <Row className='pl-4 mt-1'>
                                {VideosNb && <Col sm='1'>
                                    <Row>
                                        <h5>{VideosNb}</h5>
                                        <MdOndemandVideo size={20} />
                                    </Row>
                                </Col>}
                                {
                                    SectionsNb && <Col>
                                        <h5> {SectionsNb} sections</h5>
                                    </Col>
                                }

                            </Row>
                        </Col>
                        <Col sm='2'>
                            <Button

                                color='primary'
                                onClick={() => history.push('/candidate/subscriptions')} >
                                {t('Subscribe')}
                            </Button>
                        </Col>

                    </Row>
                    <Row className='w-100'>
                        <Accordion defaultExpanded className='w-100'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <h3>{t('CourseContent')}</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='list-decimal list-inside bg-white '>
                                    {oneTraining?.sections?.map(section =>
                                        <li
                                            key={section._id}
                                            className='pt-2 pb-2'
                                        >

                                            {section.name}
                                        </li>
                                    )}
                                </div>
                            </AccordionDetails>

                        </Accordion>

                    </Row>



                </Col>
            </Row>
        </Container>
    )
}

export default TrainingOverViewComp