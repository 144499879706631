import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { Row, Button, Col, Card, CardImg, FormGroup, InputGroup, InputGroupAddon, CardHeader, Table, CardBody, Form, Label } from "reactstrap";

import "../custom.css";
import config from "config";
import { useTranslation } from "react-i18next";


// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function UserManagementActionIntroduction(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [userRecruiter, setUserRecruiter] = useState();
  const [firstnameUserRecruiter, setFirstnameUserRecruiter] = useState("");
  const [lastnameUserRecruiter, setLastnameUserRecruiter] = useState("");

  const [jobApplicationNote, setJobApplicationNote] = useState({});
  const [jobID, setJobID] = useState("");
  const [jobApplicationID, setJobApplicationID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});
  const [createdAt, setCreatedAt] = useState("");



const fetchRecruiterUser = () => {
  // console.log("6666666666666666: jobApplicationNoteIntroduction: ");
  instance.get(`users/getprofilebyuserid/${props.recruiterUserID}`).then((resp) => {
    // console.log("6666666666666666: jobApplicationNoteIntroduction: resp: ", resp);
    // console.log("function editUser : resp: ", resp);
    setUserRecruiter(resp.data);
    // setUserID(resp.data._id);
    setFirstnameUserRecruiter(resp.data.name);
    setLastnameUserRecruiter(resp.data.lastName);
    // setEmail(resp.data.email);

  });
};

   useEffect(() => {
    fetchRecruiterUser();
  }, [props.recruiterUserID]);

  return (
    <>
    {userRecruiter && (
      <>
        <Row>  
          <Label>
            {(firstnameUserRecruiter) && (firstnameUserRecruiter)} 
          </Label>
           &nbsp;
          <Label>
            {(lastnameUserRecruiter) && (lastnameUserRecruiter)}
          </Label>
          &nbsp;
          <Label>
          {t('NoteKEY6')}:
          </Label>
          
        </Row>
      </>
    )   
    }
    </>
  );
}
export default UserManagementActionIntroduction;
