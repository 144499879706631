import React from 'react';
import {Route, Redirect} from 'react-router-dom';

var user;
const PrivateRouteCandidat = ({ component: Component, ...rest }) => (
     user=JSON.parse(localStorage.getItem("user")),
    <Route {...rest} render={(props) => (
        
        localStorage.getItem("token") && localStorage.getItem("user")
            ? (user.role==="candidat"
                ? <Component {...props} />
                : <Redirect to='/home/login'/>)
            : <Redirect to='/home/login' />
    )} />
);

export default PrivateRouteCandidat;
