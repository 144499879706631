import axios from "axios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";

import OneCardForFrCandidateRecruters from "components/recruteur/oneCardForFrCandidateRecruters";




import qs from "query-string";

import config from "config";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import OneCardForFrUserAndFakeCandidateForRecruters from "components/recruteur/OneCardForFrUser&FakeCandidateForRecruiter";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});


function FrUsersForRecrutersView() {
  const [candidates, setCandidates] = useState([]);
  var totalJobApplicationsNumber;
  const [pageCount, setPageCount] = useState(0);
  const [perPage] = useState(10);
  const location = useLocation();
  const query = qs.parse(location.search);
  const [pageNumber, setPageNumber] = useState(1);
  // var pageNumber = (query.pageNumber) || 1;

  const history = useHistory();


  

  /* const getUsersNumber = () => {
    instance.get(`users/getFranceUsersNumber/${perPage}`).then((resp) => {
      // console.log("resp: ", resp);
      // console.log("0000000000000000000000000: resp.data.cnt", resp.data.cnt);
      // setTotalJobsNumber(resp.data.cnt);
      totalJobApplicationsNumber = resp.data.cnt;
      // console.log("totalJobsNumber: ", totalJobsNumber);
      // console.log("perPage: ", perPage);
      setPageCount(Math.ceil(totalJobApplicationsNumber / perPage));
      // console.log("pageCount: ", pageCount);
  
    });
  }; */

  const getUsersNumberV1 = () => {
    instance.get(`users/FrUsersAndFakeNumber`).then((resp) => {
      setPageCount(Math.ceil(resp.data.cnt / perPage));  
    });
  };

  /* const getUsers = () => {
    // console.log("frUsersForRecrutersView getUsers");
    // console.log("frUsersForRecrutersView getUsers jobID", jobID);

    // console.log("frUsersForRecrutersView getUsers");
    // console.log("frUsersForRecrutersView getUsers: pipeline: ", pipeline);
    // console.log("frUsersForRecrutersView getUsers: pageNumber: ", pageNumber);
    // console.log("frUsersForRecrutersView getUsers: perPage: ", perPage);
    instance.get(`users/getFranceUsersByPipelinePerPage/Valid/${pageNumber}/${perPage}`).then((resp) => {
      // console.log("frUsersForRecrutersView getUsers");
      // console.log("getJobApplication: ", resp.data.users);
      // console.log("frUsersForRecrutersView JobApplicationsEmployerV3: createdAt", createdAt);
      setCandidates(resp.data.users);
      
    });
  }; */

  /* const getFranceUsersPerPage = () => {
    instance.get(`users/getFranceUsersPerPage/${pageNumber}/${perPage}`).then((resp) => {
  
      setCandidates(resp.data?.users ? resp.data.users : resp.data.Freeusers);
      
    });
  }; */

  const getFranceUsersPerPageV1 = () => {
    instance.get(`users/FrUsersAndFake/${pageNumber}/${perPage}`).then((resp) => {
  
      setCandidates(resp.data.users);
      
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // console.log("3333333333333333333333: next page url: ", `/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // setOffset(selectedPage + selectedPage * 5);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/employer/suivi/FrUsersView/page/?pageNumber=${selectedPage+1}`);
    setPageNumber(selectedPage+1);
    // history.push(`/superadmin/home`);
  };

  useEffect(() => {
    // getJobApplications();
    getUsersNumberV1()
    // getUsers();
    getFranceUsersPerPageV1();
  }, [pageNumber]);

  return (
    <>
      <EmployerLayout />
        <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">

        <Card className="bg-secondary shadow">
          <CardBody>
          <hr className="my-4" />
            {candidates?.map((candidateUser) => (
                /* <OneCardForFrCandidateRecruters 
                  key={candidateUser._id}
                  candidateUserID={candidateUser._id} 
                  candidateEmail={candidateUser.email}

                /> */
                <OneCardForFrUserAndFakeCandidateForRecruters
                  key={candidateUser._id}
                  candidateUser={candidateUser}
                />
              ))}
            </CardBody>
          <CardFooter className="bg-transparent w-full">
          <Row>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                // forcePage = {pageNumber}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            </Row>
          </CardFooter>
        </Card>
        </Col>

        </Row>

      </Container>
            
    </>
  );
}

export default FrUsersForRecrutersView;