import PageSpace from "components/styles/pageSpace";
import HomeHeaderV2 from "components/Headers/HomeHeaderV2";
import React, { useState, useEffect } from "react";
import config from "config";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;





function GeneralConditions() {
  const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);



  return (
    <>
     
      <HomeHeaderV2/>
      <PageSpace />
      <PageSpace />
      <PageSpace />
      <Container fluid>
        <>
        <Row>
        <Col>
           <div
            className="homePageStyle"
            style={{
                //backgroundColor: "#f7fafc",
                 //objectFit: "cover",
                justifyContent:'center',
                alignItems:'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
            }}
           
           
           >
            <h2>Conditions générales</h2>           
           </div>
          </Col>
          </Row>
        <Row>
              <nav>
              <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
              <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
            </nav>

            </Row>
            <Row>
                <hr className="my-2" />
            </Row>
          <Row>
                <Document file={`${config.Data_URL}/uploads/HirelandsConditionsGénérales.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
          <Row/>
        
          </Row>
        </>    
      </Container>
    </>
  );
}

export default GeneralConditions;
