import axios from "axios";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import JobCandidats from "components/recruteur/JobCandidats";
import LandingPageCandidatures from "components/landingPage/landingPageCandidatures";
import config from "config";
import LoginPostul from "components/loginPostul";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function LandingPageOnev2({ jobId }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }
  useEffect(() => {
    instance.get(`jobs/${jobId}`).then((resp) => {
      setJob(resp.data.job);
      setCreated(resp.data.job.createdAt);
      setInterviewUrl(resp.data.job.interview.url);
      setEndDate(resp.data.job.endDate.slice(0, 10));
      if (localStorage.getItem("user")) {
        resp.data.job.participations.forEach((e) => {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        });
        if (JSON.parse(localStorage.getItem("user")).jobs) {
          JSON.parse(localStorage.getItem("user")).jobs.forEach((e) => {
            if (e == jobId) {
              setUrl(true);
            }
          });
        }
        if (JSON.parse(localStorage.getItem("user")).role == "employer") {
          setUrl(true);
        }
      }
    });
  }, [jobId]);

  const participate = () => {
    const formData = new FormData();
    formData.append("CV", CV);
    formData.append("candidatID", user._id);
    formData.append("motivLttr", motivLttr);
    instance.put(`jobs/participate/${jobId}`, formData).then((resp) => {
      localStorage.setItem("user", JSON.stringify(resp.data.user));
      history.push(`/interview/${job?.interview?._id}`)
    });
  };


  return (
    <>
      <Container>
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>

                      <td
                            className={
                              (openTab === 1)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(1);}}
                            data-toggle="tab"
                            href="#link1"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Candidats
                          </td>

                          <td
                        className={openTab === 2 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        //data-toggle="tab"
                        to="#link2"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Description
                      </td>
                      {/* {user.role == "employer" && user._id == job.userID && (
                       <Col className="text-right" xs="2">
                          <Button
                        style={
                          openTab === 4
                            ? {
                                color: "#455266",
                                backgroundColor: "#cecece",
                                borderColor: "#455266",
                              }
                            : { color: "white", backgroundColor: "#455266" }
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(4);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        size="sm"
                      >
                        Modifier
                      </Button> 
                        </Col>
                      )}*/}
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <Form>
                  <h4 className=" mb-4">Description de l'emploi</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <div className="text-sm">{job.description}</div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-first-name"*/}
                    {/*            >*/}
                    {/*                First name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Lucky"*/}
                    {/*                id="input-first-name"*/}
                    {/*                placeholder="First name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-last-name"*/}
                    {/*            >*/}
                    {/*                Last name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Jesse"*/}
                    {/*                id="input-last-name"*/}
                    {/*                placeholder="Last name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h4 className=" mb-4">Données supplémentaires</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Technologies/Compétences: {job.technologies}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Langues: {job.language}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Rémunération: {job.pay}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Date d'expiration: {endDate}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">
                     About me (placeholder)
                   </h6>
                   <div className="pl-lg-4">
                     <FormGroup>
                       <label>About Me</label>
                       <Input
                         className="form-control-alternative"
                         placeholder="A few words about you ..."
                         rows="4"
                         defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                           Open Source."
                         type="textarea"
                         disabled
                       />
                     </FormGroup>
                   </div> */}
                </Form>
              </CardBody>
              <CardBody
                className={openTab === 1 ? "block" : "hidden"}
                id="link1"
              >
                <h4 className=" mb-1">Candidats ayant postulé</h4>
                <div className="pl-lg-4">
                  <Row>
                    <LandingPageCandidatures participants={job} />
                  </Row>
                </div>
              </CardBody>


              
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LandingPageOnev2;
