import useCustomTranslation from 'components/other/useCustomTranslation';
import { getUserById, EditUserParameters } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row, UncontrolledAlert } from 'reactstrap'

const ChangeCountryParameters = () => {

    const [user, setUser] = useState(null);
    const [style, setStyle] = useState('grey');
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const connectedUser = JSON.parse(localStorage.getItem('user'));
    const { t } = useCustomTranslation();

    const getUser = async () => {
        setIsLoading(true)
        try {
            const { data } = await getUserById(connectedUser?._id)
            data && setUser(data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const UpdateUserParameters = async () => {
        try {
            const { _id, changeCountryForCDI} = user
            const { data } = await EditUserParameters({ changeCountryForCDI: changeCountryForCDI, userID: _id });
            if (data.success) {
                setMsg('Votre préférence a été sauvegardée avec succès')
            }

        } catch (error) {
            console.log(error)
        }
    }

    const HandleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: +(e.target.value) })
    }

    useEffect(() => {
        getUser()
    }, [])
    return (
        <Container className='d-flex justify-content-center'>

            {!isLoading ? <Card className="bg-secondary shadow w-75">
                <CardBody className="bg-white">
                    <div className="form-group">
                        <Label>{t('changeCountry1')} ?</Label>
                        <Row>
                            <Col sm="6">
                                <Card
                                    className='w-75 cursor-pointer'
                                    style={{ border: `2px solid ${user?.changeCountryForCDI ? 'green' : style}` }}
                                    onClick={(e) => setUser({ ...user, changeCountryForCDI: true })}

                                >
                                    <CardBody>
                                        {' '}
                                        <Label>
                                            {t('yes')}
                                        </Label>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className='w-75 cursor-pointer'
                                    style={{ border: `2px solid ${!(user?.changeCountryForCDI) ? 'red' : style}` }}
                                    onClick={(e) => setUser({ ...user, changeCountryForCDI: false })}
                                >
                                    <CardBody>
                                        {' '}
                                        <Label >
                                            {t('No')}
                                        </Label>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
                {user?._id === connectedUser?._id && <CardFooter className='d-flex flex-column align-items-center'>
                    <Button
                        style={{ backgroundColor: '#f7f7f9' }}
                        onClick={UpdateUserParameters}
                    >
                        {t('save')}
                    </Button>
                    {msg && <Row className='m-2'>
                        <UncontrolledAlert color="success">
                            {msg}
                        </UncontrolledAlert>
                    </Row>}
                </CardFooter>}
            </Card>
                :
                <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-secondary w-full h-full opacity-80">
                    <ClipLoader color="black" />
                </div>}
        </Container>
    )
}

export default ChangeCountryParameters