import axios from "axios";
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";
import FillYourCandidateProfile from "components/candidat/FillYourCandidateProfile";

import InFranceCandidateLayoutV3 from "layouts/inFranceCandidateLayoutV3";

import Select from "react-select";

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
//import { DropzoneArea } from 'material-ui-dropzone';
//import {useDropzone} from 'react-dropzone';

import React, { useEffect, useState } from "react";
import config from "config";
import 'react-phone-number-input/style.css';





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});


function InFranceCreateProfile() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");
  const [formValidation, setFormValidation] = useState(false);
  

  const [alreadyPosted, setAlreadyPosted] = useState(false);


  const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/profiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };



  useEffect(() => {
  
    fetchProfile();
  
  }, [location]);
  
  return (
    <>
      <InFranceCandidateLayoutV3 />

      <PageSpace />
      <PageTitleStyle
        title="Mon profil"
      >
        
      </PageTitleStyle>
      

      <Container>
      {alreadyPosted &&
        <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <>
              <label className="px-lg-3">Votre formulaire d'inscription est déjà réalisé</label>
            </>
            
          </Col>

        </Row>
      }
        {!alreadyPosted &&
        <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="3" >
          </Col>
          <Col xs="9">
            <>
            <FillYourCandidateProfile
            />
            </>
            
          </Col>

        </Row>
      }
      </Container>
        
    </>
  );
}

export default InFranceCreateProfile;
