
import JobAppVideos from "components/employer/jobAppVideos";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { 
  Col, 
  Card, 
} from "reactstrap";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";



const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});



function CandidateAllInterviewsVideosSelectOneJobAPP(props) {
  const [allJobsOptionsForThisUser, setAllJobsOptionsForThisUser] = useState([]);
  const [selectedJobIDForInterview, setSelectedJobIDForInterview] = useState("");
  const [selectedJobAppForInterview, setSelectedJobAppForInterview] = useState({});

  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  
  
  
  const user = JSON.parse(localStorage.getItem("user"));


  // function getJobTitle (jobIDParam){
    const getJobTitle = (jobIDParam) => {


    // let jobTitleOutput = "0000000000000";
    let jobTitleOutput;
    console.log("getJobTitle: jobIDParam: ", jobIDParam);
  
    if (jobIDParam !== "") {
            
      instance.get(`jobs/${jobIDParam}`).then((respForJobTitle) => {
        console.log("getJobTitle: respForJobTitle: ", respForJobTitle);
  
        if ((respForJobTitle.data !== null) && (respForJobTitle.data.job !== null) && (respForJobTitle.data.job.title !== null)){
              jobTitleOutput = respForJobTitle.data.job.title;
              console.log("getJobTitle: respForJobTitle.data.job.title: ", respForJobTitle.data.job.title);
              // return respForJobTitle.data.job.title;
              return jobTitleOutput;
        };
      });
  
    }
    console.log("getJobTitle: jobTitheOutput", jobTitleOutput)
    // return jobTitleOutput;
  }
  const getSelectedJobApp = async (jobIDParameter) => {
      var jobIdToFoundItem;
      var newArray;
    
      // console.log("getSelectedJobApp: step 0: ");
      // console.log("getSelectedJobApp: step 1: ", candidatId);
          
      instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
        if (resp.data.length) {
          
          newArray = resp.data;
          console.log("getSelectedJobApp: newArray: ", newArray);
          
          
          for (var i = 0; i < newArray.length; i++) {
    
            jobIdToFoundItem = newArray[i].jobID;
            if (jobIdToFoundItem === jobIDParameter) {
              console.log("getSelectedJobApp: newArray[i] jobApp found: ", newArray[i]);
              setSelectedJobAppForInterview(newArray[i]);
    
      
          
            }
            
          }
    
          
            }
            
    
        
        });
    
      
  };

  





const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);

        }
        

    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });


    // setAllJobAppsIDsForThisUser(newArray.map(item => ({value: item._id, label: item.nom})));
};






const fetchAllJobOptionsForThisUserV4 = async () => {
  var jobIdItem;
  var jabAppIDItem;
  var jobTitleItem;
  var newArray;

  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
      
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      
      newArray = resp.data;
      console.log("fetchAllJobOptionsForThisUser: newArray: ", newArray);
      console.log("fetchAllJobOptionsForThisUser: newArray[0]: ", newArray[0]);
      
      for (var i = 0; i < newArray.length; i++) {

        jabAppIDItem = newArray[i]._id;
        console.log("fetchAllJobOptionsForThisUser: for jabAppIDItem:  ", jabAppIDItem);
        console.log("fetchAllJobOptionsForThisUser: for looop: newArray : ", newArray);
        console.log("fetchAllJobOptionsForThisUser: for looop:: i: ", i);
        console.log("fetchAllJobOptionsForThisUser: for jobAppID: (newArray[i])._id: ", (newArray[i])._id);
        jobIdItem = (newArray[i]).jobID;
        console.log("fetchAllJobOptionsForThisUser: jobIdItem: ", jobIdItem);
        if (jobIdItem !== "") {
          
          instance.get(`jobs/${jobIdItem}`).then((respForJobOption) => {

            



            console.log("fetchAllJobOptionsForThisUser: respForJobOption: ", respForJobOption);
            if ((respForJobOption.data !== null) && (respForJobOption.data.job !== null) && (respForJobOption.data.job.title !== null)){
                  console.log("fetchAllJobOptionsForThisUser: newArray[i]: ", newArray[i]);
                  // (newArray[i]).nom = respForJobTitle.data.job.title;
                  // setAllJobAppsTitlesForThisUser([...allJobAppsTitlesForThisUser, respForJobTitle.data.job.title]);
                  console.log("fetchAllJobOptionsForThisUser xxxx: for jobAppID: newArray[i]: ", newArray[i]);
                  jobTitleItem = respForJobOption.data.job.title;
                  console.log("fetchAllJobOptionsForThisUser xxxx: for jobTitleItem: ", jobTitleItem);
                  console.log("fetchAllJobOptionsForThisUser: respForJobOption.data.job.title: ", respForJobOption.data.job.title);
                  setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: respForJobOption.data.job._id, label: respForJobOption.data.job.title}]);
            };
          });

          jobTitleItem = getJobTitle(jobIdItem);
          console.log("fetchAllJobOptionsForThisUser xxxx: for jobTitleItem: ", jobTitleItem);
          // setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: jabAppIDItem, label: jobTitleItem}]);
  
      
        }
        // setAllJobsOptionsForThisUser(allJobsOptionsForThisUser => [...allJobsOptionsForThisUser, {value: (newArray[i])._id, label: respForJobOption.data.job.title}]);
        
      }
      console.log("fetchAllJobApplicationsForThisUser: after for looop: newArray : ", newArray);

      
        }
        

    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });

  
};








   useEffect(() => {
    fetchAllJobApplicationsForThisUser();
    fetchAllJobOptionsForThisUserV4();
  }, []);




  return (
    <>


        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">




                <form>
                  <>
                    <hr className="my-4" />
                    {allJobApplicationsForThisUser && (
                      <>
                      {/* console.log("outFrUserProfile render: allJobAppsIDsForThisUser", allJobAppsIDsForThisUser)*/}
                      {/*console.log("outFrUserProfile render: allJobAppsTitlesForThisUser", allJobAppsTitlesForThisUser)*/}
                      {console.log("outFrUserProfile render: allJobsOptionsForThisUser", allJobsOptionsForThisUser)}
                      
                      <label className="px-lg-3">Merci de choisir la candidature</label>
                      <Select 
                      //name="Niveau du diplome" 
                      //label="Niveau du diplome" 
                      name="jobAppIdd_selected"
                      // placeholder="gender" 
                      className="w-full "
                      // options={job_IDs_for_recruters}
                      options={allJobsOptionsForThisUser}
                      //fullWidth 
                      //onBlur={handleBlur} 
                      // onChange={handleChange} 
                      //value={NiveauDiplome}
                      onChange={(e) => { 
                        // console.log("0000000000000000000: e.value: ", e.value)
                        // setSelectedJobID(e.value);
                        setSelectedJobIDForInterview(e.value);
                        getSelectedJobApp(e.value);
                        // setLoading(false);
                      }} 
                      // value={values.NiveauDiplome || ""} 
                      //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                      //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                    />
                {console.log("outFrUserProfile render: selectedJobAppForInterview", selectedJobAppForInterview)}

                {selectedJobAppForInterview._id &&
                    <JobAppVideos
                      key = {selectedJobAppForInterview._id}
                      jobAppID = {selectedJobAppForInterview._id}
                    />

                }

                    </>
                    )
                    }
                    {!allJobApplicationsForThisUser &&
                      <p>Ce candidat n'a éalisé aucun entretien</p>
                      
                    }
                </>
                </form>



              
            </Card>
          </Col>
        </Row>
    </>
  );
}

export default CandidateAllInterviewsVideosSelectOneJobAPP;
