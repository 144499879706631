import {
    ViewListIcon,
    ArrowCircleDownIcon,
    PencilAltIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  } from "@heroicons/react/outline";
  import {
    LocationMarkerIcon,
    BriefcaseIcon,
    MailIcon,
    PhoneIcon,
    LinkIcon,
    CodeIcon,
    ArchiveIcon,
    OfficeBuildingIcon,
    PaperClipIcon,
    AcademicCapIcon,
    LightBulbIcon,
  } from "@heroicons/react/solid";
  import {
    DropdownMenu,
    Media,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Container,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardImg,
    CardHeader,
    CardBody,
    CardFooter,
    FormText,
    FormFeedback,
    CardText,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Table,
  } from "reactstrap";
  import axios from "axios";
  import React, { useState, useEffect } from "react";
  import { useHistory, useLocation, Link } from "react-router-dom";
  import qs from "query-string";
  import VideoPlayer from "react-video-js-player";
  import DownloadLink from "react-download-link";
  import { ClipLoader } from "react-spinners";
  import { UserIcon } from "@heroicons/react/outline";
  import "../custom.css";
  import config from "config";
  import ShowCandidat from "components/recruteur/ShowCandidat";

  
  
  const instance = axios.create({
    baseURL: config.WS_BASE_URL,
  });
  
  instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
  });
  function CandidatShowMyProfileGenPrezVideo() {


    const [candidatID, setCandidatID] = useState("");
    const [participations, setParticipations] = useState([]);
    const [candidatModal, setCandidatModal] = useState();
    const [participantId, setParticipantId] = useState("");
    const [modalDelOpen, setModalDelOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const query = qs.parse(location.search);






    const [videos, setVideos] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [candidat, setCandidat] = useState("");
    const [drive, setDrive] = useState("");
    const [hasDrive, setHasdrive] = useState(false);
    const [motivLttr, setMotivLttr] = useState(false);
    let user = JSON.parse(localStorage.getItem("user"));

    

    const fetchData = async () => {
        instance.get(`jobs/${user._id}/${config.General_Presentation_Job_ID}`).then((resp) => {
            console.log("user_id", user._id);
            console.log(resp.data);
            setParticipations(resp.data);
            console.log("33333333333333333333333333");
            console.log(" 444444444444444 participations", participations);
      
          });
      };


      useEffect(() => {
        fetchData();
      }, []);

    //console.log("jooob", candidatModal);
    return (
      <>
      <Container>
        <Row>
        <Col className="order-xl-1" xl="12">
        
        <Card className="bg-transparent w-full">

            {participations
                .map((participant) =>
                <Row
                className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                key={participant._id}
            >
                <Col lg="2">
                <img
                    src={`${config.Data_URL}${participant.candidat.image}`}
                    className="rounded-circle"
                    onError={(e) => {
                    e.target.src =
                        require("assets/img/brand/500.png").default;
                    }}
                    style={{
                    width: "100%",
                    height: "100%",

                    //objectFit: "cover",
                    }}
                    //alt="Responsive image"
                />
                </Col>
                <Col lg="10">
                <Col lg="12" className="mb-2">
                    <div>
                    <span className="card-t">
                        {participant.candidat.name}{" "}
                        {participant.candidat.lastName}
                    </span>
                    <button
                        aria-label="Close"
                        className=" close"
                        type="button"
                        onClick={() => {
                        setCandidatID(
                            participant?.interviewCandidat?._id
                        );
                        setParticipantId(participant?._id);
                        setModalDelOpen(!modalDelOpen);
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    </div>
                </Col>
                <Row>
                    <Col lg="8">
                    <Col lg="12">
                        <div
                        className="text-sm"
                        style={{ display: "inline-flex" }}
                        >
                        {" "}
                        <MailIcon
                            className=""
                            style={{ height: "1.2rem" }}
                        />{" "}
                        &nbsp; <span>{participant.candidat.email}</span>{" "}
                        </div>
                    </Col>
                    <Col lg="12">
                        <div
                        className="text-sm"
                        style={{ display: "inline-flex" }}
                        >
                        {" "}
                        <AcademicCapIcon
                            className=""
                            style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp:
                                "1" /* number of lines to show */,
                            WebkitBoxOrient: "vertical",
                            height: "1.2rem",
                            }}
                        />{" "}
                        &nbsp;{" "}
                        <span>
                            Diplome: {participant.candidat.studyLevel}
                        </span>{" "}
                        </div>
                    </Col>

                    <Col lg="12">
                        <div
                        className="text-sm"
                        style={{ display: "inline-flex" }}
                        >
                        {" "}
                        <LightBulbIcon
                            className=""
                            style={{ height: "1.2rem" }}
                        />{" "}
                        &nbsp;{" "}
                        <span>
                            {" "}
                            Expérience:{" "}
                            {participant.candidat.yearsOfExperience}
                        </span>{" "}
                        </div>
                    </Col>
                    <Col lg="12">
                        <div
                        className="text-sm"
                        style={{ display: "inline-flex" }}
                        >
                        {" "}
                        <LocationMarkerIcon
                            className=""
                            style={{ height: "1.2rem" }}
                        />{" "}
                        &nbsp; <span> aaaaaa</span>{" "}
                        </div>
                    </Col>

                    {/* <div>
                        Status:{" "}
                        <span
                        className={`${
                            job.status === "inactive"
                            ? "text-red-600"
                            : "text-green-600"
                        }`}
                        >
                        {job.status}
                        </span>
                    </div>
                    <div>
                        Updated on:{" "}
                        {`${job.updatedAt.slice(
                        0,
                        10
                        )} ${job.updatedAt.slice(11, 19)}`}
                    </div> */}
                    </Col>
                    <Col lg="4" className=" flex items-center">
                    <div>
                        <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                            setModalOpen(!modalOpen);
                            setCandidatModal(participant);
                            {/*console.log("999999999999999999", participant.interviewCandidat)*/};
                            history.push(
                                `/candidat/monprofil?candidature=${participant.interviewCandidat}`
                              );
                        }}
                        // href={`http://localhost:5100/${participant.CV}`}
                        //   target="blank"
                        >
                        Voir votre vidéo de présentation générale
                        </Button>
                    </div>
                    </Col>
                </Row>
                </Col>
            </Row>
            
                )
            }{" "}

        </Card>






        {query.candidature && (
          <Modal
            toggle={() => setModalOpen(!modalOpen)}
            isOpen={modalOpen}
            size="lg"
            style={{ maxWidth: "1100px", width: "80%" }}
          >
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                {participations?.title}
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <ShowCandidat
                //candidatId={query.candidature}
                candidatId={query.candidature}
                candidatModal={candidatModal}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
                size="sm"
              >
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Modal
          toggle={() => setModalDelOpen(!modalDelOpen)}
          isOpen={modalDelOpen}
        >
          {candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Êtes-vous sûr de vouloir supprimer cette interview ?
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}
          {!candidatID && (
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
                Ce candidat n'a pas encore passé l'entretien!
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalDelOpen(!modalDelOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
          )}

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              size="sm"
              onClick={() => setModalDelOpen(!modalDelOpen)}
            >
              ANNULER
            </Button>
            {candidatID && (
              <Button
                color="danger"
                type="button"
                size="sm"
                onClick={() => {
                  //removeCandidat();
                  setModalDelOpen(!modalDelOpen);
                }}
              >
                SUPPRIMER
              </Button>
            )}
          </ModalFooter>
        </Modal>

        </Col>
        </Row>

        </Container>
      </>
    );
  }
  
  export default CandidatShowMyProfileGenPrezVideo;
  
