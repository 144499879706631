import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";

import { useLocation, useHistory } from "react-router-dom";
import Header from "components/Headers/Header.js";
import config from "../../config";
import axios from "axios";
import EmployerLayout from "layouts/EmployerLayout";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function CompetitionForm() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [specs, setSpecs] = useState("");
  const history = useHistory();

  const createCompetition = () => {
    // console.log("eventImage", data.eventImage)
    const formData = new FormData();
    
    formData.append("title", title);
    formData.append("description", description);
    // formData.append("image", data.eventImage);
    
    formData.append("userID", JSON.parse(localStorage.getItem("user"))._id);
    formData.append("specs", specs);
    // formData.append("startDate", data.startDate);
    // formData.append("endDate", data.endDate);
    console.log("CompetitionForm: onSubmit: ");
    console.log("CompetitionForm: onSubmit: config.WS_BASE_URL: ", config.WS_BASE_URL);
    console.log("CompetitionForm: onSubmit: config.formData: ", formData);

    instance.put(`competitions/createNewCompetition`, formData)
      .then((res) => {
        console.log("CompetitionForm: createCompetition: res", res);
        history.push("/employer/Competitions/EmployerCompetitions");
      })
      .catch((err) => console.log(err));
      // console.log(formData);
  };


  return (
    <>
      <EmployerLayout />
      <Container fluid className="mt-4">
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
        <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
          <Form>
            <FormGroup>
              <Label for="title">Titre de la compétition*</Label>
              <Input

                type="text"
                name="title"
                id="title"
                placeholder="Titre de la compétition"
                onChange={(e) =>
                  setTitle(e.target.value)
                }
              />
              <FormFeedback>le titre est requis</FormFeedback>
            </FormGroup>
            {/*
              <FormGroup>
                <Label>Image*</Label>
                <Input
                  invalid={!!errors?.eventImage}
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  {...register("eventImage", {
                    required: true,
                  })}
                  onChange={handleChange}
                />
                <div
                  style={{
                    width: "100%",
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#fb6340",
                  }}
                >
                  {errors?.eventImage && "l'image est requise"}
                </div>
              </FormGroup>
              */}

            <FormGroup>
              <Label>Cahier de charge (Il faut mettre un fichier PDF)*</Label>
              <Input
                  className="form-control-alternative"
                  type="file"
                  accept=".pdf"
                  onChange={(e) =>
                    setSpecs(e.target.files[0])
                  }
                />
              <div
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#fb6340",
                }}
              >
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="Description">Description*</Label>
              <Input
                type="text"
                name="description"
                id="description"
                placeholder="Description"
                onChange={(e) =>
                  setDescription(e.target.value)
                }
              />
              <FormFeedback>la description est requise</FormFeedback>
            </FormGroup>
            {/*
                        <FormGroup>
              <Label for="startDate">Date de debut*</Label>
              <Input
                {...register("startDate", {
                  required: true,
                })}
                invalid={!!errors?.startDate}
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Date de debut"
                onChange={(e)=>setValue("startDate",e.target.value)}
              />
              <FormFeedback>la Date de debut est requise</FormFeedback>
            </FormGroup>
            */}
            {/*
                        <FormGroup>
              <Label for="endDate">Date de fin*</Label>
              <Input
                {...register("endDate", {
                  required: true,
                })}
                invalid={!!errors?.endDate}
                type="date"
                name="endDate"
                id="endDate"
                placeholder="Date de fin"
                onChange={(e)=>setValue("endDate",e.target.value)}
              />
              <FormFeedback>la Date de fin est requise</FormFeedback>
            </FormGroup>

          */}


            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={() => {
                createCompetition();
              }}
            >
              Sauvegarder
            </Button>
          </Form>
        </Card>
        </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompetitionForm;