import EmployerLayout from "layouts/EmployerLayout";
import React, { useState } from "react";

import { Box } from "@mui/material";
import JobApplicationsEmployerV3 from "components/employer/jobApplicationsEmployerV3";
import JobApplicationsCardsEmployerV2 from "components/employer/jobApplicationsCardsEmployerV2";
import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";


function RecruiteContractOutFranceView() {
  // let jobs_length = config.Jobs_for_outfrSearchPermanentFr.length;
  const [openTab, setOpenTab] = useState(1);
  return (
    <>
      <EmployerLayout />
      {/*
      <JobApplicationsCardsEmployer key="627d34016543d2ab235e8126" jobID="627d34016543d2ab235e8126" />
       */}
        <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">

        
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        En cours
                      </td>

                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          Validé
                        </td>

                          <td
                            className={
                              (openTab === 3)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);}}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Rejeté
                          </td>

                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              {openTab === 1 &&
              <CardBody
                className={openTab === 1 ? "block" : "hidden"}
                id="link1"
              >
                {/*
                <JobApplicationsCardsEmployerV2 key={"derrrrfgrgtrgtrgtrgtrgtr"} jobID={"627d34016543d2ab235e8126"} />
                <JobApplicationsCardsEmployerV2 key={"vsdvfreffrefrefrefre"} jobID={"27ce5096543d2ab235e8112"} />
              */ }
              {config.Jobs_for_outfrSearchPermanentFr
                .map((jobID) => (
                  console.log("wwwwwwwwwwwwwwwwwwwwww", jobID),
                  
                  <JobApplicationsCardsEmployerV2 
                  // key={jobID}
                  jobID={jobID} 
                  pipeline=""
                />

               ))}


              
              </CardBody>
              }
              {openTab === 2 &&
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
              <Row>
              {config.Jobs_for_outfrSearchPermanentFr
                .map((jobID) => (
                  console.log("wwwwwwwwwwwwwwwwwwwwww", jobID),
                  
                  <JobApplicationsCardsEmployerV2 
                  key={jobID}
                  jobID={jobID} 
                  pipeline="Validé"
                />

               ))}

              </Row>
              </CardBody>
              }
              {openTab === 3 &&
              <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link3"
              >
              <Row>
              {config.Jobs_for_outfrSearchPermanentFr
                .map((jobID) => (
                  console.log("wwwwwwwwwwwwwwwwwwwwww", jobID),
                  
                  <JobApplicationsCardsEmployerV2 
                  key={jobID}
                  jobID={jobID} 
                  pipeline="Rejeté"
                />

               ))}

              </Row>
              </CardBody>
              }
              </Card>
          </Col>
        </Col>
        </Row>
{/*
        <Row>
          <h2>gggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg</h2>
                
                  <JobApplicationsCardsEmployerV2 
                  key="edezdezdezdezdezde"
                  jobID="627d34086543d2ab235e814a" 
                  pipeline=""
                />



              </Row>
            */}
      </Container>
       
      

      

      
    </>
  );
}

export default RecruiteContractOutFranceView;