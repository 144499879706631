import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Col, 
  Card, 
} from "reactstrap";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";
import { useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function JobAppVideos( props ) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: props.jobAppID: ", props.jobAppID);

  

  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionType, setCurrentQuestionType] = useState();
  const [currentQCMQuestionOption1, setCurrentQCMQuestionOption1] = useState();
  const [currentQCMQuestionOption2, setCurrentQCMQuestionOption2] = useState();
  const [currentQCMQuestionOption3, setCurrentQCMQuestionOption3] = useState();
  const [currentQCMQuestionOption4, setCurrentQCMQuestionOption4] = useState();
  const [currentQCMQuestionOption5, setCurrentQCMQuestionOption5] = useState();
  const [currentQCMQuestionOption6, setCurrentQCMQuestionOption6] = useState();
  const [currentQCMQuestionSelectedOption, setCurrentQCMQuestionSelectedOption] = useState();
  const [currentQCMQuestionCorrectOption, setCurrentQCMQuestionCorrectOption] = useState();

  


  const { t, i18n } = useTranslation();

  const [candidat, setCandidat] = useState("");


  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobID, setJobID] = useState("");
  const [job, setJob] = useState({});

  
  
  const user = JSON.parse(localStorage.getItem("user"));



const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.jobAppID}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
    if (res.data.videos.length) {
      setVideos(res.data.videos);
      // if (!res.data.merged) {
      //   startMerge(res.data);
      // }
      // console.log(res.data.videos);
    }
  })
  .catch((err) => {
    console.log(err);
  });
};


const fetchJobData = () => {
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }


};




   useEffect(() => {
    fetchJobApplication();
    fetchJobData();
  
  }, []);

  const videoUrlHandler = (videoUrl, CurrentQType, currentQ) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
  };
  const QCMQuestionVideoUrlHandler = (videoUrl, CurrentQType, currentQ, QCMQuestionOption1, QCMQuestionOption2, QCMQuestionOption3, QCMQuestionOption4, QCMQuestionOption5, QCMQuestionOption6, qcmSelectedOption, QCMCorrectOption) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
    setCurrentQCMQuestionOption1(QCMQuestionOption1);
    setCurrentQCMQuestionOption2(QCMQuestionOption2);
    setCurrentQCMQuestionOption3(QCMQuestionOption3);
    setCurrentQCMQuestionOption4(QCMQuestionOption4);
    setCurrentQCMQuestionOption5(QCMQuestionOption5);
    setCurrentQCMQuestionOption6(QCMQuestionOption6);
    setCurrentQCMQuestionSelectedOption(qcmSelectedOption);
    setCurrentQCMQuestionCorrectOption(QCMCorrectOption);

  };



  return (
    <>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
                <hr className="my-4" />
                {!videos && <h3>{t('VideoNotFound')}!</h3>}
                <div className="flex  space-x-2 py-3 justify-between">
                  <ul className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">
                    {/* <h3 className="flex items-center justify-center py-3 bg-blue-600 text-white rounded-t-md">
                    
                  <button onClick={downloadEmployeeData}>Download Full interview</button>
                        </h3> */}
                    <h3 className="flex px-3 py-3 bg-white text-black font-bold ">
                      {t('InterviewQuestionsTitle')}
                      <span style={{ marginLeft: "auto" }}>
                      </span>
                    </h3>
                    {videos &&
                      videos.map((video) => (
                        <>
                          {((video?.question?.type === "QCM with only one corerct answer") || (video?.question?.type === "QCM with only one correct answer")) && (

                            <li
                            className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                            style={{color: video.qcmSelectedOption === video?.question?.QCMCorrectOption ? "green" : "red"}}
                            key={video._id}
                            onClick={
                              QCMQuestionVideoUrlHandler.bind(
                                this,
                                video.video,
                                video.question.type,
                                video.question.question,
                                video.question.QCMOption1,
                                video.question.QCMOption2,
                                video.question.QCMOption3,
                                video.question.QCMOption4,
                                video.question.QCMOption5,
                                video.question.QCMOption6,
                                video.qcmSelectedOption,
                                video.question.QCMCorrectOption
                            )
                          }
                            >
                            {t('QuestionType')}: {video?.question?.type}
                            <br />
                            {t('States')}: {video?.question?.question}
                            <br />
                            {t('CandidateResponse')}: {video.qcmSelectedOption}
                            <br />
                            {t('CorrectOption')}: {video?.question?.QCMCorrectOption}
                            </li>
                          
                            )
                          }
                          {((!video?.question?.type) || ((video?.question?.type !== "QCM with only one corerct answer") && (video?.question?.type !== "QCM with only one correct answer"))) && (
                          <li
                            className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                            key={video._id}
                            onClick={videoUrlHandler.bind(
                              this,
                              video.video,
                              video.question.type,
                              video.question.question
                            )}
                          >
                            {video?.question?.question}
                          </li>
                            )
                          }
                        </>


                      ))}
                  </ul>
                  <div className="w-1/2 py-3 list-decimal list-inside bg-white shadow-xl h-1/2 rounded-md">
                  {/* console.log("33333333333333333333: ShowCandidat:") */}
                  {/* console.log("33333333333333333333: ShowCandidat: videoUrl: ", videoUrl) */}
                  <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                    {currentQuestion}
                  </div>
                  {currentQuestionType && currentQuestionType !== null && ((currentQuestionType === "QCM with only one corerct answer") || (currentQuestionType === "QCM with only one correct answer")) &&
                  <>
                    {currentQCMQuestionOption1 && currentQCMQuestionOption1 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 1: {currentQCMQuestionOption1}
                    </div>
                    }
                    {currentQCMQuestionOption2 && currentQCMQuestionOption2 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 2: {currentQCMQuestionOption2}
                    </div>
                    }
                    {currentQCMQuestionOption3 && currentQCMQuestionOption3 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 3: {currentQCMQuestionOption3}
                    </div>
                    }
                    {currentQCMQuestionOption4 && currentQCMQuestionOption4 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 4: {currentQCMQuestionOption4}
                    </div>
                    }
                    {currentQCMQuestionOption5 && currentQCMQuestionOption5 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 5: {currentQCMQuestionOption5}
                    </div>
                    }
                    {currentQCMQuestionOption6 && currentQCMQuestionOption6 !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      Option 6: {currentQCMQuestionOption6}
                    </div>
                    }
                    {currentQCMQuestionSelectedOption && currentQCMQuestionSelectedOption !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      {t('CandidateResponse')}: {currentQCMQuestionSelectedOption}
                    </div>
                    }
                    {currentQCMQuestionCorrectOption && currentQCMQuestionCorrectOption !== null &&
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                      {t('CorrectOption')}: {currentQCMQuestionCorrectOption}
                    </div>
                    }
                  </>
                  }

                  {config.Environment !== "Dev" &&
                  <>
                    {videoUrl && !videoUrl.includes("mp3") && (
                      <>

                        <video
                          src={`${config.Video_URL}${videoUrl}`} type="video/webm"
                          controls
                          className="px-4 py-4"
                          controlsList="nodownload"
                        />
                        {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}
                      </>
                    )}

                    {videoUrl && videoUrl.includes("mp3") && (
                      <>
                        <audio
                          src={`${config.Video_URL}${videoUrl}`} 
                          type="audio/mp3"
                          controls
                          controlsList="nodownload"
                          // className="px-4 py-4"
                        />
                        {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}
                      </>
                    )}
                  </>
                  }
                  </div>
                </div>
                
            </Card>
          </Col>
        </Row>
    </>
  );
}

export default JobAppVideos;
