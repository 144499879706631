import React from "react";
import Header from "components/Headers/Header";
import SidebarEmployer from "components/Sidebar/SidebarEmployer";

const EmployerLayout = () => {
  

    return (
      <>
        <SidebarEmployer/>
        {/* <AdminNavbar/> */}
        <Header/>
      </>
    );
  
}

export default EmployerLayout;
