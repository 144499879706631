import axios from "axios";
import QuestionsV2 from "components/employer/QuestionsV2";
import SendEmailInvitationForOneJob from "components/employer/sendEmailInvitationForOneJob";
import PageSpace from "components/styles/pageSpace";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";

import {
  Modal,
  ModalFooter,
  Button,
  Form,
  Container,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import config from "config";
import OneJobEmployerApplications from "components/employer/oneJobEmployerApplications";


import Select from "react-select";
import { options_job_visibility, options_job_contract,OfferTypesOptions } from 'options';
import AcceptanceCriteria from "./AcceptanceCriteria";
import { getActiveContracTypes } from "network/ApiAxios";
import Datetime from 'react-datetime';
import { useTranslation } from "react-i18next";
import { ApplicationMethodOptions } from "options";



const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function OneJobEmployerV2({ jobId }) {
  const history = useHistory();
  const [jobCreatorUser, setJobCreatorUser] = useState({});
  const [jobCreatorEmployerCampanyID, setJobCreatorEmployerCampanyID] = useState("");
  const [job, setJob] = useState({});
  const [jobID, setJobID] = useState("");
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  const [jobVisibility, setJobVisibility] = useState("");
  const [contract, setContract] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [activeContracTypes, setActiveContacTypes] = useState([])
  const [studyLevel, setStudyLevel] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [pay, setPay] = useState(null);
  const [TJM, setTJM] = useState(null);
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("");
  const [technologies, setTechnologies] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [location, setLocation] = useState('')
  const [application_method, setApplication_method] = useState('');
  const [msg, setMsg] = useState(null);
  const [isShown , setIsShown] = useState(false);

  const { t, i18n } = useTranslation();


  const optionsDate = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }

  const fetchJobCreatorData = (jobCreatorUserIDParam) => {
    instance.get(`users/getprofilebyuserid/${jobCreatorUserIDParam}`).then((resp) => {
      // console.log("function editUser : resp: ", resp);
      setJobCreatorUser(resp.data);
      setJobCreatorEmployerCampanyID(resp.data.employerCampanyID);

    });
  };

  const fetchJobData = () => {
    instance.get(`jobs/${jobId}`).then((resp) => {
      console.log(resp.data.job)
      setJob(resp.data.job);
      setJobID(resp.data.job._id);
      setJobVisibility(resp.data.job.visibility);
      setContract(resp.data.job.contract);
      setCreated(resp.data.job.createdAt);
      resp.data.job.interview && setInterviewUrl(resp.data.job.interview.url);
      fetchJobCreatorData(resp.data.job.userID);
      setDescription(resp.data.job.description);
      setTitle(resp.data.job.title);
      resp?.data?.job?.studyLevel && setStudyLevel(resp.data.job.studyLevel)
      resp?.data?.job?.yearsOfExperience && setYearsOfExperience(resp.data.job.yearsOfExperience)
      resp?.data?.job?.gender && setGender(resp.data.job.gender)
      resp?.data?.job?.startDate && setStartDate(resp.data.job.startDate)
      resp?.data?.job?.endDate && setEndDate(resp.data.job.endDate)
      resp?.data?.job?.technologies && setTechnologies(resp.data.job.technologies)
      resp?.data?.job?.pay && setPay(resp.data.job.pay)
      resp?.data?.job?.TJM && setTJM(resp.data.job.TJM)
      resp?.data?.job?.language && setLanguage(resp.data.job.language)
      resp?.data?.job?.location && setLocation(resp.data.job.location)
      resp?.data?.job?.application_method && setApplication_method(resp.data.job.application_method)
      // setEndDate(resp.data.job.endDate.slice(0, 10));
      if (localStorage.getItem("user")) {
        resp.data.job.participations.forEach((e) => {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        });
        if (JSON.parse(localStorage.getItem("user")).jobs) {
          JSON.parse(localStorage.getItem("user")).jobs.forEach((e) => {
            if (e == jobId) {
              setUrl(true);
            }
          });
        }
        if (JSON.parse(localStorage.getItem("user")).role == "employer") {
          setUrl(true);
        }
      }
    });

  };

  const ChangeOneFIeldJob = async (fielValue, field) => {
    setMsg(null)
    const {data} = await instance.put(`jobs/updateJobOneField/${jobId}/${fielValue}`, { "fieldValue": field })
    data.success && setMsg(data.message)
  }

  // const changeJobVisbility = () => {
  const changeJobVisbility = async () => {
    // console.log("7777777777777777777 changeJobVisbility: ");
    // console.log("111111111111111111111111: jobVisibility: ", jobVisibility);

    instance.put(`jobs/updateJobVisibility/${jobId}`, { visibility: jobVisibility }).then((resp) => {

    });

  };
  const changeJobContract = () => {
    // console.log("111111111111111111111111: changeJobContract: ", changeJobContract)

    instance.put(`jobs/updateJobContract/${jobId}`, { contract: contract }).then((resp) => {
    });

  };


  const changeJobDescription = () => {
    // console.log("222222222222222222: changeJobDescription: ", changeJobDescription);
    // console.log("222222222222222222: changeJobDescription: description: ", description);
    instance.put(`jobs/updateJobOneField/${jobId}/description`, { "fieldValue": description }).then((resp) => {
    });

  };

  const changeJobTitle = () => {
    setMsg(null)
    // console.log("222222222222222222: changeJobTitle: ");
    // console.log("222222222222222222: changeJobTitle: title: ", title);
    instance.put(`jobs/updateJobOneField/${jobId}/title`, { "fieldValue": title }).then((resp) => {
      console.log(resp.data.message)
      setMsg(resp.data.message)
    });

  };

  const deleteJob = () => {
    instance.delete(`jobs/${jobId}`).then(() => {
      history.push("/admin/tab2");
    });
  };

  const getAllActiveContracTypes = async () => {
    try {
      const { data } = await getActiveContracTypes()
      data && setActiveContacTypes(data.map(el => el = { label: el.name, value: el._id }))
      //console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllActiveContracTypes()
    fetchJobData();

  }, [jobId]);

  const copyToClipboard = link => {
    const textField = document.createElement('textarea');
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();

    try {
        document.execCommand('copy');
        // You can provide some feedback here that the copy was successful
        console.log('Copied to clipboard:', link);
        setIsShown(true)

    } catch (err) {
        console.error('Unable to copy', err);
    } finally {
        document.body.removeChild(textField);
    }
}



  return (
    <>
      <Container>
        <Row>
          <Col className="order-xl-1">
            <Card
              className="bg-secondary shadow"
            >
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                      //role="tablist"
                      //onClick={() => history.push('/admin/edit-profile')}
                      // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        {t("Parameters")}
                      </td>

                      {user.role === "employer" && user.employerCampanyID === jobCreatorEmployerCampanyID && (
                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          Questions
                        </td>
                      )}
                      {user.role === "employer" && user.employerCampanyID === jobCreatorEmployerCampanyID && (

                        <td
                          className={openTab === 3 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          {t('AcceptanceCriteria')}
                        </td>

                      )}

                      {user.role === "employer" && user.employerCampanyID === jobCreatorEmployerCampanyID && (
                        <>
                          <td
                            className={
                              (openTab === 5)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(5);
                            }}
                            data-toggle="tab"
                            href="#link5"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('Candidates')}
                          </td>
                        </>
                      )}
                      {user.role === "employer" && user.employerCampanyID === jobCreatorEmployerCampanyID && (
                        <>
                          <td
                            className={
                              (openTab === 6)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(6);
                            }}
                            data-toggle="tab"
                            href="#link6"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            {t('Invite')}
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              {openTab === 1 &&
                <CardBody
                  className={openTab === 1 ? "block" : "hidden"}
                  id="link1"
                >
                  <Form>
                    <h4 className=" mb-4">Description {t('OfferDesKey1')}</h4>
                    <div className="pl-lg-4">
                      <Row>
                        <Input
                          type="textarea"
                          rows="5"
                          readOnly
                          value={job.description || ""}
                        >
                        </Input>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h4 className=" mb-4">{t('OfferDesKey2')}</h4>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            Technologies/{t('OutFrSidebarKey8')}: {job.technologies}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('Language')}: {job.language}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {/Freelance/i.test(job?.contract) ? `TJM (€) : ${job?.TJM ? job.TJM : ''}  ` : `${t('Pay')} (K€) : ${job?.pay ? job.pay : ' '}`}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('LocationTitle')}: {job.location}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            Education: {job.studyLevel}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            Experience: {job.yearsOfExperience}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('CreatedJobKey5')}: {job.contract}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('Gender')}: {job.gender}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('CreatedJobKey9')}: {job?.startDate?.slice(0, 10)}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div
                            className="h5 mt-4 col-12 "
                            style={{ display: "inline-flex" }}
                          >
                            {t('CreatedJobKey10')}: {job?.endDate?.slice(0, 10)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <h4 className=" mb-4">{t('OutFrSidebarKey2')}</h4>
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey3')}</label>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          type="textarea"
                          rows="2"
                          style={{ textAlign: "center" }}
                          // value={description}
                          // placeholder={description}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          value={title}
                        >
                        </Input>

                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={changeJobTitle}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {msg && msg.includes('title') && <Row className='m-2'>
                      <UncontrolledAlert color="success">
                        {msg}
                      </UncontrolledAlert>
                    </Row>}
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey5')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          <Select
                            // name="Visibilité" 
                            //label="Niveau du diplome" 
                            // placeholder="certification_level" 
                            className="w-full "
                            // value={jobVisibility}
                            options={options_job_visibility}
                            //fullWidth 
                            //onBlur={handleBlur} 
                            // onChange={handleChange} 
                            value={
                              options_job_visibility.filter(option =>
                                option.value === jobVisibility)
                            }
                            onChange={(e) => { setJobVisibility(e.value) }}
                          // value={values.NiveauDiplome || ""} 
                          //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                          //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={changeJobVisbility}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey6')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          <Select
                            //name="Niveau du diplome" 
                            //label="Niveau du diplome" 
                            // placeholder="certification_level" 
                            className="w-full "
                            options={activeContracTypes}
                            value={
                              activeContracTypes.filter(option =>
                                option.label === contract)
                            }
                            //fullWidth 
                            //onBlur={handleBlur} 
                            // onChange={handleChange} 
                            // value={contract}
                            onChange={(e) => { setContract(e.label) }}
                          // value={values.NiveauDiplome || ""} 
                          //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                          //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => { changeJobContract() }}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('the')} description</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          type="textarea"
                          rows="5"
                          style={{ textAlign: "center" }}
                          // value={description}
                          // placeholder={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          value={description}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => { changeJobDescription() }}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('thee')} {t('StudyLevel')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setStudyLevel(e.target.value);
                          }}
                          value={studyLevel}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('studyLevel', studyLevel)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('Experience')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setYearsOfExperience(e.target.value);
                          }}
                          value={yearsOfExperience}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('yearsOfExperience', yearsOfExperience)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('thee')} {t('Gender')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                          value={gender}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('gender', gender)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} technologies</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setTechnologies(e.target.value);
                          }}
                          value={technologies}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('technologies', technologies)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    {(contract && /Freelance/i.test(contract)) ? <>
                      <Row>
                        <label className="px-lg-3">{t('OfferDesKey4')} {t('thee')} TJM (€)</label>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Input
                            type='number'
                            className="	flex items-center justify-center py-3 rounded-t-md"
                            style={{ textAlign: "center" }}
                            onChange={(e) => {
                              setTJM(+e.target.value);
                            }}
                            value={TJM}
                          >
                          </Input>
                        </Col>
                        <Col lg="6">
                          <div
                          //className="h5 mt-4 col-12"
                          //style={{ display: "inline-flex" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              //type="submit"
                              sx={{ mt: "25px" }}
                              onClick={() => ChangeOneFIeldJob('TJM', TJM)}
                            >
                              {t('OfferDesKey4')}
                            </Button>
                          </div>
                        </Col>
                      </Row>

                    </> :
                      <>
                        <Row>
                          <label className="px-lg-3">{t('OfferDesKey4')} {t('thea')} {t('Pay')} (K€)</label>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <Input
                              type='number'
                              className="	flex items-center justify-center py-3 rounded-t-md"
                              style={{ textAlign: "center" }}
                              onChange={(e) => {
                                setPay(+e.target.value);
                              }}
                              value={pay}
                            >
                            </Input>
                          </Col>
                          <Col lg="6">
                            <div
                            //className="h5 mt-4 col-12"
                            //style={{ display: "inline-flex" }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                //type="submit"
                                sx={{ mt: "25px" }}
                                onClick={() => ChangeOneFIeldJob('pay', pay)}
                              >
                                {t('OfferDesKey4')}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>}

                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('Language')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setLanguage(e.target.value);
                          }}
                          value={language}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('language', language)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('thea')} {t('Location')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Input
                          className="	flex items-center justify-center py-3 rounded-t-md"
                          style={{ textAlign: "center" }}
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                          value={location}
                        >
                        </Input>
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('location', location)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey4')} {t('thea')} {t('CreatedJobKey10')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Datetime
                          className="d-flex justify-content-center"
                          style={{ textAlign: 'center' }}
                          value={endDate ? new Date(endDate).toLocaleDateString('en-GB', optionsDate) : null}
                          onChange={(e) => setEndDate(e._isValid ? e._d : e)}
                          closeOnSelect
                          dateFormat="DD-MM-YYYY"
                          timeFormat={false}
                        />
                      </Col>
                      <Col lg="6">
                        <div
                        //className="h5 mt-4 col-12"
                        //style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={() => ChangeOneFIeldJob('endDate', endDate)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey5')}</label>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          <Select
                            // name="Visibilité" 
                            //label="Niveau du diplome" 
                            // placeholder="certification_level" 
                            className="w-full "
                            // value={jobVisibility}
                            options={options_job_visibility}
                            //fullWidth 
                            //onBlur={handleBlur} 
                            // onChange={handleChange} 
                            value={
                              options_job_visibility.filter(option =>
                                option.value === jobVisibility)
                            }
                            onChange={(e) => { setJobVisibility(e.value) }}
                          // value={values.NiveauDiplome || ""} 
                          //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                          //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={changeJobVisbility}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row>
                      <label className="px-lg-3">{t('OfferDesKey9')}</label>
                    </Row>
                    <Row className='mb-3'>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          <Select 
                            className="w-full "
                            options={ApplicationMethodOptions}
                            value={
                              ApplicationMethodOptions.filter(option =>
                                option.value === application_method)
                            }
                            
                            onChange={(e) => { setApplication_method(e.value) }}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12"
                          style={{ display: "inline-flex" }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            //type="submit"
                            sx={{ mt: "25px" }}
                            onClick={()=> ChangeOneFIeldJob('application_method', application_method)}
                          >
                            {t('OfferDesKey4')}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                    {msg && msg.includes("le type d'offre") && <Row className='m-2'>
                      <UncontrolledAlert color="success">
                        {msg}
                      </UncontrolledAlert>
                    </Row>}
                    </Row>

                    {user.role == "employer" && user._id == job.userID && (
                      <Col className="text-right ml-4" xs="4">
                        <Button
                          color="secondary"
                          style={{ float: "left" }}
                          type="button"
                          onClick={() => {
                            setModalOpen(!modalOpen);
                          }}
                          size="sm"
                        >
                          {t('OfferDesKey7')}
                        </Button>
                        <Modal
                          toggle={() => setModalOpen(!modalOpen)}
                          isOpen={modalOpen}
                        >
                          <div className=" modal-header">
                            <h4 className=" modal-title" id="exampleModalLabel">
                              {t('OfferDesKey8')} ?
                            </h4>
                            <button
                              aria-label="Close"
                              className=" close"
                              type="button"
                              onClick={() => setModalOpen(!modalOpen)}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>

                          <ModalFooter>
                            <Button
                              color="secondary"
                              type="button"
                              onClick={() => setModalOpen(!modalOpen)}
                            >
                              {t('cancel')}
                            </Button>
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => {
                                deleteJob();
                                setModalOpen(!modalOpen);
                              }}
                            >
                              {t('delete')}
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Col>
                    )}
                    {/* Description */}
                    {/* <h6 className="heading-small text-muted mb-4">
                     About me (placeholder)
                   </h6>
                   <div className="pl-lg-4">
                     <FormGroup>
                       <label>About Me</label>
                       <Input
                         className="form-control-alternative"
                         placeholder="A few words about you ..."
                         rows="4"
                         defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                           Open Source."
                         type="textarea"
                         disabled
                       />
                     </FormGroup>
                   </div> */}
                  </Form>
                </CardBody>
              }
              {openTab === 2 &&
                <CardBody
                  className={openTab === 2 ? "block" : "hidden"}
                  id="link2"
                >
                  {/*
                  <h4 className=" mb-4">Questions de l'offre</h4>
                */}
                  <div className="pl-lg-4">
                    <Row>
                      <QuestionsV2 jobId={jobId} />
                    </Row>
                  </div>
                </CardBody>
              }

              {openTab === 3 &&
                <CardBody
                  className={openTab === 3 ? "block" : "hidden"}
                  id="link3"
                >
                  {/*
                  <h4 className=" mb-4">Questions de l'offre</h4>
                */}
                  <div className="pl-lg-4">
                    <Row>
                      <AcceptanceCriteria jobId={jobId} />
                    </Row>
                  </div>
                </CardBody>
              }

              {openTab === 5 &&
                <>
                  <h4>{t('jobAppTitle')}:</h4>
                  <Row
                    className={openTab === 5 ? "block" : "hidden"}
                    id="link5"
                  >


                    <OneJobEmployerApplications jobID={jobId} />

                    {/* <JobCandidats participants={job} completed={true} /> */}
                    {/* 12 07 2022 on peut créer un composant JobApplicationEmployer qui prend comme input jobid et pas job comme le composabnt JobCandidaturesEmployer */}
                    {/* <JobApplicationsEmployerV3 jobID={jobId} /> */}
                    {/* 06 12 2022 JobApplicationsEmployerV3 dans ce fichier on trouve comment supprimer une jobApplication */}




                  </Row>
                </>


              }
              {openTab === 6 &&
                <Row
                  className={openTab === 6 ? "block" : "hidden"}
                  id="link6"
                >
                  <h4 className="pl-lg-4">{t('Invite')}:</h4>
                  <Row className="pl-lg-4 w-full">
                  
                    <Col sm='6'>
                      {jobID &&
                        <>
                          <p>{t('EmailSentKey')}:</p>
                          <p>{config.DOMAIN_NAME}/jobs/apply?jobId={jobID}</p>
                        </>
                      }
                    </Col>
                    <Col sm='6'>
                    <Button color='primary' onClick={() => copyToClipboard(`${config.DOMAIN_NAME}/jobs/apply?jobId=${jobID}`)}>Copier</Button>
                    <Row className='mt-1'>
                                {isShown && <UncontrolledAlert color="success">
                                    Lien copié
                                </UncontrolledAlert>}
                            </Row>
                    </Col>

                  </Row>

                  <PageSpace />
                  <Row className="pl-lg-4">
                    <Col>
                      <h4>{t('SendEmailInvit')}:</h4>
                      {jobID &&
                        <SendEmailInvitationForOneJob
                          jobID={jobId}
                        />
                      }
                    </Col>

                  </Row>


                </Row>
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OneJobEmployerV2;
