import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import HomepageLayout from "layouts/HomepageLayout";
import OneJobCardCandidate from "components/candidat/oneJobCardCandidate";

import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function JobCardsHomepage() {
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLaunched, setSearchLaunched] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  
  const history = useHistory();
  const query = qs.parse(location.search);
  // const [totalJobsNumber, setTotalJobsNumber] = useState("");
  var totalJobsNumber;
  var pageNumber = (query.pageNumber) || 1;
  // console.log("pageNumber: ", pageNumber);
  const user = JSON.parse(localStorage.getItem("user"));

    instance.get("jobs/getJobsNumber").then((resp) => {
      // console.log("resp: ", resp);
      // console.log("resp.data.cnt", resp.data.cnt);
      // setTotalJobsNumber(resp.data.cnt);
      totalJobsNumber = resp.data.cnt;
      // console.log("totalJobsNumber: ", totalJobsNumber);
      // console.log("perPage: ", perPage);
      setPageCount(Math.ceil(totalJobsNumber / perPage));
      // console.log("pageCount: ", pageCount);

    });


  const fetchData = () => {
    // console.log("22222222222222: fetchData: ");
    // console.log("22222222222222: fetchData: pageNumber", pageNumber);
    // console.log("22222222222222: fetchData: perPage", perPage);
    // console.log("22222222222222: fetchData: url", `jobs/getJobsPerPage/${pageNumber}/${perPage}`);

    instance.get(`jobs/getJobsPerPage/${pageNumber}/${perPage}`).then((resp) => {
      setJobs(resp.data);
      
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
  };

  const handlePageClickForSearching = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
  };

  const launchTheSearchFunction = () => {
    if ( search !== "") {
      setSearchLaunched(true);

      instance.get(`jobs/searchJobsByKeywordsNumber/${search}`).then((resp) => {
        // console.log("resp: ", resp);
        // console.log("resp.data.cnt", resp.data.cnt);
        // setTotalJobsNumber(resp.data.cnt);
        totalJobsNumber = resp.data.cnt;
        // console.log("totalJobsNumber: ", totalJobsNumber);
        // console.log("perPage: ", perPage);
        setPageCount(Math.ceil(totalJobsNumber / perPage));
        // console.log("pageCount: ", pageCount);
  
      });

      instance.get(`jobs/searchJobsByKeywords/${search}/${pageNumber}/${perPage}`).then((resp) => {
        setJobs(resp.data);

        // const data = resp.data;
        // const DATA = [];
        // console.log("666666666666666: launchTheSearchFunction: data: ", data);
        // console.log("data", data);
        {/*
          for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
          }
          // setData(DATA.slice(offset, offset + perPage));
          setData(DATA);
        */}

        // console.log("666666666666666: launchTheSearchFunction: data after slice: ", data);
        
      });
    }
    

  };


  useEffect(() => {
    // fetchTotalJobsNumber(); 
    fetchData();
  }, [pageNumber]);




  return (
      <>
      <Container className="container-fluid ">
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <Col lg="12">

          
          <Card className="bg-transparent w-full">
            <CardHeader>
              <Row>
              <Col lg="2">
              </Col>


              <Col lg="4">
           <div
            className="homePageStyle"
            style={{
                //backgroundColor: "#f7fafc",
                 //objectFit: "cover",
                justifyContent:'center',
                alignItems:'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
            }}
           
           
           >
              <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-Recherche"
                    placeholder="Rechercher"
                    onChange={(e) => 
                      setSearch(e.target.value)
                    }
                    type="text"
                  />
                </FormGroup>
            
           </div>
              </Col>


              <Col lg="4">
                <Button 
                  variant="contained" 
                  color="primary" 
                  type="submit" 
                  // sx={{ mt: "25px" }}
                  onClick={() => { 
                    // console.log("bouton cliqué 0000000000000000000000 ")
      
                    launchTheSearchFunction()
                  }}
                  >
                    Lancez la recherche
                </Button>
              </Col>
              </Row>
            </CardHeader>
            
              {searchLaunched && (
                <>
                <CardBody>
                  {jobs
                    .map((job) => (
                      <OneJobCardCandidate 
                      key={job._id}
                      id={job._id} 
                      title={job.title} 
                      description={job.description} 
                      technologies={job.technologies} 
                      contract={job.contract} 
                      entreprise={job.entreprise} 
                      location={job.location}
                      createdAt={job.createdAt}
                    />

                    ))}
                </CardBody>
                  <CardFooter className="bg-transparent w-full">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClickForSearching}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </CardFooter>
                </>
              )}
               {!searchLaunched && (
                <>
                <CardBody>
                  {jobs
                    .map((job) => (
                      <OneJobCardCandidate 
                        key={job._id}
                        id={job._id} 
                        title={job.title} 
                        description={job.description} 
                        technologies={job.technologies} 
                        contract={job.contract} 
                        entreprise={job.entreprise} 
                        location={job.location}
                        createdAt={job.createdAt}
                      />
                    ))}
                </CardBody>
                <CardFooter className="bg-transparent w-full">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </CardFooter>
                </>
              )}
            

          </Card>
          </Col>
        </Row>
      </Container>
      </>
  );
}

export default JobCardsHomepage;
