import { getJob } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Badge } from 'reactstrap';

const MyInterviewTitles = ({ jobID }) => {
    const [job, setJob] = useState(null)

    //console.log('jobID',jobID); 
    const fetchJobs = async () => {
        try {
            const { data } = await getJob(jobID)
            //console.log('MyJObs',data);
            setJob(data.job)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchJobs()
    }, [])
    return (
        <Badge
            className='p-3 m-1'
            color="primary"
            pill
        >
            {job?.title}
        </Badge>
    )
}

export default MyInterviewTitles