
import { options_jobApp_Status } from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Container,
  Button,
  Col,
  UncontrolledAlert,
} from "reactstrap";
import Select from "react-select";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function ArchiveJobApp(props) {
  const history = useHistory();



  const [actualJobAppStatus, setActualJobAppStatus] = useState("");
  const [newJobAppStatus, setNewJobAppStatus] = useState("");

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobID, setJobID] = useState("");
  const [msg, setMsg] = useState(null)





  const updatejobAppStatus = async () => {

    // console.log("jobAppRejectionReason: updatePipeline: ");
    instance.put(`jobApplication/updateJobApplicationOneField/${props.jobAppID}/status`, { "fieldValue": newJobAppStatus }).then((resp) => {
      // console.log("jobAppRejectionReason: updatePipeline: resp: ", resp);  
      if (resp.data.success) {
        // console.log("jobAppRejectionReason: updatePipeline: resp.data.success: ");
        // console.log("jobAppRejectionReason: updatePipeline: resp.data.success: ", resp.data.success);
        setActualJobAppStatus(newJobAppStatus);
        setMsg(resp.data.message)
      }
      else {
        // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
      }
    });
    // window.location.reload();


  };




  const fetchJobApplication = async () => {
    instance
      .get(`jobs/candidat/${props.jobAppID}`)
      .then((res) => {
        // console.log("ddddddddddddddddddddddddddddddddddd:");
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
        // setCandidat(res.data);
        setJobApplicationUserID(res.data.userID);
        setJobID(res.data.jobID);

        // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
        if (res.data.status) setActualJobAppStatus(res.data.status);
        else setActualJobAppStatus("Without status");

      })
      .catch((err) => {
        console.log(err);
      });
  };




  useEffect(() => {
    fetchJobApplication();

  }, []);



  return (
    <>


      <Row className='mt-5 mb-1'>
        <h4 className="px-lg-3">Statut</h4>
      </Row>
      <Row className='mb-5'>
        <Col sm='7'>
          <Select /*className="w-full"*/
            options={options_jobApp_Status}
            // value={applicationPipeline} 

            value={
              (options_jobApp_Status).filter(option =>
                option.value === actualJobAppStatus)
            }


            onChange={(e) => {
              setActualJobAppStatus(e.value);
              setNewJobAppStatus(e.value);
            }} />
        </Col>
        <Col sm='5'>
          <Button
            /* style={{
  
              width: "100%",
            }} */
            onClick={() => {
              updatejobAppStatus(newJobAppStatus);
            }}
            //className="mt-4"
            color="primary"
            type="button"
          >
            Sauvegarder
          </Button>
        </Col>
      </Row>
      {msg && <Row>
        <UncontrolledAlert color="success">
          {msg}
        </UncontrolledAlert>
      </Row>}

    </>
  );
}

export default ArchiveJobApp;
