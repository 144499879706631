import { AcademicCapIcon } from '@heroicons/react/outline'
import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap'
import EditFormation from './EditFormation'

const FormationSection = ({ profileC, candidateUser, getProfileCandidate,user,t}) => {
    return (
        <Container>
            <Card className="shadow">
                <CardHeader style={{ border: 'none' }}>
                    <Row>
                        <Col sm='10'>
                            <h6 className="heading-small text-muted">
                                {t('Education')}
                            </h6>
                        </Col>

                        <Col sm='2'>
                            {((user?._id === candidateUser?._id) && profileC && user) && <EditFormation profileC={profileC} candidateUser={candidateUser} getProfileCandidate={getProfileCandidate} t={t} />
                            }                </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="6">
                            <AcademicCapIcon style={{ height: "5rem", color: '#5e72e4', marginTop: '-20px', marginLeft: '40px' }} />
                        </Col>
                        <Col sm='6'>
                            <h3>{profileC?.diploma}</h3>
                            <Label>{profileC?.university}</Label>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    )
}

export default FormationSection