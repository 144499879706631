import Header from "components/Headers/Header";
import Competitions from "components/recruteur/competitions";
import React from "react";

function Tab2Comp() {
  return (
    <>
      <Header />
      <Competitions />
    </>
  );
}

export default Tab2Comp;
