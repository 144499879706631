
import React from 'react'
import CandidateLayoutV3 from 'layouts/CandidatHorsFrLayoutV2';
import TrainingOverViewComp from 'components/candidat/TrainingOverview';

const TrainingOverView = ({match}) => {

    const {id} = match.params;

  return (
    <>
              <CandidateLayoutV3 />
              <TrainingOverViewComp 
                id={id}
              />
              
          </>
  
  )
}

export default TrainingOverView