import PageTitleStyle from 'components/styles/PageTitleStyle';
import PageSpace from 'components/styles/pageSpace';
import React, { useState } from 'react'
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import FillYourCandidateProfile from '../FillYourCandidateProfile';

const FillProfileModal = ({ t }) => {
    const [modal, setModal] = useState(false);
    const [alreadyPosted, setAlreadyPosted] = useState(false);

    const toggle = () => setModal(!modal);
    return (
        <Container>
            <Button onClick={toggle}>
                {t('fillprofile')}
            </Button>
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Remplir votre formulaire</ModalHeader>
                <ModalBody>
                    <PageSpace />
                    <PageTitleStyle
                        title="Mon profil"
                    >

                    </PageTitleStyle>


                    <Container>
                        {alreadyPosted &&
                            <Row className="justify-content-center border-0 py-2" style={{ width: "100%" }}>
                                <Col xs="2" >
                                </Col>
                                <Col xs="10">
                                    <>
                                        <label className="px-lg-3">Votre formulaire d'inscription est déjà réalisé</label>
                                    </>

                                </Col>

                            </Row>
                        }
                        {!alreadyPosted &&
                            <Row className="justify-content-center border-0 py-2" style={{ width: "100%" }}>
                                
                                    <>
                                        <FillYourCandidateProfile
                                        />
                                    </>


                            </Row>
                        }
                    </Container>


                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Do Something
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter> */}
            </Modal>

        </Container>
    )
}

export default FillProfileModal