import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
//import AdminNavbar from "components/Navbars/AdminNavbar.js";
import CandidatOutFrNavbar from "components/Navbars/CandidatOutFrNavbar";
import AdminFooter from "components/Footers/AdminFooter.js";
//import Sidebar from "components/Sidebar/Sidebar.js";
import Header from "components/Headers/Header";



import routes from "routes.js";
import SidebarSuperAdmin from "components/Sidebar/SidebarSuperAdmin";

const SuperAdminLayout = () => {
  

    return (
      <>
        <SidebarSuperAdmin/>
        {/* <AdminNavbar/> */}
        <Header/>
      </>
    );
  
}

export default SuperAdminLayout;
