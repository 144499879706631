import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from 'reactstrap';
import config from 'config';
import videoExpl from '../../assets/videos/videoExemple.mp4'
import ImgUrl from '../../assets/img/brand/1.png'



const OneSectionForTraining = ({ section }) => {

    return (
        <Container className='w-full p-2'>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    {section?.name}
                </AccordionSummary>

                <AccordionDetails>

                    {section?.TextContent && <div dangerouslySetInnerHTML={{ __html: section.TextContent }} />}

                    {section?.fileAttachement && <div className='mt-4 mb-4'>
                        {
                            section?.fileAttachement.includes('.pdf') ?
                                <>
                                    <iframe
                                        title='pdf viewer'
                                        src={/*config.Environment === "Dev" ?
                                            "https://app.hirelands.com/CvCandidate/62e8ea745bc2ec2b8c692e68/uploads/ad6b66a4-a44d-47e1-b008-7653284fb7ea.pdf"
                                            :*/
                                            `${config.CVs_URL}${section?.fileAttachement}`}
                                        frameBorder="0"
                                        style={{ width: "100%", height: "100vh", border: "none" }}
                                    >

                                    </iframe>
                                </>

                                :
                                <div className='d-flex flex-column align-items-center'>
                                    <img src={config.Environment === "Dev" ? ImgUrl
                                        : `${config.CVs_URL}${section?.fileAttachement}`}
                                        alt='imagee'
                                        style={{ width: '60%' }}
                                    />
                                </div>
                        }
                    </div>}

                    {section?.video && <div className='d-flex flex-column align-items-center'>
                        <video
                            src={config.Environment === "Dev" ? videoExpl
                                : `${config.Video_URL}${section?.video}`}
                            type="video/*"
                            controls
                            className="px-4 py-4"
                            style={{ width: '75%' }}
                        />
                    </div>}
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default OneSectionForTraining