import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import OutFrFreelanceCandidateCompetencies from "components/outFrCandidatSearchCdiFr/outFrFreelanceCandidateCompetencies";


import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";




function OutFrFreelanceCompetenciesView() {

  
  return (
    <>
      <CandidateLayoutV3 />

      
      
      
      {/* <Header /> */}
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
          <h2>Projet: Je suis hors de la France et je cherche un freelance en France</h2>
          <h3>Mes compétences</h3>
      </Container>
      <OutFrFreelanceCandidateCompetencies />
    

    </>
  );
}

export default OutFrFreelanceCompetenciesView;
