import React from "react";
import PageTitleStyle from "components/styles/PageTitleStyle";
import PageSpace from "components/styles/pageSpace";
import DisplayOneLearningPath from "components/employer/displayOneLearningPath";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";



import EmployerLayout from "layouts/EmployerLayout";

class DisplayOneLearningPathView extends React.Component {
  render() {
    return (
      <>
        <EmployerLayout />
        <PageSpace />
        <PageTitleStyle
          title="Parcours de formation"
        >
        </PageTitleStyle>
        <Container>
          <Row>
            <Col lg="2">
            </Col>
            <Col lg="10">
              <DisplayOneLearningPath 
                learningPathID = "63bdd4bb6dbbbbba62030818"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DisplayOneLearningPathView;
