import React from 'react'
import { Button, Row } from 'reactstrap'
import useCustomTranslation from './useCustomTranslation';

const TranslationLang = () => {
    const { t, i18n } = useCustomTranslation();

    const ChangeTheLanguage = (lang) => {
        i18n.changeLanguage(lang);
        //history.push(`/home/homepage/?lang=${lang}`)
      };
    return (
        <>
            {i18n.language === "en" ? (
                <Button color="primary"
                    outline onClick={() => ChangeTheLanguage("fr")}>
                    FR
                </Button>
            ) : (
                <Button color="primary"
                    outline onClick={() => ChangeTheLanguage("en")}>EN</Button>
            )}
        </>
    )
}

export default TranslationLang