import React from 'react'
import {Col, Container, Row } from 'reactstrap'

import InFranceCandidateLayoutV3 from 'layouts/inFranceCandidateLayoutV3';
import CandidateProfileComp from 'components/candidat/CandidateProfileComp';

const InFrCandidateProfile = ({ match }) => {
    const { id } = match.params

    return (
        <>
            <InFranceCandidateLayoutV3 />
            <Container>
                <Row className='ml-2'>
                    <Col xs="2">
                    </Col>
                    <Col xs="10">
                        <CandidateProfileComp id={id} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default InFrCandidateProfile