
import React from 'react'
import CandidateLayoutV3 from 'layouts/CandidateLayoutV3';
import TrainingSubscription from 'components/candidat/TrainingSubscriptions';
import { Col, Container, Row } from 'reactstrap';
import PageSpace from 'components/styles/pageSpace';
import PageTitleStyle from 'components/styles/PageTitleStyle';
import useCustomTranslation from 'components/other/useCustomTranslation';

const TrainingSubscriptionsView = () => {
    const { t } = useCustomTranslation();


    return (
        <>
            <CandidateLayoutV3 />
            <Container>
                <PageSpace />
                <PageTitleStyle
                    title={t('Subscriptions')}
                >

                </PageTitleStyle>

                <Row className="justify-content-center" style={{ width: "100%" }}>
                    <Col sm="2" >
                    </Col>
                    <Col sm="10">
                        <TrainingSubscription />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TrainingSubscriptionsView