import React from 'react'
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap'
import OneCompetencyCandidate from './OneCompetencyCandidate'
import { PlusIcon } from '@heroicons/react/solid'
import AddCompetency from './AddCompetency'


const CandidateCompetencies = ({ competencies, user, candidateUser,getCandidateCompetencies,t }) => {
    return (
        <Container>
            <Card className="shadow">
                <CardHeader style={{ border: 'none' }}>
                    <Row className="p-2 bd-highlight">
                        <Col sm='6'>
                            <h6 className="heading-small text-muted mb-4">{`${t('OutFrSidebarKey8')} (${competencies?.competencies?.length ? competencies?.competencies?.length : 0 })`}</h6>
                        </Col>

                        <Col sm='6'>
                            {user?._id == candidateUser?._id && user &&
                                <>
                                    <AddCompetency competencies={competencies} getCandidateCompetencies={getCandidateCompetencies} t={t} />
                                </>}
                        </Col>

                    </Row>
                </CardHeader>
                <CardBody className="bg-white">
                        <Table responsive="sm">
                            <thead>
                                <tr>
                                    <th>{t('OutFrSidebarKey8')}</th>
                                    <th>{t('Level')}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {competencies?.competencies?.map((compentency, i) => <OneCompetencyCandidate key={i} compentency={compentency} user={user} candidateUser={candidateUser} competencyID={competencies._id} getCandidateCompetencies={getCandidateCompetencies} /> )}
                            </tbody>
                        </Table>
                </CardBody>
            </Card>
        </Container>
    )
}

export default CandidateCompetencies