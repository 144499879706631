import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'reactstrap';

import DisplayOpenAiGPT from 'components/other/DisplayOpenAiGPT';
import { getOpenAiJobAppCVAnalysis } from 'network/ApiAxios';

const OpenAiCvCandidateAnalysisReport = ({ candidatId }) => {

    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const GetOpenAIOralCodeQuestionsReport = async () => {
        setIsLoading(true);
        try {
            const { data } = await getOpenAiJobAppCVAnalysis(candidatId);
            if (data.result) {
                setResult(data.result)
            }

            setIsLoading(false)


        } catch (error) {
            console.log(error)
            setResult(error?.response?.data?.result)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        GetOpenAIOralCodeQuestionsReport()
    }, [])

    return (
        <Row>
            <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                    <h4 className=" mb-4"> Analyse du CV par l'IA </h4>
                    {isLoading ? <div className='w-full d-flex justify-content-center'>
                        <Spinner
                            color="primary"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    </div> :
                        <DisplayOpenAiGPT Result={result} />
                    }

                </Card>
            </Col>
        </Row>
    )
}

export default OpenAiCvCandidateAnalysisReport