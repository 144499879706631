import React from "react";

// reactstrap components
import {Button, Container, Row, Col} from "reactstrap";
import {useHistory} from "react-router-dom";
import Header from "./Header";
const EditHeader = () => {

    const username = JSON.parse(localStorage.getItem("user")).name;
    const history = useHistory();

    return (
        <>
        <Header/>
            <div
                className=" pb-8 pt-5 pt-lg-6 d-flex align-items-center"
                style={{
                    minHeight: "300px",
                    backgroundImage:
                        "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center top"
                }}
            >
                {/* Mask */}
                
                {/* Header container */}
                <Container className="d-flex align-items-center" fluid>
                    <Row style={{marginLeft: ""}}>
                        <Col lg="7" md="10">
                            <h1 className="page-t">{username}</h1>
                            {/*<p className="text-white mt-0 mb-5">*/}
                            {/*    This is your profile page. You can see the progress you've*/}
                            {/*    made with your work and manage your projects or assigned tasks*/}
                            {/*</p>*/}
                            <Button
                                className="btn-secondaryG"
                                onClick={() => history.push('/admin/user-profile')}
                            >
                                Retour au profil
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default EditHeader;
