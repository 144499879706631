
import {options_user_Pipeline} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
} from "reactstrap";
import Select from "react-select";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function UserPipeline(props) {
  const history = useHistory();
  
  const [candidateUser, setCandidateUser] = useState({});

  const [userPipeline, setUserPipeline] = useState("");
  const [oldUserPipeline, setOldUserPipeline] = useState("");
  const [newUserPipeline, setNewUserPipeline] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));






  const updateUserPipeline = async () => {
    console.log("00000000000000000000000000: userPipeline:  updateUserPipeline: ");
    console.log("00000000000000000000000000: userPipeline:  updateUserPipeline: props.managedUserID: userPipeline: ", userPipeline);

    // var pipeline = "Rejeté";
    const response = await instance.put(`users/updateUserPipeline/${props.managedUserID}`, {userPipeline,});
    // console.log("ccccccccccccccccccccccccc : response: ", response);
    if (response.data.success) {
      let candidateUserID = props.managedUserID;
      let recruiterUserID = user._id;
      let actionType = "Update User Pipeline";
      let oldPipeline = oldUserPipeline;
      let newPipeline = newUserPipeline;


      const response_management_action = await instance.post(`userManagementActions/createAction/`, 
      {
        candidateUserID,
        recruiterUserID,
        actionType,
        oldPipeline,
        newPipeline,
      });
    }
    else {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
    }
  };

  const getCandidateUser = () => {
    console.log("00000000000000000000000000: userPipeline:  getCandidateUser getCandidate: ");
    console.log("00000000000000000000000000: userPipeline:  getCandidateUser getCandidate: props.managedUserID: ", props.managedUserID);
    instance.get(`users/getprofilebyuserid/${props.managedUserID}`).then((resp) => {
      // setParticipants(resp.data.job);
      // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ");
      // console.log("00000000000000000000000000 oneUserCardSuperAdmin getCandidate: resp.data : ", resp.data);
      setCandidateUser(resp.data);
      if (resp.data.userPipeline)
      {
        setUserPipeline(resp.data.userPipeline);
        setOldUserPipeline(resp.data.userPipeline);
      }
      
    });
  
  
  };


  useEffect(() => {
    getCandidateUser();
  }, []);



  return (
    <>



        <Row>
          <label className="px-lg-3">Pipeline</label>
                   <Select className="w-full"
                          options={options_user_Pipeline}   
                          // value={applicationPipeline} 

                          value = {
                            (options_user_Pipeline).filter(option => 
                              option.value === userPipeline)
                         }

                                          
                          onChange={(e) => {
                            setUserPipeline(e.value);
                            setNewUserPipeline(e.value);
                            }} />
          <Button
                style={{
               
                  width: "100%",
                }}
                  onClick={()=>{
                    
                    updateUserPipeline(userPipeline);
                  }}
                  className="mt-4"
                  color="primary"
                  type="button"
            >
                  Sauvegarder le pipeline
          </Button>
        </Row>

    </>
  );
}

export default UserPipeline;
