import React, { useEffect, useState } from "react";
import "./timer.css";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  FilmIcon,
  ChatAltIcon,
} from "@heroicons/react/solid";
import { setGlobalCssModule } from "reactstrap/lib/utils";
import { Container } from "reactstrap";

class Timer extends React.Component {
  constructor(props) {
    super();
    this.state = { time: {}, seconds: props.time };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  
  setBool(setbool,bool){
  setbool(!bool)
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      this.setBool(this.props.setValue,this.props.value)
    }
  }

  render() {
    return (
      <Container>
        <span style={{ whiteSpace:'normal',display:'inline-flex' }}> 
           {this.startTimer()} {this.state.time.h ? `${this.state.time.h}:` : null}{ this.state.time.m}:{this.state.time.s}
        </span>
       
      </Container>
    );
  }
}
export default Timer;
