import React from "react";
import UsersTable from "components/employer/UsersTable";
import CreateUser from "components/recruteur/createUser";
import EmployerLayout from "layouts/EmployerLayout";
import PageTitleStyle from "components/styles/PageTitleStyle";
import PageSpace from "components/styles/pageSpace";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";


const UserManagement = ()=> {
  const { t, i18n } = useTranslation();
    return (
      <>
        <EmployerLayout />
        <PageSpace />
        <PageTitleStyle
          title={t('USERManagementKEY1')}
        >
        </PageTitleStyle>
        <Row className='w-full'>
          <Col sm='2'></Col>
          <Col sm='10'>
          <CreateUser />
          <UsersTable />
          </Col>
        </Row>
      </>
    );
}

export default UserManagement;
