import { AcademicCapIcon } from '@heroicons/react/outline'
import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap'

const FormationSectionSP = ({ profileC, candidateUser, getProfileCandidate, user, t }) => {
    const EducationEntries = candidateUser?.ParsedDataCV?.education?.entries;
    //console.log('EducationEntries',EducationEntries)
    return (
        <Container className='mb-1'>
            <Card className="shadow">
                <CardHeader style={{ border: 'none' }}>
                    <Row>
                        <Col>
                            <h6 className="heading-small text-muted">
                                {t('Education')}s
                            </h6>
                        </Col>

                    </Row>
                </CardHeader>
                <CardBody>
                    {EducationEntries?.map((education, i) => <Row className='mb-4' key={i}>
                        <Col sm="1">
                            <AcademicCapIcon style={{ height: "3rem", color: '#5e72e4' }} />
                        </Col>
                        <Col sm='11'>
                            <Row className="items-center space-x-3 mb-2">
                                <div>
                                    <h3 className='card-t'>{education?.establishment}</h3>
                                    <h5> {(education?.start_date || education?.end_date) && `${education?.start_date} - ${education?.end_date}`}</h5>
                                    <span
                                        className="text-sm"
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp:
                                                "6" /* number of lines to show */,
                                            WebkitBoxOrient: "vertical",
                                        }}>{education?.description}</span>
                                </div>
                            </Row>
                        </Col>
                    </Row>)}
                </CardBody>
            </Card>
        </Container>
    )
}

export default FormationSectionSP
