import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function InFranceGeneralInterviewOneCard( props ) {
  const location = useLocation();
  const [selectedProfileType, setSelectedProfileType] = useState("");
  const [profileTypeDescription, setProfileTypeDescription] = useState("");
  const [profileTypeName, setProfileTypeName] = useState("");
  const [jobs, setJobs] = useState([]);
  const [jobID, setJobID] = useState();
  var [job, setJob] = useState({});
  //let job = {};
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  
  const history = useHistory();
  const query = qs.parse(location.search);
  const user = JSON.parse(localStorage.getItem("user"));

  const getSelectedProfileTypeFunction = () => {
    console.log("oneFrCandidatePrezInterviewModify");
    // var pipeline = "Rejeté";
    // const response = getProfileTypes();
    // const { data } = response;
    console.log("oneFrCandidatePrezInterviewModify: props.selectedProfileTypeID: ", props.selectedProfileTypeID);
    
    instance.get(`profiletypes/getProfileTypeById/${props.selectedProfileTypeID}`).then((resp) => {
      console.log("oneFrCandidatePrezInterviewModify getProfileTypesFunction: resp: ", resp);
      console.log("oneFrCandidatePrezInterviewModify getProfileTypesFunction: resp.data: ", resp.data);
      setSelectedProfileType(resp.data.ProfileTypeItem);
      setJobID(resp.data.ProfileTypeItem.jobID);
      setProfileTypeDescription(resp.data.ProfileTypeItem.frCandidatesdescription);
      setProfileTypeName(resp.data.ProfileTypeItem.name);

      });


  };


  useEffect(() => {
    getSelectedProfileTypeFunction();
  }, []); //empty array as second argument.
  


  return (
    <>
      <Row 
        className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
        onClick={() => 
          history.push(`/infrancecandidate/contract/passPresentationInterview/apply?selectedProfileTypeID=${props.selectedProfileTypeID}`)
        }
      >
        {/* console.log("dddddddddddd: job: ", job) */}

      <Col lg="2">
        <img
          src={`${config.Data_URL}${job.image}`}
          onError={(e) => {
            e.target.src =
              require("assets/img/brand/job_offer_HL_300_225.jpg").default;
          }}
          style={{
            width: "120x",
            height: "120px",

            objectFit: "cover",
          }}
          alt="Responsive image"
      />
      </Col>
      <Col lg="8">
        <div className="flex items-center space-x-3 mb-2">
          <div>
            {profileTypeName &&
              <h3 className="card-t">{job.title}</h3>
            }            
            {profileTypeDescription &&
              <span
              className="text-sm"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp:
                  "2" /* number of lines to show */,
                WebkitBoxOrient: "vertical",
              }}
            >
              {profileTypeDescription}
            </span>
            }

          </div>
        </div>
      </Col>

      <Col lg="2" className=" flex items-center">
          <Button
            type="button"
            color="primary"
          >
            Postuler
          </Button>
      </Col>

      </Row>


    </>
  );
}

export default InFranceGeneralInterviewOneCard;
