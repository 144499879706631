import ConfigureSelect from 'components/other/ConfigureSelect';
import { fetchQuestionsById } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, Table } from 'reactstrap';

const ConfigureAcceptianceCriteria = ({ jobId, JobAcceptanceCriteria, isUpdated, setIsUpdated, t }) => {
    console.log('jobId', jobId)
    const [questions, setQuestions] = useState([]);
    const [questionsOptions, setQuestionsOptions] = useState([]);


    //const [modal, setModal] = useState(false);

    //const toggle = () => setModal(!modal);
    const GetQuestionByJobID = async () => {
        try {
            const { data } = await fetchQuestionsById(jobId)
            setQuestions(data.job.questions)
            const QCMQuestions = data.job.questions.filter(Q => Q.type.includes("QCM")).map(Q => Q = { value: Q._id, label: Q.question });
            setQuestionsOptions(QCMQuestions);
            console.log('QCMQuestions', QCMQuestions)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        GetQuestionByJobID()
    }, [])
    return (
        <>
            {/*  <Button color="danger" onClick={toggle}>
                Configuration automatique
            </Button>
            <Modal
                toggle={() => setModal(!modal)}
                isOpen={modal}
                size="lg"> */}


            {/* <ModalBody> */}
            <Table className="mt-5 w-full" responsive='sm'>
                <thead className="justify-content-center">
                    <tr >
                        <th /*className="text-center"*/><h4>{t('AcceptanceCriteria')}</h4></th>
                        <th className="text-center"><h4>Questions</h4></th>
                    </tr>
                </thead>
                <tbody>
                    {JobAcceptanceCriteria &&
                        JobAcceptanceCriteria.sort((a, b) => {
                            // Sort configured options first
                            if (a.ConfiguredOptions.length > 0 && b.ConfiguredOptions.length === 0) {
                                return -1;
                            } else if (a.ConfiguredOptions.length === 0 && b.ConfiguredOptions.length > 0) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }).map((AcceptanceCriteria) => {
                            return (
                                <tr
                                    key={AcceptanceCriteria._id}
                                //className="flex py-2 border-t border-b first:border-t-0 last:border-b-0 justify-between text-black"
                                >
                                    <td className='w-50'>
                                        <div className="w-full"><li style={{ textWrap: "wrap" }} className="mw-75 text-black">{AcceptanceCriteria.description}</li></div>

                                    </td>
                                    <td>
                                        <div className="w-full">
                                            <ConfigureSelect
                                                placeholder={t('configSelectLabel')}
                                                options={questionsOptions}
                                                AcceptanceCriteria={AcceptanceCriteria}
                                                questions={questions}
                                                isUpdated={isUpdated}
                                                setIsUpdated={setIsUpdated}
                                                t={t}
                                            />
                                        </div>
                                    </td>

                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            {/* </ModalBody> */}

            {/* </Modal> */}
        </>
    )
}

export default ConfigureAcceptianceCriteria