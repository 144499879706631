import FreelanceMissionsCardsInFranceCandidate from "components/candidat/freelanceMissionsCardsInFranceCandidate";
import { Box } from "@mui/material";

import React from "react";

import CandidateLayoutV3 from "layouts/CandidateLayoutV3";

function CandidateFreelanceMissionsView() {
  return (
    <>
      <CandidateLayoutV3 />
      <FreelanceMissionsCardsInFranceCandidate />
     
    </>
  );
}

export default CandidateFreelanceMissionsView;
