import axios from "axios";
import React, { useEffect, useState } from "react";
import { sendEmailForOutFrUserManagement } from "network/ApiAxios";
// import ShowCandidat from "components/recruteur/ShowCandidat";
import ShowApplicationEmployerV4 from "components/employer/showApplicationEmployerV4";

import { email_target_OutFrContractUserManagement } from 'options';

//import OneInterview from "components/recruteur/OneInterview";
import { useHistory, useLocation, Link } from "react-router-dom";
import qs from "query-string";
//import ShowCandidat from "./ShowCandidat";
import Select from "react-select";
import { SearchIcon } from "@heroicons/react/outline";
import {
  Button,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,

} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function SendEmailForOutFrContractUserManagement( props ) {
  // const [candidateEmail, setCandidateEmail] = useState("");
  const [profileTypes, setProfileTypes] = useState([]);
  const [profileTypeJobID, setProfileTypeJobID] = useState([]);
  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false);
  const [candidateUserEmail, setCandidateUserEmail] = useState("");
  const [emailTarget, setEmailTarget] = useState("");


  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }




  const sendEmailFunction = async () => {
    console.log("pppppppppppppp: sendEmailForOneJobApp: sendEmailForJobAppFunction: emailTarget: ", emailTarget);
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: ");
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: handleJobValidation(): ", handleJobValidation());
    // console.log("pppppppppppppp: learningPath: createPlaybookFunction: creatorID: ", creatorID);

     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: title: ", title);
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: description: ", description);
     const response = await sendEmailForOutFrUserManagement(user.email, candidateUserEmail, emailTarget);
     
     // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: ", response_Playbook_Creation);
     const { data } = response;
     if (data.success) {
      setEmailSent(true);
       // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: success");
       // resetForm()
       // history.push(`/employer/trainings/YourLearningPathsView`);
     } else {
       // console.log("pppppppppppppp: learningPath: createPlaybookFunction: response: failure");
     }
   
 };


const getUserCandidate = () => {
  // console.log("ssssssssssssssssss: sendEmailForOutFrContractUserManagement: getUserCandidate: ");
  instance.get(`users/getprofilebyuserid/${props.candidateUserID}`).then((resp) => {
    // console.log("ssssssssssssssssss: sendEmailForOutFrContractUserManagement: getUserCandidate : resp: ", resp);
    // console.log("ssssssssssssssssss: sendEmailForOutFrContractUserManagement: getUserCandidate : resp.data.email: ", resp.data.email);
    setCandidateUserEmail(resp.data.email);

  });
};



  useEffect(() => {
    getUserCandidate();
    // verifyIfThisIsAProfilTypeJob();
  }, []);


  return (
    <>
    
      <Container>
      <Row>
        <h4>Contacter le candidat par email:</h4>
      </Row> 
      <Row>
      
        <Col lg="8" >
          
          <FormGroup>
            <label
                className="form-control-label"
                htmlFor="input-username"
            >
                Email du candidat:
            </label>
            <Input
                className="form-control-alternative"
                value={candidateUserEmail}
                id="input-Email"
                placeholder="Email"
                // onChange={(e) => setCandidateEmail(e.target.value)}
                type="text"
            />
          </FormGroup>

        </Col>
        <Col lg="2">
        </Col>
      </Row>

      <Row >
        <Col lg="8" >

          <FormGroup>
            <label
                className="form-control-label"
                htmlFor="input-reason"
            >
                Motif de l'Email:
            </label>
            <Select className="w-full"
                  options={email_target_OutFrContractUserManagement}   
                  // value={applicationPipeline} 
                  value = {
                    email_target_OutFrContractUserManagement.filter(option => 
                      option.value === emailTarget)
                  }
                                  
                  onChange={(e) => {
                    setEmailTarget(e.value);
                    }} />
          </FormGroup>

        </Col>
        <Col lg="2">
            <label
                    className="form-control-label"
                    htmlFor="input-button-send"
                >
                
            </label>
            <Button
              className="btn-secondary"
              onClick={sendEmailFunction}
            >
              Contacter le candidat par Email
            </Button>
        </Col>
      </Row>
        {emailSent &&
          <Row>
            <Col lg="6">
            <Label
              className="form-control-label"
              htmlFor="input-button-send"
            >
              Email envoyé avec succès!
            </Label>
            </Col>
          </Row>
        }
      </Container>
    
    </>
  );
}

export default SendEmailForOutFrContractUserManagement;
