import OpenedOutFrCandidatedProfilesForRecruiters from 'components/employer/getOpenedOutFrCandidatesProfilesForRecruiters'
import EmployerLayout from 'layouts/EmployerLayout'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const OpenOutFrCandidatesProfiles = () => {
    return (
        <>
            <EmployerLayout />
            <Container>
                <Row>
                    <Col lg="2">
                    </Col>
                    <Col lg="10">
                        <OpenedOutFrCandidatedProfilesForRecruiters />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default OpenOutFrCandidatesProfiles