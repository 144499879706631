import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { login } from "network/ApiAxios";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LoginPostul = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const tryLogin = async () => {
    const response = await login(email, password);
    const { data } = response;
    if (data.success) {
      setError("");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      if ( (props.redirect === "job") && (props.id)) {
        // history.push(`/candidat/tab2?jobId=${props.id}`);
        history.push(`/jobs/apply?jobId=${props.id}`);
      } 
      /* else if ( (props.redirect === "job" || !props.redirect) && !props.id ) {
        // history.push(`/candidat/tab2?jobId=${props.id}`);
        console.log('login success')
        history.push("/candidate/home");
      }  */
      /* if ( (props.redirect === "job" || !props.redirect) && (!props.id) && (data.user.location !== "France")) {
        // history.push(`/candidat/tab2?jobId=${props.id}`);
        history.push("/outfrapplypermanentfr/home");
      }  */
      else if (props.redirect === "competition") {
        history.push(`/candidat/tab2comp?competitionId=${props.id}`);
      }
      else {
        history.push("/candidate/home");
      }
    } else {
      setPassword("");
      setError(data.msg);
    }
  };

  const { t, i18n } = useTranslation();


  return (
    <>
      <Col lg="12" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <h4>{t('loginTitle')}</h4>
            </div>
            {/* <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={require("assets/img/icons/common/github.svg").default}
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
              <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={require("assets/img/icons/common/google.svg").default}
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div> */}
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mot de passe"
                    type="password"
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {/*<div className="custom-control custom-control-alternative custom-checkbox">*/}
              {/*    <input*/}
              {/*        className="custom-control-input"*/}
              {/*        id=" customCheckLogin"*/}
              {/*        type="checkbox"*/}
              {/*    />*/}
              {/*    <label*/}
              {/*        className="custom-control-label"*/}
              {/*        htmlFor=" customCheckLogin"*/}
              {/*    >*/}
              {/*        <span className="text-muted">Remember me</span>*/}
              {/*    </label>*/}
              {/*</div>*/}
              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
              <div className="text-center">
                <Button
                  className="my-4"
                  style={{
                    
                    width: "100%",
                  }}
                  color="primary"
                  type="button"
                  onClick={tryLogin}
                >
                  {t('loginTitle')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          {/* <Col xs="6">
            <a
              className="text-light"
              onClick={() => props.history.push("/auth/reset-password")}
            >
              <small>Forgot password?</small>
            </a>
          </Col> */}
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              onClick={() => history.push("/home/register")}
            >
              <small>{t('accountCreation')}</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default LoginPostul;
