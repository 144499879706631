import { ConfigureACWithQuestion } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from 'react-select'
import { Button, Col, Container, Row } from 'reactstrap';
import { AcceptanceCriteriaOptions } from 'options';
import ConfigureQuestionOptionsModal from './ConfigureQuestionOptionsModal';

const ConfigureSelect = ({ placeholder, options, AcceptanceCriteria, questions,isUpdated, setIsUpdated,t }) => {
    const [SelectedOpt, setSelectedOpt] = useState(null);
    const [MatchQS, setMatchQS] = useState(null);

    const ConfigureCriteriaWithRelatedQuestion = async (CriteriaId, question) => {
        //console.log('CriteriaId',CriteriaId)
        //console.log('questionId',question.value)
        setSelectedOpt(question)
        try {
            const { data } = await ConfigureACWithQuestion(CriteriaId, { questionID: question.value })
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setMatchQS(questions?.find(qs => (SelectedOpt === null) ? qs._id === AcceptanceCriteria?.questionID : qs._id === SelectedOpt.value)
        )
        //console.log('MatchQS', MatchQS)
    }, [SelectedOpt])

    const liStyle = 'mt-2 mb-2';
    const styleCheck = {color:'green', zoom:'140%'};
    const styleX = {color:'red', zoom:'140%'};
    const checkElement = <span style={styleCheck}>✔</span>
    const Xelement = <span style={styleX}>✘</span>

    const configOptForQuestion = (opt)=>{
        return (AcceptanceCriteria?.ConfiguredOptions.find(el=> el.option === opt)?.Status === true) ? checkElement  : (AcceptanceCriteria?.ConfiguredOptions.find(el=> el.option === opt)?.Status === false) ? Xelement : null
    } 

    return (
        <Container>
            <Row>
                <Col sm='6'>
                    <Select
                        className='lg'
                        placeholder={placeholder}
                        options={options}
                        value={(SelectedOpt === null) ? options.find(Q => Q.value === AcceptanceCriteria?.questionID) : SelectedOpt}
                        onChange={ConfigureCriteriaWithRelatedQuestion.bind(this, AcceptanceCriteria._id)}

                    />
                </Col>
                {MatchQS && <Col sm='6'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{t('QuestionsOpts')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {MatchQS.QCMOption1 && <li className={`${liStyle}`}>{MatchQS.QCMOption1} {configOptForQuestion('Option 1')}</li>}
                            {MatchQS.QCMOption2 && <li className={`${liStyle}`}>{MatchQS.QCMOption2} {configOptForQuestion('Option 2')}</li>}
                            {MatchQS.QCMOption3 && <li className={`${liStyle}`}>{MatchQS.QCMOption3} {configOptForQuestion('Option 3')}</li>}
                            {MatchQS.QCMOption4 && <li className={`${liStyle}`}>{MatchQS.QCMOption4} {configOptForQuestion('Option 4')}</li>}
                            {MatchQS.QCMOption5 && <li className={`${liStyle}`}>{MatchQS.QCMOption5} {configOptForQuestion('Option 5')}</li>}
                            {MatchQS.QCMOption6 && <li className={`${liStyle}`}>{MatchQS.QCMOption6} {configOptForQuestion('Option 6')}</li>}
                            <hr />
                           
                           
                                <ConfigureQuestionOptionsModal
                                    qs={MatchQS}
                                    AcceptanceCriteria ={AcceptanceCriteria}
                                    isUpdated={isUpdated}
                                    setIsUpdated={setIsUpdated}
                                    t={t}
                                />
                        </AccordionDetails>
                    </Accordion>

                </Col>}
            </Row>

        </Container>
    )
}

export default ConfigureSelect