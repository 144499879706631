import React from "react";
import Home from "views/home"
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button
} from "reactstrap";
import AdminFooter from "components/Footers/AdminFooter"

class HomeHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-grad pb-1 pt-4 pt-md-1">
          <Navbar
            className="navbar-top navbar-dark"
            expand="md"
            id="navbar-main"
          >
            <Container fluid>
              <div
                className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                to="/"
              >
                 <img  src={require("assets/img/brand/2022-01-04-ConvertImage.png").default} 
                         style={{
                                width: "80px",
                                height: "40px",

                                objectFit: "cover",
                              }}
                              alt="Responsive image"/>
                {/* {props.brandText} */}
              </div>
              <Nav className="align-items-center d-none d-md-flex" navbar>
              <Button   className=""
                        style={
                        
                            { color: "white", backgroundColor: "#455266", borderColor:"transparent", marginRight:"0px" }
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                        
                        href="#link2"
                        
                        //onClick={() => history.push('/admin/edit-profile')}
                        size=""
                      >
                        Home
                      </Button>
              <Button   className=""
                        style={
                        
                            { color: "white", backgroundColor: "#455266", borderColor:"transparent", marginRight:"0px" }
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                        
                        href="#link2"
                        
                        //onClick={() => history.push('/admin/edit-profile')}
                        size=""
                      >
                        S'inscrire
                      </Button>
                      <Button   className=""
                        style={
                        
                            { color: "white", backgroundColor: "#455266", borderColor:"transparent", marginRight:"0px" }
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          
                        }}
                        
                        href="#link2"
                        
                        //onClick={() => history.push('/admin/edit-profile')}
                        size=""
                      >
                        S'identifier
                      </Button>
                 
              </Nav>
            </Container>
          </Navbar>
        </div>
        
      </>
    );
  }
}

export default HomeHeader;
