import React from "react";
import { useRef } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import UsersTable from "components/employer/UsersTable";
import UsersTableForTrainingsTracking from "components/employer/UsersTableForTrainingsTracking";


import CreateUser from "components/recruteur/createUser";
import AddUserForTrainingsTracking from "components/employer/AddUserForTrainingsTracking";

import EmployerLayout from "layouts/EmployerLayout";

class UserManagementForTraningsTracking extends React.Component {
  render() {
    return (
      <>
        <EmployerLayout />
        <div className="flex flex-col items-center space-y-5 py-3 ">
              <h3>Gestion des utilisateurs</h3>
        </div>
        <AddUserForTrainingsTracking />
       <UsersTableForTrainingsTracking />
      </>
    );
  }
}

export default UserManagementForTraningsTracking;
