import axios from "axios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";

import OneCardForOutFrUserManagement from "components/superAdmin/oneCardForOutFrUserManagement";
import qs from "query-string";

import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import SuperAdminLayout from "layouts/SuperAdminLayout";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});


function OutFrContractusersView() {
  const [candidates, setCandidates] = useState([]);
  const [pipeline, setPipeline] = useState("nothing");
  const [openTab, setOpenTab] = useState(1);
  var totalJobApplicationsNumber;
  const [pageCount, setPageCount] = useState(0);
  const [perPage] = useState(10);
  const location = useLocation();
  const query = qs.parse(location.search);
  var pageNumber = (query.pageNumber) || 1;

  const history = useHistory();

  const getUsersNumber = () => {
    instance.get(`users/getOutFranceUsersByPipelineNumber/${pipeline}`).then((resp) => {
      // console.log("resp: ", resp);
      // console.log("0000000000000000000000000: resp.data.cnt", resp.data.cnt);
      // setTotalJobsNumber(resp.data.cnt);
      totalJobApplicationsNumber = resp.data.cnt;
      // console.log("totalJobsNumber: ", totalJobsNumber);
      // console.log("perPage: ", perPage);
      setPageCount(Math.ceil(totalJobApplicationsNumber / perPage));
      // console.log("pageCount: ", pageCount);
  
    });
  }; 



  const getCandidates = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);

    // console.log("1111111111111111111111111111 outFrContractusersView getCandidates");
    // console.log("1111111111111111111111111111 outFrContractusersView getCandidates: pipeline: ", pipeline);
    instance.get(`users/getOutFrContractUsersByPipelinePerPage/${pipeline}/${pageNumber}/${perPage}`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setCandidates(resp.data.users);
      
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // console.log("3333333333333333333333: next page url: ", `/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // setOffset(selectedPage + selectedPage * 5);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/superadmin/profiles/page/?pageNumber=${selectedPage+1}`);
    history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=${selectedPage+1}`);
    // history.push(`/superadmin/home`);
  };

  useEffect(() => {
    getUsersNumber();
    getCandidates();
  }, [pageNumber, pipeline, openTab]);

  return (
    <>
      <EmployerLayout />
        <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">

        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Table className="GatTable">
              <tbody>
                <tr>
                <td
                    className={
                      (openTab === 1)
                        ? "tdActive "
                        : "td "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1)
                      setPipeline("nothing");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link20"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-users" aria-hidden="true" />{" "}
                    Pas de pipeline
                </td>

                <td
                    className={
                      (openTab === 2)
                        ? "tdActive "
                        : "td "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                      setPipeline("Sourcing");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link21"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-users" aria-hidden="true" />{" "}
                    Sourcing
                </td>

                  <td
                    className={openTab === 3 ? "tdActive " : "td "}
                    //activeClassName={openTab === tab_Nb_for_A_qualifier_Pipeline ? "active" : {}}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(3);
                      setPipeline("To_treat");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    //data-toggle="tab"
                    // to="#link1"
                    //role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    // size="sm"
                  >
                    <i className="fa fa-book" aria-hidden="true" />{" "}
                    A traiter
                  </td>
                  
                  
                  <td
                    className={openTab === 4 ? "tdActive " : "td "}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(4);
                      setPipeline("Valid");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link2"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                    Valide
                  </td>
                  <td
                    className={openTab === 5 ? "tdActive " : "td "}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(5);
                      setPipeline("To_be_continued");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);                   
                    }}
                    data-toggle="tab"
                    // href="#link2"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                    A suivre
                  </td>
                  <td
                    className={
                      (openTab === 6)
                        ? "tdActive "
                        : "td "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(6);
                      setPipeline("Rejected");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link3"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-users" aria-hidden="true" />{" "}
                    Rejeté
                  </td>

                  <td
                    className={
                      (openTab === 7)
                        ? "tdActive "
                        : "td "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(7);
                      setPipeline("Off_target");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link3"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-users" aria-hidden="true" />{" "}
                    Hors cible
                  </td>

                  <td
                    className={
                      (openTab === 8)
                        ? "tdActive "
                        : "td "
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(8);
                      setPipeline("Classified");
                      history.push(`/employer/suivi/OutFrContractusersView/page/?pageNumber=1`);
                    }}
                    data-toggle="tab"
                    // href="#link8"
                    role="tablist"
                    //onClick={() => history.push('/admin/edit-profile')}
                    size="sm"
                  >
                    <i className="fa fa-users" aria-hidden="true" />{" "}
                    Classé
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardHeader>
          <CardBody>
          <Button
            // color="primary"
            // size="sm"
            type="button"
            className="shadow-xl text-white"
            style={{backgroundColor:"#11a0ef"}}
            onClick={() => getCandidates()}
          >
            Rafraichir la liste des utilisateurs
          </Button>
          <hr className="my-4" />
            {candidates
              .map((candidateUser) => (
                <OneCardForOutFrUserManagement 
                  key={candidateUser._id}
                  candidateUserID={candidateUser._id} 
                />
              ))}
            </CardBody>
          <CardFooter className="bg-transparent w-full">
          <Row>
            <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            </Row>
          </CardFooter>
        </Card>
        </Col>

        </Row>

      </Container>
            
    </>
  );
}

export default OutFrContractusersView;