import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import HomeNavbar from "components/Navbars/HomeNavbar";

class HomeLayout extends React.Component {
  
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">
          <HomeNavbar />
          <div className="homeHeader bg-grad pb-1 pt-4 pt-md-1">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                   
                  </Col>
                </Row>
              </div>
            </Container>
            
          </div>
          
          {/* Page content */}
          <Container className="mt-6 mb-6">
         
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/home/login" />
              </Switch>
            </Row>
          </Container>
        </div>
       {/*  <AuthFooter /> */}
      </>
    );
  }
}

export default HomeLayout;
