import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalBody,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap";
import "reactjs-popup/dist/index.css";
import config from "config";
import EditJobAcceptanceCriteriaResponse from "./EditJobAcceptanceCriteriaResponse";
import { AcceptanceCriteriaOptions } from "options";
import AcceptanceCriteriaSelect from "components/other/AcceptanceCriteriaSelect";
import { useTranslation } from "react-i18next";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function JobAcceptanceCriteria(props) {
  const { t, i18n } = useTranslation();

  const [AcceptanceCriteriaToModify, setAcceptanceCriteriaToModify] = useState("");
  const [loading, setLoading] = useState(true);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [modalOpen1, setModalOpen1] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [questionAddingModalOpen, setQuestionAddingModalOpen] = useState(false);

  const [JobAcceptanceCriteria, setAcceptanceCriteria] = useState("");

  const [description, setDescription] = useState("");

  const [data, setData] = useState([]);
  const [preselectedData, setPreselectedData] = useState([]);
  const [automaticResponse , setAutomaticResponse] = useState([])
  const [msg,setMsg] = useState(null)
  //const [responsee, setResponsee] = useState('')
  //const [updateTrigger, setUpdateTrigger] = useState(false);
  //const [isOpen, setIsOpen] = useState(false);
  //const [selectedOption, setSelectedOption] = useState(null)


  // const handleUpdate = () => {
  //   setUpdateTrigger(!updateTrigger);
  // };

  const getAutomaticQualifiedACResponse = async()=>{
    try {
      const {data} = await instance.get(`AutomaticQualificationForJobCriteria/${props.candidatId}/${props.jobID}`)
      console.log('Automatic',data)
      setAutomaticResponse(data)
    } catch (error) {
      setMsg(error?.response?.data?.msg)
      console.log('error',error?.response?.data.msg)
    }
  }


  useEffect(() => {
    GetAcceptanceCriteriaByjobID();
    GetPreselectedAcceptanceCriteriaByjobID();
    getAutomaticQualifiedACResponse()
  }, []);

  /*  useEffect(() => {
     GetPreselectedAcceptanceCriteriaByjobID();
     JobAcceptanceCriteria.length > 0 && (
       JobAcceptanceCriteria.map((criteria) => {
         const matchingResponse = preselectedData.find((response) => response.AcceptanceCriteriaID._id === criteria._id);
 
         updateResponseText(criteria, matchingResponse?.status)
       }))
 
   }, []); */


  const GetAcceptanceCriteriaByjobID = async () => {
    console.log('props', props)
    const response = await instance.get(`JobAcceptanceCriteria/getAcceptanceCriteriaByjobID/${props.jobID}`)

    const { data } = response;
    if (data.success) {
      setAcceptanceCriteria(response.data.AcceptanceCriteria);
      //console.log('AC',response.data.AcceptanceCriteria)

      console.log(data);
    } else {
      console.log("err");

    }
  }

  const GetPreselectedAcceptanceCriteriaByjobID = async () => {


    console.log("propsssss", props)
    instance
      .get(`JobAcceptanceCriteria/getResponseAcceptancesCriteria/${props.candidatId}`)
      .then((response) => {

        setPreselectedData(response.data.acceptanceCriteriaResponse)


        console.log("setPreselectedData", response.data.acceptanceCriteriaResponse
        );
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }


  /* const updateResponseText = (criteriaId, updatedStatus) => {
    const updatedCriteria = JobAcceptanceCriteria.map((criteria) => {
      if (criteria._id === criteriaId) {
        return {
          ...criteria,
          status: updatedStatus, // Update the response text
        };
      }
      return criteria;
    });

    setAcceptanceCriteria(updatedCriteria);
  };  */



  return (

    <Container>
      <Table responsive className="mt-5">
        {JobAcceptanceCriteria && JobAcceptanceCriteria.length === 0 && (
          <h3>{t('AccCriteriaKey2')}!</h3>
        )}
        <thead>
          <tr>
            <th><h5>{t('AcceptanceCriteria')}</h5></th>
            <th><h5>{t('OfferDesKey4')} {t('thee')} status</h5></th>
            <th><h5>{t('QuestionCatalogAnalysisKEY5')}</h5></th>
          </tr>
        </thead>
        <tbody>
          {JobAcceptanceCriteria.length > 0 && (
            JobAcceptanceCriteria.map((criteria) => {
              const matchingResponses = preselectedData?.filter((response) => response.AcceptanceCriteriaID._id === criteria._id);
              const displayMessage = matchingResponses.length === 0;
              const responseText = displayMessage ? 'Pas de réponse' : matchingResponses[0].status;
              const ACautomaticResp = automaticResponse.find(ACresp => ACresp.AcceptanceCriteriaID === criteria._id )
              const AutomaticRes = (ACautomaticResp && 'AutomaticStatus' in ACautomaticResp) ? ACautomaticResp.AutomaticStatus : "Configuration manuelle" 
              //console.log("AutomaticRes",AutomaticRes)

              return (
                <React.Fragment key={criteria._id}>
                  <tr /*className="flex items-center justify-between text-black w-full"*/ className="w-full">
                    <td className="w-75">
                      <div style={{ textWrap: "wrap" }}>
                        {(criteria.description.length >= 60) ? `${criteria.description.slice(0, 60)}...` : criteria.description}
                      </div>
                    </td>
                    <td className='w-75'>
                      <AcceptanceCriteriaSelect
                        criteriaId={criteria._id}
                        responseText={responseText}
                        AcceptanceCriteriaOptions={AcceptanceCriteriaOptions}
                        instance={instance}
                        candidatId={props.candidatId}
                        t={t}
                      />
                    </td>
                    <td className="text-center">
                      {!msg && <h5 >{AutomaticRes}</h5>}
                    </td>
                    {/* <td>
               <Button
                   style={{
                      className:"justify-content-center" ,
                     backgroundColor: "#404954",
                     borderColor: "#404954",
                     color: "white",
                     marginLeft: "10%",

                   }}
                   color=""
                   type="button"
                   size="sm"
                   onClick={() => {
                     setAcceptanceCriteriaToModify(criteria);
                     setModalOpen(!modalOpen);
                   }}
                 >
                   Modifier
                 </Button>
               </td> */}
                  </tr>
                  {/*!displayMessage && (
               matchingResponses.slice(1).map((response) => (
                 <tr key={response._id} className="flex items-center justify-between text-black">
                   <td></td>
                   {/* <td>{response.status.toString()}</td> */}
                  {/*<td>{response.status == true ? "oui" : "non"}</td>

                 </tr>
               ))
               )*/}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </Table>

      {/* <Modal
   toggle={() => setModalOpen(modalOpen)}
   isOpen={modalOpen}
   size="lg"
 >
   <div className="modal-header">
     <button
       aria-label="Close"
       className="close"
       type="button"
       onClick={() => setModalOpen(!modalOpen)}
     >
       <span aria-hidden={true}>×</span>
     </button>
   </div>
   <ModalBody>
     <EditJobAcceptanceCriteriaResponse
     setPreselectedData={setPreselectedData}
       AcceptanceCriteriaId={AcceptanceCriteriaToModify}
       candidatId={props.candidatId}
         setModalOpen={setModalOpen}
         updateResponseText={updateResponseText}
         onUpdate={handleUpdate}
         />
   </ModalBody>
 </Modal> */}


    </Container >


  );
}

export default JobAcceptanceCriteria;
