import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerCand } from "../../network/ApiAxios";
import config from "../../config";
import { PencilAltIcon } from "@heroicons/react/outline";
import Select from "react-select";
import HomepageLayout from "layouts/HomepageLayout";
import RegisterV4 from "components/other/registerV4";


const customStyles = {
    // option: (provided, state) => ({
    //   ...provided,
    //   borderBottom: '1px dotted pink',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    // }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
    }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';
  
    //   return { ...provided, opacity, transition };
    // }
  }
const RegisterV4View = (props) => {
 


  return (
    <>
      <HomepageLayout />

    <div 
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
        // height: '100vh',
      }}
    
    >
    <RegisterV4 />
    </div>


    </>
  );
};

export default RegisterV4View;
