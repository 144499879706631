import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import EditHeader from "../../components/Headers/EditHeader";
import { edit } from "../../network/ApiAxios";
import axios from "axios";
import config from "config";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

const EditProfile = (props) => {
  let user = JSON.parse(localStorage.getItem("user"));

  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [isTestUser, setIsTestUser] = useState(false);
  const [company, setCompany] = useState(user.company);
  const [companyEmail, setCompanyEmail] = useState(user.companyEmail);
  const [phone, setPhone] = useState(user.phone);
  const [webSite, setWebSite] = useState(user.webSite);
  const [activity, setActivity] = useState(user.activity);
  const [description, setDescription] = useState(user.description);
  const [location, setLocation] = useState(user.location);
  const [image, setImage]=useState();
  const options = [
    { value: "Agroalimentaire", label: "Agroalimentaire" },
    { value: "Banque / Assurance", label: "Banque / Assurance" },
    {
      value: "Bois/ Papier/ Carton/ Imprimerie",
      label: "Bois/ Papier/ Carton/ Imprimerie",
    },
    {
      value: "BTP/ Matériaux de construction",
      label: "BTP/ Matériaux de construction",
    },
    { value: "Chimie/ Parachimie", label: "Chimie/ Parachimie" },
    {
      value: "Commerce/ Négoce/ Distribution",
      label: "Commerce/ Négoce/ Distribution",
    },
    {
      value: "Édition/ Communication/ Multimédia",
      label: "Édition/ Communication/ Multimédia",
    },
    { value: "Électronique/ Électricité", label: "Électronique/ Électricité" },
    { value: "Études et conseils", label: "Études et conseils" },
    { value: "Industrie pharmaceutique", label: "Industrie pharmaceutique" },
    { value: "Informatique/ Télécoms", label: "Informatique/ Télécoms" },
    {
      value: "Machines et équipements/ Automobile",
      label: "Machines et équipements/ Automobile",
    },
    {
      value: "Métallurgie/ Travail du métal",
      label: "Métallurgie/ Travail du métal",
    },
    { value: "Plastique/ Caoutchouc", label: "Plastique/ Caoutchouc" },
    { value: "Services aux entreprises", label: "Services aux entreprises" },
    {
      value: "Textile/ Habillement/ Chaussure",
      label: "Textile/ Habillement/ Chaussure",
    },
    { value: "Transports/ Logistique", label: "Transports/ Logistique" },
  ];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")).email === "test@test.com") {
      setIsTestUser(true);
    }
  }, []);

  const editUser = async () => {
    const formData = new FormData();
    formData.append("userID", user._id);
    formData.append("name", name);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("company", company);
    formData.append("companyEmail", companyEmail);
    formData.append("phone", phone);
    formData.append("webSite", webSite);
    formData.append("activity", activity);
    formData.append("description", description);
    formData.append("location", location);
    formData.append("image", image);
      console.log("image", image)
    const response =  await instance.post("/users/edit",  formData );
    const { data } = response;
    if (data.success) {
      user = {
        ...user,
        name,
        lastName,
        email,
        company,
        companyEmail,
        phone,
        webSite,
        activity,
        description,
        location,
        image
      };
      localStorage.setItem("user", JSON.stringify(data.user[0]));
      instance.put("/jobs/imUpdate",  {userID: data.user[0]._id, image: data.user[0].image} )
      props.history.push("/admin/user-profile");
    }
  };

  return (
    <>
      <EditHeader />
      {/* Page content */}
      <Container className="mt--7" >
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-Card border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="card-t">Modifier le profil</h3>
                    {isTestUser ? (
                      <h5>
                        You are not allowed to edit the test user. Create
                        another user to test this functionality
                      </h5>
                    ) : null}
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                     color="primary"
                      href="#pablo"
                      onClick={editUser}
                      //size="sm"
                      disabled={isTestUser}
                    >
                      sauvegarder
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white">
                <Form>
                <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Logo 
                          </label>
                          <Input 
                          className="form-control-alternative" 
                          type="file" 
                          accept= 'image/*'
                          onChange={(e)=>setImage(e.target.files[0])} />

                        </FormGroup>
                      </Col>
                      </Row>
                      </div>
                  <h6 className="heading-small text-muted mb-4">Information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nom
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={name}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Prénom
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={lastName}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setLastName(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nom de l’entreprise
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={company}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setCompany(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Numéro de téléphone
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={phone}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setPhone(e.target.value)}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email de l’entreprise
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            value={companyEmail}
                            onChange={(e) => setCompanyEmail(e.target.value)}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Pays
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={location}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setLocation(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Secteur d'activité
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={activity}
                            id="input-username"
                            placeholder="Username"
                            onChange={(e) => setActivity(e.target.value)}
                            type="select"
                          >
                            <option>Agroalimentaire</option>
                            <option>Banque / Assurance</option>
                            <option>Bois/ Papier/ Carton/ Imprimerie</option>
                            <option>BTP/ Matériaux de construction</option>
                            <option>Chimie/ Parachimie</option>
                            <option>Commerce/ Négoce/ Distribution</option>
                            <option>Édition/ Communication/ Multimédia</option>
                            <option>Électronique/ Électricité</option>
                            <option>Études et conseils</option>
                            <option>Industrie pharmaceutique</option>
                            <option>Informatique/ Télécoms</option>
                            <option>Machines et équipements/ Automobile</option>
                            <option>Métallurgie/ Travail du métal</option>
                            <option>Plastique/ Caoutchouc</option>
                            <option>Services aux entreprises</option>
                            <option>Textile/ Habillement/ Chaussure</option>
                            <option>Transports/ Logistique</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Site WEB
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            value={webSite}
                            onChange={(e) => setWebSite(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}

                  <div className="pl-lg-4">
                    <FormGroup>
                      <label className="form-control-label">
                        Description de l'entreprise
                      </label>
                      <Input
                        className="form-control-alternative"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="4"
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;
