import React, { useEffect, useState } from 'react'
import { Badge, Card, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, ListGroup, ListGroupItem, Row } from 'reactstrap'
import avatarImg from '../../assets/img/brand/AvatarPlaceholder1.png';
import avatarImg2 from '../../assets/img/profileavatar.jpg';
import avatarImg3 from '../../assets/img/brand/profileAvatar2.webp';
import { useHistory } from 'react-router-dom';
import { getProfileFrUserByID } from 'network/ApiAxios';
import { getCompetenciesByCandidate } from 'network/ApiAxios';
import config from 'config';
import { getUserById } from 'network/ApiAxios';
import { getOneFakeUser } from 'network/ApiAxios';
import { Padding } from '@mui/icons-material';
const ProfileImageByDefault = require('../../assets/img/brand/500.png');

const OneCardForTalentedCandidateForEmployer = ({ key, id, location }) => {
    const [user, setUser] = useState(null);
    const [profileC, setProfileC] = useState(null);
    const [competencies, setCompetencies] = useState([])

    const PathPortion = user?.location === 'France' ? "sharedFrProfile" : "sharedOutFrProfile"
    const history = useHistory()
    const NavigateToSharedProfile = () => {
        //history.push(`/${PathPortion}/${id}`)};
        window.open(`${config.CVs_URL}/${PathPortion}/${id}`, '_blank');
    }

    const getUserDetails = async () => {
        try {
            const { data } = await getUserById(id);
            //console.log('data user', data)
            if (data) {
                setUser(data);

            }

        } catch (error) {
            console.log(error)
            const resp = await getOneFakeUser(id)
            setUser(resp.data)
        }
    }


    const getProfileCandidate = async () => {
        try {
            const { data } = await getProfileFrUserByID(id)
            setProfileC(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCandidateCompetencies = async () => {
        setCompetencies([])
        try {
            //const { data } = await getMyCompetencies(id)
            const { data } = await getCompetenciesByCandidate(id)
            data && setCompetencies(data.competencies)
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getUserDetails()
        getProfileCandidate()
        getCandidateCompetencies()
    }, [id])

    const overlayTextStyle = {
        position: 'absolute',
        //top: '0%', 
        left: '20%',
        transform: 'translate(-50%, -50%)', // Center the text
        color: 'white',
        textAlign: 'center',
    };



    const CompetenciesDisplayed = competencies.length ? competencies : user?.ParsedDataCV?.competencies ? user?.ParsedDataCV?.competencies : [];

    //console.log('CompetenciesDisplayed', CompetenciesDisplayed)

    const card = {
        position: "relative",
        width: "100%",
        height: "247px",
        backgroundSize: "cover",
        borderRadius: "8px",
        color: "white"
    }

    const cardContent = {
        //background: 'rgba(255, 255, 255, 0.7)', /* Semi-transparent background*/ 
        position: "absolute",
        top: "75%",
        left: "35%",
        transform: "translate(-50%, -50%)",
        //textAlign: "center",
        color: "white",
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)"
    }

    const personaLInfo = user?.ParsedDataCV?.personal_infos;

    const cardTitle = {
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }



    return (
        <>
            {user && <a href={`${config.DOMAIN_NAME}/${PathPortion}/${id}`} target='_blank' rel="noreferrer">
            <Card
                key={key}
                style={{ width: '324px',height:"423", cursor: 'pointer', position: 'relative' }}
                //onClick={NavigateToSharedProfile}
            >                                                                             
                {/*                 <CardImg top width="100%" src={(user?.image) ? `${config.Data_URL}${user?.image}` : ProfileImageByDefault} />
//  */}                <div className="card" style={{ backgroundImage: `url(${user?.image ? `${config.CVs_URL}${user.image}` : user?.cv_photo ? `${config.CVs_URL}${user.cv_photo}` : avatarImg3})`, ...card }}>
                    <div className="card-content" style={cardContent}>
                        <CardTitle style={{ color: 'white' }} className='mt-1' tag="h2">{`${user?.name}`}</CardTitle>
                        <div className='d-flex justify-content-around'>
                            {user?.location && <div className='d-flex'>
                                <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg> {(user?.ParsedDataCV?.personal_infos?.address.city || profileC?.village) ? "Localisé(e) à " : "Localisé(e) en"} { user?.ParsedDataCV?.personal_infos?.address.city || profileC?.village} {user.location}
                            </div>}

                        </div>

                    </div>
                </div>
                <div style={overlayTextStyle}>
                    <CardText >
                        <span className={`badge ${!('Freelance_disponibility' in user) ? '' : (user?.Freelance_disponibility) ? "bg-success" : "bg-warning"} `}>{!('Freelance_disponibility' in user) ? '' : (user?.Freelance_disponibility) ? "Disponible" : "Indisponible"}</span>
                    </CardText>
                </div>

                <CardBody>
                    <CardTitle className='mt-1' style={cardTitle} tag="h2">{user?.Freelance_disponibility ? "Freelancer" : personaLInfo?.current_profession ? personaLInfo?.current_profession : (profileC?.expertiseField || profileC?.profileType)}</CardTitle>
                    {user?.TJM && <CardText className='badge bg-light' >{user.TJM} €/jour</CardText>}

                    {CompetenciesDisplayed.length ?
                    <Row style={{ height:"67px", overflow: 'hidden' }} /*className='no-gutters d-flex flex-wrap'*/>
                        {CompetenciesDisplayed.map((comp, i) => <Badge className='p-2 m-1 d-flex justify-content-center align-items-center' color='primary' key={i}>
                            {comp.name}
                        </Badge>)}
                    </Row>
                    : null}

                </CardBody>
            </Card>
            </a>}
        </>
    )
}

export default OneCardForTalentedCandidateForEmployer