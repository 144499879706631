
import { getAllActiveSubscriptionProducts } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row, Spinner } from 'reactstrap'
import OneSubscription from './OneSubscription'
import { CheckCandidateSubscription } from 'network/ApiAxios'
//import {useHistory} from 'react-router-dom'

const TrainingSubscription = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [hasActiveSubscription, setHasActiveSubscription] = useState(null);
    const [candidateActiveSubscription, setCandidateActiveSubscription] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'))

    const getActiveSubsciptionLists = async () => {
        setIsLoading(true)
        try {
            const { data } = await getAllActiveSubscriptionProducts()
            setSubscriptions(data)
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoading(false)
        }
    }

    const CheckSubscription = async () => {
        setIsLoading(true);
        try {
            const { data } = await CheckCandidateSubscription(user?.email);
            console.log('check', data)
            setHasActiveSubscription(data.hasActiveSubscription)
            data?.subscriptions?.length && setCandidateActiveSubscription(data.subscriptions);

        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getActiveSubsciptionLists()
        CheckSubscription()
    }, [])



    const { t} = useTranslation();
    const contentBtn = (!hasActiveSubscription && !candidateActiveSubscription.length) ? `${t('BtnSubscription2')}` : `${t('BtnSubscription1')}`


    return (
        <Container>
            {
                isLoading ? <div className='w-full d-flex justify-content-center'>
                    <Spinner
                        color="primary"
                        type="grow"
                    >
                        Loading...
                    </Spinner>
                </div>
                    :
                    <div className='shadow p-3 bg-body rounded bg-secondary shadow border-0 d-flex flex justify-content-around align-items-center flex-wrap '>

                        <Card
                            className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                            color="primary"
                            outline
                            style={{ width: "22rem" }}
                        >
                            <CardBody>
                                <CardTitle tag="h1">
                                    {t('Offer')} Free
                                </CardTitle>
                                <Row className="d-flex justify-content-center" style={{ height: "4rem" }}>
                                    <CardText className='w-400 text-break'>
                                        Non accès aux fomations
                                    </CardText>
                                </Row>

                                <h2 className='mt-3'>0€ /{t('mounth')}</h2>
                                <Button disabled={!hasActiveSubscription && !candidateActiveSubscription.length} id='Free' className='rounded-pill mt-4' color='primary'>
                                    {/*  {contentBtn('Free')} */}
                                    {contentBtn}
                                </Button>
                                <CardText style={{ height: '1.6rem' }} className='mt-1'>

                                </CardText>
                            </CardBody>
                        </Card>


                        {subscriptions && subscriptions.length ? subscriptions.map((product, i) => <OneSubscription callback={getActiveSubsciptionLists} productId={product.productId} key={i} contentBtn={contentBtn} hasActiveSubscription={hasActiveSubscription} candidateActiveSubscription={candidateActiveSubscription} user={user} t={t} />)
                            : null
                        }

                    </div>

            }


        </Container>
    )
}

export default TrainingSubscription