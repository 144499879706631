import React from "react";
import { useRef } from "react";
import PageTitleStyle from "components/styles/PageTitleStyle";
import PageSpace from "components/styles/pageSpace";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import CreateLearningPath from "components/employer/createLearningPath";
import EmployerLayout from "layouts/EmployerLayout";

class CreateLearningPathView extends React.Component {
  render() {
    return (
      <>
        <EmployerLayout />
        <PageSpace />
        <PageTitleStyle
          title="Créer un nouveau parcours de formation"
        >
        </PageTitleStyle>
        <Container>
          <Row>
            <Col lg="2">
            </Col>
            <Col lg="10">
              <CreateLearningPath />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CreateLearningPathView;
