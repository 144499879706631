import React from "react";
import HomepageLayout from "layouts/HomepageLayout";
import { Card, CardBody, Col } from "reactstrap";
import qs from "query-string";
import { useLocation } from 'react-router-dom';
import RegisterCandidateV2 from "components/other/RegisterCandidateV2";



const SignUpCandidateView = () => {
    const location = useLocation();
    const query = qs.parse(location.search);
    const {id, redirect} = query;
    //console.log('id',id);
    //console.log('redirect', redirect);




    return (
        <>
            <HomepageLayout />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 50,
                }}

            >
                <Col lg="8" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <RegisterCandidateV2 id={id} redirect={redirect} />
                        </CardBody>
                    </Card>
                </Col>
            </div>


        </>
    );
};

export default SignUpCandidateView;
