import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { H2, H3 } from "components/Typography";

import Offres from "components/recruteur/jobs";
import CandidatOffres from "components/candidat/CandidatOffres";
import { Input, } from "reactstrap";
import Select from "react-select";

import { Container, Box, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
//import { DropzoneArea } from 'material-ui-dropzone';
//import {useDropzone} from 'react-dropzone';

import { Formik } from "formik";
import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

const options_niveau_diplome = [
  { value: 'BAC', label: 'BAC' },
  { value: 'BTS', label: 'BTS' },
  { value: 'License', label: 'License' },
  { value: 'Maitrise', label: 'Maitrise' },
  { value: 'Master juste la première année', label: 'Master juste la première année' },
  { value: 'Master deuxième année', label: 'Master deuxième année' },
  { value: 'BAC+5 (Ingénierie)', label: 'BAC+5 (Ingénierie)' },
  { value: 'Doctorat', label: 'Doctorat' },
  { value: 'Autre', label: 'Autre' }
];

function EtrangerCDIenFranceHome() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [ville, setVille] = useState("");
  const [CV, setCV] = useState("");
  const [university, setUniversity] = useState("");
  const [NiveauDiplome, setNiveauDiplome] = useState("");
  

  const [CVinput, setCVinput] = useState(false);
  const handleFormSubmit = async values => {
    //console.log(values);
  };

  const createProfileWithCV = () => {


    const formData = new FormData();
    formData.append("user", user._id);
    formData.append("CV", CV);
    formData.append("title", title);
    formData.append("ville", ville);
    formData.append("studyLevel", NiveauDiplome);
    formData.append("university", university);
    instance.post(`outFrSearchCdiFrProfiles/createprofilewithcv`, formData).then((resp) => {
      // localStorage.setItem("user", JSON.stringify(resp.data.user));
      // console.log("8888888888888: ", user);
      history.push(`horsfrancecdienfrance`)
    });
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  const initialValues = {
    titre: "",
    localisation: "",
    diplome: "",
    NiveauDiplome: "",
    university: ""
    };
  const checkoutSchema = yup.object().shape({
    // titre: yup.string().required("required"),
  });
  
  return (
    <>
      <CandidateLayoutV3 />
      {/* <Header /> */}
      {/*<CandidatOffres Offres />*/}
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
          <h2>Projet: Je suis hors de la France et je cherche un CDI en France</h2>
          <h3>Etapes</h3>
      </Container>
      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>

        <Grid item xs={12}
              sx={{ 
                mt: "25px",
                mb: "25px"      
            }}
              >
                <label className="px-lg-3">Vous êtes hors de la France et vous cherchez un CDI en France</label>
                <Button 
                  variant="contained" 
                  color="primary" 
                  // type="submit" 
                  // target="_blank"
                  onClick={() => { history.push(`/outfrapplypermanentfr/contract/home`) }}
                  // onClick={() => openInNewTab('/outfrapplypermanentfr/createprofile')}
                  >
                Cliquer ici
              </Button>
                </Grid>

      <Grid item xs={12}
              sx={{ 
                mt: "25px",
                mb: "25px"      
            }}
              >
                <label className="px-lg-3">Vous êtes hors de la France et vous cherchez une mission Freelance en France</label>
                <Button 
                  variant="contained" 
                  color="primary" 
                  // type="submit" 
                  // target="_blank"
                  onClick={() => { history.push(`/outfrapplypermanentfr/freelance/createprofile`) }}
                  // onClick={() => openInNewTab('/outfrapplypermanentfr/mycompetencies')}
                  >
                Cliquez ici
              </Button>
                </Grid>
      </Box>
    </>
  );
}

export default EtrangerCDIenFranceHome;
