import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import EditLocalisationSection from './EditLocalisationSection'

const LocalisationSection = ({ profileC, candidateUser, getProfileCandidate, getUser, user, t, isSharedProfile }) => {
    const address = candidateUser?.ParsedDataCV?.personal_infos?.address
    return (
        <Container>
            <Card className="shadow">
                <CardHeader style={{ border: 'none' }}>
                    <Row>
                        <Col sm='10'>
                            <h6 className="heading-small text-muted">
                                {t('LocationTitle')} & {t('Mobility')}
                            </h6>
                        </Col>

                        <Col sm='2'>
                            {((user?._id === candidateUser?._id) && profileC && user) && <EditLocalisationSection profileC={profileC} candidateUser={candidateUser} getUser={getUser} getProfileCandidate={getProfileCandidate} t={t} />
                            }                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className='mb-3'>
                        <Col sm='2'>
                            <svg style={{ color: '#5e72e4' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                            </svg>

                        </Col>
                        <Col sm='10'>
                            {
                                (isSharedProfile && (address?.country || address?.city)) ? < p > {address.country}, {address.city}</p>
                                :
                            <p>{candidateUser?.location}, {profileC?.village}</p>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='2'>
                            <svg style={{ height: '2rem', color: '#5e72e4' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                            </svg>
                        </Col>
                        <Col sm='10'>
                            <span style={{ fontSize: '0.9rem' }}>{profileC?.mobility}</span>

                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container >
    )
}

export default LocalisationSection