import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import DownloadLink from "react-download-link";
import { ClipLoader } from "react-spinners";
import { Button, Col, Card, CardImg, FormGroup, InputGroup, InputGroupAddon, CardHeader, Table, CardBody, Form } from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function ShowApplicationEmployerV4({ candidatId }) {
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);

  
  const options_jobApplicationPipeline = [
    { value: 'Sourcing', label: 'Sourcing' },
    { value: 'A relancer', label: 'A relancer' },
    { value: 'A qualifier', label: 'A qualifier' },
    { value: 'Validé', label: 'Validé' },
    { value: 'Rejeté', label: 'Rejeté' },
  ];
  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [candidat, setCandidat] = useState("");
  const [jobApplicationCV, setJobApplicationCV] = useState("");
  const [drive, setDrive] = useState("");
  const [hasDrive, setHasdrive] = useState(false);
  const [motivLttr, setMotivLttr] = useState(false);
  const [applicationPipeline, setApplicationPipeline] = useState("");

  const [openTab, setOpenTab] = useState(1);
  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormCV, setJobApplicationFormCV] = useState("");

  const [jobApplicationCompetencies, setJobApplicationCompetencies] = useState("");
  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [profileAnalysis, setProfileAnalysis] = useState("");
  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");

  

  


  
  
  
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);


  const startMerge = (data) => {
    var buffer = [];
    console.log("data", data);
    for (let i = 0; i < data.videos.length; i++) {
      buffer.push(
        `./videos/${data.prenom}--${data.videos[i].question._id}..mp4`
      );
    }
    instance.put(`merges/${candidat._id}`, { buffer });
  };

  const updatePipeline = async (pipeline) => {
    // var pipeline = "Rejeté";
    const response = await instance.put(`jobApplication/updatePipeline/${candidatId}`, {pipeline,});
    // console.log("ccccccccccccccccccccccccc : response: ", response);
    if (response.data.success) {
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: success");
      setApplicationPipeline(pipeline);
    } else {
      // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
    }
  };

  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: jobApplicationUserID: ", jobApplicationUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${jobApplicationUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormCV(resp.data.CV);
      // console.log("33333333333333333333: fetchForm: ");
      // console.log("33333333333333333333: fetchForm: resp: : ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};
const fetchCandidateCompetencies = async () => {
  // console.log("222222222222222222: fetchCandidateCompetencies: step 0: ");
  // console.log("222222222222222222: fetchCandidateCompetencies: step 1: fetchCandidateCompetencies: ", jobApplicationUserID);
  
  instance.get(`/candidatesCompetencies/getCompetenciesByUserID/${jobApplicationUserID}`).then((resp) => {
    setJobApplicationCompetencies(resp.data);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 2:");
    // console.log("222222222222222222: fetchCandidateCompetencies: step 3 : resp: ", resp);
    // console.log("222222222222222222: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
    });
};

const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${candidatId}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobApplicationCV(res.data.CV);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
    setApplicationPipeline(res.data.pipeline);
    if (res.data.drive) {
      setHasdrive(!hasDrive);
    }
    if (res.data.videos.length) {
      setVideos(res.data.videos);
      // if (!res.data.merged) {
      //   startMerge(res.data);
      // }
      // console.log(res.data.videos);
    }
  })
  .catch((err) => {
    console.log(err);
  });
};
const fetchProfileAnalysis = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getGeneralValidationByUserID/${jobApplicationUserID}`).then((resp) => {
    setProfileAnalysis(resp.data);
    // console.log("333333333333333333: fetchProfileAnalysis: step 2:");
    // console.log("333333333333333333: fetchProfileAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchProfileAnalysis: step 4 : resp.data: ", resp.data);
    });
};

const fetchCandidateCompetenciesAnalysis = async () => {
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${jobApplicationUserID}`).then((resp) => {
    setCandidateCompetenciesAnalysis(resp.data);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
};
const fetchJobApplicationNotes = async () => {
  console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${candidatId}`).then((resp) => {
    if (resp.data.length) {
      setJobApplicationNotes(resp.data);
    }
    
    console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};


const createJobApplicationNote = async () => {
  let jobApplicationID = candidatId;
  let note = addThisNote;



  // console.log("444444444444444444: createJobApplicationNote: step 0: ");
  // console.log("444444444444444444: createJobApplicationNote: step 1: ", candidatId);
  // console.log("444444444444444444: createJobApplicationNote: step 5 addThisNote: ", addThisNote);
  // console.log("444444444444444444: createJobApplicationNote: step 6 formData: ", formData);
  // console.log("444444444444444444: createJobApplicationNote: step 7 inputs: ", inputs);
  
  instance.post("/jobApplicationNotes/createNote", 
  {
    jobApplicationID,
    note,
  }
  ).then((resp) => {
    console.log("444444444444444444: createJobApplicationNote: step 2:");
    console.log("444444444444444444: createJobApplicationNote: step 3 : resp: ", resp);
    console.log("444444444444444444: createJobApplicationNote: step 4 : resp.data: ", resp.data);
    });
};


   useEffect(() => {
    fetchJobApplication();
    fetchForm();
    fetchCandidateCompetencies();
    fetchProfileAnalysis();
    fetchCandidateCompetenciesAnalysis();
    fetchJobApplicationNotes();
  
  }, [candidatId, jobApplicationUserID, newAddedNote]);

  const videoUrlHandler = (videoUrl, currentQ) => {
    setVideoUrl(videoUrl);
    setCurrentQuestion(currentQ);
  };
  const setInstalled = (id) => {
    var installed = true;
    instance.put(`jobs/installed/${id}`, {
      installed,
    });
  };
  const setDriveLink = async (id) => {
    if (drive) {
      await instance.put(`jobs/drive/${id}`, {
        drive,
      });
    }
  };
  const refreshCandidat = () => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        setCandidat(res.data);
      });
  };
  const downloadEmployeeData = () => {
    fetch(
      `${config.Data_URL}videos/${candidat.nom}-${candidat.prenom}-FullInterview.mp4`
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${candidat.nom}-${candidat.prenom}-FullInterview.mp4`;
        a.click();
      });
      //window.location.href = response.url;
      setInstalled(candidat._id);
    });
  };

  return (
    <>
      <div
        className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
        style={{ marginTop: "" }}
      >
        <div className="flex items-center  ">
          <CardImg
            variant=""
            className="rounded-circle"
            style={{
              width: "80px",
              height: "80px",
              alignSelf: "center",
              objectFit: "cover",
            }}
            alt="Responsive image"
            src={"assets/img/brand/500.png"}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="pt-2 font-bold    ">
            <span
              className="pt-2 left-0 font-bold "
              style={{ display: "inline-flex" }}
            >
              Prénom:&nbsp;<h4> {candidat.prenom}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Nom:&nbsp; <h4>{candidat.nom} </h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Email:&nbsp; <h4>{candidat.email}</h4>
            </span>
          </div>
        </div>


        <Row>
          <label className="px-lg-3">Pipeline</label>
                   <Select className="w-full"
                          options={options_jobApplicationPipeline}   
                          // value={applicationPipeline} 
                          value = {
                            options_jobApplicationPipeline.filter(option => 
                              option.label === applicationPipeline)
                         }
                                          
                          onChange={(e) => {
                            setApplicationPipeline(e.value);
                            }} />
          <Button
                style={{
               
                  width: "100%",
                }}
                  onClick={()=>{
                    
                    updatePipeline(applicationPipeline);
                  }}
                  className="mt-4"
                  color="primary"
                  type="button"
            >
                  Sauvegarder le pipeline
          </Button>
        </Row>


        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Entretien
                      </td>

                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          Formulaire
                        </td>

                        <>
                          <td
                            className={
                              (openTab === 3)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);}}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            CV
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 4)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(4);}}
                            data-toggle="tab"
                            href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Compétences
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 5)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(5);}}
                            data-toggle="tab"
                            href="#link5"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Analyse
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 6)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(6);}}
                            data-toggle="tab"
                            href="#link6"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Remarques
                          </td>
                        </>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              {openTab === 1 &&
                <CardBody
                  className={openTab === 1 ? "block" : "hidden"}
                  id="link1"
                >
                <hr className="my-4" />
                {!videos && <h3>Aucune vidéo trouvée pour ce candidat!</h3>}
                <div className="flex  space-x-2 py-3 justify-between">
                  <ul className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">
                    {/* <h3 className="flex items-center justify-center py-3 bg-blue-600 text-white rounded-t-md">
                    
                  <button onClick={downloadEmployeeData}>Download Full interview</button>
                        </h3> */}
                    <h3 className="flex px-3 py-3 bg-white text-black font-bold ">
                      Questions d'Interview
                      <span style={{ marginLeft: "auto" }}>
                        {candidat.merged && (
                          <ArrowCircleDownIcon
                            className="h-6 pr-2 cursor-pointer "
                            onClick={downloadEmployeeData}
                          />
                        )}
                        {!candidat.merged && (
                          <div className=" flex justify-center items-center top-0 left-0 z-50  w-full h-full opacity-100">
                            <ClipLoader color="#000" />
                          </div>
                        )}
                      </span>
                    </h3>
                    {videos &&
                      videos.map((video) => (
                        <li
                          className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                          key={video._id}
                          onClick={videoUrlHandler.bind(
                            this,
                            video.video,
                            video.question.question
                          )}
                        >
                          {video?.question?.question}
                        </li>
                      ))}
                  </ul>
                  <div className="w-1/2 py-3 list-decimal list-inside bg-white shadow-xl h-1/2 rounded-md">
                  {/* console.log("33333333333333333333: ShowCandidat:") */}
                  {/* console.log("33333333333333333333: ShowCandidat: videoUrl: ", videoUrl) */}

                  {videoUrl && !videoUrl.includes("mp3") && (
                      <>
                        <div className=" question  text-sm py-2 px-2 text-left last:border-b-0 text-black">
                          {currentQuestion}
                        </div>
                        <video
                          src={`${config.Video_URL}${videoUrl}`} type="video/webm"
                          controls
                          className="px-4 py-4"
                        />
                        {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}
                      </>
                    )}

                  {videoUrl && videoUrl.includes("mp3") && (
                      <>
                        <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                          {currentQuestion}
                        </div>
                        <audio
                          src={`${config.Video_URL}${videoUrl}`} 
                          type="audio/mp3"
                          controls
                          // className="px-4 py-4"
                        />
                        {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}
                      </>
                    )}
                  </div>
                </div>
                </CardBody>
              }
              {openTab === 2 &&
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <Form>
                  <h4 className=" mb-4">Formulaire</h4>
                  
                  <p className=" mb-4">Prénom: {jobApplicationForm.firstname}</p>
                  <p className=" mb-4">Nom: {jobApplicationForm.lastname}</p>
                  <p className=" mb-4">Genre: {jobApplicationForm.gender}</p>
                  <p className=" mb-4">Situation familiale: {jobApplicationForm.familySituation}</p>
                  <p className=" mb-4">Age: {jobApplicationForm.age}</p>
                  <p className=" mb-4">Email: {jobApplicationForm.email}</p>
                  <p className=" mb-4">Whatsapp: {jobApplicationForm.whatsappnumber}</p>
                  <p className=" mb-4">Linkedin: {jobApplicationForm.linkedin}</p>
                  <p className=" mb-4">Diplome: {jobApplicationForm.diploma}</p>
                  <p className=" mb-4">Domaine d'expertise: {jobApplicationForm.expertiseField}</p>
                  <p className=" mb-4">Profil technique: {jobApplicationForm.technicalProfileOrNot}</p>
                  <p className=" mb-4">Type de profil: {jobApplicationForm.profileType}</p>
                  <p className=" mb-4">Projet désiré: {jobApplicationForm.desiredProject}</p>
                  <p className=" mb-4">Expérience: {jobApplicationForm.experience}</p>
                  <p className=" mb-4">Top 3 des technologies: {jobApplicationForm.topTechnologies}</p>
                  <p className=" mb-4">Certifications: {jobApplicationForm.certifications}</p>
                  <p className=" mb-4">Niveau de certification: {jobApplicationForm.certificationLevel}</p>
                  <p className=" mb-4">Contrat actuel: {jobApplicationForm.actualContract}</p>
                  <p className=" mb-4">Mobilité: {jobApplicationForm.mobility}</p>
                  <p className=" mb-4">Est ce que vous ne voulez pas travaillé dans le secteur Banques, Assurance et Finances: {jobApplicationForm.baf}</p>
                  <p className=" mb-4">Titre: {jobApplicationForm.title}</p>
                  <p className=" mb-4">Ville: : {jobApplicationForm.village}</p>
                  <p className=" mb-4">Pays: {jobApplicationForm.country}</p>
                  <p className=" mb-4">Niveau d'étude: {jobApplicationForm.studyLevel}</p>
                  <p className=" mb-4">Université: {jobApplicationForm.university}</p>
                  <p className=" mb-4">Téléphone: {jobApplicationForm.phonenumber}</p>
                  <p className=" mb-4">Date: {jobApplicationForm.date}</p>
                </Form>
              </CardBody>
              }
              {openTab === 3 &&
              <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link3"
              >
                {config.Environment === "Dev" &&
                    <Row>
                  
                    {/*
                    <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">
                    <Document file={`assets/pdf/1648553251-CV-Mamadou-Fode-Bailo-DIALLO.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  </div>
                    */}

                  <img
                    src={"assets/img/brand/500.png"}
                    onError={(e) => {
                      e.target.src =
                        require("assets/img/brand/500.png").default;
                    }}
                  />
                  
                  </Row>
                }
                {config.Environment !== "Dev" && jobApplicationCV && jobApplicationFormCV &&
                  <Row>
                    <nav>
                    <button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</button>
                    <button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</button>
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                  </nav>

                    <Document file={`${config.Data_URL}${jobApplicationFormCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    {/*
                      <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                      </div>

                    */}


                  
                  </Row>
                }
                 {config.Environment !== "Dev" && jobApplicationCV && !jobApplicationFormCV &&
                  <Row>
                    <nav>
                      <button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</button>
                      <button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</button>
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    </nav>
                    {/*

                    <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                  </div>

                  */}
                    <Document file={`${config.CVs_URL}${jobApplicationCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={pageNumber} />
                    </Document>


                    
                  </Row>
                }

              </CardBody>
              }
             {openTab === 4 &&
              <CardBody
                className={openTab === 4? "block" : "hidden"}
                id="link4"
              >
                { /* console.log("Compétences") */}
                <Form>
                  <h4 className=" mb-4">Compétences </h4>
                  {(jobApplicationCompetencies.profileType) &&
                    <p className=" mb-4">profileType: {jobApplicationCompetencies.profileType}</p>
                  }
                  {(jobApplicationCompetencies.ccnaNetSec) &&
                    <p className=" mb-4">CCNA: {jobApplicationCompetencies.ccnaNetSec}</p>
                  }
                  {(jobApplicationCompetencies.ccnp_NetSec) &&
                    <p className=" mb-4">CCNP: {jobApplicationCompetencies.ccnp_NetSec}</p>
                  }
                  {(jobApplicationCompetencies.ciscoNetSec) &&
                    <p className=" mb-4">cisco: {jobApplicationCompetencies.ciscoNetSec}</p>
                  }
                  {(jobApplicationCompetencies.nexusNetSec) &&
                    <p className=" mb-4">Nexus: {jobApplicationCompetencies.nexusNetSec}</p>
                  }
                  {(jobApplicationCompetencies.MPLSNetSec) &&
                    <p className=" mb-4">MPLS: {jobApplicationCompetencies.MPLSNetSec}</p>
                  }
                  {(jobApplicationCompetencies.FortinetNetSec) &&
                    <p className=" mb-4">Fortinet: {jobApplicationCompetencies.FortinetNetSec}</p>
                  }
                  {(jobApplicationCompetencies.PaloAltoNetSec) &&
                    <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAltoNetSec}</p>
                  }
                  {(jobApplicationCompetencies.F5NetSec) &&
                    <p className=" mb-4">F5: {jobApplicationCompetencies.F5NetSec}</p>
                  } 
                  {(jobApplicationCompetencies.ACENetSec) &&
                    <p className=" mb-4">ACE: {jobApplicationCompetencies.ACENetSec}</p>
                  }     
                  {(jobApplicationCompetencies.CiscoASANetSec) &&
                    <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASANetSec}</p>
                  }
                  {(jobApplicationCompetencies.Windows_Server_SW) &&
                    <p className=" mb-4">Windows_Server: {jobApplicationCompetencies.Windows_Server_SW}</p>
                  }
                  {(jobApplicationCompetencies.Active_Directory_SW) &&
                    <p className=" mb-4">Active Directory: {jobApplicationCompetencies.Active_Directory_SW}</p>
                  }
                  {(jobApplicationCompetencies.Office_365_SW) &&
                    <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_SW}</p>
                  }
                  {(jobApplicationCompetencies.EXCHANGE_SW) &&
                    <p className=" mb-4">EXCHANGE: {jobApplicationCompetencies.EXCHANGE_SW}</p>
                  }
                  {(jobApplicationCompetencies.SHAREPOINT_SW) &&
                    <p className=" mb-4">SHAREPOINT: {jobApplicationCompetencies.SHAREPOINT_SW}</p>
                  }
                  {(jobApplicationCompetencies.SCCM_SW) &&
                    <p className=" mb-4">SCCM: {jobApplicationCompetencies.SCCM_SW}</p>
                  }
                  {(jobApplicationCompetencies.SCOM_SW) &&
                    <p className=" mb-4">SCOM: {jobApplicationCompetencies.SCOM_SW}</p>
                  } 
                  {(jobApplicationCompetencies.IIS_SW) &&
                    <p className=" mb-4">IIS: {jobApplicationCompetencies.IIS_SW}</p>
                  }     
                  {(jobApplicationCompetencies.Powershell_SW) &&
                    <p className=" mb-4">Powershell: {jobApplicationCompetencies.Powershell_SW}</p>
                  }
                  {(jobApplicationCompetencies.HyperV_SW) &&
                    <p className=" mb-4">HyperV: {jobApplicationCompetencies.HyperV_SW}</p>
                  }
                  {(jobApplicationCompetencies.Azure_SW) &&
                    <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SW}</p>
                  }
                  {(jobApplicationCompetencies.AWS_SW) &&
                    <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SW}</p>
                  }
                  {(jobApplicationCompetencies.Devops_SW) &&
                    <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SW}</p>
                  }
                  {(jobApplicationCompetencies.Docker_SW) &&
                    <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SW}</p>
                  }
                  {(jobApplicationCompetencies.Kubernetes_SW) &&
                    <p className=" mb-4">Kubernetes: {jobApplicationCompetencies.Kubernetes_SW}</p>
                  }
                  {(jobApplicationCompetencies.LINUX_SL) &&
                    <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_SL}</p>
                  } 
                  {(jobApplicationCompetencies.Redhat_SL) &&
                    <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_SL}</p>
                  }     
                  {(jobApplicationCompetencies.Ubuntu_SL) &&
                    <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_SL}</p>
                  }
                  {(jobApplicationCompetencies.Shell_SL) &&
                    <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_SL}</p>
                  }
                  {(jobApplicationCompetencies.Apache_SL) &&
                    <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_SL}</p>
                  }
                  {(jobApplicationCompetencies.Docker_SL) &&
                    <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SL}</p>
                  }
                  {(jobApplicationCompetencies.Kubernetes_SL) &&
                    <p className=" mb-4">Kubernetes: {jobApplicationCompetencies.Kubernetes_SL}</p>
                  }
                  {(jobApplicationCompetencies.Ansible_SL) &&
                    <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_SL}</p>
                  }
                  {(jobApplicationCompetencies.Terraform_SL) &&
                    <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_SL}</p>
                  }
                  {(jobApplicationCompetencies.Puppet_SL) &&
                    <p className=" mb-4">Puppet: {jobApplicationCompetencies.Puppet_SL}</p>
                  } 
                  {(jobApplicationCompetencies.Jenkins_SL) &&
                    <p className=" mb-4">Jenkins: {jobApplicationCompetencies.Jenkins_SL}</p>
                  }     
                  {(jobApplicationCompetencies.GIT_SL) &&
                    <p className=" mb-4">GIT: {jobApplicationCompetencies.GIT_SL}</p>
                  }
                  {(jobApplicationCompetencies.Openstack_SL) &&
                    <p className=" mb-4">Openstack: {jobApplicationCompetencies.Openstack_SL}</p>
                  }
                  {(jobApplicationCompetencies.Azure_SL) &&
                    <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SL}</p>
                  }
                  {(jobApplicationCompetencies.AWS_SL) &&
                    <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SL}</p>
                  }
                  {(jobApplicationCompetencies.Devops_SL) &&
                    <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SL}</p>
                  }
                  {(jobApplicationCompetencies.profile_Change_SR) &&
                    <p className=" mb-4">Ouvert pour un changement de profil: {jobApplicationCompetencies.profile_Change_SR}</p>
                  }
                  {(jobApplicationCompetencies.Windows_Server_SR) &&
                    <p className=" mb-4">Windows_Server: {jobApplicationCompetencies.Windows_Server_SR}</p>
                  }
                  {(jobApplicationCompetencies.Directory_SR) &&
                    <p className=" mb-4">Directory: {jobApplicationCompetencies.Directory_SR}</p>
                  } 
                  {(jobApplicationCompetencies.Office_365_SR) &&
                    <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_SR}</p>
                  }     
                  {(jobApplicationCompetencies.EXCHANGE_SR) &&
                    <p className=" mb-4">EXCHANGE: {jobApplicationCompetencies.EXCHANGE_SR}</p>
                  }
                  {(jobApplicationCompetencies.SHAREPOINT_SR) &&
                    <p className=" mb-4">SHAREPOINT: {jobApplicationCompetencies.SHAREPOINT_SR}</p>
                  }
                  {(jobApplicationCompetencies.SCCM_SR) &&
                    <p className=" mb-4">SCCM: {jobApplicationCompetencies.SCCM_SR}</p>
                  }
                  {(jobApplicationCompetencies.SCOM_SR) &&
                    <p className=" mb-4">SCOM: {jobApplicationCompetencies.SCOM_SR}</p>
                  }
                  {(jobApplicationCompetencies.IIS_SR) &&
                    <p className=" mb-4">IIS: {jobApplicationCompetencies.IIS_SR}</p>
                  }
                  {(jobApplicationCompetencies.Powershell_SR) &&
                    <p className=" mb-4">Powershell: {jobApplicationCompetencies.Powershell_SR}</p>
                  }
                  {(jobApplicationCompetencies.HyperV_SR) &&
                    <p className=" mb-4">HyperV: {jobApplicationCompetencies.HyperV_SR}</p>
                  }
                  {(jobApplicationCompetencies.Azure_SR) &&
                    <p className=" mb-4">Azure: {jobApplicationCompetencies.Azure_SR}</p>
                  } 
                  {(jobApplicationCompetencies.AWS_SR) &&
                    <p className=" mb-4">AWS: {jobApplicationCompetencies.AWS_SR}</p>
                  }     
                  {(jobApplicationCompetencies.Devops_SR) &&
                    <p className=" mb-4">Devops: {jobApplicationCompetencies.Devops_SR}</p>
                  }
                  {(jobApplicationCompetencies.LINUX_SR) &&
                    <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_SR}</p>
                  }
                  {(jobApplicationCompetencies.Shell_SR) &&
                    <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_SR}</p>
                  }
                  {(jobApplicationCompetencies.Redhat_SR) &&
                    <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_SR}</p>
                  }
                  {(jobApplicationCompetencies.Ubuntu_SR) &&
                    <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_SR}</p>
                  }
                  {(jobApplicationCompetencies.Apache_SR) &&
                    <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_SR}</p>
                  }
                  {(jobApplicationCompetencies.Docker_SR) &&
                    <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_SR}</p>
                  }
                  {(jobApplicationCompetencies.Ansible_SR) &&
                    <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_SR}</p>
                  } 
                  {(jobApplicationCompetencies.Terraform_SR) &&
                    <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_SR}</p>
                  }     
                  {(jobApplicationCompetencies.Puppet_SR) &&
                    <p className=" mb-4">Puppet: {jobApplicationCompetencies.Puppet_SR}</p>
                  }
                  {(jobApplicationCompetencies.Jenkins_SR) &&
                    <p className=" mb-4">Jenkins: {jobApplicationCompetencies.Jenkins_SR}</p>
                  }
                  {(jobApplicationCompetencies.GIT_SR) &&
                    <p className=" mb-4">GIT: {jobApplicationCompetencies.GIT_SR}</p>
                  }
                  {(jobApplicationCompetencies.Openstack_SR) &&
                    <p className=" mb-4">Openstack: {jobApplicationCompetencies.Openstack_SR}</p>
                  }
                  {(jobApplicationCompetencies.CCNP_SR) &&
                    <p className=" mb-4">CCNP: {jobApplicationCompetencies.CCNP_SR}</p>
                  }
                  {(jobApplicationCompetencies.CCNA_SR) &&
                    <p className=" mb-4">CCNA: {jobApplicationCompetencies.CCNA_SR}</p>
                  }
                  {(jobApplicationCompetencies.Cisco_SR) &&
                    <p className=" mb-4">Cisco: {jobApplicationCompetencies.Cisco_SR}</p>
                  }
                  {(jobApplicationCompetencies.Nexus_SR) &&
                    <p className=" mb-4">Nexus: {jobApplicationCompetencies.Nexus_SR}</p>
                  } 
                  {(jobApplicationCompetencies.Fortinet_SR) &&
                    <p className=" mb-4">Fortinet: {jobApplicationCompetencies.Fortinet_SR}</p>
                  }     
                  {(jobApplicationCompetencies.PaloAlto_SR) &&
                    <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAlto_SR}</p>
                  }
                  {(jobApplicationCompetencies.F5_SR) &&
                    <p className=" mb-4">F5: {jobApplicationCompetencies.F5_SR}</p>
                  }
                  {(jobApplicationCompetencies.ACE_SR) &&
                    <p className=" mb-4">ACE: {jobApplicationCompetencies.ACE_SR}</p>
                  }
                  {(jobApplicationCompetencies.CiscoASA_SR) &&
                    <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASA_SR}</p>
                  }
                  {(jobApplicationCompetencies.profile_change_Virt) &&
                    <p className=" mb-4">profile change: {jobApplicationCompetencies.profile_change_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Windows_Server_Virt) &&
                    <p className=" mb-4">Windows Server: {jobApplicationCompetencies.Windows_Server_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Office_365_Virt) &&
                    <p className=" mb-4">Office 365: {jobApplicationCompetencies.Office_365_Virt}</p>
                  }
                  {(jobApplicationCompetencies.LINUX_Virt) &&
                    <p className=" mb-4">LINUX: {jobApplicationCompetencies.LINUX_Virt}</p>
                  } 
                  {(jobApplicationCompetencies.Redhat_Virt) &&
                    <p className=" mb-4">Redhat: {jobApplicationCompetencies.Redhat_Virt}</p>
                  }     
                  {(jobApplicationCompetencies.Ubuntu_Virt) &&
                    <p className=" mb-4">Ubuntu: {jobApplicationCompetencies.Ubuntu_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Shell_Virt) &&
                    <p className=" mb-4">Shell: {jobApplicationCompetencies.Shell_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Apache_Virt) &&
                    <p className=" mb-4">Apache: {jobApplicationCompetencies.Apache_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Docker_Virt) &&
                    <p className=" mb-4">Docker: {jobApplicationCompetencies.Docker_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Ansible_Virt) &&
                    <p className=" mb-4">Ansible: {jobApplicationCompetencies.Ansible_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Terraform_Virt) &&
                    <p className=" mb-4">Terraform: {jobApplicationCompetencies.Terraform_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Cisco_Virt) &&
                    <p className=" mb-4">Cisco: {jobApplicationCompetencies.Cisco_Virt}</p>
                  }
                  {(jobApplicationCompetencies.Nexus_Virt) &&
                    <p className=" mb-4">Nexus: {jobApplicationCompetencies.Nexus_Virt}</p>
                  } 
                  {(jobApplicationCompetencies.Fortinet_Virt) &&
                    <p className=" mb-4">Fortinet: {jobApplicationCompetencies.Fortinet_Virt}</p>
                  }     
                  {(jobApplicationCompetencies.PaloAlto_Virt) &&
                    <p className=" mb-4">PaloAlto: {jobApplicationCompetencies.PaloAlto_Virt}</p>
                  }
                  {(jobApplicationCompetencies.F5_Virt) &&
                    <p className=" mb-4">F5: {jobApplicationCompetencies.F5_Virt}</p>
                  }
                  {(jobApplicationCompetencies.ACE_Virt) &&
                    <p className=" mb-4">ACE: {jobApplicationCompetencies.ACE_Virt}</p>
                  }
                  {(jobApplicationCompetencies.CiscoASA_Virt) &&
                    <p className=" mb-4">Cisco ASA: {jobApplicationCompetencies.CiscoASA_Virt}</p>
                  }
                  {(jobApplicationCompetencies.MySQL_BDD) &&
                    <p className=" mb-4">MySQL: {jobApplicationCompetencies.MySQL_BDD}</p>
                  }
                  {(jobApplicationCompetencies.SQL_Server_BDD) &&
                    <p className=" mb-4">SQL_Server: {jobApplicationCompetencies.SQL_Server_BDD}</p>
                  }
                  {(jobApplicationCompetencies.ORACLE_BDD) &&
                    <p className=" mb-4">ORACLE: {jobApplicationCompetencies.ORACLE_BDD}</p>
                  }
                  {(jobApplicationCompetencies.POSTGRES_BDD) &&
                    <p className=" mb-4">POSTGRES: {jobApplicationCompetencies.POSTGRES_BDD}</p>
                  }
                  {(jobApplicationCompetencies.Oracle_RAC_BDD) &&
                    <p className=" mb-4">Oracle RAC: {jobApplicationCompetencies.Oracle_RAC_BDD}</p>
                  }
                  {(jobApplicationCompetencies.INGRES_BDD) &&
                    <p className=" mb-4">INGRES: {jobApplicationCompetencies.INGRES_BDD}</p>
                  }
                  {(jobApplicationCompetencies.Hadoop_BigDataBI) &&
                    <p className=" mb-4">Hadoop: {jobApplicationCompetencies.Hadoop_BigDataBI}</p>
                  } 
                  {(jobApplicationCompetencies.Cloudera_BigDataBI) &&
                    <p className=" mb-4">Cloudera: {jobApplicationCompetencies.Cloudera_BigDataBI}</p>
                  }     
                  {(jobApplicationCompetencies.Apache_Hive_BigDataBI) &&
                    <p className=" mb-4">Apache Hive_: {jobApplicationCompetencies.Apache_Hive_BigDataBI}</p>
                  }
                  {(jobApplicationCompetencies.Apache_NiFi_BigDataBI) &&
                    <p className=" mb-4">Apache NiFi: {jobApplicationCompetencies.Apache_NiFi_BigDataBI}</p>
                  }
                  {(jobApplicationCompetencies.Spark_BigDataBI) &&
                    <p className=" mb-4">Spark: {jobApplicationCompetencies.Spark_BigDataBI}</p>
                  }
                  {(jobApplicationCompetencies.Talend_BigDataBI) &&
                    <p className=" mb-4">Talend: {jobApplicationCompetencies.Talend_BigDataBI}</p>
                  }
                  {(jobApplicationCompetencies.Power_BI_BigDataBI) &&
                    <p className=" mb-4">Power BI: {jobApplicationCompetencies.Power_BI_BigDataBI}</p>
                  }
                  {(jobApplicationCompetencies.Python_Dev) &&
                    <p className=" mb-4">Python: {jobApplicationCompetencies.Python_Dev}</p>
                  }
                  {(jobApplicationCompetencies.PHP_Dev) &&
                    <p className=" mb-4">PHP: {jobApplicationCompetencies.PHP_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Java_Dev) &&
                    <p className=" mb-4">Java: {jobApplicationCompetencies.Java_Dev}</p>
                  } 
                  {(jobApplicationCompetencies.Spring_Dev) &&
                    <p className=" mb-4">Spring: {jobApplicationCompetencies.Spring_Dev}</p>
                  }     
                  {(jobApplicationCompetencies.Angular_Dev) &&
                    <p className=" mb-4">Angular: {jobApplicationCompetencies.Angular_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Angular_JS_Dev) &&
                    <p className=" mb-4">Angular JS: {jobApplicationCompetencies.Angular_JS_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Laravel_Dev) &&
                    <p className=" mb-4">Laravel: {jobApplicationCompetencies.Laravel_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Symfony_Dev) &&
                    <p className=" mb-4">Symfony: {jobApplicationCompetencies.Symfony_Dev}</p>
                  }
                  {(jobApplicationCompetencies.JavaScript_Dev) &&
                    <p className=" mb-4">JavaScript: {jobApplicationCompetencies.JavaScript_Dev}</p>
                  }
                  {(jobApplicationCompetencies.React_JS_Dev) &&
                    <p className=" mb-4">React JS: {jobApplicationCompetencies.React_JS_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Vue_JS_Dev) &&
                    <p className=" mb-4">Vue JS: {jobApplicationCompetencies.Vue_JS_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Node_JS_Dev) &&
                    <p className=" mb-4">Node JS: {jobApplicationCompetencies.Node_JS_Dev}</p>
                  } 
                  {(jobApplicationCompetencies.C_sharp_Dev) &&
                    <p className=" mb-4">C sharp: {jobApplicationCompetencies.C_sharp_Dev}</p>
                  }     
                  {(jobApplicationCompetencies.DotNET_Dev) &&
                    <p className=" mb-4">DotNET: {jobApplicationCompetencies.DotNET_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Langage_R_Dev) &&
                    <p className=" mb-4">Langage R: {jobApplicationCompetencies.Langage_R_Dev}</p>
                  }
                  {(jobApplicationCompetencies.Ruby_Dev) &&
                    <p className=" mb-4">Ruby: {jobApplicationCompetencies.Ruby_Dev}</p>
                  }
                  {(jobApplicationCompetencies.ITIL_Autres) &&
                    <p className=" mb-4">ITIL: {jobApplicationCompetencies.ITIL_Autres}</p>
                  }
                  {(jobApplicationCompetencies.Management_Autres) &&
                    <p className=" mb-4">Management: {jobApplicationCompetencies.Management_Autres}</p>
                  }
                  {(jobApplicationCompetencies.SCRUM_Autres) &&
                    <p className=" mb-4">SCRUM: {jobApplicationCompetencies.SCRUM_Autres}</p>
                  }
                  {(jobApplicationCompetencies.Chefferie_de_projet_Autres) &&
                    <p className=" mb-4">Chefferie de projet: {jobApplicationCompetencies.Chefferie_de_projet_Autres}</p>
                  }


                </Form>

              </CardBody>
              }
              {openTab === 5 &&
              <CardBody
                className={openTab === 5? "block" : "hidden"}
                id="link5"
              >
                { console.log("Analyse") }
                <Form>
                  <h4 className=" mb-4">Analyse Générale </h4>
                  {(candidateCompetenciesAnalysis.Validation_or_not_by_domain_Validation) &&
                    <p className=" mb-4">Validé ou pas: {candidateCompetenciesAnalysis.Validation_or_not_by_domain_Validation}</p>
                  }
                  {(candidateCompetenciesAnalysis.Validation_or_not_by_domain_Motif) &&
                    <p className=" mb-4">Motif de la non validation: {candidateCompetenciesAnalysis.Validation_or_not_by_domain_Motif}</p>
                  }
                </Form>

              </CardBody>
              }
              {openTab === 6 &&
              <CardBody
                className={openTab === 6? "block" : "hidden"}
                id="link6"
              >
                { console.log("Analyse") }
                <form>
                  <h4 className=" mb-4">Remarques </h4>
                  <Grid item xs={12}>
                    <label className="px-lg-3">Ajouter une remarque</label>
                    <TextField
                        name="addThisNote"
                        // label="Nom" 
                        // placeholder="Email" 
                        className="w-full " 
                        // onBlur={handleBlur} 
                        // value={email}
                        // onChange={handleChange} 
                        onChange={e => setAddThisNote(e.target.value)}
                        value={addThisNote || ""} 
                        // error={!!touched.titre && !!errors.titre} 
                        // helperText={touched.titre && errors.titre} 
                        />
                    </Grid>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    // type="submit" 
                    sx={{ mt: "25px" }}
                    onClick={() => { 
                      // console.log("bouton cliqué 0000000000000000000000 ")
        
                      createJobApplicationNote();
                      setNewAddedNote(newAddedNote + 1 );
                      setAddThisNote("");
                    }}
                    >
                      Enregistrer
                  </Button>





                </form>
                {jobApplicationNotes &&
                      jobApplicationNotes.map((jobApplicationNote) => (
                        <Grid item xs={12} key={jobApplicationNote._id}>
                        <label 
                          className="px-lg-3"
                          
                        >{jobApplicationNote.note}</label>
                      </Grid>

                      ))}

              </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ShowApplicationEmployerV4;
