import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerCand, registerEmployer } from "../../network/ApiAxios";
import config from "../../config";
import { PencilAltIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { options_Pays } from "options";
import LoginPostul from "components/loginPostul";
import axios from "axios";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});
const customStyles = {
    // option: (provided, state) => ({
    //   ...provided,
    //   borderBottom: '1px dotted pink',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    // }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
    }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';
  
    //   return { ...provided, opacity, transition };
    // }
  }
const RegisterEmployer = (props) => {
    //shared
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
    //Candidat
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webSite, setWebSite] = useState("");
  const [activity, setActivity] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const [newAccountCreated, setNewAccountCreated] = useState(false);
  const [newEmployerAccountCreated, setNewEmployerAccountCreated] = useState(false);


  const [role, setRole] = useState("employer");
  const [checkbox, setCheckbox] = useState(false);
  const [acceptNewsletterReception, setAcceptNewsletterReception] = useState(false);
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);
  const [type, setType] = useState("");

  const [formIsValid, setFormIsValid] = useState(true);
  const [formIsValidForEmployer, setFormIsValidForEmployer] = useState(true);
  const [openTab, setOpenTab] = React.useState(2);


  const handleEmployerValidation = () => {
    let isValid = true;

    if (!name) {
      isValid = false;
      setFormIsValidForEmployer(false);
      setError("impossible de procéder avec un prénom vide ");
      return isValid;
    }

    if (!lastName) {
      isValid = false;
      setFormIsValidForEmployer(false);
      setError("impossible de procéder avec un nom vide ");
      return isValid;
       
    };

  if (!email) {

    if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@");
      let lastDotPos = email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
         lastDotPos > 2 &&
         email.length - lastDotPos > 2
        )
      ) {
        isValid = false;
        setFormIsValidForEmployer(false);
        setError("L'Email  n'est pas valide");
        return isValid;
      }
    }

    console.log(error);
   
  }

  if (!company) {
    setFormIsValidForEmployer(false);
    isValid = false;
    setError("impossible de procéder avec Nom de l'entreprise vide ");
    return isValid;
  }
 
    if (!location) {
      isValid = false;
      setFormIsValidForEmployer(false);
      setError("impossible de procéder avec pays vide ");
      return isValid;
    }





  if (!password) {
    isValid = false;
    setError("impossible de procéder avec un mot de passe vide ");
    return isValid;
  }
  if (!confirmPassword) {
    isValid = false;
    setError("impossible de procéder sans confirmer le mot de passe ");
    return isValid;
  }

  if (password !== confirmPassword) {
    isValid = false;
    setError("Merci de de bien confirmer le mot de passe: Les mots de passe ne correspondent pas !");
    // setError("Les mots de passe ne correspondent pas");
    return isValid;
  }

  return isValid;

  }

  const registerEmployerFunction = async () => {
    console.log("33333333333333333333333: registerEmployer: ");
    // handleEmployerValidation();
    //if (formIsValidForEmployer && !newEmployerAccountCreated) {

    if (handleEmployerValidation()) {
      let Activity=activity.value
      const response = await registerEmployer(
        name,
        lastName,
        email,
        company,
        companyEmail,
        phone,
        webSite,
        Activity,
        description,
        location,
        password,
        role,
        acceptNewsletterReception
      );
      const { data } = response;
      if (!data.success) {
        setError(data.msg);
        return;
      }
      if (config.DEMO) {
        setToastMessage(
          "Votre compte a été créé avec succès !"
                  );
        setUserID(data.userID);
      }
      setNewEmployerAccountCreated(true);
      setError("");
      setName("");
      setEmail("");
      setCompanyEmail("");
      setCompany("");
      setPhone("");
      setLastName("");
      setWebSite("");
      setLocation("");
      setPassword("");
      setActivity("");
      setDescription("");   

      setConfirmPassword("");
      setCheckbox(false);
      setShowToast(true);
      setAcceptNewsletterReception(false);
    } else {
      console.log("errored");
    }
  };




  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "35%",
          right: 10,
          bottom: 80,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          autohide={!config.DEMO}
        >
          <Toast.Header>
            {/* <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/argon-react.png").default}
              alt="..."
            /> */}
            <h3>Welcome to HireLands</h3>
          </Toast.Header>
          <Toast.Body>
            {toastMessage} <br/>
            {(config.DEMO && (props.redirect !== "job")) ? (
              <a href={config.DOMAIN_NAME + "/home/login"}>
                se connecter ?
              </a>
            ) : null}
          </Toast.Body>
        </Toast>
      </div>
      <h3 className="text-center"> S'inscrire en tant que recruteur:</h3>
      <Form role="form">
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Prénom"
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Nom"
              type="text"
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Email"
              type="email"
              autoComplete="new-email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Nom de l’entreprise"
              type="text"
              value={company}
              required
              onChange={(e) => setCompany(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        {/*
          <FormGroup>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <PencilAltIcon />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email de l’entreprise"
                type="email"
                autoComplete="new-email"
                value={companyEmail}
                required
                onChange={(e) => setCompanyEmail(e.target.value)}
              />
            </InputGroup>
        </FormGroup>
        */}
      {/*
        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Numéro de téléphone de l'entreprise"
              type="number"
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
      */}
      {/*
          <FormGroup>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <PencilAltIcon />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Site WEB"
                type="text"
                value={webSite}
                required
                onChange={(e) => setWebSite(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
      */}
      { /*
                    <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
              
            </InputGroupAddon>
            {/* <Input
              placeholder="Secteur d’activité"
              type="text"
              value={activity}
              required
              onChange={(e) => setActivity(e.target.value)}
            /> 
          <label className="px-lg-3">Secteur d’activité</label>
              <Select className="w-full "
                    options={options}
                    
                    onChange={(e) => {
                      setActivity(e);
                      }} />
          </InputGroup>
        </FormGroup>
      */ }


        <FormGroup>
          <InputGroup className="input-group-alternative mb-3">
            <InputGroupAddon addonType="prepend">
            </InputGroupAddon>
            <label className="px-lg-3">Pays</label>
            <Select 
              className="w-full "
              options={options_Pays}
              
              onChange={(e) => {
                  setLocation(e.value);
                  }} 
            />
          </InputGroup>
        </FormGroup>
        {/*
          <FormGroup>
            <InputGroup className="input-group-alternative mb-3">
              <InputGroupAddon addonType="prepend"></InputGroupAddon>
              <label className="px-lg-3">Description de l’entreprise</label>
              <textarea
                style={{ width: "100%", height: "70px" }}
                placeholder=""
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </InputGroup>
          </FormGroup>
        */}

        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Mot de passe"
              type="password"
              autoComplete="new-password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <PencilAltIcon />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Confirmer mot de passe"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        {error ? (
          <div className="text-muted font-italic">
            <small>
              error:{" "}
              <span className="text-red font-weight-700">{error}</span>
            </small>
          </div>
        ) : null}
        <Row className="my-4">
          <Col xs="12">
              <input
                id="customCheckRegister"
                type="checkbox"
                checked={checkbox}
                onChange={() => setCheckbox(!checkbox)}
              />
              <label>
                  <span className="text-muted">
                      J'accepte et reconnais avoir pris connaissance des {" "}
                      <a 
                        href="/home/generalConditions" 
                        // onClick={(e) => e.preventDefault()} 
                        target="blank"
                      >
                      CGU
                      </a>
                  </span>
              </label>
          </Col>
        </Row>
        <Row className="my-4">
              <Col xs="12">
                  <input
                  // className="custom-control-input"
                  id="customCheckNewsletter"
                  type="checkbox"
                  checked={acceptNewsletterReception}
                  onChange={() => setAcceptNewsletterReception(!acceptNewsletterReception)}
                  />
                  <label
                  // className="custom-control-label"
                  // htmlFor="customCheckRegister"
                  >
                  <span className="text-muted">
                      Inscrivez-vous à notre newsletter pour recevoir nos dernières nouveautés
                  </span>
                  </label>
              </Col>
          </Row>
        <div className="text-center">
          <Button
          style={{
          
            width: "100%",
          }}
            onClick={()=>{
              
              registerEmployerFunction();}}
            className="mt-4"
            color="primary"
            type="button"
          >
            Créer mon compte
          </Button>
        </div>
      </Form>
    </>
  );
};

export default RegisterEmployer;
