import Header from "components/Headers/Header";
import Offres from "components/recruteur/jobs";
import CandidatOffres from "components/candidat/CandidatOffres";

import React from "react";

function TabOffresCandidat() {
  return (
    <>
      <Header />
      {/*<CandidatOffres Offres />*/}
      <CandidatOffres />
     
    </>
  );
}

export default TabOffresCandidat;
