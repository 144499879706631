import React, { useCallback, useEffect, useState } from 'react'
import OneQuestionJob from './OneQuestionJob'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UpdateQuestionsByJobId } from 'network/ApiAxios';


const QuestionsLists = ({ questions, jobId, callback }) => {
  const [questionList, setQuestionList] = useState(questions);

  useEffect(() => {
    setQuestionList(questions)
  }, [questions])

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const UpdateQuestionsWithCurrentOrder = debounce(UpdatedQuestions => {
    console.log('UpdatedQuestions', UpdatedQuestions)
    UpdateQuestionsByJobId(jobId, UpdatedQuestions)
  }, 1000);


  /* const moveQuestion = (fromIndex, toIndex) => {
      const updatedList = [...questionList];
      const [movedItem] = updatedList.splice(fromIndex, 1);
      updatedList.splice(toIndex, 0, movedItem);
      setQuestionList(updatedList);
      //console.log('updatedList',updatedList.map(el=> el._id))
      UpdateQuestionsWithCurrentOrder({newQuestionIDs : updatedList.map(el=> el._id)})
    }; */

  const moveQuestion = useCallback((dragIndex, hoverIndex) => {
    console.log('before qLits', questionList)
    const dragCard = questionList[dragIndex];
    /* setQuestionList(update(questionList, {
        $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
        ],
    })); */

    const updatedQuestionList = [
      ...questionList.slice(0, dragIndex),
      ...questionList.slice(dragIndex + 1),
    ];
    updatedQuestionList.splice(hoverIndex, 0, dragCard);
    setQuestionList(updatedQuestionList);

    UpdateQuestionsWithCurrentOrder({ newQuestionIDs: updatedQuestionList.map(el => el._id) })

    console.log('After qLits', questionList)


  }, [questionList]);

  return (
    <DndProvider backend={HTML5Backend}>
      {questionList &&
        questionList.map((question, index) => <OneQuestionJob
          key={question._id}
          index={index}
          question={question}
          jobId={jobId}
          callback={callback}
          moveQuestion={moveQuestion}
          questionList={questionList}
          setQuestionList={setQuestionList}
        />

        )}
    </DndProvider>
  )
}

export default QuestionsLists