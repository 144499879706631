import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
//import AdminNavbar from "components/Navbars/AdminNavbar.js";
import CandidatOutFrNavbar from "components/Navbars/CandidatOutFrNavbar";
import AdminFooter from "components/Footers/AdminFooter.js";
//import Sidebar from "components/Sidebar/Sidebar.js";
import SidebarCandidatHorsFr from "components/Sidebar/SidebarCandidatHorsFr";
import SidebarCandidatHorsFrV2 from "components/Sidebar/SidebarCandidatHorsFrV2";
import Header from "components/Headers/Header";



import routes from "routes.js";
import SidebarCandidateV5 from "components/Sidebar/SidebarCandidateV5";

const CandidatHorsFrLayoutV2 = () => {
  

    return (
      <>
        <SidebarCandidateV5/>
        {/* <CandidatOutFrNavbar/> */}
        <Header/>
      </>
    );
  
}

export default CandidatHorsFrLayoutV2;
