import React, { useCallback, useRef, useState } from "react";
import {
  FilmIcon,
  ChatAltIcon,
  ClockIcon,
  VideoCameraIcon,
  CheckIcon,
  BanIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Webcam from "react-webcam";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Button, Container, Row, Col
} from "reactstrap";
import Timer from "./timer";
import config from "config";
function Record({
  data,
  interviewId,
  deviceId,
  setQuestionCompleted,
  questionCompleted,
  callback,
  condd,
  setCondd,
  totalQues,
  index,
  question,
  setIndex,
  takesIND,
  setTakesIND,
  unlThinking,
  setUnlThinking,
  unlTakes,
  setUnlTakes,
  setTakesIndexer,
  takesIndexer,
}) {
  const location = useLocation();
  const query = qs.parse(location.search);
  const buttonNameRef = useRef();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState({});
  const [finishCapturing, setFinishCapturing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [thinking, setThinking] = useState(true);

  const [timeOut, setTimeOut] = useState(false);

  const [outOfTakes, setoutOfTakes] = useState(false);
  const [opacity, setOpacity]=useState("0.3")
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRetakeOpen, setModalRetakeOpen] = useState(false);
  // console.log("YYYYYYYYYYYYYYYYYYYYYYYY: Record: question id", query.questionId);
  // console.log("YYYYYYYYYYYYYYYYYYYYYYYY: Record: candidatID: localStorage.getItem candidatId", localStorage.getItem("candidatId"));
  useEffect(() => {
    setIndex(index);
    if (question.thinkTime == "Unlimited") {
      setUnlThinking(true);
    } else if (question.thinkTime != "Unlimited") {
      setUnlThinking(false);
    }
    // console.log("indexxx2222222", takesIND);
    // console.log("indexxxeeeeer", takesIndexer);
    // console.log("out of takes",outOfTakes)
   
    if (takesIND <=1 && takesIND>=0) {
      // console.log("indexxx", takesIND);
      // console.log("outOfTakes", outOfTakes);
      setoutOfTakes(true);
    }
    
    setTimeout(() => {
      setLoading(false);
    }, 12000);
  }, []);

  const handleStartCapture = useCallback(() => {
    setOpacity("1");
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(data);
      }
    },
    [setRecordedChunks]
  );

  const handleStopCapture = useCallback(() => {
    setCapturing(false);
    setFinishCapturing(true);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const lastSaveVideo = async () => {
    const blob = new Blob([recordedChunks], {
      type: "video/webm",
    });

    const video = new File([blob], `${localStorage.getItem("candidatId")}--${question._id}`, {
      lastModified: new Date(),
      type: blob.type,
    });
    const formData = new FormData();

    formData.append("video", video);
    formData.append("interview", interviewId);
    formData.append("question", query.questionId);
    formData.append("candidat", localStorage.getItem("candidatId"));
    fetch(
      `${config.WS_BASE_URL}jobs/interview/${interviewId}/${
        query.questionId
      }/${localStorage.getItem("candidatId")}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => 
        {
          // console.log(res);
          console.log("TTTTTTTTTTTTTTTT: Record: lastSaveVideo: fetch res", res);
        }
        )
      .catch((err) => 
      {
        // console.log(err);
        console.log("TTTTTTTTTTTTTTTT: Record: lastSaveVideo: fetch err", err);
      }  
      );




      setCondd(false);
      callback();

    

  };
  const retakeCallback = () => {
    setCondd(false);
    callback();
    setTakesIND(takesIND - 1);

    // console.log(takesIND);
  };
  //console.log("unlThininininini", unlThinking);
  return (
    <div className="relative d-flex flex-column align-items-center">
      
      <Row style={{width:"100%", backgroundColor: "#00000075" }}>
        <Col xs="4" style={{ display: "inline-flex"}}>
          <ClockIcon className="h-6 pr-2" color="white" />
            <h4 className="justify-left text-white" >
              Time limit: {question.timeLimit} minutes
            </h4>
        </Col>
        <Col xs="4"><h4 className="	text-white" style={{textAlign:"center"}}> Question {index + 1} of {totalQues}:{" "}</h4></Col>


        <Col xs="4" style={{ display: "inline-flex"}} >
        <FilmIcon className="h-6 pr-2" color="white" />
        <h4 className=" text-white"> 
              {unlTakes
                ? `Remaining Takes: Unlimited`
                : `Remaining Takes: ${takesIND-1}`}
            </h4>
        </Col>

      </Row>
      <Row style={{width:"100%" }}>
        <Col xs="3" >
          
        </Col>
        <Col xs="6"><h4 className="	flex items-center justify-center py-3  text-white rounded-t-md" 
        style={{textAlign:"center", background: "linear-gradient(87deg , #11cdef 0, #1171ef 100%)"}}> {question.question}</h4></Col>


        <Col xs="3">
        
        </Col>

      </Row>
     
      {loading && (
        <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
          <ClipLoader color="#f00" />
        </div>
      )}
      <div className="position-relative ">
        <Webcam style={{opacity:opacity}} videoConstraints={{ deviceId: deviceId }} ref={webcamRef} />
        {!capturing && !finishCapturing && (
          <>
            <div className="position-absolute top-0 right-0" />
            <button
              onClick={handleStartCapture}
              className="btn position-absolute d-flex top-0 right-0 text-white px-3 py-2"
              style={{ backgroundColor: "#00000075", height: "50px" }}
            >
              <VideoCameraIcon style={{ height: "1.5rem" }} />
              {!thinking && !unlThinking && handleStartCapture()}
              <p>Record</p>
            </button>
          </>
        )}
        {capturing && (
          <button
            ref={buttonNameRef}
            onClick={handleStopCapture}
            className="btn position-absolute d-flex top-0 right-0  text-red px-3 py-2"
            style={{ backgroundColor: "#00000075", height: "50px" }}
          >
            <BanIcon style={{ height: "1.5rem" }} />
            {timeOut && !finishCapturing && handleStopCapture()}
            <p>Stop</p>
          </button>
        )}
        {finishCapturing && (
          <>
            <button
              onClick={() => {
                setModalOpen(!modalOpen);
                setIndex(index + 1);
              }}
              className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
              style={{ backgroundColor: "#00000075", height: "50px" }}
            >
              <CheckIcon style={{ height: "1.5rem" }} />
              <p>Save</p>
            </button>
            {!outOfTakes && (
              <button
                onClick={() => {
                 setModalRetakeOpen(!modalRetakeOpen)
                }}
                className="btn position-absolute d-flex top-0 right-0 text-white px-3 py-2"
                style={{ backgroundColor: "#00000075", height: "50px" }}
              >
                <RefreshIcon style={{ height: "1.5rem" }} />
                <p>Retake</p>
              </button>
            )}
          </>
        )}
        {thinking && !capturing && !finishCapturing && !unlThinking && (
          <Card
            style={{
              width: "100%",
              top: "50%",
              //height: "50px",
              marginBottom: "-3%",
              backgroundColor: "#00000075",
              alignItems: "center",
            }}
            className=" position-absolute d-flex  px-3 py-2"
          >
            <CardBody
              style={{
                padding: "0.5rem",
                color: "white",
                display: "inline-flex",
              }}
            >
              <ChatAltIcon className="h-6 pr-2" />
              <Timer
                time={question.thinkTime * 60 + 4}
                value={thinking}
                setValue={setThinking}
              />
              &nbsp; avant de commencer l'enregistrement
            </CardBody>
          </Card>
        )}
        {capturing && !timeOut && (
          <Card
            style={{
              width: "50%",
              //top: "50%",
              left: "25%",
              height: "50px",
              marginBottom: "-3%",
              backgroundColor: "#00000075",
              alignItems: "center",
            }}
            className=" position-absolute d-flex top-0 px-3 py-2"
          >
            <CardBody
              style={{
                padding: "0.5rem",
                color: "white",
                textAlign: "center",
                display: "inline-flex",
                whiteSpace: "nowrap"
              }}
            >
              <ClockIcon className="h-6 pr-2" />
              Time limit:&nbsp;{" "}
              <Timer
                time={question.timeLimit * 60}
                value={timeOut}
                setValue={setTimeOut}
              />
            </CardBody>
          </Card>
        )}
      </div>
      <Modal
        toggle={() => setModalRetakeOpen(!modalRetakeOpen)}
        isOpen={modalRetakeOpen}
       
      >
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
          Êtes-vous sûr de vouloir reprendre la question ?
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalRetakeOpen(!modalRetakeOpen);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              setModalRetakeOpen(!modalRetakeOpen);
            }}
          >
            Annuler
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              retakeCallback();
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </Modal>          
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        onClosed={() => {
          setIndex(index - 1);
        }}
      >
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
          Soumettre et passer à la question suivante
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            Annuler
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              setModalOpen(!modalOpen);
              lastSaveVideo();
              setTakesIndexer(-1);

              
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Record;
