import PageTitleStyle from 'components/styles/PageTitleStyle'
import PageSpace from 'components/styles/pageSpace'
import { fetchActiveJobOffersInFranceWithPayOrTJMConditionNumber, fetchActiveJobOffersInFranceWithPayOrTJMCondition, getProfileProgressForFrCandidate } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import OneJobCardCandidate from './oneJobCardCandidate'
import { getProfileFrUserByID } from 'network/ApiAxios'
import ChecklistForApply from './MyProfile/CheckListForApply'

const InFrCandidateJobsWithPayOrTJMCondition = ({ isFreelancing }) => {
    const [jobs, setJobs] = useState([]);
    const fieldValue = isFreelancing ? 'Freelance' : 'NotFreelance';
    const [limit] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [pageNb, setPageNb] = useState(1);
    const [
        candidateProgressProfile,
        setCandidateProgressProfile,
    ] = useState(null);
    const [profileCV, setProfileCV] = useState(null)
    const [isAppliedToGeneralPresentation, setIsAppliedToGeneralPresentation] = useState(null)

    const user = JSON.parse(localStorage.getItem("user"));

    const CheckProgressForFrCandidate = async () => {
        try {
            const { data } = await getProfileProgressForFrCandidate(user?._id)
            data && setCandidateProgressProfile(((user?.CV || (data.profileExistence && profileCV)) && 50) + (data.IsAppliedToGeneralPresentation && 50))
            setIsAppliedToGeneralPresentation(data.IsAppliedToGeneralPresentation)
            //console.log(((user?.CV || data.profileExistence) && 50) + (data.IsAppliedToGeneralPresentation && 50))
            //console.log('progress', data)
        } catch (error) {
            console.log(error);
        }
    }

    const getCVProfileFrCandidate = async () => {
        try {
            const { data } = await getProfileFrUserByID(user?._id)
            data?.CV && setProfileCV(data?.CV)
        } catch (error) {
            console.log(error);
        }
    }

    const getActiveJobOffersInFranceWithPayOrTJMConditionNumber = async () => {
        try {
            const { data } = await fetchActiveJobOffersInFranceWithPayOrTJMConditionNumber(fieldValue)
            setPageCount(Math.ceil(data.cnt / limit))

        } catch (error) {
            console.log(error)
        }
    }

    const getActiveJobOffersInFranceWithPayOrTJMCondition = async () => {
        try {
            const { data } = await fetchActiveJobOffersInFranceWithPayOrTJMCondition(fieldValue, pageNb, limit)
            setJobs(data)

        } catch (error) {
            console.log(error)
        }
    }

    const handlePageClick = (e) => {
        setPageNb(e.selected + 1);
    };

    useEffect(() => {
        CheckProgressForFrCandidate()
        getCVProfileFrCandidate()
        getActiveJobOffersInFranceWithPayOrTJMConditionNumber()
    }, [])

    useEffect(()=>{
        getActiveJobOffersInFranceWithPayOrTJMCondition()
    },[pageNb])
    return (
        <Container className="container-fluid ">
            <Row>
                <Col lg="2">
                </Col>
                <Col lg="10">
                    {(candidateProgressProfile!==100 && candidateProgressProfile!== null) && <Card className='mt-1'>
                        <CardHeader>
                            <ChecklistForApply
                                profileCV={profileCV}
                                userCV={user?.CV}
                                IsAppliedToGeneralPresentation={isAppliedToGeneralPresentation}
                                isOpen
                            />
                        </CardHeader>
                    </Card>}
                </Col>

            </Row>
            <PageSpace />
            <PageTitleStyle title={!isFreelancing ? 'Offres dont la rémunération est plus de 50K€' : 'les missions dont le TJM est de plus de 500€'} />
            <PageSpace />
            <Row>
                <Col lg="2">
                </Col>
                <Col lg="10">
                    <Card className="bg-transparent w-full">

                        <CardBody>
                            {jobs?.map(job => (
                                <OneJobCardCandidate
                                    key={job._id}
                                    id={job._id}
                                    title={job.title}
                                    description={job.description}
                                    technologies={job.technologies}
                                    contract={job.contract}
                                    entreprise={job.entreprise}
                                    location={job.location}
                                    createdAt={job.createdAt}
                                    candidateProgressProfile={candidateProgressProfile}
                                />))}
                        </CardBody>
                        <CardFooter className="bg-transparent w-full d-flex justify-content-center">
                            <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default InFrCandidateJobsWithPayOrTJMCondition