import {
  ChatAlt2Icon,
  LocationMarkerIcon,
  XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useEffect, useState, Component } from "react";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  FilmIcon,
  ChatAltIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Select from "react-select";
import config from "config";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function Questions({ jobId }) {
  const [question, setQuestion] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [questions, setQuestions] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [timeLimit, setTimeLimit] = useState({ value: "1", label: "1" });
  const [takes, setTakes] = useState({ value: "1", label: "1" });
  const [thinkTime, setThinkTime] = useState({ value: "1", label: "1" });
  const [isThink, setIsThink] = useState(false);
  const [isTime, setIsTime] = useState(false);
  const [isTakes, setIsTakes] = useState(false);
  const [isNewQuestion, setIsNewQuestion] = useState(false);

  // console.log("aaaaaaa",ThisQuestion)
  const optionsTimes = [
    { value: "0.5", label: "0.5" },
    { value: "1", label: "1" },
    { value: "1.5", label: "1.5" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3", label: "3" },
  ];
  const optionsTakes = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "Unlimited", label: "Unlimited" },
  ];
  const optionsThink = [
    { value: "0.5", label: "0.5" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "Unlimited", label: "Unlimited" },
  ];
  const fetchData = () => {
    instance
      .get(`jobs/${jobId}`)
      .then((res) => setQuestions(res.data.job.questions))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addQuestion = (e) => {
    if (question) {
      instance
        .post(`jobs/${jobId}/question`, {
          question,
        })
        .then((res) => {
          res.statusText.toLowerCase() === "ok" && fetchData();
          setQuestion("");
        })
        .catch((err) => console.log(err));
    }
    e.preventDefault();
    setQuestion("");
  };
  const updateQuestion = (qs) => {
    var time = timeLimit.value;
    var take = takes.value;
    var thinkT = thinkTime.value;
    var question = newQuestion;
    instance.put(`jobs/question/${qs._id}/${qs.job}`, {
      time,
      take,
      thinkT,
      question,
    });
    window.location.reload();
  };
  const updateQuestions = (id) => {
    var time = timeLimit.value;
    var take = takes.value;
    var thinkT = thinkTime.value;
    instance.post(`jobs/questions/${id}`, {
      time,
      take,
      thinkT,
    });
    window.location.reload();
  };

  const removeQuestion = (qs) => {
    instance
      .delete(`jobs/${qs._id}/${qs.job}/question`, {
        title: qs.question,
      })
      .then((res) => res.statusText.toLowerCase() === "ok" && fetchData())
      .catch((err) => console.log(err));
  };

  return (
    <div className="w-full">
      <h3 className="font-semibold text-lg text-black">Questions</h3>
      
        <div className="flex items-center space-x-2">
          <input
            type="text"
            className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm"
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Type your question here"
            value={question}
          />
          <div
            className="px-4 py-[1.5px] bg-gray-300 cursor-pointer"
            onClick={addQuestion}
          >
            <PlusCircleIcon className="h-6" />
          </div>
          <div className="px-4 py-[1.5px] bg-gray-300 cursor-pointer">
            <DuplicateIcon className="text-green-500 h-6" />
          </div>
        </div>
     
      <div className="mt-3 w-full flex flex-col">
        {!questions && (
          <h3>There is no questions in this job please add some!</h3>
        )}
        <ul className="list-decimal list-inside">
          {questions &&
            questions.map((question) => (
              <div
                key={question._id}
                className="flex items-center py-2 border-t border-b first:border-t-0 last:border-b-0 justify-between text-black"
              >
                <li>{question.question}</li>
                <div className="flex items-center space-x-2 text-gray-500">
                  <Popup
                    trigger={
                      <button style={{ display: "inline-flex" }}>
                        {" "}
                        {question.timeLimit && (
                          <h6>
                            {question.timeLimit !== "Unlimited"
                              ? `${question.timeLimit}`
                              : `∞`}
                          </h6>
                        )}
                        <ClockIcon className="h-6 cursor-pointer" />
                        {question.takes && (
                          <h6>
                            {question.takes !== "Unlimited"
                              ? `${question.takes}`
                              : `∞`}
                          </h6>
                        )}
                        <FilmIcon className="h-6 cursor-pointer" />
                        {question.thinkTime && (
                          <h6>
                            {question.thinkTime !== "Unlimited"
                              ? `${question.thinkTime}`
                              : `∞`}
                          </h6>
                        )}
                        <ChatAltIcon className="h-6 cursor-pointer" />
                      </button>
                    }
                    position="right center"
                  >
                    <div>
                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <ClockIcon className="h-6" />
                        <h4>Time Limit </h4>
                      </div>
                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <div style={{ width: "100px" }}>
                          <Select
                            options={optionsTimes}
                            defaultValue={{
                              label: question.timeLimit,
                              value: question.timeLimit,
                            }}
                            onChange={(e) => {
                              setTimeLimit(e);
                              setIsTime(true);
                              if (!isThink) {
                                setThinkTime({
                                  value: question.thinkTime,
                                  label: question.thinkTime,
                                });
                              }
                              if (!isTakes) {
                                setTakes({
                                  value: question.takes,
                                  label: question.takes,
                                });
                              }
                              if (!isNewQuestion) {
                                setNewQuestion(question.question);
                              }
                            }}
                          />
                        </div>
                        <h5>minutes</h5>
                      </div>

                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <FilmIcon className="h-6" />
                        <h4>Allowed takes </h4>
                      </div>
                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <div style={{ width: "100px" }}>
                          <Select
                            options={optionsTakes}
                            defaultValue={{
                              label: question.takes,
                              value: question.takes,
                            }}
                            onChange={(e) => {
                              setTakes(e);
                              setIsTakes(true);
                              if (!isThink) {
                                setThinkTime({
                                  value: question.thinkTime,
                                  label: question.thinkTime,
                                });
                              }
                              if (!isTime) {
                                setTimeLimit({
                                  value: question.timeLimit,
                                  label: question.timeLimit,
                                });
                              }
                              if (!isNewQuestion) {
                                setNewQuestion(question.question);
                              }
                            }}
                          />
                        </div>
                        <h5>takes</h5>
                      </div>

                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <ChatAltIcon className="h-6" />
                        <h4>Think time </h4>
                      </div>
                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <div style={{ width: "100px" }}>
                          <Select
                            options={optionsThink}
                            defaultValue={{
                              label: question.thinkTime,
                              value: question.thinkTime,
                            }}
                            onChange={(e) => {
                              setThinkTime(e);
                              setIsThink(true);
                              if (!isTime) {
                                setTimeLimit({
                                  value: question.timeLimit,
                                  label: question.timeLimit,
                                });
                              }
                              if (!isTakes) {
                                setTakes({
                                  value: question.takes,
                                  label: question.takes,
                                });
                              }
                              if (!isNewQuestion) {
                                setNewQuestion(question.question);
                              }
                            }}
                          />
                        </div>
                        <h5>minutes</h5>
                      </div>
                      <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                        <button
                          className="text-blue"
                          onClick={updateQuestion.bind(this, question)}
                        >
                          Apply
                        </button>
                        <button
                          className="text-blue"
                          style={{ marginLeft: "100px" }}
                          onClick={updateQuestions.bind(this, jobId)}
                        >
                          Apply to all
                        </button>
                      </div>
                    </div>
                  </Popup>
                  <Popup
                    trigger={
                      <button style={{ display: "inline-flex" }}>
                        {" "}
                        <PencilAltIcon className="h-6 cursor-pointer" />
                      </button>
                    }
                    position="right center"
                  >
                    <div className="flex items-center space-x-2">
                      <textarea
                        type="text"
                        className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm"
                        onChange={(e) => {
                          setNewQuestion(e.target.value);
                          setIsNewQuestion(true);
                          if (!isTime) {
                            setTimeLimit({
                              value: question.timeLimit,
                              label: question.timeLimit,
                            });
                          }
                          if (!isTakes) {
                            setTakes({
                              value: question.takes,
                              label: question.takes,
                            });
                          }
                          if (!isThink) {
                            setThinkTime({
                              value: question.thinkTime,
                              label: question.thinkTime,
                            });
                          }
                        }}
                        placeholder="Type your question here"
                        defaultValue={question.question}
                      />
                    </div>
                    <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                      <button
                        className="text-blue"
                        onClick={updateQuestion.bind(this, question)}
                      >
                        Apply
                      </button>
                    </div>
                  </Popup>

                  {/* <LocationMarkerIcon className="h-6 cursor-pointer" /> */}
                  <XIcon
                    className="h-6 cursor-pointer text-red-600"
                    onClick={removeQuestion.bind(this, question)}
                  />
                </div>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default Questions;
