import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationNote from "components/employer/jobApplicationNote";
import SendEmailInvitForOneJobApp from "components/employer/sendEmailInvitForOneJobApp";
import DisplayUserManagementNote from "components/employer/displayUserManagementNote";


import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function UserManagementNotes(props) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [candidateUserNotes, setCandidateUserNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
  const user = JSON.parse(localStorage.getItem("user"));




const fetchUserNotes = async () => {
    console.log("333333333333333333: jobAppNotes v5: step 0: ");
  
  instance.get(`/userManagementNotes/getNotesByUserID/${props.candidateUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      // console.log("333333333333333333: jobAppNotes v5: step 1: resp.data.length:", resp.data.length);
      setCandidateUserNotes(resp.data);
    }
    
    // console.log("333333333333333333: jobAppNotes: step 2:");
    // console.log("333333333333333333: jobAppNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: jobAppNotes: step 4 : resp.data: ", resp.data);
    });
};


const createUserNote = async () => {
  let note = addThisNote;
  let recruiterUserID = user._id;
  let candidateUserID = props.candidateUserID;


  console.log("444444444444444444: userManagementNotes: createUserNote: step 0: ");
  console.log("444444444444444444: userManagementNotes: createUserNote: step 5 addThisNote: ", addThisNote);
  
  instance.post("/userManagementNotes/createNote", 
  {
    candidateUserID,
    recruiterUserID,
    note,
  }
  ).then((resp) => {
    // console.log("444444444444444444: createUserNote: step 2:");
    // console.log("444444444444444444: createUserNote: step 3 : resp: ", resp);
    // console.log("444444444444444444: createUserNote: step 4 : resp.data: ", resp.data);
    });
};


   useEffect(() => {
    fetchUserNotes();
  }, [newAddedNote]);



  return (
    <>

          <Row className="pl-lg-4">
            <Col>
              <h4>Envoyer une invitation au candidat par email:</h4>
              {jobID &&
              <SendEmailInvitForOneJobApp
                jobID = {jobID}
                jobApplicationUserID = {jobApplicationUserID}
              />
            }
            </Col>
        </Row>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <form>
                  <Grid item xs={12}>
                    <label className="px-lg-3">Ajouter une remarque</label>
                    <Input
                        name="addThisNote"
                        // label="Nom" 
                        // placeholder="Email" 
                        className="w-full " 
                        type="textarea"
                        rows="5"
                        // onBlur={handleBlur} 
                        // value={email}
                        // onChange={handleChange} 
                        onChange={e => setAddThisNote(e.target.value)}
                        value={addThisNote || ""} 
                        // error={!!touched.titre && !!errors.titre} 
                        // helperText={touched.titre && errors.titre} 
                    >
                    </Input>
                    </Grid>
                  <Button 
                    className="mt-2"
                    variant="contained" 
                    color="primary" 
                    // type="submit" 
                    // sx={{ mt: "25px" }}
                    onClick={() => { 
                      // console.log("bouton cliqué 0000000000000000000000 ")
        
                      createUserNote();
                      setNewAddedNote(newAddedNote + 1 );
                      setAddThisNote("");
                    }}
                    >
                      Enregistrer
                  </Button>

                </form>
                <h4 className="mt-4 mb-4">Remarques pour cet utilisateur:</h4>

                <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <h4 className="mt-4 mb-4">Remarques:</h4>


                {candidateUserNotes && candidateUserNotes.length !== 0 && (
                <>
                    {candidateUserNotes
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .map((candidateUserNoteItem) => (
          

                        <DisplayUserManagementNote
                          noteID = {candidateUserNoteItem._id}
                          key={candidateUserNoteItem._id}
                        />
                      
 
                    ))}
                </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
     
    </>
  );
}

export default UserManagementNotes;
