import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap'
import Img from '../../assets/img/examples/Training.png'
import { useHistory } from 'react-router-dom';
import { createSessionForCheckoutPayment } from 'network/ApiAxios';
import { getUserById } from 'network/ApiAxios';
import useCustomTranslation from 'components/other/useCustomTranslation';


const OneTrainingForCandidateCard = ({ item, hasActiveSubscription }) => {
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const { t, i18n } = useCustomTranslation();
    //console.log('hasActiveSubscription', hasActiveSubscription)


    //const paymentDone = user?.payments.find(pay => pay.priceId === item?.priceId && pay.status === "success");


    const GenerateCheckoutSession = async () => {
        try {
            localStorage.setItem('priceId', item?.priceId);
            const { data } = await createSessionForCheckoutPayment({ priceId: item?.priceId })
            console.log(data.url);
            window.location.href = data.url;
        } catch (error) {
            console.log(error)
        }
    }

    const GoToTrainingDetails = () => {
        history.push(`/candidate/trainingDetails/${item?._id}`)
    }

    const GoToTrainingOverview = () => {
        history.push(`/candidate/trainingOverview/${item?._id}`)
    }

    return (
        <>
            <Card

            >
                <img
                    alt="Sample"
                    src={Img}
                />
                <CardBody className='d-flex flex-column align-items-center'>
                    <CardTitle tag="h4">
                        {item.label}
                    </CardTitle>
                </CardBody>
                <CardFooter className='d-flex justify-content-center'>
                    {/* <h4 className='mt-1'>{item?.price} {item?.price && '€'}</h4> */}

                    {/*(user?.payments.length && paymentDone) ?
                        <Button
                            color='dark'
                            onClick={GoToTrainingDetails}
                            style={{ whiteSpace: 'normal' }}
                            size='sm'>
                            Commencer la formation
                        </Button>
                        :

                        <Button
                            color='dark'
                            style={{ whiteSpace: 'normal' }}
                            size='sm'
                            onClick={GenerateCheckoutSession}
                        >

                            Payer
                        </Button>*/}
                        {hasActiveSubscription ? <Button
                            color='dark'
                            onClick={GoToTrainingDetails}
                            style={{ whiteSpace: 'normal' }}
                            size='sm'>
                            {t('Start')} {t('thea')} {t('Training')}
                        </Button>
                        :
                        <Button
                            color='dark'
                            onClick={GoToTrainingOverview}
                            style={{ whiteSpace: 'normal' }}
                            size='sm'>
                            {t('TrainingOverview')}
                        </Button>}
                </CardFooter>
            </Card>
        </>
    )
}

export default OneTrainingForCandidateCard