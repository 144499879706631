import Header from "components/Headers/Header";
import Competitions from "components/recruteur/competitions";
import CompetitionsEmployer from "components/recruteur/competitionsEmployer";
import React from "react";

function Tab2CompEmployer() {
  return (
    <>
      <Header />
      <CompetitionsEmployer />
    </>
  );
}

export default Tab2CompEmployer;
