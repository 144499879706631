import React from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { BuildingOfficeIcon, LocationMarkerIcon, OfficeBuildingIcon } from '@heroicons/react/solid';

const WorkExperienceSP = ({ candidateUser, t }) => {
    const WorkEntries = candidateUser?.ParsedDataCV?.work_experience?.entries;
    return (
        <>
            {WorkEntries?.length ? <Container>
                <Card className="shadow">
                    <CardHeader style={{ border: 'none' }}>
                        <Row>
                            <Col>
                                <h6 className="heading-small text-muted">
                                    {t('Experiences')}
                                </h6>
                            </Col>

                        </Row>
                    </CardHeader>
                    <CardBody>
                        {WorkEntries?.map((work, i) => <Row className='mb-4' key={i}>
                            <Col sm="1">
                                <svg style={{ height: "3rem", color: '#5e72e4' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" /*className="w-6 h-6"*/>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                </svg>

                                {/*<OfficeBuildingIcon

                              style={{ height: "4rem", color: '#5e72e4', marginTop: '-20px' }} />*/}
                            </Col>
                            <Col sm='11'>
                                <Row className="items-center space-x-3 mb-2">
                                    <div>
                                        <h3 className="card-t">{work?.title}</h3>
                                        <h5 className> {(work?.start_date || work?.end_date) && `${work?.start_date} - ${work?.end_date}`}</h5>

                                        <span
                                            className="text-sm"
                                            style={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp:
                                                    "6" /* number of lines to show */,
                                                WebkitBoxOrient: "vertical",
                                            }}
                                        >
                                            {work?.description}
                                        </span>
                                    </div>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        {(work?.company || work?.industry) && <div style={{ display: "inline-flex" }}>
                                            {" "}
                                            <OfficeBuildingIcon
                                                style={{ height: "1.2rem" }}
                                            />{" "}
                                            <span> {work.company || work.industry}</span>{" "}
                                        </div>}
                                    </Col>
                                    <Col lg="6">
                                        {(work?.location?.country || work?.location?.city) && <div style={{ display: "inline-flex" }}>
                                            {" "}
                                            <LocationMarkerIcon
                                                style={{ height: "1.2rem" }}
                                            />{" "}
                                            <span> {work?.location?.country}, {work?.location?.city} </span>{" "}
                                        </div>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>)}
                    </CardBody>
                </Card>
            </Container>
            : null}
        </>
    )
}

export default WorkExperienceSP