import { useHistory, useLocation } from "react-router-dom";
import { options_recruiters, options_recruiters_array, options_recruiters_initiales_array } from 'options';
import { useTranslation } from "react-i18next";

import {
    Button,
    Form,
    FormGroup,
    Container,
    Label,
    Input,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormText,
    FormFeedback,
    CardText,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    PencilAltIcon,
    LocationMarkerIcon,
    BriefcaseIcon,
    MailIcon,
    PhoneIcon,
    LinkIcon,
    CodeIcon,
    ArchiveIcon,
    OfficeBuildingIcon,
    PaperClipIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { Link } from 'react-router-dom'
import { set } from "react-hook-form";

function OneJobCardCandidateForHomePage(props) {
    const history = useHistory();
    var photo_number_string = props.createdAt;
    photo_number_string = photo_number_string.substr(17, 2);
    var photo_number = Number(photo_number_string) //1234
    const [modal, setModal] = useState(false)
    return (
        <>
            <Row className={`px-2 py-3 flex hover:black hover:border hover:border-black duration-75 hover:z-20 shadow-lg rounded-sm bg-white cursor-pointer mb-2`}
                key={props.id}
            >
                <Col lg="2">
                    <img
                        src={require("assets/img/brand/job_offer_HL_300_225.jpg").default}
                        style={{
                            width: "120x",
                            height: "120px",

                            objectFit: "cover",
                        }}
                        alt="Responsive image"
                    />
                </Col>
                <Col lg="8">
                    <div className="flex items-center space-x-3 mb-2">
                        <div>
                            <h3 className="w-full card-content" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{props.title}</h3>
                            <span
                                className="text-sm"
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp:
                                        "2" /* number of lines to show */,
                                    WebkitBoxOrient: "vertical",
                                }}
                            >
                                {props.description}
                            </span>
                        </div>
                    </div>
                    <Row>
                        <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                    style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {props.technologies}</span>{" "}
                            </div>
                        </Col>
                        <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                    style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {props.contract}</span>{" "}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                    style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {props.entreprise}</span>{" "}
                            </div>
                        </Col>
                        <Col lg="6">
                            <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                    style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {props.location}</span>{" "}
                            </div>
                        </Col>
                    </Row>
                    {photo_number && (photo_number > 0) && (photo_number < 10) && (
                        <Row>
                            <Col lg="6">
                                <div style={{
                                    display: "inline-flex",
                                    height: "56px",
                                    paddingTop: "12px",
                                    paddingBottom: "12px",
                                }}>
                                    {" "}
                                    <OfficeBuildingIcon
                                        style={{ height: "1.2rem" }}
                                    />{" "}
                                    &nbsp; <span>{`Recruteur: ${options_recruiters_initiales_array[photo_number]}`}</span>{" "}
                                </div>
                            </Col>
                            <Col lg="2">
                                <img
                                    // src={require(`assets/img/recruiters/photo_recruiter_${photo_number}.jpg`)}
                                    src={require(`assets/img/recruiters/photo_recruiter_0${photo_number}.jpg`).default}
                                    style={{
                                        width: "56x",
                                        height: "56px",
                                        boxSizing: "border-box",
                                        backgroundClip: "content-clip",


                                        // objectFit: "cover",
                                    }}
                                    alt="Responsive image"
                                />

                            </Col>


                        </Row>
                    )}
                    {photo_number && (photo_number > 9) && (photo_number < 41) && (
                        <Row>
                            <Col lg="6">
                                <div style={{
                                    display: "inline-flex",
                                    height: "56px",
                                    paddingTop: "12px",
                                    paddingBottom: "12px",
                                }}>
                                    {" "}
                                    <OfficeBuildingIcon
                                        style={{ height: "1.2rem" }}
                                    />{" "}
                                    &nbsp; <span>{`Recruteur: ${options_recruiters_initiales_array[photo_number]}`}</span>{" "}
                                </div>
                            </Col>
                            <Col lg="2">
                                <img
                                    // src={require(`assets/img/recruiters/photo_recruiter_${photo_number}.jpg`)}
                                    src={require(`assets/img/recruiters/photo_recruiter_${photo_number}.jpg`).default}
                                    style={{
                                        width: "56x",
                                        height: "56px",
                                        boxSizing: "border-box",
                                        backgroundClip: "content-clip",


                                        // objectFit: "cover",
                                    }}
                                    alt="Responsive image"
                                />

                            </Col>


                        </Row>
                    )}
                </Col>
                <Col lg="2" className=" flex items-center">
                    <div>
                        <Button
                            onClick={() => setModal(true)}
                            type="button"
                            color="primary"
                        >
                            S'inscrire/Connecter
                        </Button>
                        <Modal
                            toggle={() => {
                                setModal(!modal);
                            }}
                            isOpen={modal}
                            size="lg"
                        >
                            <div className=" modal-header">
                                <h4
                                    className=" modal-title"
                                    id="exampleModalLabel"
                                >
                                    {props.title}
                                </h4>
                                <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => {
                                        setModal(!modal);
                                    }}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <ModalBody>
                                <Row>
                                    <Col sm="1"></Col>
                                    <Col lg="5">
                                        <Button

                                            className="btn-secondary"
                                            onClick={() => history.push('/home/login')}
                                        >
                                            vous avez déjà un compte,
                                            connectez-vous!
                                        </Button>
                                    </Col>
                                    <Col lg="5">
                                        <Button
                                            color="primary"
                                            style={{float:'right'}}
                                            to="/homepage/register"
                                            tag={Link}
                                        >
                                            Créez un nouveau compte!
                                        </Button>
                                    </Col>
                                </Row>

                            </ModalBody>
                        </Modal>
                    </div>
                </Col>

            </Row>
        </>
    );
}

export default OneJobCardCandidateForHomePage;