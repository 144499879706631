import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerCand } from "../../network/ApiAxios";
import config from "../../config";
import { PencilAltIcon } from "@heroicons/react/outline";
import Select from "react-select";


const customStyles = {
    // option: (provided, state) => ({
    //   ...provided,
    //   borderBottom: '1px dotted pink',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    // }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
    }),
    // singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';
  
    //   return { ...provided, opacity, transition };
    // }
  }
const Register = () => {
    //shared
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
    //Candidat
  const [email, setEmail] = useState("");
  const [studyLevel, setStudyLevel] = useState("");  
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [adress, setAdress] = useState("");
  const [projets, setProjets] = useState("");
  //const [projet_France_CDI, set_Projet_France_CDI] = useState("");
  //const [projet_Etranger_hors_France_CDI, set_projet_Etranger_hors_France_CDI] = useState("");
    //admin
  const [company, setCompany] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [webSite, setWebSite] = useState("");
  const [activity, setActivity] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  



  const [role, setRole] = useState("candidat");
  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);
  const [type, setType] = useState("");

  const [formIsValid, setFormIsValid] = useState(true);
  const [openTab, setOpenTab] = React.useState(2);

  const options = [
    { value: "Agroalimentaire", label: "Agroalimentaire" },
    { value: "Banque / Assurance", label: "Banque / Assurance" },
    { value: "Bois/ Papier/ Carton/ Imprimerie", label: "Bois/ Papier/ Carton/ Imprimerie" },
    { value: "BTP/ Matériaux de construction", label: "BTP/ Matériaux de construction" },
    { value: "Chimie/ Parachimie", label: "Chimie/ Parachimie" },
    { value: "Commerce/ Négoce/ Distribution", label: "Commerce/ Négoce/ Distribution" },
    { value: "Édition/ Communication/ Multimédia", label: "Édition/ Communication/ Multimédia" },
    { value: "Électronique/ Électricité", label: "Électronique/ Électricité" },
    { value: "Études et conseils", label: "Études et conseils" },
    { value: "Industrie pharmaceutique", label: "Industrie pharmaceutique" },
    { value: "Informatique/ Télécoms", label: "Informatique/ Télécoms" },
    { value: "Machines et équipements/ Automobile", label: "Machines et équipements/ Automobile" },
    { value: "Métallurgie/ Travail du métal", label: "Métallurgie/ Travail du métal" },
    { value: "Plastique/ Caoutchouc", label: "Plastique/ Caoutchouc" },
    { value: "Services aux entreprises", label: "Services aux entreprises" },
    { value: "Textile/ Habillement/ Chaussure", label: "Textile/ Habillement/ Chaussure" },
    { value: "Transports/ Logistique", label: "Transports/ Logistique" },
    { value: "Autres", label: "Autres" },
  ];

  const options_projects = [
    { value: 'Je suis en France et je cherche uniquement un CDI en France', label: 'Je suis en France et je cherche uniquement un CDI en France' },
    { value: 'Je suis en France et je cherche uniquement une mission en Freelance en France', label: 'Je suis en France et je cherche uniquement une mission en Freelance en France' },
    { value: 'Je suis en France et je cherche un CDI ou une mission en Freelance en France', label: 'Je suis en France et je cherche un CDI ou une mission en Freelance en France' },
    { value: 'Je suis hors de la France et je cherche un CDI en France', label: 'Je suis hors de la France et je cherche un CDI en France' },
    { value: 'Je suis hors de la France et je cherche une mission en Freelance', label: 'Je suis hors de la France et je cherche une mission en Freelance' }
  ];

  const options_Pays = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Brazil", label: "Brazil" },
    { value: "Brunei", label: "Brunei" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Cabo Verde", label: "Cabo Verde" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo (Congo-Brazzaville)", label: "Congo (Congo-Brazzaville)" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czechia (Czech Republic)", label: "Czechia (Czech Republic)" },
    { value: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Eswatini", label: "Eswatini" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Greece", label: "Greece" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Holy See", label: "Holy See" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Laos", label: "Laos" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar (formerly Burma)", label: "Myanmar (formerly Burma)" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "North Korea", label: "North Korea" },
    { value: "North Macedonia", label: "North Macedonia" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestine State", label: "Palestine State" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russia" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "South Sudan", label: "South Sudan" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syria" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States of America", label: "United States of America" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
    { value: "Autres", label: "Autres" },
  ];


  const handleEmployerValidation = () => {
    let isValid = true;
 
    if (!location) {
      isValid = false;
      setError("impossible de procéder avec pays vide ");
    }
    if (!activity) {
      isValid = false;
      setError("impossible de procéder avec un secteur d'activité vide ");
    }
    if (!phone) {
      isValid = false;
      setError("impossible de procéder avec un numéro de téléphone vide ");
    }
    if (!companyEmail) {
      isValid = false;
     setError("impossible de procéder avec un Email de l'entreprise vide ");

      if (typeof companyEmail !== "undefined") {
        let lastAtPos = companyEmail.lastIndexOf("@");
        let lastDotPos = companyEmail.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            companyEmail.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           companyEmail.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email de l'entreprise n'est pas valide");
        }
      }

      console.log(error);
     
    }
    if (!company) {
      isValid = false;
      setError("impossible de procéder avec Nom de l'entreprise vide ");
    }
    if (!email) {
      isValid = false;
     setError("impossible de procéder avec un Email vide ");

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email  n'est pas valide");
        }
      }

      console.log(error);
     
    }
    if (!lastName) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
    }
    if (!name) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
    }
       
    return isValid;
  };
  const handleCandidatValidation = () => {
    let isValid = true;

    if (!adress) {
      isValid = false;
      setError("impossible de procéder avec une adresse vide ");
    }
    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un pays vide ");
    }
    if (!yearsOfExperience) {
      isValid = false;
      setError("impossible de procéder avec Nb d'années d'experience vide ");
    }
    if (!studyLevel) {
      isValid = false;
      setError("impossible de procéder avec Niveau d'étude vide ");
    }
    if (!email) {
      isValid = false;
     setError("impossible de procéder avec un Email vide ");

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email n'est pas valide");
        }
      }

      console.log(error);
     
    }
    if (!lastName) {
      isValid = false;
      setError("impossible de procéder avec un prénom vide ");
    }
    if (!name) {
      isValid = false;
      setError("impossible de procéder avec un nom vide ");
    }
       
    return isValid;
  };
  const registerUser = async () => {
    // if (!(name && email && password && confirmPassword && checkbox)) {
    //     setError("Make sure to fill all the inputs and agree to the privacy policy");
    //     return;
    // }
    console.log("formisvalid", handleEmployerValidation());

    if (handleEmployerValidation()) {

      if (password !== confirmPassword) {
        setError("Les mots de passe ne correspondent pas");
        return;
      }
      let Activity=activity.value
      const response = await register(
        name,
        lastName,
        email,
        company,
        companyEmail,
        phone,
        webSite,
        Activity,
        description,
        location,
        password,
        role
      );
      const { data } = response;
      if (!data.success) {
        setError(data.msg);
        return;
      }
      if (config.DEMO) {
        setToastMessage(
          "Votre compte a été créé avec succès !"
                  );
        setUserID(data.userID);
      }
      setError("");
      setName("");
      setEmail("");
      setCompanyEmail("");
      setCompany("");
      setPhone("");
      setLastName("");
      setWebSite("");
      setLocation("");
      setPassword("");
      setActivity("");
      setDescription("");   

      setConfirmPassword("");
      setCheckbox(false);
      setShowToast(true);
    } else {
      console.log("errored");
    }
  };
  const registerCandidat = async () => {
    // if (!(name && email && password && confirmPassword && checkbox)) {
    //     setError("Make sure to fill all the inputs and agree to the privacy policy");
    //     return;
    // }
    console.log("formisvalid", handleCandidatValidation());

    if (handleCandidatValidation()) {

      if (password !== confirmPassword) {
        setError("Les mots de passe ne correspondent pas");
        return;
      }
      
      const response = await registerCand(
        name,
        lastName,
        email,
        studyLevel,
        yearsOfExperience,
        adress,
        password,
        role,
        projets,
        location,
      );
      const { data } = response;
      //console.log("99999999999999 location: ", location);
      if (!data.success) {
        setError(data.msg);
        return;
      }
      if (config.DEMO) {
        setToastMessage(
          "Votre compte a été créé avec succès !"
        );
        setUserID(data.userID);
      }
      setError("");
      setName("");
      setLastName("");
      setEmail("");
      setStudyLevel("");
      setYearsOfExperience("");
      setAdress("");
      setPassword("");
      

      setConfirmPassword("");
      setCheckbox(false);
      setShowToast(true);
      setLocation("");
      setProjets("");
    } else {
      console.log("errored");
    }
  };

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "35%",
          right: 10,
          bottom: 80,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={10000}
          autohide={!config.DEMO}
        >
          <Toast.Header>
            {/* <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/argon-react.png").default}
              alt="..."
            /> */}
            <h3>Welcome to HireLands</h3>
          </Toast.Header>
          <Toast.Body>
            {toastMessage} <br/>
            {config.DEMO ? (
              <a href={config.DOMAIN_NAME + "/home/login"}>
                se connecter ?
              </a>
            ) : null}
          </Toast.Body>
        </Toast>
      </div>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">





          <CardHeader className="bg-transparent pb-5">
          <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-sm uppercase px-5 py-3 shadow-lg rounded block leading-normal " 
                }
                style={openTab===1?{color:"white", backgroundColor:"#5e72e4"}:{color:"#5e72e4", backgroundColor:"white"}}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                  setRole("employer");
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Recruteur ?
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-sm  uppercase px-5 py-3 shadow-lg rounded block leading-normal " }
                style={openTab===2?{color:"white", backgroundColor:"#5e72e4"}:{color:"#5e72e4", backgroundColor:"white"}}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                  setRole("candidat");
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Candidat ?
              </a>
            </li>
            
          </ul>
         
        </div>
          </CardHeader>




          <CardBody className="px-lg-5 py-lg-5">
            

            {/* candidat */}
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
               <h3 className="text-center"> S'inscrire en tant que candidat:</h3>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nom"
                    type="text"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Prénom"
                    type="text"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>


              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    
                  </InputGroupAddon>
                  {/* <Input
                    placeholder="Secteur d’activité"
                    type="text"
                    value={activity}
                    required
                    onChange={(e) => setActivity(e.target.value)}
                  /> */}
                <label className="px-lg-3">Pays</label>
                   <Select className="w-full "
                          options={options_Pays}
                          
                          onChange={(e) => {
                            setLocation(e.value);
                            }} />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Niveau d'étude"
                    type="text"
                    value={studyLevel}
                    required
                    onChange={(e) => setStudyLevel(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nb d'années d'experience"
                    type="email"
                    autoComplete="new-email"
                    value={yearsOfExperience}
                    required
                    onChange={(e) => setYearsOfExperience(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Adresse"
                    type="text"
                    value={adress}
                    required
                    onChange={(e) => setAdress(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    
                  </InputGroupAddon>
                  {/* <Input
                    placeholder="Secteur d’activité"
                    type="text"
                    value={activity}
                    required
                    onChange={(e) => setActivity(e.target.value)}
                  /> */}
                <label className="px-lg-3">Mes projets</label>
                   <Select 
                    className="w-full "
                    //isMulti={true}
                    options={options_projects}
                    //value={projets}
                    //onChange={setProjets}
                    onChange={(e) => {
                      setProjets(e.value);
                      //console.log("888888888888888888 e.value: ", e.value);
                      }} 
                  />
                </InputGroup>
              </FormGroup>
              
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mot de passe"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirmer mot de passe"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={checkbox}
                      onChange={() => setCheckbox(!checkbox)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                style={{
                  
                  width: "100%",
                }}
                  onClick={()=>{
                    
                    registerCandidat();}}
                  className="mt-4"
                  color="primary"
                  type="button"
                >
                  Créer mon compte
                </Button>
              </div>
            </Form>
                </div>
                {/* Recruteur */}
            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
            <h3 className="text-center"> S'inscrire en tant que recruteur:</h3>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nom"
                    type="text"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Prénom"
                    type="text"
                    value={lastName}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nom de l’entreprise"
                    type="text"
                    value={company}
                    required
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email de l’entreprise"
                    type="email"
                    autoComplete="new-email"
                    value={companyEmail}
                    required
                    onChange={(e) => setCompanyEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Numéro de téléphone de l'entreprise"
                    type="number"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Site WEB"
                    type="text"
                    value={webSite}
                    required
                    onChange={(e) => setWebSite(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    
                  </InputGroupAddon>
                  {/* <Input
                    placeholder="Secteur d’activité"
                    type="text"
                    value={activity}
                    required
                    onChange={(e) => setActivity(e.target.value)}
                  /> */}
                <label className="px-lg-3">Secteur d’activité</label>
                   <Select className="w-full "
                          options={options}
                          
                          onChange={(e) => {
                            setActivity(e);
                            }} />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Pays"
                    type="text"
                    value={location}
                    required
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <label className="px-lg-3">Description de l’entreprise</label>
                  <textarea
                    style={{ width: "100%", height: "70px" }}
                    placeholder=""
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mot de passe"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <PencilAltIcon />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirmer mot de passe"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      checked={checkbox}
                      onChange={() => setCheckbox(!checkbox)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                style={{
               
                  width: "100%",
                }}
                  onClick={()=>{
                    
                    registerUser();}}
                  className="mt-4"
                  color="primary"
                  type="button"
                >
                  Créer mon compte
                </Button>
              </div>
            </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
