import CandidatOffres from "components/candidat/CandidatOffres";
import JobCardsInFranceCandidate from "components/candidat/jobCardsInFranceCandidate";

import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Label,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "config";
import { useHistory, useLocation } from "react-router-dom";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});


function OutFrTerminateYourProfileBeforeV2() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
   const history = useHistory();
   const location = useLocation();

   const [alreadyPosted, setAlreadyPosted] = useState(false);
   const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);
   const [interviewPassed, setInterviewPassed] = useState(false);
   const [loading, setLoading] = useState(true);

   

   const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  const fetchCandidateCompetencies = () => {
    instance({
      method: "get",
      url: `/candidatesCompetencies/getCompetenciesByUserID/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setCompetenciesAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };
    const outfrancecheckprofileprogress = () => {
      instance({
        method: "get",
        url: `/users/outfrancecheckprofileprogress/${user._id}`,
        headers: {'Content-Type': 'application/json' },  
      }).then((resp) => {
        setAlreadyPosted(resp.data.profileExistence);
        setCompetenciesAlreadyPosted(resp.data.competenciesExistence);
        setInterviewPassed(resp.data.interviewPassed);
        // console.log("5555555555555555555555555555 resp.data: ", resp.data);

        });
      };

    useEffect(() => {
  
      // fetchProfile();
      // fetchCandidateCompetencies();
      outfrancecheckprofileprogress();
      setTimeout(() => {
        setLoading(false);
    }, 7000);
    }, []);

  return (
    <>
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      {(loading) && (
        <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
          <ClipLoader color="#f00" />
        </div>
      )}
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
          <FormGroup>
            <Label>Attention: Il faut passer au moins un entretien technique en vidéo pour que votre candidature soit prise en compte.</Label>
          </FormGroup>
          <hr className="my-2"/>
            {!alreadyPosted &&
            <>
              <FormGroup>
                <Label className="px-lg-3">Etape 1: Votre candidature n'est pas prise en compte car il faut terminer votre profil avant.</Label>
                <Button 
                  variant="contained" 
                  color="primary" 
                  // type="submit" 
                  // target="_blank"
                  // onClick={() => { history.push(`/outfrapplypermanentfr/createprofile`) }}
                  onClick={() => 
                    
                    window.open('/outfrapplypermanentfr/createprofile', "_self", '_blank', 'noopener,noreferrer')
                  }
                  >
                Cliquer ici
                </Button>
              </FormGroup>
            </>
            }

            {!competenciesAlreadyPosted &&
            <>
              <FormGroup>
                <Label className="px-lg-3">Etape 2: Votre candidature n'est pas prise en compte car il faut remplir votre tableau de compétences avant.</Label>
                <Button 
                  variant="contained" 
                  color="primary" 
                  // type="submit" 
                  // target="_blank"
                  // onClick={() => { history.push(`/outfrapplypermanentfr/mycompetencies`) }}
                  onClick={() => 
                    
                    window.open('/outfrapplypermanentfr/mycompetencies', "_self", '_blank', 'noopener,noreferrer')
                  }
                  >
                Cliquer ici
                </Button>
              </FormGroup>
            </>
          }

          <FormGroup>
            <Label className="px-lg-3">Etape 3: Votre candidature n'est pas prise en compte car il faut passer au moins un auto-entretiens vidéo et technique.</Label>
          <Button 
            variant="contained" 
            color="primary" 
            // type="submit" 
            // target="_blank"
            // onClick={() => { history.push(`/outfrapplypermanentfr/mycompetencies`) }}
            onClick={() => 
              
              window.open('/outfrapplypermanentfr/contract/generalinterviews', "_self", '_blank', 'noopener,noreferrer')
            }
            >
          Cliquer ici
        </Button>
         </FormGroup>
        </Col>
        </Row>




    
      </Container>
      
     
    </>
  );
}

export default OutFrTerminateYourProfileBeforeV2;
