import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DisplayOpenAiGPT = ({ Result }) => {
    return (
        <>
            {Result && <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Voir l'analyse
                </AccordionSummary>
                <AccordionDetails>
                    <div dangerouslySetInnerHTML={{ __html: Result }} />
                </AccordionDetails>
            </Accordion>}
        </>
    )
}

export default DisplayOpenAiGPT