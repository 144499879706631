import React from 'react'
import CardComp from '../MyProfile/CardComp'
import { Row } from 'reactstrap';

const SelfSummarySectionSP = ({ candidateUser }) => {
    const personaLInfo = candidateUser?.ParsedDataCV?.personal_infos;
    return (
        <>
           {(personaLInfo?.self_summary || personaLInfo?.objective ) && <><CardComp header={`${candidateUser?.name && candidateUser?.name} en quelques mots`} hidden>
                <Row>
                    <p className='p-3'>{personaLInfo?.self_summary}</p>
                    <p className='p-3'>{personaLInfo?.objective}</p>


                </Row>

            </CardComp>
            <br/>
            </>}
        </>
    )
}

export default SelfSummarySectionSP