import React, { useState } from 'react'
import { CheckIcon, ExclamationCircleIcon, ChevronUpIcon,ChevronDownIcon} from '@heroicons/react/solid'



const Checklist = ({ candidateProgressProfile,t }) => {
  //const {profileExistence,competenciesExistence,interviewPassed} = candidateProgressProfile 
  const [isShown, setIsShown] = useState(false);
  const toggleShow = () => setIsShown((prevState) => !prevState);
  const style1 = { marginRight: '20px', fontSize: '30px', color: 'green', height: "2rem" }
  const style2 = { marginRight: '20px', fontSize: '30px', color: 'yellow', height: "2rem" }

  return (
    <div>
      <div onClick={toggleShow} className='d-flex align-items-center justify-content-center'>
      <span style={{ fontWeight: 'bold',cursor:'pointer'}}>{t('ProfileCheckTitle')}</span>
      {isShown ? <ChevronUpIcon style={{height: "2rem",cursor:'pointer'}} /> : <ChevronDownIcon style={{height: "2rem",cursor:'pointer'}} />}
      </div>


      {isShown && <ul style={{ listStyle: 'none' }}>
        <div className='d-flex align-items-center'>
          {(candidateProgressProfile && candidateProgressProfile.profileExistence) ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
          <li style={{ display: 'inline' }}> {t('checklist1')}</li>

        </div>
        <div className='d-flex align-items-center'>
          {(candidateProgressProfile && candidateProgressProfile.competenciesExistence) ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
          <li style={{ display: 'inline' }}> {t('checklist2')}</li>

        </div>
        <div className='d-flex align-items-center'>
          {(candidateProgressProfile && candidateProgressProfile.interviewPassed) ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
          <li style={{ display: 'inline' }}>{t('checklist3')}</li>

        </div>

        <div className='d-flex align-items-center'>
          {(candidateProgressProfile && candidateProgressProfile.IsAppliedToGeneralPresentation) ? <CheckIcon style={style1} /> : <ExclamationCircleIcon style={style2} />}
          <li style={{ display: 'inline' }}>{t('checklist4')}</li>

        </div>
      </ul>}
    </div>
  )
}

export default Checklist