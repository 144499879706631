import React, { useState, useEffect } from "react";
import { ArrowDownIcon, BriefcaseIcon } from "@heroicons/react/outline";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import config from "config";
import Header from "components/Headers/Header.js";
// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function CreateForm() {
  const locationQuery = useLocation();
  const history = useHistory();
  const query = qs.parse(locationQuery.search);

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDiscription] = useState("");
  const [studyLevel, setStudyLevel] = useState("");
  const [contract, setContract] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [pay, setPay] = useState("");
  const [gender, setGender] = useState("");
  const [language, setLanguage] = useState("");
  const [technologies, setTechnologies] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [active, setActive] = useState("");
  const [interview, setInterview] = useState("");
  const [errors, setErrors] = useState({
    title: "",
    location: "",
    description: "",
    status: "",
    interview: "",
    studyLevel: "",
    contract: "",
    yearsOfExperience: "",
    gender: "",
    technologies: "",
    startDate: "",
    endDate: "",
    pay: "",
    language: "",
  });
  const [image, setImage] = useState();
  const [userID, setUserID] = useState();

  const [error, setError] = useState();
  useEffect(() => {
    setUserID(JSON.parse(localStorage.getItem("user"))._id);
    if (JSON.parse(localStorage.getItem("user")).image) {
      setImage(JSON.parse(localStorage.getItem("user")).image);
    }
  }, []);


  const handleJobValidation = () => {
    let isValid = true;
    if (!active) {
      isValid = false;
      setError("Veuillez fournir un statut (Active ou Inactive) ");
    }

    if (!description) {
      isValid = false;
      setError("Veuillez fournir une Description pour l'offre ");
    }
    
    if (!endDate) {
      isValid = false;
      setError("impossible de procéder sans Date de début et Date de la fin de l'offre ");
    }

    if (!startDate) {
      isValid = false;
      setError("impossible de procéder sans Date de début et Date de la fin de l'offre ");
    }

    if (!technologies) {
      isValid = false;
      setError("impossible de procéder avec Tehnologies vide ");
    }

    if (!language) {
      isValid = false;
      setError("impossible de procéder avec Langue vide ");
    }

    if (!gender) {
      isValid = false;
      setError("impossible de procéder avec le Genre vide ");
    }

    if (!yearsOfExperience) {
      isValid = false;
      setError("impossible de procéder avec Nb d'années d'experience vide ");
    }

    if (!contract) {
      isValid = false;
      setError("impossible de procéder avec le Type de contrat vide ");
    }

    if (!studyLevel) {
      isValid = false;
      setError("impossible de procéder avec Niveau d'étude vide ");
    }

    if (!location) {
      isValid = false;
      setError("impossible de procéder avec un Emplacement vide ");
    }

    if (!title) {
      isValid = false;
      setError("impossible de procéder avec un Titre de l'offre vide ");
    }
    
   return isValid;
  };
  const createJob = async () => {
     
    if (handleJobValidation()){
      const jobCreated = await instance.post("/jobs", {
        title,
        location,
        description,
        status: active,
        studyLevel,
        contract,
        yearsOfExperience,
        gender,
        technologies,
        startDate,
        endDate,
        pay,
        language,
        image,
        userID,
        entreprise: JSON.parse(localStorage.getItem("user")).company,
      });
      await instance.post(`/jobs/${jobCreated.data.job._id}/interview`, {
        url: `${title}-${jobCreated.data.job._id.toString().slice(0, 4)}`,
      });
      history.push("/employer/jobs");
    }
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (title !== "" && description !== "" && location !== "") {
      history.push(`${locationQuery.pathname}?status=true`);
    }
  };

  const addInterview = (e) => {
    e.preventDefault();
    if (interview !== "") {
      instance.post(`jobs/${query.job}/interview`, {
        url: interview,
      });
      history.push("/admin/tab2");
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    setTitle("");
    setLocation("");
    setDiscription("");
  };

  return (
    <>
    <Header/>
      {Object.keys(query).length === 0 && (
        <Container fluid className="mt-4">
          <Card className="p-4 shadow-sm p-3 mb-5 bg-white rounded">
            <Form onSubmit={onSubmitForm}>
              <div className="form-group">
                <Label>Titre de l'offre*</Label>
                <p>
                  Un titre de poste spécifique pour lequel les candidats
                  passeront un entretien
                </p>
                <Input
                  value={title}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        title: "¨Veuillez attribuer un titre",
                      });
                    } else {
                      setErrors({ ...errors, title: "" });
                    }
                    setTitle(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.title ? "border-danger" : null
                  }`}
                  placeholder="Example: accountant"
                />
                {errors.title && <p className="text-red">{errors.title}</p>}
              </div>
              <div className="form-group">
                <Label>Emplacement*</Label>
                <p>Un lieu spécifique (ville, état/province, pays)</p>
                <Input
                  value={location}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        location: "¨Veuillez attribuer un emplacement",
                      });
                    } else {
                      setErrors({ ...errors, location: "" });
                    }
                    setLocation(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.location ? "border-danger" : null
                  }`}
                  placeholder="City, State, Country"
                />
                {errors.location && (
                  <p className="text-red">{errors.location}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Niveau d'étude*</Label>

                <Input
                  value={studyLevel}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        studyLevel: "¨Veuillez attribuer le niveau d'étude",
                      });
                    } else {
                      setErrors({ ...errors, studyLevel: "" });
                    }
                    setStudyLevel(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.studyLevel ? "border-danger" : null
                  }`}
                  placeholder="Example: Mastère en informatique ou un diplôme équivalent"
                />
                {errors.studyLevel && (
                  <p className="text-red">{errors.studyLevel}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Type de contrat* </Label>

                <Input
                  value={contract}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        contract: "¨Veuillez attribuer le type de contrat",
                      });
                    } else {
                      setErrors({ ...errors, contract: "" });
                    }
                    setContract(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.contract ? "border-danger" : null
                  }`}
                  placeholder="Example: CDI"
                />
                {errors.contract && (
                  <p className="text-red">{errors.contract}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Nombre d'années d'expérience*</Label>

                <Input
                  value={yearsOfExperience}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        yearsOfExperience:
                          "Veuillez attribuer le nombre d'années d'expérience",
                      });
                    } else {
                      setErrors({ ...errors, yearsOfExperience: "" });
                    }
                    setYearsOfExperience(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.yearsOfExperience ? "border-danger" : null
                  }`}
                  placeholder="Example: 2 ans "
                />
                {errors.yearsOfExperience && (
                  <p className="text-red">{errors.yearsOfExperience}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Rémunération </Label>

                <Input
                  value={pay}
                  onChange={(e) => {
                    setPay(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.pay ? "border-danger" : null
                  }`}
                />
                {errors.pay && <p className="text-red">{errors.pay}</p>}
              </div>
              <div className="form-group">
                <Label>Genre*</Label>

                {/* <Input
                value={gender}
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrors({ ...errors, gender: "¨Veuillez attribuer le genre" });
                  } else {
                    setErrors({ ...errors, gender: "" });
                  }
                  setGender(e.target.value);
                }}
                type="text"
                className={`form-control ${
                  errors.gender ? "border-danger" : null
                }`}
                placeholder="Example: H/F"
              /> */}
                <Select
                  className={`w-full  ${
                    errors.gender ? "border-danger" : null
                  }`}
                  options={[
                    { value: "Homme", label: "Homme" },
                    { value: "Femme", label: "Femme" },
                    { value: "Homme/Femme", label: "Homme/Femme" },
                  ]}
                  onChange={(e) => {
                    console.log("eeee",e.value)
                    if (!e.value) {
                      setErrors({
                        ...errors,
                        gender: "¨Veuillez attribuer le genre",
                      });
                    } else {
                      setErrors({ ...errors, gender: "" });
                    }
                    setGender(e.value);
                    
                  }}
                />
                {errors.gender && <p className="text-red">{errors.gender}</p>}
              </div>
              <div className="form-group">
                <Label>Langue*</Label>

                <Input
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.language ? "border-danger" : null
                  }`}
                  placeholder="Example: Anglais/Français"
                />
                {errors.language && (
                  <p className="text-red">{errors.language}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Technologies*</Label>

                <Input
                  value={technologies}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        technologies: "¨Veuillez attribuer les technologies",
                      });
                    } else {
                      setErrors({ ...errors, technologies: "" });
                    }
                    setTechnologies(e.target.value);
                  }}
                  type="text"
                  className={`form-control ${
                    errors.technologies ? "border-danger" : null
                  }`}
                  placeholder="Example: Full stack MEAN"
                />
                {errors.technologies && (
                  <p className="text-red">{errors.technologies}</p>
                )}
              </div>
              <div className="form-group">
                <Label>Date de début*</Label>

                <Input
                  value={startDate}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        startDate: "¨Veuillez attribuer la date de début",
                      });
                    } else {
                      setErrors({ ...errors, startDate: "" });
                    }
                    setStartDate(e.target.value);
                  }}
                  type="date"
                  className={`form-control ${
                    errors.startDate ? "border-danger" : null
                  }`}
                  placeholder="Date de début"
                />
                {errors.startDate && (
                  <p className="text-red">{errors.startDate}</p>
                )}
              </div>
              <div className="form-group">
                <Label> Date de la fin*</Label>

                <Input
                  value={endDate}
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        endDate: "¨Veuillez attribuer la date de la fin  ",
                      });
                    } else {
                      setErrors({ ...errors, endDate: "" });
                    }
                    setEndDate(e.target.value);
                  }}
                  type="date"
                  className={`form-control ${
                    errors.endDate ? "border-danger" : null
                  }`}
                  placeholder="Date de la fin"
                />
                {errors.endDate && <p className="text-red">{errors.endDate}</p>}
              </div>
              <div className="form-group">
                <Label>Description*</Label>
                <p>Une description détaillée du poste </p>
                <textarea
                  value={description}
                  className={`form-control ${
                    errors.description ? "border-danger" : null
                  }`}
                  rows="5"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setErrors({
                        ...errors,
                        description: "¨Please provide a description",
                      });
                    } else {
                      setErrors({ ...errors, description: "" });
                    }
                    setDiscription(e.target.value);
                  }}
                />
                {errors.description && (
                  <p className="text-red">{errors.description}</p>
                )}
              </div>
              {/* <div className="form-group">
              <Button type="submit" className="btn text-white float-right" style={{backgroundColor:"#455266"}}>
                Continue
              </Button>
              <Button onClick={resetForm} className="btn">
                Cancel
              </Button>
            </div> */}
            </Form>
            <div className="flex justify-center">
              <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                <div className="flex items-center space-x-2">
                  <ArrowDownIcon className="h-8 text-green-500" />
                  <h3 className="text-green-500">Active</h3>
                </div>
                <p>
                  Activez le poste pour que les candidats intéressés puissent
                  passer l'entretien.
                </p>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="active"
                    name="status"
                    onChange={(e) => setActive(e.target.value)}
                  />
                  <label htmlFor="active" className="ml-2">
                    Activer
                  </label>
                </div>
              </div>
              <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
                <div className="flex items-center space-x-2">
                  <ArrowDownIcon className="h-8 text-red-500" />
                  <h3 className="text-red-500">Inactive</h3>
                </div>
                <p>
                  Enregistrez l'offre comme inactif si vous voulez le garder
                  comme brouillon.
                </p>
                <div className="flex items-center">
                  <input
                    type="radio"
                    value="inactive"
                    name="status"
                    onChange={(e) => setActive(e.target.value)}
                  />
                  <label htmlFor="active" className="ml-2">
                    Désactiver
                  </label>
                </div>
              </div>
            </div>
            {errors.active && <p className="text-red">{errors.active}</p>}
            {error ? (
                <div className="text-muted font-italic">
                  <small>
                    error:{" "}
                    <span className="text-red font-weight-700">{error}</span>
                  </small>
                </div>
              ) : null}
            <div className="flex justify-center mt-4 space-x-2">
              <Button
                onClick={createJob}
                className="px-5 py-2  bg-green-400 text-white  hover:border-green-500 hover:border-[1px]"
                color="primary"
              >
                Créer l'offre
              </Button>
            </div>
          </Card>
        </Container>
      )}
      {query.status && (
        <div>
          <h2 className=" bg-gray-400 text-xl text-white p-5">
            Create New Job
          </h2>
          <div className="flex justify-center">
            <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
              <div className="flex items-center space-x-2">
                <ArrowDownIcon className="h-8 text-green-500" />
                <h3 className="text-green-500">Active</h3>
              </div>
              <p>
                Set the job to active so you can start sending interview
                invitations
              </p>
              <div className="flex items-center">
                <input
                  type="radio"
                  value="active"
                  name="status"
                  onChange={(e) => setActive(e.target.value)}
                />
                <label htmlFor="active" className="ml-2">
                  Set to active
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-between ml-4 px-2 py-2 w-1/3 bg-white shadow-lg h-52">
              <div className="flex items-center space-x-2">
                <ArrowDownIcon className="h-8 text-red-500" />
                <h3 className="text-red-500">Inactive</h3>
              </div>
              <p>Save the job as inactive if you want to keep it as a draft</p>
              <div className="flex items-center">
                <input
                  type="radio"
                  value="inactive"
                  name="status"
                  onChange={(e) => setActive(e.target.value)}
                />
                <label htmlFor="active" className="ml-2">
                  Set to inactive
                </label>
              </div>
            </div>
          </div>
          {errors.active && <p className="text-red">{errors.active}</p>}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={createJob}
              className="px-5 py-2 bg-green-400 text-white  hover:border-green-500 hover:border-[1px]
            "
            >
              Create job
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateForm;
