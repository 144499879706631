import React from "react";
import Header from "components/Headers/Header";
import CreateForm from "components/recruteur/CreateForm";

function Tab1() {
  return (
    <>
      <Header />
      <CreateForm />
    </>
  );
}

export default Tab1;
