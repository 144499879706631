import React from "react";

import InFranceCandidateLayoutV3 from "layouts/inFranceCandidateLayoutV3";
import InFrCandidateJobsWithPayOrTJMCondition from "components/candidat/InFrCandidateJobsWithPayOrTJMCondition";
function InFranceCandidateJobsWithPayGreaterThen50kView() {
  return (
    <>
      <InFranceCandidateLayoutV3 />
      <InFrCandidateJobsWithPayOrTJMCondition />
    </>
  );
}

export default InFranceCandidateJobsWithPayGreaterThen50kView;
