import CandidatOffres from "components/candidat/CandidatOffres";
import JobCardsInFranceCandidate from "components/candidat/jobCardsInFranceCandidate";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "config";
import { useHistory, useLocation } from "react-router-dom";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});


function OutFrFreelanceterminateYourProfileBefore() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
   const history = useHistory();
   const location = useLocation();

   const [alreadyPosted, setAlreadyPosted] = useState(false);
   const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);
   const [interviewPassed, setInterviewPassed] = useState(false);


    const outfrancecheckprofileprogress = () => {
      instance({
        method: "get",
        url: `/users/outFrFreelanceCheckProfileProgress/${user._id}`,
        headers: {'Content-Type': 'application/json' },  
      }).then((resp) => {
        
        setAlreadyPosted(resp.data.profileExistence);
        setCompetenciesAlreadyPosted(resp.data.competenciesExistence);
        setInterviewPassed(resp.data.interviewPassed);
        // console.log("5555555555555555555555555555 resp.data: ", resp.data);

        });
      };

    useEffect(() => {
        outfrancecheckprofileprogress();
    }, [location]);

  return (
    <>
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          {!alreadyPosted &&
                  <Grid item xs={12}
                  >
                    <label className="px-lg-3">Il faut terminer votre profil avant de pouvoir postuler aux offres</label>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      // type="submit" 
                      // target="_blank"
                      // onClick={() => { history.push(`/outfrapplypermanentfr/createprofile`) }}
                      onClick={() => 
                        
                        window.open('/outfrapplypermanentfr/freelance/createprofile', "_self", '_blank', 'noopener,noreferrer')
                      }
                      >
                    Cliquer ici
                  </Button>
                    </Grid>

      }
      {!competenciesAlreadyPosted &&
          <Grid item xs={12}
          >
            <label className="px-lg-3">Il faut remplir votre tableau de compétences avant de pouvoir postuler aux offres</label>
            <Button 
              variant="contained" 
              color="primary" 
              // type="submit" 
              // target="_blank"
              // onClick={() => { history.push(`/outfrapplypermanentfr/mycompetencies`) }}
              onClick={() => 
                
                window.open('/outfrapplypermanentfr/freelance/mycompetencies', "_self", '_blank', 'noopener,noreferrer')
              }
              >
            Cliquer ici
          </Button>
            </Grid>
      }
      {!interviewPassed &&
        <Grid item xs={12}
        >
          <label className="px-lg-3">Passer au moins un entretien vidéo</label>
          <Button 
            variant="contained" 
            color="primary" 
            // type="submit" 
            // target="_blank"
            // onClick={() => { history.push(`/outfrapplypermanentfr/mycompetencies`) }}
            onClick={() => 
              
              window.open('/outfrapplypermanentfr/freelance/generalinterviews', "_self", '_blank', 'noopener,noreferrer')
            }
            >
          Cliquer ici
        </Button>
         </Grid>
    }
      </Container>
      
     
    </>
  );
}

export default OutFrFreelanceterminateYourProfileBefore;
