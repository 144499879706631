import useCustomTranslation from 'components/other/useCustomTranslation';
import OneCardCandidatesByCompany from 'components/recruteur/OneCardForCandidatesByCompany'
import EmployerLayout from 'layouts/EmployerLayout'
import { SearchCandidatesNumberByCompany } from 'network/ApiAxios';
import { SearchCandidatesByCompany } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Row, Spinner } from 'reactstrap';


const SearchCandidatesByCompanyView = () => {
    const [candidates, setCandidates] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [pageCount, setPageCount] = useState(0);
    const [perPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);

    const user = JSON.parse(localStorage.getItem('user'));
    const { t } = useCustomTranslation();

    const HandleSearch = (e) => {
        setSearch(e.target.value);
    }

    const FetchCandidates = async(pageNb)=>{
        try {
            const { data } = await SearchCandidatesByCompany(user?.employerCampanyID, pageNb, perPage, search)
            setCandidates(data.userDocuments)
        } catch (error) {
            console.log(error)
        }
    }

    const FetchCandidatesNumber = async()=>{
        try {
            const resp = await SearchCandidatesNumberByCompany(user?.employerCampanyID, search);
            setPageCount(resp.data.cnt / perPage);
        } catch (error) {
            console.log(error)
        }
    }

    const FetchCandidatesWhoAplliedByCompany = async () => {
        setCandidates([])
        //setPageNumber(1);
        setIsLoading(true);
        try {
            await FetchCandidatesNumber();
            await FetchCandidates(1);

        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false)
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPageNumber(selectedPage + 1);
        FetchCandidates(selectedPage + 1)
    };

    useEffect(()=>{
        FetchCandidatesNumber()
        FetchCandidates(pageNumber)
    },[])

     /* useEffect(() => {
        FetchCandidates()
    }, [pageNumber]) */ 

    return (
        <>
            <EmployerLayout />
            <Container>
                <Row>
                    <Col lg="2">
                    </Col>
                    <Col lg="10">

                        <Card className="bg-secondary shadow mt-4">
                            <CardHeader>
                                <Row>
                                    <Col sm='2'></Col>
                                    <Col sm='6'>
                                        <Input
                                            type='text'
                                            onChange={HandleSearch}
                                            placeholder={t('placeholderInput')}
                                        />
                                    </Col>
                                    <Col sm='2'>
                                        <Button
                                            color='primary'
                                            onClick={FetchCandidatesWhoAplliedByCompany}
                                        >
                                            {t('SearchContent')}
                                        </Button>
                                    </Col>
                                    <Col sm='2'></Col>
                                </Row>

                            </CardHeader>
                            { isLoading ? <Row className='justify-content-center align-items-center'>
                                <Spinner color="primary">
                                    Loading...
                                </Spinner>
                            </Row> :
                            <>
                            {candidates.length ? <CardBody>

                                {candidates?.map((candidateUser) => (
                                    /*<OneCardForOutFrCandidateRecruters 
                                      key={candidateUser._id}
                                      candidateUserID={candidateUser._id} 
                                    /> */
                                    <OneCardCandidatesByCompany
                                        key={candidateUser._id}
                                        candidateUser={candidateUser}
                                    />
                                ))}
                            </CardBody> : null}
                            </>}
                            <CardFooter className='d-flex justify-content-center'>
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    //forcePage = {pageNumber}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />

                            </CardFooter>
                        </Card>
                    </Col>

                </Row>

            </Container>

        </>
    )
}

export default SearchCandidatesByCompanyView