import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import SuperAdminLayout from "layouts/SuperAdminLayout";

import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  // Button,
  Form,
  FormGroup,
  // Container,
  Label,
  Input,
  Row,
  Col,
  // Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";

import { Box, Container, Button, Card, Grid, MenuItem, TextField } from "@mui/material";

import Offres from "components/recruteur/jobs";
import config from "config";
import Header from "components/Headers/Header";
import EmployerLayout from "layouts/EmployerLayout";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function HomeSuperAdmin({}) {

  return (
    <>
    <SuperAdminLayout />

    <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
        }>
  <Container
        
        style={{
          //backgroundColor: "#f7fafc",
           //objectFit: "cover",
          justifyContent:'center',
          alignItems:'center',
          width: "100%",
          height: "75%",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: "50px",
          paddingBottom: "50px",

      }}
        >

      <Row className="homePageStyle">
       <div>
          <h2>Arrêtez de perdre trop de temps avec le recrutement</h2>
          <p>
          Les entretiens peuvent coûter cher et prendre beaucoup de temps.
          </p>
          <p>
          Avec les entretiens Vidéos, vous n'avez plus besoin de rencontrer les candidats en tête à tête au premier tour, ce qui réduit les coûts et supprime les problèmes de planning.
          </p>
          {' '}
          <span> </span>
          <h3>Besoin de recruter ? </h3>
        <h2> 
        <Button
            color="primary"
            variant="contained"
            style={{
              // backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center",
              

            }}
          href={`/employer/jobs/`}
          >
          Essayez les entretiens en Vidéo
          </Button>
          </h2>
      </div>
      </Row>

      </Container>

      <Container
        
        style={{
          //backgroundColor: "#f7fafc",
           //objectFit: "cover",
          justifyContent:'center',
          alignItems:'center',
          width: "100%",
          height: "75%",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: "50px",
          paddingBottom: "50px",

      }}
        >
          
        <Row>
          <Col>
           <div
            className="homePageStyle"
           style={{
                //backgroundColor: "#f7fafc",
                 //objectFit: "cover",
                justifyContent:'center',
                alignItems:'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
            }}
           
           
           >
            <h2>Trouvez les bons candidats avec les entretiens en Vidéo</h2>
            <br></br>
            <br></br>
            <p>Réalisez rapidment la présélection avec les interviews en Vidéo</p>
            <p>Éliminez les problèmes de planification des entretiens</p>
            <p>Obtenez plus d'informations sur les candidats en moins de temps</p>
            <p>Mener des entretiens à distance</p>

            <h2> 
        <Button
            variant="contained" 
            color="primary" 
            type="submit" 
            
            style={{
              backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center"

            }}
          href={`/employer/talentsprofiles`}
          >
          Accédez à la CVthèque
          </Button>
          </h2>
            
           </div>
          </Col>
          <Col>
                      <img
                        src={`assets/img/brand/cv_video_1.jpg`}
                        //className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/cv_video_1.jpg").default;
                        }}
                        style={{
                          width: "100%",
                          height: "75%",
                          paddingRight: "10%",
                          paddingLeft: 0,
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "80px",
                          

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
         </Col>
        </Row>
      </Container>



      <Container
        
        style={{
          //backgroundColor: "#f7fafc",
           //objectFit: "cover",
          justifyContent:'center',
          alignItems:'center',
          width: "100%",
          height: "75%",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: "50px",
          paddingBottom: "50px",
          
      }}
        >
          
        <Row>
        <Col>
                      <img
                        src={`assets/img/brand/cv_video_2.jpg`}
                        //className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/cv_video_2.jpg").default;
                        }}
                        style={{
                          width: "100%",
                          height: "75%",
                          paddingRight: 0,
                          paddingLeft: "10%",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "80px",
                          //paddingTop: "60px",
                          

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
         </Col>
          <Col>
           <div
            className="homePageStyle"
           style={{
                //backgroundColor: "#f7fafc",
                 //objectFit: "cover",
                justifyContent:'center',
                alignItems:'center',
                width: "100%",
                height: "75%",
                paddingRight: 0,
                paddingLeft: 0,
            }}
           
           
           >
            <h2>Simplifiez-vous la vie avec la présélection Vidéo</h2>
            <br></br>
            <br></br>
            <p>Etape 1: Préparez les questions</p>
            <p>Etape 2: Le candidat est invité à répondre aux questions</p>
            <p>Etape 3: Le recruteur visionne et analyse les réponses du candidat</p>
            <h2> 
        <Button
            variant="contained" 
            color="primary" 
            type="submit" 
            
            style={{
              backgroundColor: '#2196f3',
              justifyContent: "center",
              size: "lg",
              alignItems: "center"

            }}
          href={`/employer/talentsprofiles/`}
          >
          Accédez à la CVthèque
          </Button>
          </h2>
            
           </div>
          </Col>

        </Row>
      </Container>
      </Box>
      





      

    </>
  );
}

export default HomeSuperAdmin;
