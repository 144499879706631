import { LevelCompetenciesoptions } from 'options';
import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';
import Select from 'react-select'
import { EditLevelOneCompetency } from 'network/ApiAxios';

const EditLevelCompetency = ({ compentency, user, candidateUser, competencyID, getCandidateCompetencies }) => {
    //console.log('compentency',compentency);
    //console.log('competencyID',competencyID);
    const [modal, setModal] = useState(false);
    const [level, setLevel] = useState(null)

    const handleLevelChange = (selectedOption) => {
        setLevel(selectedOption.value);
    };

    const ChangeLevelForOneCompetency = async () => {
        try {
            await EditLevelOneCompetency(competencyID, compentency._id, { level: level })
            toggle()
            await getCandidateCompetencies()
        } catch (error) {
            console.log(error);
        }
    }

    const toggle = () => setModal(!modal);
    return (
        <Container>
            {(user?._id === candidateUser?._id && user) &&
                <svg onClick={toggle} style={{ height: '1.2rem', color: '#5e72e4', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                    <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                </svg>
            }
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Changer le niveau de compétence</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm='12'>
                            <Select
                                options={LevelCompetenciesoptions}
                                onChange={handleLevelChange}
                            />
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ChangeLevelForOneCompetency}>
                        Sauvegarder
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>

        </Container>
    )
}

export default EditLevelCompetency