import config from 'config'
import React from 'react'
import { Button } from 'reactstrap'
import avatarImg3 from '../../../assets/img/brand/profileAvatar2.webp';
import {useHistory} from 'react-router-dom';
import './CSS/ImgSection.css';

const SharedProfileImgSection = ({candidateUser}) => {
  const history = useHistory();

  const avatar = {
    //height: "200px",
    //Width: "200px",
    borderRadius: "50%",
    objectFit: "cover",
    cursor: 'pointer'
}
  
const HandleClick = (path)=>{
  history.push(path)
}

  return (
    <div className='cnt'>
      <div className="profile-section">
        <div className="profile-picture">
          <img
            style={avatar}
            src={candidateUser?.image ? `${config.CVs_URL}${candidateUser.image}` : candidateUser?.cv_photo ? `${config.CVs_URL}${candidateUser.cv_photo}` : avatarImg3}
            alt='imageProfile'
          />
        </div>
        <div className="profile-name">{candidateUser?.name[0]?.toUpperCase()} {candidateUser?.lastName[0]?.toUpperCase()}</div>
      </div>

      <div className="btn-wrapper">
        <Button onClick={()=>HandleClick('/homepage/register')}>Proposez une offre</Button>
        <Button onClick={()=>HandleClick('/home/talentedCandidatesListsV1')}>Recherchez des candidats</Button>
      </div>
    </div>
  )
}

export default SharedProfileImgSection