import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import HomepageLayout from "layouts/HomepageLayout";
import OneJobCardCandidate from "components/candidat/oneJobCardCandidate";
import Select from "react-select";
import { options_job_contract_V2 } from 'options';



import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";
import { useTranslation } from "react-i18next";
import OneJobCardCandidateForHomePage from "components/candidat/OneJobCardForHomePage";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});







function JobCardsHomepageV2() {
  
  // let initialTotalJobsNumber;
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLaunched, setSearchLaunched] = useState(false);
  const [updateSearch, setUpdateSearch] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [contractType, setContractType] = useState("All");
  const [pageNb, setPageNb] = useState(1);

  
  const history = useHistory();
  const query = qs.parse(location.search);
  const user = JSON.parse(localStorage.getItem("user"));
  // const [totalJobsNumber, setTotalJobsNumber] = useState("");
  const [totalJobsNumber, setTotalJobsNumber] = useState(0);
  const [totalJobsNumberWithoutSearching, setTotalJobsNumberWithoutSearching] = useState(0);
  const [pageCountWithoutSearching, setPageCountWithoutSearching] = useState(0);
  const [pageNbWithoutSearching, setPageNbWithoutSearching] = useState(1);
  // var totalJobsNumber;
  var pageNumber = (query.pageNumber) || 1;
  // console.log("pageNumber: ", pageNumber);
  const { t, i18n } = useTranslation();



  
  const fetchJobsData = () => {
    // console.log("0000000000000: JobCardsHomepageV2: fetchData: ");

    if (!searchLaunched)
    {
      // console.log("0000000000000: JobCardsHomepageV2: searchLaunched: ", searchLaunched);
      instance.get("jobs/getActiveOrVisibleJobsNumber").then((resp) => {
        // console.log("0000000000000: JobCardsHomepageV2: fetchData: resp: ", resp);
        // console.log("resp: ", resp);
        // console.log("resp.data.cnt", resp.data.cnt);
        // setTotalJobsNumber(resp.data.cnt);
        setTotalJobsNumberWithoutSearching(resp.data.cnt);
        
        // totalJobsNumber = resp.data.cnt;
        // console.log("0000000000000: JobCardsHomepageV2: fetchData: totalJobsNumber: ", totalJobsNumber);
        // console.log("perPage: ", perPage);
        setPageCountWithoutSearching(Math.ceil(resp.data.cnt / perPage));
        //setPageCount(Math.ceil(resp.data.cnt / perPage));
        
        // console.log("0000000000000: JobCardsHomepageV2: fetchData: pageCount: ", pageCount);
  
      });
      
      instance.get(`jobs/getActiveOrVisibleJobsPerPage/${pageNbWithoutSearching}/${perPage}`).then((resp) => {
        setJobs(resp.data);
        
      });

    }
    else
    {
      instance.get(`jobs/searchActiveOrVisibleJobsByKeywordsAndContractTypeNumber/${search}/${contractType}`).then((resp) => {
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: resp: ", resp);
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: search", search);
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: contractType", contractType);
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: resp.data.cnt", resp.data.cnt);
        setTotalJobsNumber(resp.data.cnt);
        // totalJobsNumber = resp.data.cnt;
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: totalJobsNumber", totalJobsNumber);
        // console.log("perPage: ", perPage);
        setPageCount(Math.ceil(resp.data.cnt / perPage));
        // console.log("55555555555555555: launchTheSearchWithContractTypeFunction: pageCount: ", pageCount);
  
      });
      // console.log("4444444444444444444444444444");
      // console.log(`4444444444444444444444444444: jobs/searchJobsByKeywordsAndContractType/${search}/${contractType}/${pageNb}/${perPage}`);
      instance.get(`jobs/searchJobsByKeywordsAndContractType/${search}/${contractType}/${pageNb}/${perPage}`).then((resp) => {
        setJobs(resp.data);

        // const data = resp.data;
        // const DATA = [];
        // console.log("666666666666666: launchTheSearchFunction: data: ", data);
        // console.log("data", data);
        {/*
          for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
          }
          // setData(DATA.slice(offset, offset + perPage));
          setData(DATA);
        */}

        // console.log("666666666666666: launchTheSearchFunction: data after slice: ", data);
        
      });
    }
    // console.log("22222222222222: fetchData: ");
    // console.log("22222222222222: fetchData: pageNumber", pageNumber);
    // console.log("22222222222222: fetchData: perPage", perPage);
    // console.log("22222222222222: fetchData: url", `jobs/getJobsPerPage/${pageNumber}/${perPage}`);


  };

  const fetchDataAfterPaginationClicked = () => {
    // console.log("0000000000000: JobCardsHomepageV2: fetchData: ");



      
      instance.get(`jobs/getJobsPerPage/${pageNb}/${perPage}`).then((resp) => {
        setJobs(resp.data);
        
      });

    // console.log("22222222222222: fetchData: ");
    // console.log("22222222222222: fetchData: pageNumber", pageNumber);
    // console.log("22222222222222: fetchData: perPage", perPage);
    // console.log("22222222222222: fetchData: url", `jobs/getJobsPerPage/${pageNumber}/${perPage}`);


  };

  const handlePageClickWithoutSearching = (e) => {
    setSearchLaunched(false);
    const selectedPage = e.selected + 1;
    setPageNbWithoutSearching(e.selected + 1);
    // console.log("3333333333333333333333: handlePageClick: selectedPage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    // history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
    // console.log("3333333333333333333333: handlePageClick: `jobs/getJobsPerPage/${e.selected + 1}/${perPage}`: ", `jobs/getJobsPerPage/${e.selected + 1}/${perPage}`);

  };

  const handlePageClickForSearching = (e) => {
    setSearchLaunched(true);
    const selectedPage = e.selected;
    setPageNb(e.selected + 1);
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    // history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
  };



  const launchTheSearchWithContractTypeFunction = () => {
    if ( search !== "") {
      setSearchLaunched(true);
      setUpdateSearch(!updateSearch);


    }
    

  };



  useEffect(() => {
    // fetchTotalJobsNumber(); 
    fetchJobsData();
    // fetchDataAfterPaginationClicked();
    // fetchDataV2();
  }, [pageNb, updateSearch, pageCount, contractType, totalJobsNumber, totalJobsNumberWithoutSearching, pageCountWithoutSearching, pageNbWithoutSearching]);




  return (
      <>
      <Container className="container-fluid ">
        <Row>
          <Col lg="12">

          
          <Card className="bg-transparent w-full">
            <CardHeader>
              <Row>

              <Col lg="2">
              </Col>





              <Col lg="4">
              <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-Recherche"
                    placeholder={t("SearchContent")}
                    onChange={(e) => 
                      setSearch(e.target.value)
                    }
                    type="text"
                  />
                </FormGroup>
            

              </Col>

              <Col lg="3">
                  <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="contract_type"
                        // placeholder="gender" 
                        className="w-full "
                        options={options_job_contract_V2}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { setContractType(e.value) }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
              </Col>


              <Col lg="3">
                <Button 
                  variant="contained" 
                  color="primary" 
                  type="submit" 
                  // sx={{ mt: "25px" }}
                  onClick={() => { 
                    // console.log("bouton cliqué 0000000000000000000000 ")
      
                    launchTheSearchWithContractTypeFunction()
                  }}
                  >
                    {t("SearchContent")}
                </Button>
              </Col>
              </Row>
            </CardHeader>


            {searchLaunched && (
                <>
                <CardBody>
                  {jobs
                    .map((job) => (
                      <OneJobCardCandidateForHomePage 
                      key={job._id}
                      id={job._id} 
                      title={job.title} 
                      description={job.description} 
                      technologies={job.technologies} 
                      contract={job.contract} 
                      entreprise={job.entreprise} 
                      location={job.location}
                      createdAt={job.createdAt}
                    />

                    ))}
                </CardBody>
                  <CardFooter className="bg-transparent flex items-center">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClickForSearching}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </CardFooter>
                </>
              )}
               {!searchLaunched && (
                <>
                <CardBody>
                  {jobs
                    .map((job) => (
                      <OneJobCardCandidateForHomePage 
                        key={job._id}
                        id={job._id} 
                        title={job.title} 
                        description={job.description} 
                        technologies={job.technologies} 
                        contract={job.contract} 
                        entreprise={job.entreprise} 
                        location={job.location}
                        createdAt={job.createdAt}
                      />
                    ))}
                </CardBody>
                <CardFooter className="bg-transparent flex items-center">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountWithoutSearching}
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={handlePageClickWithoutSearching}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </CardFooter>
                </>
              )}


            

            

          </Card>
          </Col>
        </Row>
      </Container>
      </>
  );
}

export default JobCardsHomepageV2;
