import React, { useEffect, useState } from 'react'
import CandidatHorsFrLayoutV2 from '../../layouts/CandidateLayoutV3'
import { Alert, Card, Col, Container, Row, UncontrolledAlert } from 'reactstrap'
import ProgressBar from 'components/candidat/MyProfile/ProgressBar';
import Checklist from 'components/candidat/MyProfile/Checklist';
import CardComp from 'components/candidat/MyProfile/CardComp';
import { getUserById, getProfileOutFrUserById, getProfileProgressForOutFrCandidate, getProfileFrUserByID, getProfileProgressForFrCandidate } from 'network/ApiAxios';
import FormComp from 'components/candidat/MyProfile/FormComp';
import { LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { getCompetenciesByCandidate } from 'network/ApiAxios';
import EditLocalisationSection from 'components/candidat/MyProfile/EditLocalisationSection';
import LocalisationSection from 'components/candidat/MyProfile/LocalisationSection';
import FormationSection from 'components/candidat/MyProfile/FormationSection';
import { getCandidateJobApplications } from 'network/ApiAxios';
import OutFrCandidateProfileComp from 'components/candidat/OutFrCandidateProfileComp';

const OutFrCandidateProfile = ({ match }) => {
    const { id } = match.params


    return (
        <>
            <CandidatHorsFrLayoutV2 />
            <Container>
                <Row className='ml-2'>
                    <Col xs="2">
                    </Col>
                    <Col xs="10">
                        <OutFrCandidateProfileComp id={id} />
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default OutFrCandidateProfile