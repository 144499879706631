
import {options_jobAppRejectionReason} from "options";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
} from "reactstrap";
import Select from "react-select";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function JobAppRejectionReason(props) {
  const history = useHistory();
  


  const [actualJobAppRejectionReason, setActualJobAppRejectionReason] = useState("");
  const [newJobAppRejectionReason, setNewJobAppRejectionReason] = useState("");

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [jobID, setJobID] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));






  const updateRejectionReason = async () => {
    
    // console.log("jobAppRejectionReason: updatePipeline: ");
    instance.put(`jobApplication/updateJobApplicationOneField/${props.jobAppID}/rejectionReason`, {"fieldValue": newJobAppRejectionReason}).then((resp) => {
      // console.log("jobAppRejectionReason: updatePipeline: resp: ", resp);  
      if (resp.data.success) {
          // console.log("jobAppRejectionReason: updatePipeline: resp.data.success: ");
          // console.log("jobAppRejectionReason: updatePipeline: resp.data.success: ", resp.data.success);
          setActualJobAppRejectionReason(newJobAppRejectionReason);
        }
      else {
        // console.log("bbbbbbbbbbbbbbbbbbbbbbbb: failure");
      }
    });
      // window.location.reload();
    

  };




const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${props.jobAppID}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    // setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
    setActualJobAppRejectionReason(res.data.rejectionReason);

  })
  .catch((err) => {
    console.log(err);
  });
};




   useEffect(() => {
    fetchJobApplication();
  
  }, []);



  return (
    <>



        <Row>
          <label className="px-lg-3">Motif de rejet</label>
                   <Select className="w-full"
                          options={options_jobAppRejectionReason}   
                          // value={applicationPipeline} 

                          value = {
                            (options_jobAppRejectionReason).filter(option => 
                              option.label === actualJobAppRejectionReason)
                         }

                                          
                          onChange={(e) => {
                            setActualJobAppRejectionReason(e.value);
                            setNewJobAppRejectionReason(e.value);
                            }} />
          <Button
                style={{
               
                  width: "100%",
                }}
                  onClick={()=>{
                    
                    updateRejectionReason(newJobAppRejectionReason);
                  }}
                  className="mt-4"
                  color="primary"
                  type="button"
            >
                  Sauvegarder le motif
          </Button>
        </Row>

    </>
  );
}

export default JobAppRejectionReason;
