import axios from "axios";
import Questions from "components/recruteur/Questions";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import CompCandidats from "./CompCandidats";
import SpecificationsForOneCompetitionEmployer from "components/employer/SpecificationsForOneCompetitionEmployer";
import EmployerLayout from "layouts/EmployerLayout";
import InvitationLinkForOneCompetition from "components/recruteur/InvitationLinkForOneCompetition";

import config from "config";
import LoginPostul from "components/loginPostul";
const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

function EmployerOneCompetition({ match }) {
  
  let competitionId = match.params.competitionId;
  const history = useHistory();
  const [competition, setCompetition] = useState({});
  const [created, setCreated] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [codeDescription, setCodeDescription] = useState("");
  const [codeLink, setCodeLink] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [success, setSuccess] = useState(false);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }
  useEffect(() => {
    instance.get(`competitions/getOneByID/${competitionId}`).then((resp) => {
      setCompetition(resp.data.Item);
      setCreated(resp.data.Item.createdAt);
      // setInterviewUrl(resp.data.job.interview.url);
      // setEndDate(resp.data.Item.endDate.slice(0, 10));
      resp.data.Item.participations.forEach((e) => {
        if (localStorage.getItem("user")) {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        }
      });
    });
  }, [competitionId]);

  const deleteCompetition = () => {
    instance.delete(`competitions/${competitionId}`).then(() => {
      history.push("/admin/tab2comp");
    });
  };
  const participate = () => {
    const formData = new FormData();
    formData.append("CV", CV);
    formData.append("candidatID", user._id);
    formData.append("codeLink", codeLink);
    formData.append("codeDescription", codeDescription);
    instance
      .put(`jobs/participateComp/${competitionId}`, formData)
      .then((resp) => {
        
        if (resp.data.success){
          setSuccess(true);
          setApplied(true);}
      });
  };

  return (
    <>
    <EmployerLayout />

      <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col className="order-xl-2 mb-5 mb-xl-0" lg="3">
            <Card className="card-profile shadow">
              <Row>
                <hr className="my-2" />
              </Row>
              <div className="text-center">
                <h3>{competition.title}</h3>
                <div
                  className="h5 mt-4 col-12 "
                  style={{ display: "inline-flex" }}
                >
                  <img
                    src={`${config.Data_URL}${competition.image}`}
                    onError={(e) => {
                      e.target.src =
                        require("assets/img/brand/500.png").default;
                    }}
                    style={{
                      width: "100%",
                      height: "100%",

                      objectFit: "cover",
                    }}
                    alt="Responsive image"
                  />
                  {/* {job.location} */}
                </div>

                <hr className="my-4" />
                <p></p>
              </div>
            </Card>
          </Col>
          <Col className="order-xl-1" lg="7">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Description
                      </td>

                      <td
                        className={openTab === 2 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        //data-toggle="tab"
                        to="#link2"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Cahier de charge
                      </td>


                      {user.role == "employer" &&
                        user._id == competition.userID && (
                          <td
                            className={openTab === 3 ? "tdActive " : "td "}
                            //activeClassName={openTab === 1 ? "active" : {}}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);
                            }}
                            //data-toggle="tab"
                            to="#link3"
                            //role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            // size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Candidats
                          </td>
                        )}
                      <td
                        className={openTab === 4 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(4);
                        }}
                        //data-toggle="tab"
                        to="#link4"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Inviter
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              <CardBody
                className={openTab === 1 ? "block" : "hidden"}
                id="link1"
              >
                <Form>
                  <h4 className=" mb-4">Description</h4>
                  <div className="pl-lg-4">
                    <Row>
                      <div className="text-sm">{competition.description}</div>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-first-name"*/}
                    {/*            >*/}
                    {/*                First name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Lucky"*/}
                    {/*                id="input-first-name"*/}
                    {/*                placeholder="First name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*    <Col lg="6">*/}
                    {/*        <FormGroup>*/}
                    {/*            <label*/}
                    {/*                className="form-control-label"*/}
                    {/*                htmlFor="input-last-name"*/}
                    {/*            >*/}
                    {/*                Last name*/}
                    {/*            </label>*/}
                    {/*            <Input*/}
                    {/*                className="form-control-alternative"*/}
                    {/*                defaultValue="Jesse"*/}
                    {/*                id="input-last-name"*/}
                    {/*                placeholder="Last name"*/}
                    {/*                type="text"*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  {/*
                    <h4 className=" mb-4">Données supplémentaires</h4>
                    <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <div
                          className="h5 mt-4 col-12 "
                          style={{ display: "inline-flex" }}
                        >
                          Date d'expiration: {endDate}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  */}

                  <hr className="my-4" />
                  {user.role == "employer" && user._id == competition.userID && (
                    <Col className="text-right ml-4" xs="6">
                      <Button
                        color="secondary"
                        style={{ float: "left" }}
                        type="button"
                        onClick={() => {
                          setModalOpen(!modalOpen);
                        }}
                        size="sm"
                      >
                        Supprimer
                      </Button>
                      <Modal
                        toggle={() => setModalOpen(!modalOpen)}
                        isOpen={modalOpen}
                      >
                        <div className=" modal-header">
                          <h4 className=" modal-title" id="exampleModalLabel">
                            Êtes-vous sûr de vouloir supprimer cette compétition
                            ?
                          </h4>
                          <button
                            aria-label="Close"
                            className=" close"
                            type="button"
                            onClick={() => setModalOpen(!modalOpen)}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </div>

                        <ModalFooter>
                          <Button
                            color="secondary"
                            type="button"
                            onClick={() => setModalOpen(!modalOpen)}
                          >
                            ANNULER
                          </Button>
                          <Button
                            color="danger"
                            type="button"
                            onClick={() => {
                              deleteCompetition();
                              setModalOpen(!modalOpen);
                            }}
                          >
                            SUPPRIMER
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  )}
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">
                     About me (placeholder)
                   </h6>
                   <div className="pl-lg-4">
                     <FormGroup>
                       <label>About Me</label>
                       <Input
                         className="form-control-alternative"
                         placeholder="A few words about you ..."
                         rows="4"
                         defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                           Open Source."
                         type="textarea"
                         disabled
                       />
                     </FormGroup>
                   </div> */}
                </Form>
              </CardBody>
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <SpecificationsForOneCompetitionEmployer
                  competitionID={`${competition._id}`}
                   specificationsPDF={`${competition.specs}`}
                />
                

              </CardBody>
              <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link3"
              >
                <h4 className=" mb-1">Candidats ayant participé</h4>
                <div className="pl-lg-4">
                  <Row>
                    <CompCandidats participants={competition.participations} />
                  </Row>
                </div>
              </CardBody>

              <CardBody
                className={openTab === 4 ? "block" : "hidden"}
                id="link4"
              >
                    <InvitationLinkForOneCompetition
                       competitionID={`${competition._id}`}
                    />
              </CardBody>

            </Card>
          </Col>


        </Row>
      </Container>
    </>
  );
}

export default EmployerOneCompetition;
