import { fetchAllActiveJobOffersWithPayOrTJMConditionNumber, fetchAllActiveJobOffersWithPayOrTJMCondition } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import OneJobCardCandidateForLP from './OneJobCardForLandingPage';

const JobsWithPayOrTJMCondition = ({ isFreelancing }) => {
    const [jobs, setJobs] = useState([])
    const fieldValue = isFreelancing ? 'Freelance' : 'NotFreelance';
    const [limit] = useState(6);
    const [pageCount, setPageCount] = useState(0);
    const [pageNb, setPageNb] = useState(1);

    const getActiveJobOffersInFranceWithPayOrTJMConditionNumber = async () => {
        try {
            const { data } = await fetchAllActiveJobOffersWithPayOrTJMConditionNumber(fieldValue)
            setPageCount(Math.ceil(data.cnt / limit))

        } catch (error) {
            console.log(error)
        }
    }

    const getActiveJobOffersInFranceWithPayOrTJMCondition = async () => {
        try {
            const { data } = await fetchAllActiveJobOffersWithPayOrTJMCondition(fieldValue, pageNb, limit)
            setJobs(data)

        } catch (error) {
            console.log(error)
        }
    }

    const handlePageClick = (e) => {
        setPageNb(e.selected + 1);
    };

    useEffect(() => {
        getActiveJobOffersInFranceWithPayOrTJMConditionNumber()
        getActiveJobOffersInFranceWithPayOrTJMCondition()
    }, [pageNb])
    return (
        <>
            <Container

                style={{
                    backgroundColor: "#f7fafc",
                    //objectFit: "cover",
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "75%",
                    paddingRight: 0,
                    paddingLeft: 0,
                }}
            >

                <Row>
                    <Col>
                        <div
                            className="landingPageStyle"
                            style={{
                                backgroundColor: "#f7fafc",
                                //objectFit: "cover",
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: "100%",
                                height: "75%",
                                paddingRight: 0,
                                paddingLeft: 0,
                            }}
                        >
                            <h1>Trouvez le job qui vous convient</h1>
                            <br></br>
                            <br></br>
                            <p>{isFreelancing? "Missions" : "Offres"} dont le {isFreelancing? "TJM" : "salaire"} est de plus de <span className='font-bold'>{ isFreelancing? "500€" :"50K€"}</span></p>
                        </div>
                    </Col>
                    <Col>
                        <img
                            src={`assets/img/brand/video-interview-700x467.jpg`}
                            //className="rounded-circle"
                            onError={(e) => {
                                e.target.src =
                                    require("assets/img/brand/video-interview-700x467.jpg").default;
                            }}
                            style={{
                                width: "100%",
                                height: "75%",
                                paddingRight: 0,
                                paddingLeft: 0,


                                //objectFit: "cover",
                            }}
                            alt="LPImage"
                        />
                    </Col>
                </Row>
            </Container>
            <Container className="landingPageStyle">
                <div >
                    <h2>{isFreelancing? "Missions" : "Offres CDI/CDD"} dont le {isFreelancing? "TJM" : "salaire"} est de plus de <span style={{ color: "#1374e9" }}>{ isFreelancing? "500€" :"50K€"}</span> </h2>
                </div>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-transparent shadow p-2">
                            <CardBody>
                                {jobs?.map(job => (
                                    <OneJobCardCandidateForLP
                                        key={job._id}
                                        id={job._id}
                                        title={job.title}
                                        description={job.description}
                                        technologies={job.technologies}
                                        contract={job.contract}
                                        entreprise={job.entreprise}
                                        location={job.location}
                                        createdAt={job.createdAt}
                                    />))}
                            </CardBody>
                            <CardFooter className="d-flex justify-content-center">
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default JobsWithPayOrTJMCondition