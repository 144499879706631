import React from "react";
import { useTranslation } from "react-i18next";
const image_bloc_CTAForEmployers = require('assets/img/recruiters/image_bloc_CTAForEmployers.png');


function CallToActionForEmployers() {
    const { t, i18n } = useTranslation();


  return (
  <>
    <section class="call-to-action">

        <div class="auto-container"><div class="outer-box aos-init aos-animate" data-aos="fade-up">


            <div class="wrapper">
                <div id="one">   <div class="content-column">
                    <div class="sec-title">
                        <h2>{t("title")}?</h2>
                        <div class="text">
                            {t("Des1")}
                            <br /> 
                            {t("Des2")}.</div>
                        <a style={{ textDecoration: 'none' }} href="/homepage/register" class="theme-btn btn-style-one bg-blue">
                            <span class="btn-title">{t("ButtonHomeContent")}</span>
                        </a>
                    </div>
                </div></div>
                <div id="two">  <div class="image-column">
                    <figure class="image"><img src={require("assets/img/recruiters/image_bloc_CTAForEmployers.png").default} alt="resource" />
                    </figure>
                </div></div>
            </div>

        </div>
        </div>
    </section>
    </>
);




}

export default CallToActionForEmployers;
