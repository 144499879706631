import axios from "axios";
import React, { useEffect, useState } from "react";

import qs from "query-string";
import {
    MailIcon,
    LightBulbIcon,
} from "@heroicons/react/solid";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";
//import {getAll} from "../../network/ApiAxios";
import config from "config";
import { set } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UserProfileForRecruters from "./UserProfileForRecruiter";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});
function OneCardCandidatesByCompany({candidateUser}) {
    const { t, i18n } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);





    return (
        <>
            <Container>

                {candidateUser &&
                    <>

                        <>
                            {candidateUser && (

                                <Row
                                    className="mb-3 flex w-full hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                                    key={candidateUser._id}
                                    onClick={() => {
                                        setModalOpen(!modalOpen);
                                    }}
                                >
                                    <Col lg="2">
                                        <img
                                            src={`assets/img/brand/500.png`}
                                            className="rounded-circle"
                                            onError={(e) => {
                                                e.target.src =
                                                    require("assets/img/brand/500.png").default;
                                            }}
                                            style={{
                                                width: "100%",
                                                height: "100%",

                                                //objectFit: "cover",
                                            }}
                                        //alt="Responsive image"
                                        />
                                    </Col>
                                    <Col lg="10">
                                        <Col lg="12" className="mb-2">
                                            <div>
                                                <span className="card-t">
                                                    {candidateUser.name}{" "}
                                                    {candidateUser.lastName}{" "}
                                                </span>
                                            </div>
                                        </Col>
                                        <Row>

                                            <Col lg="8">
                                                <Col lg="12">
                                                    <div
                                                        className="text-sm"
                                                        style={{ display: "inline-flex" }}
                                                    >
                                                        {" "}
                                                        <MailIcon
                                                            className=""
                                                            style={{ height: "1.2rem" }}
                                                        />{" "}
                                                        &nbsp; <span>
                                                            {candidateUser.email}
                                                            {" - "}
                                                            {"Pays: "}
                                                            {candidateUser.location}

                                                        </span>{" "}
                                                    </div>
                                                </Col>

                                                <Col lg="12">
                                                    <div
                                                        className="text-sm"
                                                        style={{ display: "inline-flex" }}
                                                    >
                                                        {" "}
                                                        <LightBulbIcon
                                                            className=""
                                                            style={{ height: "1.2rem" }}
                                                        />{" "}
                                                        &nbsp;{" "}
                                                        <span>
                                                            {" "}
                                                            Date:{candidateUser?.date.slice(0, 10)}
                                                        </span>{" "}
                                                    </div>
                                                </Col>


                                            </Col>

                                            <Col lg="4" className=" flex items-center">
                                                <div>
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                    >
                                                        {t('SeeProfile')}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}

                        </>




                        <Modal
                            toggle={() => setModalOpen(!modalOpen)}
                            isOpen={modalOpen}
                            size="lg"
                            style={{ maxWidth: "1100px", width: "80%" }}
                        >
                            <div className=" modal-header">

                                <button
                                    aria-label="Close"
                                    className=" close"
                                    type="button"
                                    onClick={() => setModalOpen(!modalOpen)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <ModalBody>
                                <UserProfileForRecruters
                                    candidateUserID={candidateUser?._id}
                                />

                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="danger"
                                    type="button"
                                    onClick={() => setModalOpen(!modalOpen)}
                                    size="sm"
                                >
                                    Fermer
                                </Button>
                            </ModalFooter>
                        </Modal>



                    </>
                }
            </Container>
        </>
    );
}

export default OneCardCandidatesByCompany;
