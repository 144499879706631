import {
  ViewListIcon,
  ArrowCircleDownIcon,
  PencilAltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import JobApplicationNote from "components/employer/jobApplicationNote";
import SendEmailInvitForOneJobApp from "components/employer/sendEmailInvitForOneJobApp";

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardBody, 
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";


const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function UserCandidateNotes(props) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);
  
  const [candidat, setCandidat] = useState("");
  const [hasDrive, setHasdrive] = useState(false);

  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
  const user = JSON.parse(localStorage.getItem("user"));





const fetchOtherNotesForThisCandidate = async () => {
    console.log("333333333333333333: jobAppNotes v5: step 0: ");
  
  instance.get(`/jobApplicationNotes/getNotesByCandidateID/${props.candidateUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      // console.log("333333333333333333: jobAppNotes v5: step 1: resp.data.length:", resp.data.length);
      setOtherJobApplicationNotes(resp.data);
    }
    
    // console.log("333333333333333333: jobAppNotes: step 2:");
    // console.log("333333333333333333: jobAppNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: jobAppNotes: step 4 : resp.data: ", resp.data);
    });
};

const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplication/getJobApplicationsByUserID/${props.candidateUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};


   useEffect(() => {
    // fetchJobApplication();
    // fetchJobApplicationNotes();
    fetchAllJobApplicationsForThisUser();
    // fetchJobData();
    fetchOtherNotesForThisCandidate();
  
  }, []);



  return (
    <>


        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardBody>
                <h4 className="mt-4 mb-4">Remarques créées au niveau des offres:</h4>


                {otherJobApplicationNotes && otherJobApplicationNotes.length !== 0 && (
                <>
                    {otherJobApplicationNotes
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .map((otherJobApplicationNotesItem) => (
          

                        <JobApplicationNote
                          jobApplicationNoteID = {otherJobApplicationNotesItem._id}
                          actualJobApplicationID = {props.candidatId}
                          key={otherJobApplicationNotesItem._id}
                        />
                      
 
                    ))}
                </>
                )}
                {(!otherJobApplicationNotes || otherJobApplicationNotes.length === 0) && (
                <>
                  <p>Pas de remarques</p>
                </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
     
    </>
  );
}

export default UserCandidateNotes;
