const ApplicationMethodOptions = [
  {value:'candidate can choose', label:'Le candidat peut choisir'},
  {value:'only video is allowed', label:'Seule la vidéo est autorisée'},
]

const OfferTypesOptions = [
  {value:'Normal_job', label:'Offre normale'},
  {value:'Question_Catalog', label:'Catalogue de question'},
  {value:'Preparation', label:'Préparation'}
]

const LevelCompetenciesoptions =[
  {value:'débutant', label:'débutant'},
  {value:'Intermédiaire', label:'Intermédiaire'},
  {value:'confirmé', label:'confirmé'},
  {value:'expert',label:'expert'}
]

const options_Pays = [
    { value: "", label: "" },
    { value: "France", label: "France" },
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Brazil", label: "Brazil" },
    { value: "Brunei", label: "Brunei" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { value: "Cabo Verde", label: "Cabo Verde" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo (Congo-Brazzaville)", label: "Congo (Congo-Brazzaville)" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croatia", label: "Croatia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czechia (Czech Republic)", label: "Czechia (Czech Republic)" },
    { value: "Democratic Republic of the Congo", label: "Democratic Republic of the Congo" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Eswatini", label: "Eswatini" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Greece", label: "Greece" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Holy See", label: "Holy See" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Laos", label: "Laos" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar (formerly Burma)", label: "Myanmar (formerly Burma)" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "North Korea", label: "North Korea" },
    { value: "North Macedonia", label: "North Macedonia" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestine State", label: "Palestine State" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russia" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    { value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Korea", label: "South Korea" },
    { value: "South Sudan", label: "South Sudan" },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syria" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-Leste", label: "Timor-Leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States of America", label: "United States of America" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
    { value: "Autres", label: "Autres" },
  ];

const options_profile_technical_or_not = [
  { value: "Je ne fais pas de la technique, je suis un Chef de projet", label: "Je ne fais pas de la technique, je suis un Chef de projet" },
  { value: "Je fais de la technique", label: "Je fais de la technique" },
  { value: "Je ne fais pas de la technique, je suis un manager", label: "Je ne fais pas de la technique, je suis un manager" },
  { value: "Je ne fais pas de la technique, je suis commercial", label: "Je ne fais pas de la technique, je suis commercial" },
  { value: "Je ne fais pas de la technique, je fais d'autres types d'activités", label: "Je ne fais pas de la technique, je fais d'autres types d'activités" },
];

const options_profile_categories = [
  { value: "Chef de projet", label: "Chef de projet" },
  { value: "Système Linux", label: "Système Linux" },
  { value: "Système Windows", label: "Système Windows" },
  { value: "Base de données", label: "Base de données" },
  { value: "Développeur Java", label: "Développeur Java" },
  { value: "Développeur dotnet", label: "Développeur dotnet" },
  { value: "Développeur Python", label: "Développeur Python" },
  { value: "Développeur PHP", label: "Développeur PHP" },
  { value: "Développeur mais autres technologies", label: "Développeur mais autres technologies" },
  { value: "Virtualisation", label: "Virtualisation" },
  { value: "Réseaux et sécurité", label: "Réseaux et sécurité" },
  { value: "Réseaux et système", label: "Réseaux et système" },
  { value: "BI", label: "BI" },
  { value: "Big Data", label: "Big Data" },
  { value: "Manager", label: "Manager" },
  { value: "Autre type de profil", label: "Autre type de profil" },
];

const options_certification_level = [
  { value: "Pas de certif", label: "Pas de certif" },
  { value: "Basique (Practioner, fondamental, Premier niveau ...)", label: "Basique" },
  { value: "Moyen ou Associate", label: "Moyen ou Associate" },
  { value: "Professionnel", label: "Professionnel" },
  { value: "Expert", label: "Expert" },
];

const options_actual_contract = [
  { value: "CDD", label: "CDD" },
  { value: "CDI", label: "CDI" },
  { value: "Freelance", label: "Freelance" },
  { value: "Sans contrat", label: "Sans contrat" },
];

const options_mobility = [
  { value: "Je peux travailler et je suis mobile pour travailler dans toute la France", label: "Je peux travailler et je suis mobile pour travailler dans toute la France" },
  { value: "Je ne suis pas mobile dans toute la France, ma mobilité est: Juste Paris", label: "Je ne suis pas mobile dans toute la France, ma mobilité est: Juste Paris" },
  { value: "Je ne suis pas mobile dans toute la France, ma mobilité est: Ile de France (Grand Paris)", label: "Je ne suis pas mobile dans toute la France, ma mobilité est: Ile de France (Grand Paris)" },
  { value: "Je ne suis pas mobile dans toute la France, je suis mobile au niveau d'autres villes", label: "Je ne suis pas mobile dans toute la France, je suis mobile au niveau d'autres villes" },
];
const options_BAF = [
  { value: "Non aucun problème", label: "Non aucun problème" },
  { value: "Oui je refuse", label: "Oui je refuse" },
];
const options_gender = [
  { value: '', label: '' },
  { value: 'Une femme', label: 'Une femme' },
  { value: 'Un homme', label: 'Un homme' },
  { value: 'Autre', label: 'Autre' }
];

const options_family_Situation = [
  { value: '', label: '' },
  { value: 'Célibataire', label: 'Célibataire' },
  { value: 'Marié avec enfant', label: 'Marié avec enfant' },
  { value: 'Marié sans enfant', label: 'Marié sans enfant' }
];




const options_CCNP = [
  { value: "", label: "" },
  { value: "Oui (CCNP ou HCIP SR)", label: "Oui (CCNP ou HCIP SR)" },
  { value: "Non je n'ai pas cette certification (CCNP ou HCIP SR)", label: "Non je n'ai pas cette certification (CCNP ou HCIP SR)" },
];
const options_CCNA_NetSec = [
  { value: "", label: "" },
  { value: "Oui, j'ai une certification CCNA valide (CCNA)", label: "Oui, j'ai une certification CCNA valide (CCNA)" },
  { value: "J'ai obtenu la certification CCNA mais elle n'est plus valide (CCNA)", label: "J'ai obtenu la certification CCNA mais elle n'est plus valide (CCNA)" },
  { value: "Non je n'ai pas cette certification (CCNA)", label: "Non je n'ai pas cette certification (CCNA)" },
];

const options_Cisco_NetSec = [
  { value: "", label: "" },
  { value: "0 : Aucune connaissance (Cisco)", label: "0 : Aucune connaissance (Cisco)" },
  { value: "1 : Connaissance basique (Cisco)", label: "1 : Connaissance basique (Cisco)" },
  { value: "2 : Administrateur pas autonome (Cisco)", label: "2 : Administrateur pas autonome (Cisco)" },
  { value: "3 : Administrateur autonome (Cisco)", label: "3 : Administrateur autonome (Cisco)" },
  { value: "4 : Spécialiste (Cisco)", label: "4 : Spécialiste (Cisco)" },
];
const options_Competency_degree = [
  { value: "", label: "" },
  { value: "0 : Aucune connaissance", label: "0 : Aucune connaissance" },
  { value: "1 : Connaissance basique", label: "1 : Connaissance basique" },
  { value: "2 : Administrateur pas autonome", label: "2 : Administrateur pas autonome" },
  { value: "3 : Administrateur autonome", label: "3 : Administrateur autonome" },
  { value: "4 : Spécialiste", label: "4 : Spécialiste" },
];

const options_profile_change_SR = [
  { value: "Système Linux Cloud Devops", label: "Système Linux Cloud Devops" },
  { value: "Système Windows Cloud Devops", label: "Système Windows Cloud Devops" },
  { value: "Réseau et sécurité", label: "Réseau et sécurité" },
  { value: "Non je veux rester dans mon domaine", label: "Non je veux rester dans mon domaine" },
];
const options_job_visibility = [
  { value: "", label: "" },
  { value: "Publique", label: "Publique" },
  { value: "Privé", label: "Privé" },
];
const options_job_contract = [
  { value: "CDI en France", label: "CDI en France" },
  { value: "Freelance en France", label: "Freelance en France" },
  { value: "Stage en France", label: "Stage en France" },
  { value: "CDD en France", label: "CDD en France" },
  { value: "Freelance à l'étranger", label: "Freelance à l'étranger" },
  { value: "Introduction d'un étranger en CDI", label: "Introduction d'un étranger en CDI" },
];

const options_job_contract_V2 = [
  { value: "CDIFrance", label: "CDI en France" },
  { value: "FreelanceFrance", label: "Freelance en France" },
  { value: "StageFrance", label: "Stage en France" },
  { value: "CDDFrance", label: "CDD en France" },
  { value: "FreelanceEtranger", label: "Freelance à l'étranger" },
  { value: "IntroductionEtrangerCDI", label: "Introduction d'un étranger en CDI" },
];
const options_france_departments = [
  { value: "01 - Ain - Bourg-en-bresse", label: "01 - Ain - Bourg-en-bresse" },

  { value: "02 - Aisne - Laon", label: "02 - Aisne - Laon" },

  { value: "03 - Allier - Moulins", label: "03 - Allier - Moulins" },

  { value: "04 - Alpes-de-Haute-Provence - Digne-les-bains", label: "04 - Alpes-de-Haute-Provence - Digne-les-bains" },

  { value: "05 - Hautes-alpes - Gap", label: "05 - Hautes-alpes - Gap" },

  { value: "06 - Alpes-maritimes - Nice", label: "06 - Alpes-maritimes - Nice" },

  { value: "07 - Ardèche - Privas", label: "07 - Ardèche - Privas" },

  { value: "08 - Ardennes - Charleville-mézières", label: "08 - Ardennes - Charleville-mézières" },

  { value: "09 - Ariège - Foix", label: "09 - Ariège - Foix" },

  { value: "10 - Aube - Troyes", label: "10 - Aube - Troyes" },

  { value: "11 - Aude - Carcassonne", label: "11 - Aude - Carcassonne" },

  { value: "12 - Aveyron - Rodez", label: "12 - Aveyron - Rodez" },

  { value: "13 - Bouches-du-Rhône - Marseille", label: "13 - Bouches-du-Rhône - Marseille" },

  { value: "14 - Calvados - Caen", label: "14 - Calvados - Caen" },

  { value: "15 - Cantal - Aurillac", label: "15 - Cantal - Aurillac" },

  { value: "16 - Charente - Angoulême", label: "16 - Charente - Angoulême" },

  { value: "17 - Charente-maritime - La rochelle", label: "17 - Charente-maritime - La rochelle" },
  { value: "18 - Cher - Bourges", label: "18 - Cher - Bourges" },
  { value: "19 - Corrèze - Tulle", label: "19 - Corrèze - Tulle" },

  { value: "2A - Corse-du-sud - Ajaccio", label: "2A - Corse-du-sud - Ajaccio" },

  { value: "2B - Haute-Corse - Bastia", label: "2B - Haute-Corse - Bastia" },

  { value: "21 - Côte-d'Or - Dijon", label: "21 - Côte-d'Or - Dijon" },

  { value: "22 - Côtes-d'Armor - Saint-brieuc", label: "22 - Côtes-d'Armor - Saint-brieuc" },

  { value: "23 - Creuse - Guéret", label: "23 - Creuse - Guéret" },

  { value: "24 - Dordogne - Périgueux", label: "24 - Dordogne - Périgueux" },

  { value: "25 - Doubs - Besançon", label: "25 - Doubs - Besançon" },

  { value: "26 - Drôme - Valence", label: "26 - Drôme - Valence" },

  { value: "27 - Eure - Évreux", label: "27 - Eure - Évreux" },

  { value: "28 - Eure-et-loir - Chartres", label: "28 - Eure-et-loir - Chartres" },

  { value: "29 - Finistère - Quimper", label: "29 - Finistère - Quimper" },

  { value: "30 - Gard - Nîmes", label: "30 - Gard - Nîmes" },

  { value: "31 - Haute-garonne - Toulouse", label: "31 - Haute-garonne - Toulouse" },

  { value: "32 - Gers - Auch", label: "32 - Gers - Auch" },

  { value: "33 - Gironde - Bordeaux", label: "33 - Gironde - Bordeaux" },

  { value: "34 - Hérault - Montpellier", label: "34 - Hérault - Montpellier" },

  { value: "35 - Ille-et-vilaine - Rennes", label: "35 - Ille-et-vilaine - Rennes" },

  { value: "36 - Indre - Châteauroux", label: "36 - Indre - Châteauroux" },

  { value: "37 - Indre-et-loire - Tours", label: "37 - Indre-et-loire - Tours" },

  { value: "38 - Isère - Grenoble", label: "38 - Isère - Grenoble" },

  { value: "39 - Jura - Lons-le-saunier", label: "39 - Jura - Lons-le-saunier" },

  { value: "40 - Landes - Mont-de-marsan", label: "40 - Landes - Mont-de-marsan" },

  { value: "41 - Loir-et-cher - Blois", label: "41 - Loir-et-cher - Blois" },

  { value: "42 - Loire - Saint-étienne", label: "42 - Loire - Saint-étienne" },

  { value: "43 - Haute-loire - Le puy-en-velay", label: "43 - Haute-loire - Le puy-en-velay" },

  { value: "44 - Loire-atlantique - Nantes", label: "44 - Loire-atlantique - Nantes" },

  { value: "45 - Loiret - Orléans", label: "45 - Loiret - Orléans" },

  { value: "46 - Lot - Cahors", label: "46 - Lot - Cahors" },

  { value: "47 - Lot-et-garonne - Agen", label: "47 - Lot-et-garonne - Agen" },

  { value: "48 - Lozère - Mende", label: "48 - Lozère - Mende" },

  { value: "49 - Maine-et-loire - Angers", label: "49 - Maine-et-loire - Angers" },

  { value: "50 - Manche - Saint-lô", label: "50 - Manche - Saint-lô" },

  { value: "51 - Marne - Châlons-en-champagne", label: "51 - Marne - Châlons-en-champagne" },

  { value: "52 - Haute-marne - Chaumont", label: "52 - Haute-marne - Chaumont" },

  { value: "53 - Mayenne - Laval", label: "53 - Mayenne - Laval" },

  { value: "54 - Meurthe-et-moselle - Nancy", label: "54 - Meurthe-et-moselle - Nancy" },

  { value: "55 - Meuse - Bar-le-duc", label: "55 - Meuse - Bar-le-duc" },

  { value: "56 - Morbihan - Vannes", label: "56 - Morbihan - Vannes" },

  { value: "57 - Moselle - Metz", label: "57 - Moselle - Metz" },

  { value: "58 - Nièvre - Nevers", label: "58 - Nièvre - Nevers" },

  { value: "59 - Nord - Lille", label: "59 - Nord - Lille" },

  { value: "60 - Oise - Beauvais", label: "60 - Oise - Beauvais" },

  { value: "61 - Orne - Alençon", label: "61 - Orne - Alençon" },

  { value: "62 - Pas-de-calais - Arras", label: "62 - Pas-de-calais - Arras" },

  { value: "63 - Puy-de-dôme - Clermont-ferrand", label: "63 - Puy-de-dôme - Clermont-ferrand" },

  { value: "64 - Pyrénées-atlantiques - Pau", label: "64 - Pyrénées-atlantiques - Pau" },

  { value: "65 - Hautes-Pyrénées - Tarbes", label: "65 - Hautes-Pyrénées - Tarbes" },

  { value: "66 - Pyrénées-orientales - Perpignan", label: "66 - Pyrénées-orientales - Perpignan" },

  { value: "67 - Bas-rhin - Strasbourg", label: "67 - Bas-rhin - Strasbourg" },

  { value: "68 - Haut-rhin - Colmar", label: "68 - Haut-rhin - Colmar" },

  { value: "69 - Rhône - Lyon", label: "69 - Rhône - Lyon" },

  { value: "70 - Haute-saône - Vesoul", label: "70 - Haute-saône - Vesoul" },

  { value: "71 - Saône-et-loire - Mâcon", label: "71 - Saône-et-loire - Mâcon" },

  { value: "72 - Sarthe - Le mans", label: "72 - Sarthe - Le mans" },

  { value: "73 - Savoie - Chambéry", label: "73 - Savoie - Chambéry" },

  { value: "74 - Haute-savoie - Annecy", label: "74 - Haute-savoie - Annecy" },

  { value: "75 - Paris - Paris", label: "75 - Paris - Paris" },

  { value: "76 - Seine-maritime - Rouen", label: "76 - Seine-maritime - Rouen" },

  { value: "77 - Seine-et-marne - Melun", label: "77 - Seine-et-marne - Melun" },

  { value: "78 - Yvelines - Versailles", label: "78 - Yvelines - Versailles" },

  { value: "79 - Deux-sèvres - Niort", label: "78 - Yvelines - Versailles" },

  { value: "80 - Somme - Amiens", label: "80 - Somme - Amiens" },

  { value: "81 - Tarn - Albi", label: "81 - Tarn - Albi" },

  { value: "82 - Tarn-et-Garonne - Montauban", label: "82 - Tarn-et-Garonne - Montauban" },

  { value: "83 - Var - Toulon", label: "83 - Var - Toulon" },

  { value: "84 - Vaucluse - Avignon", label: "84 - Vaucluse - Avignon" },

  { value: "85 - Vendée - La roche-sur-yon", label: "85 - Vendée - La roche-sur-yon" },

  { value: "86 - Vienne - Poitiers", label: "86 - Vienne - Poitiers" },

  { value: "87 - Haute-vienne - Limoges", label: "87 - Haute-vienne - Limoges" },

  { value: "88 - Vosges - Épinal", label: "88 - Vosges - Épinal" },

  { value: "89 - Yonne - Auxerre", label: "89 - Yonne - Auxerre" },

  { value: "90 - Territoire de belfort - Belfort", label: "90 - Territoire de belfort - Belfort" },

  { value: "91 - Essonne - Évry", label: "91 - Essonne - Évry" },

  { value: "92 - Hauts-de-seine - Nanterre", label: "92 - Hauts-de-seine - Nanterre" },

  { value: "93 - Seine-Saint-Denis - Bobigny", label: "93 - Seine-Saint-Denis - Bobigny" },

  { value: "94 - Val-de-marne - Créteil", label: "94 - Val-de-marne - Créteil" },

  { value: "95 - Val-d'Oise - Cergy Pontoise", label: "95 - Val-d'Oise - Cergy Pontoise" },

  { value: "971 - Guadeloupe - Basse-terre", label: "971 - Guadeloupe - Basse-terre" },

  { value: "972 - Martinique - Fort-de-france", label: "972 - Martinique - Fort-de-france" },

  { value: "973 - Guyane - Cayenne", label: "973 - Guyane - Cayenne" },

  { value: "974 - La réunion - Saint-denis", label: "974 - La réunion - Saint-denis" },

  { value: "976 - Mayotte - Mamoudzou", label: "976 - Mayotte - Mamoudzou" },


  
];

const options_recruiters = [
  { value: "Emmanuelle Laisné", label: "0" },
  { value: "Emmanuelle Laisné 1", label: "1" },
  { value: "Emmanuelle Laisné 2", label: "2" },
  { value: "Emmanuelle Laisné 3", label: "3" },
  { value: "Emmanuelle Laisné 4", label: "4" },
  { value: "Emmanuelle Laisné 5", label: "5" },
  { value: "Emmanuelle Laisné 6", label: "6" },
  { value: "Emmanuelle Laisné 7", label: "7" },
  { value: "Emmanuelle Laisné 8", label: "8" },
  { value: "Emmanuelle Laisné 9", label: "9" },
];

const options_recruiters_array = [
  "Emmanuelle L.",
  "Laurence F.",
  "Aubrette L.",
  "Ruby Ruel",
  "Michèle Giguère",
  "Inès De La Vergne",
  "Amabella Chatigny",
  "France Faubert",
  "Patience Allain",
  "Simone Bourassa",
  "Monique Lamarre",
  "Christophe Neufville",
  "Hugues Alexandre",
  "Olivier Lepage",
  "Julien Thériault",
  "Pierre Lagacé",
  "Roland Huppé",
  "Marc Dufour",
  "David Baron",
  "Vincent Dodier",
  "Éric Houde",
  "Élodie Desruisseaux",
  "Aya Sirois",
  "Céline Quiron",
  "Gabrielle Pouliotte",
  "Camille Bilodeau",
  "Claude Brousseau",
  "Christelle Turcotte",
  "Marie de Chateaub",
  "Joanna Brisette",
  "Jacqueline Duplessis",
  "Christian Poisson",
  "Pascal Brochu",
  "Gauthier Jardine",
  "Adrien Poissonnier",
  "Alexis Lebel",
  "Arthur Melanson",
  "Gauthier Mouet",
  "Thomas Allain",
  "Alexandre Boutot",
  "Marcel Saindon",
];

const options_recruiters_initiales_array = [
  "Emmanuelle L.",
  "Laurence F.",
  "Aubrette L.",
  "Ruby R.",
  "Michèle G.",
  "Inès V.",
  "Amabella C.",
  "France F.",
  "Patience A.",
  "Simone B.",
  "Monique L.",
  "Christophe N.",
  "Hugues A.",
  "Olivier L.",
  "Julien T.",
  "Pierre L.",
  "Roland H.",
  "Marc D.",
  "David B.",
  "Vincent D;",
  "Éric H.",
  "Élodie D.",
  "Aya S.",
  "Céline Q.",
  "Gabrielle P.",
  "Camille B.",
  "Claude B.",
  "Christelle T.",
  "Marie de C.",
  "Joanna B.",
  "Jacqueline D.",
  "Christian P.",
  "Pascal B.",
  "Gauthier J.",
  "Adrien P.",
  "Alexis L.",
  "Arthur M.",
  "Gauthier M.",
  "Thomas A.",
  "Alexandre B.",
  "Marcel S.",
];

const options_interview_method = [
  { value: "Je veux passer l'entretien en vidéo avec la caméra", label: "Je veux passer l'entretien en vidéo avec la caméra" },
  { value: "Je veux passer l'entretien en audio (pas de caméra à activer)", label: "Je veux passer l'entretien en audio (pas de caméra à activer)" },
];

const options_jobApplicationPipeline = [
  { value: 'Sourcing', label: 'Sourcing' },
  { value: 'A relancer', label: 'A relancer' },
  { value: 'A refaire', label: 'A refaire' },
  { value: 'A qualifier', label: 'A qualifier' },
  { value: 'Avis final', label: 'Avis final' },
  { value: 'Validé', label: 'Validé' },
  { value: 'A suivre', label: 'A suivre' },
  { value: 'Rejeté', label: 'Rejeté' },
  { value: 'Offre', label: 'Offre' },
  { value: 'CDI', label: 'CDI' },
];

const options_user_Pipeline = [
  { value: 'nothing', label: 'Pas de pipeline' },
  { value: 'Sourcing', label: 'Sourcing' },
  { value: 'To_treat', label: 'A traiter' },
  { value: 'Valid', label: 'Valide' },
  { value: 'To_be_continued', label: 'A suivre' },
  { value: 'Classified', label: 'Classé' },
  { value: 'Rejected', label: 'Rejeté' },
  { value: 'Off_target', label: 'Hors cible' },
];

const optionsThink = [
  { value: "0.5", label: "0.5" },
  { value: "1", label: "1" },
];

const optionsTimes = [
  { value: "0.5", label: "0.5" },
  { value: "1", label: "1" },
  { value: "1.5", label: "1.5" },
  { value: "2", label: "2" },
  { value: "2.5", label: "2.5" },
  { value: "3", label: "3" },
];

const options_bac_obtenu = [
  { value: 'Oui', label: "Oui, J'ai déjà obtenu le diplome du BAC" },
  { value: 'Non', label: "Non, je ne l'ai pas eu, j'ai fait d'autres formations" },
];

const options_videosPermission = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];

const options_FrCandidate_videosPermission = [
  { value: 'Yes', label: "Oui, tous les recruteurs des entreprises peuvent accéder à mes vidéos" },
  { value: 'No', label: "Non, je veux choisir à qui donner l'accès à mes vidéos" },
];


const email_target_OutFrContract_JobAppManagement = [
  { value: 'outFrContract: complete the form', label: "Demande de remplissage du formulaire" },
  { value: 'outFrContract: complete the competencies', label: "Demande de remplissage des compétences" },
  { value: 'outFrContract: pass the interview', label: "Demande de passage de l'entretien" },
];

const email_target_OutFrContractUserManagement = [
  { value: 'outFrContract: complete the form', label: "Demande de remplissage du formulaire" },
  { value: 'outFrContract: complete the competencies', label: "Demande de remplissage des compétences" },
  { value: 'outFrContract: pass at least one video interview', label: "Demande de passage d'au moins un entretien vidéo" },
];

const frUserManagementNotesTypes = [
  { value: 'Search the Linkedin profile', label: "Recherche du profil sur Linkedin" },
  { value: 'Search the Linkedin profile: not founded', label: "Profil Linkedin non trouvé" },
  { value: 'Linkedin: Invitation send', label: "Profil Linkedin trouvé: Demande d'ajout envoyé" },
];

const outFrUserManagementNotesTypes = [
  { value: 'Search the Linkedin profile', label: "Recherche du profil sur Linkedin" },
  { value: 'Search the Linkedin profile: not founded', label: "Profil Linkedin non trouvé" },
  { value: 'Linkedin: Invitation send', label: "Profil Linkedin trouvé: Demande d'ajout envoyé" },
  { value: 'Linkedin: Relaunch 1', label: "Linkedin: Relance 1" },
  { value: 'Email: Relaunch 1', label: "Email: Relance 1" },
  { value: 'Whatsapp: Relaunch 1', label: "Whatsapp: Relance 1" },
  { value: 'Linkedin: Relaunch 2', label: "Linkedin: Relance 2" },
  { value: 'Email: Relaunch 2', label: "Email: Relance 2" },
  { value: 'Whatsapp: Relaunch 2', label: "Whatsapp: Relance 2" },
  { value: 'No return despite several reminders', label: "Pas de retour malgré plusieurs relances" },
];

const options_jobAppRejectionReason = [
  { value: 'Relancé sans retour', label: 'Relancé sans retour' },
  { value: 'Niveau de communication insuffisant', label: 'Niveau de communication insuffisant' },
  { value: 'Trop junior', label: 'Trop junior' },
  { value: "Expérience signficative pas suffisante", label: "Expérience signficative pas suffisante" },
  { value: 'Il ne parle pas le français', label: 'Il ne parle pas le français' },
  { value: 'Niveau technique pas suffisant', label: 'Niveau technique pas suffisant' },
  { value: 'Il est très faible techniquement', label: 'Il est très faible techniquement' },
  { value: 'Niveau de présentabilité pas suffisant', label: 'Niveau de présentabilité pas suffisant' },
  { value: 'Il est dans un domaine qui ne nous intéressé pas en ce moment', label: 'Il est dans un domaine qui ne nous intéressé pas en ce moment' },
  { value: 'Il a signé avec une autre boîte', label: 'Il a signé avec une autre boîte' },
  { value: 'Hors grille', label: 'Hors grille' },
  { value: 'Age', label: 'Age' },
  { value: 'Il ne veut pas travailler dans les domaine financier', label: 'Il ne veut pas travailler dans les domaine financier' },
  { value: 'Pas technique', label: 'Pas technique' },
  { value: 'Mobilité limitée', label: 'Mobilité limitée' },
  { value: 'Plusieurs motifs de rejet', label: 'Plusieurs motifs de rejet' },
  { value: 'Test vidéo pas de son', label: 'Test vidéo pas de son' },
  { value: 'Il a répondu à quelques questions en vidéo', label: 'Il a répondu à quelques questions en vidéo' },
  { value: 'Autre', label: 'Autre' },

  
];
const options_jobApp_Status = [
  { value: 'Archived', label: 'Archivé' },
  { value: 'Without status', label: 'Pas de status' },
  { value: 'Not archived', label: 'Pas archivé' },
];

const options_include_in_score_caldulation = [
  { value: true, label: 'Inclure dans le calcul du score' },
  { value: false, label: 'Ne pas inclure dans le calcul du score' },
];

const AcceptanceCriteriaOptions = [
  { value: true, label: "Critère accepté" },
  { value: false, label: "Critère non accepté" }
]

module.exports = {
options_Pays,
options_profile_categories,
options_certification_level,
options_actual_contract,
options_mobility,
options_BAF,
options_gender,
options_CCNP,
options_CCNA_NetSec,
options_Cisco_NetSec,
options_Competency_degree,
options_profile_change_SR,
options_profile_technical_or_not,
options_family_Situation,
options_job_visibility,
options_job_contract,
options_france_departments,
options_recruiters,
options_recruiters_array,
options_interview_method,
options_recruiters_initiales_array,
options_job_contract_V2,
options_jobApplicationPipeline,
optionsThink,
optionsTimes,
options_bac_obtenu,
options_user_Pipeline,
email_target_OutFrContract_JobAppManagement,
email_target_OutFrContractUserManagement,
frUserManagementNotesTypes,
outFrUserManagementNotesTypes,
options_jobAppRejectionReason,
options_jobApp_Status,
options_videosPermission,
options_include_in_score_caldulation,
options_FrCandidate_videosPermission,
LevelCompetenciesoptions,
AcceptanceCriteriaOptions,
OfferTypesOptions,
ApplicationMethodOptions
};