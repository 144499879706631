import { XIcon } from '@heroicons/react/outline';
import React, { useRef, useState } from 'react'
import { Badge, Button, Modal, ModalBody } from 'reactstrap';
import Popup from "reactjs-popup";
import {
    ClockIcon,
    ChatAltIcon,
    PencilAltIcon,
    DocumentDuplicateIcon
} from "@heroicons/react/solid";
import { useTranslation } from 'react-i18next';
import { optionsTimes } from 'options';
import Select from 'react-select';
import axios from 'axios';
import config from 'config';
import EditJobQuestion from './editJobQuestion';
import { useDrag, useDrop } from 'react-dnd';
import { CreateQuestion } from 'network/ApiAxios';


const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});

const OneQuestionJob = ({ question, jobId, callback, index, moveQuestion, questionList, setQuestionList }) => {
    const { t, i18n } = useTranslation();

    const [newQuestion, setNewQuestion] = useState("");
    const [timeLimit, setTimeLimit] = useState({ value: "1", label: "1" });
    const [thinkTime, setThinkTime] = useState({ value: "1", label: "1" });
    const [isThink, setIsThink] = useState(false);
    const [isTime, setIsTime] = useState(false);
    const [isNewQuestion, setIsNewQuestion] = useState(false);
    const [questionIdToModify, setQuestionIdToModify] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    /* const [, ref] = useDrag({
        type: 'QUESTION',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'QUESTION',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveQuestion(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    }); */

    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "QUESTION",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveQuestion(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "QUESTION",
        item: () => {
            return { index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));


    const updateQuestion = (qs) => {
        var time = timeLimit.value;
        //var take = takes.value;
        var take = 1;
        var thinkT = thinkTime.value;
        var question = newQuestion;
        instance.put(`jobs/question/${qs._id}/${jobId}`, {
            time,
            take,
            thinkT,
            question,
        });
        window.location.reload();
    };

    const updateQuestions = (id) => {
        var time = timeLimit.value;
        //var take = takes.value;
        var take = 1;
        var thinkT = thinkTime.value;
        instance.post(`jobs/questions/${id}`, {
            time,
            take,
            thinkT,
        });
        window.location.reload();
    };

    const removeQuestion = (qs) => {
        instance
            .delete(`jobs/${qs._id}/${jobId}/question`, {
                title: qs.question,
            })
            .then((res) => res.statusText.toLowerCase() === "ok" && callback())
            .catch((err) => console.log(err));
    };

    const removeCodingQuestion = (qs) => {
        instance
            .delete(`jobs/${qs._id}/${jobId}/question`, {
                title: qs.question,
            })
            .then((res) => res.statusText.toLowerCase() === "ok" && callback())
            .catch((err) => console.log(err));
    };

    const DuplicateQuestion = async(qs) => {
        console.log('questionList', questionList)
        //save question and append it to the job
        const formData = new FormData();

        formData.append("timeLimit", question?.timeLimit);
        formData.append("thinkTime", question?.thinkTime);

        formData.append("type", question?.type);
        formData.append("question", question?.question);
        question.questionStatementImage && formData.append("questionStatementImage", question.questionStatementImage);

        if (question?.type === "QCM with only one correct answer") {
            formData.append("QCMOption1", question?.QCMOption1);
            formData.append("QCMOption2", question?.QCMOption2);
            formData.append("QCMOption3", question?.QCMOption3);
            formData.append("QCMOption4", question?.QCMOption4);
            formData.append("QCMOption5", question?.QCMOption5);
            formData.append("QCMOption6", question?.QCMOption6);
            formData.append("QCMCorrectOption", question?.QCMCorrectOption);
            formData.append("included_in_the_score_calculation", question?.included_in_the_score_calculation);

        }
        setQuestionList([...questionList, qs])
        await CreateQuestion(jobId, formData)
        callback()

    }

    const style = {
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        backgroundColor: 'white',
        cursor: 'move',
    };

    return (
        <>
            <div
                //style={style}
                ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}
                //ref={(node) => ref(drop(node))}
                key={question?._id}
                className="flex items-center py-2 border-t border-b first:border-t-0 last:border-b-0 justify-between text-black mb-1"
            >
                <li>{question?.question}: {(question?.type) && (question.type) && <Badge
                    className='p-3 m-1'
                    color="dark"
                    pill
                >
                    {question?.type}
                </Badge>}
                {question?.isFollowUpQuestion === true && <Badge
                        className='p-3 m-1'
                        color="primary"
                        pill
                    >
                        Suivi
                    </Badge>}
                </li>
                <div className="flex items-center space-x-2 text-gray-500">
                    <Button
                        style={{
                            backgroundColor: "#404954",
                            borderColor: "#404954",
                            color: "white",
                        }}
                        className=""
                        color=""
                        type="button"
                        size="sm"
                        onClick={() => {
                            setQuestionIdToModify(question._id);
                            setModalOpen(!modalOpen);
                        }}
                    >
                        {t('OfferDesKey4')}
                    </Button>
                    <Popup
                        trigger={
                            <button style={{ display: "inline-flex" }}>
                                {" "}
                                {question?.timeLimit && (
                                    <h6>
                                        {question?.timeLimit !== "Unlimited"
                                            ? `${question?.timeLimit}`
                                            : `∞`}
                                    </h6>
                                )}
                                <ClockIcon className="h-6 cursor-pointer" />
                                {question?.thinkTime && (
                                    <h6>
                                        {question?.thinkTime !== "Unlimited"
                                            ? `${question?.thinkTime}`
                                            : `∞`}
                                    </h6>
                                )}
                                <ChatAltIcon className="h-6 cursor-pointer" />
                            </button>
                        }
                        position="right center"
                    >
                        <div>
                            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                                <ClockIcon className="h-6" />
                                <h4>Time Limit </h4>
                            </div>
                            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                                <div style={{ width: "100px" }}>
                                    <Select
                                        options={optionsTimes}
                                        defaultValue={{
                                            label: question?.timeLimit,
                                            value: question?.timeLimit,
                                        }}
                                        onChange={(e) => {
                                            setTimeLimit(e);
                                            setIsTime(true);
                                            if (!isThink) {
                                                setThinkTime({
                                                    value: question?.thinkTime,
                                                    label: question?.thinkTime,
                                                });
                                            }

                                            if (!isNewQuestion) {
                                                setNewQuestion(question?.question);
                                            }
                                        }}
                                    />
                                </div>
                                <h5>minutes</h5>
                            </div>


                            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                                <button
                                    className="text-blue"
                                    onClick={updateQuestion.bind(this, question)}
                                >
                                    Apply
                                </button>
                                <button
                                    className="text-blue"
                                    style={{ marginLeft: "100px" }}
                                    onClick={updateQuestions.bind(this, jobId)}
                                >
                                    Apply to all
                                </button>
                            </div>
                        </div>
                    </Popup>
                    <Popup
                        trigger={
                            <button style={{ display: "inline-flex" }}>
                                {" "}
                                <PencilAltIcon className="h-6 cursor-pointer" />
                            </button>
                        }
                        position="right center"
                    >
                        <div className="flex items-center space-x-2">
                            <textarea
                                type="text"
                                className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm"
                                onChange={(e) => {
                                    setNewQuestion(e.target.value);
                                    setIsNewQuestion(true);
                                    if (!isTime) {
                                        setTimeLimit({
                                            value: question?.timeLimit,
                                            label: question?.timeLimit,
                                        });
                                    }
                                    if (!isThink) {
                                        setThinkTime({
                                            value: question?.thinkTime,
                                            label: question?.thinkTime,
                                        });
                                    }
                                }}
                                placeholder="Type your question here"
                                defaultValue={question?.question}
                            />
                        </div>
                        <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
                            <button
                                className="text-blue"
                                onClick={updateQuestion.bind(this, question)}
                            >
                                Apply
                            </button>
                        </div>
                    </Popup>

                    {/* <LocationMarkerIcon className="h-6 cursor-pointer" /> */}
                    <XIcon
                        className="h-6 cursor-pointer text-red-600"
                        onClick={removeQuestion.bind(this, question)}
                    />
                    <DocumentDuplicateIcon onClick={() => DuplicateQuestion(question)} className="h-6 cursor-pointer" />
                </div>

                {/*question?.type == "Code" && <>
                    <XIcon
                        className="h-6 cursor-pointer text-red-600"
                        onClick={removeCodingQuestion.bind(this, question)}
                    />
                </>*/}
            </div>
            <Modal
                toggle={() => setModalOpen(!modalOpen)}
                isOpen={modalOpen}
                size="lg">
                <ModalBody>
                    <EditJobQuestion
                        questionID={questionIdToModify}
                        jobID={jobId}
                    />
                </ModalBody>

            </Modal>
        </>
    )
}

export default OneQuestionJob