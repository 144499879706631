
import React from 'react'
import TrainingLists from 'components/candidat/TrainingLists';
import CandidateLayoutV3 from 'layouts/CandidateLayoutV3';

const TrainingCandidates = ({match}) => {
    
    const {type} = match.params;
    return (
        <>
            <CandidateLayoutV3 />
            <TrainingLists type={type} />

        </>
    )
}

export default TrainingCandidates