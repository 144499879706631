import JobCardsInFranceCandidate from "components/candidat/jobCardsInFranceCandidate";
import React from "react";

import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import JobCardsInFranceCandidateV1 from "components/candidat/jobCardsInFranceCandidateV1";

function CandidateJobOffersView() {
  return (
    <>
      <CandidateLayoutV3 />
      <JobCardsInFranceCandidateV1 />
      
     
    </>
  );
}

export default CandidateJobOffersView;
