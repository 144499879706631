import axios from "axios";
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";


import CandidateLayoutV3 from "layouts/CandidateLayoutV3";

import Select from "react-select";

import {
  Row,
  Col,
  Button,
  Form,
  Input,
} from "reactstrap";
import { Container, Box, Grid, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
//import { DropzoneArea } from 'material-ui-dropzone';
//import {useDropzone} from 'react-dropzone';

import React, { useEffect, useState } from "react";
import config from "config";
import { options_Pays, options_profile_categories, options_certification_level, options_actual_contract, options_mobility, options_BAF, options_gender, options_profile_technical_or_not, options_family_Situation, options_bac_obtenu, options_videosPermission } from 'options';
import 'react-phone-number-input/style.css';
import OutFrTerminateYourProfileBeforeV2 from "components/outFrCandidatSearchCdiFr/outFrTerminateYourProfileBeforeV2";





const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

const options_niveau_diplome = [
  { value: 'BAC', label: 'BAC' },
  { value: 'BTS', label: 'BTS' },
  { value: 'License', label: 'License' },
  { value: 'Maitrise', label: 'Maitrise' },
  { value: 'Master juste la première année', label: 'Master juste la première année' },
  { value: 'Master deuxième année', label: 'Master deuxième année' },
  { value: 'BAC+5 (Ingénierie)', label: 'BAC+5 (Ingénierie)' },
  { value: 'Doctorat', label: 'Doctorat' },
  { value: 'Autre', label: 'Autre' }
];


function EtrangerCDIenFranceCreateProfileV2() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");
  const [formValidation, setFormValidation] = useState(false);
  

  const [alreadyPosted, setAlreadyPosted] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [familySituation, setFamilySituation] = useState("");
  const [country, setCountry] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [diploma, setDiploma] = useState("");
  const [bacObtained, setBacObtained] = useState("");
  const [expertiseField, setExpertiseField] = useState("");
  const [technicalProfileOrNot, setTechnicalProfileOrNot] = useState("");

  
  const [profileType, setProfileType] = useState("");
  const [desiredProject, setDesiredProject] = useState("");
  const [experience, setExperience] = useState("");
  const [permanentContractExperience, setPermanentContractExperience] = useState("");
  
  const [topTechnologies, setTopTechnologies] = useState("");
  const [certifications, setCertifications] = useState("");
  const [certificationLevel, setCertificationLevel] = useState("");
  const [actualContract, setActualContract] = useState("");
  const [mobility, setMobility] = useState("");
  const [baf, setBAF] = useState("");

  

  const [title, setTitle] = useState("");
  const [village, setVillage] = useState("");
  const [CV, setCV] = useState("");
  const [university, setUniversity] = useState("");
  const [NiveauDiplome, setNiveauDiplome] = useState("");
  const [outFrVideosPermission, setOutFrVideosPermission] = useState("");

  
  

  // const handleFormSubmit = async values => {
  const handleFormSubmit = () => {
    //console.log(values);
    let isValid = true;
    setError("");


    if (!firstname) {
      isValid = false;
      setError("Problème avec le champ: Prénom. Impossible de procéder avec ce champ vide. ");
    };
    if (!lastname) {
      isValid = false;
      setError("Problème avec le champ: Nom. Impossible de procéder avec ce champ vide. ");
    };
    
    if (!email) {
      isValid = false;
     setError("Impossible de procéder avec un Email vide ");

      if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
           lastDotPos > 2 &&
           email.length - lastDotPos > 2
          )
        ) {
          isValid = false;
          setError("L'Email n'est pas valide");
        }
      }

      // console.log(error);
     
    };
    if (!gender) {
      isValid = false;
      setError("Problème avec le champ: Genre. Impossible de procéder avec ce champ vide. ");
    };
    if (!familySituation) {
      isValid = false;
      setError("Problème avec le champ: Situation familiale. Impossible de procéder avec ce champ vide.");
    };
    if (!country) {
      isValid = false;
      setError("Problème avec le champ: Pays. Impossible de procéder avec ce champ vide.");
    };
    if (!age) {
      isValid = false;
      setError("Problème avec le champ: Age. Impossible de procéder avec ce champ vide.");
    };
    if (!phonenumber) {
      isValid = false;
      setError("Problème avec le champ: Numéro de téléphone. Impossible de procéder avec ce champ vide.");
    };
    if (!whatsappnumber) {
      isValid = false;
      setError("Problème avec le champ: Whatsapp. Impossible de procéder avec ce champ vide.");
    };
    if (!linkedin) {
      isValid = false;
      setError("Problème avec le champ: Linkedin. Impossible de procéder avec ce champ vide.");
    };
    if (!diploma) {
      isValid = false;
      setError("Problème avec le champ: Diplome. Impossible de procéder avec ce champ vide.");
    };
    if (!bacObtained) {
      isValid = false;
      setError("Problème avec le champ: BAC obtenu ou pas?. Impossible de procéder avec ce champ vide.");
    };
    
    if (!NiveauDiplome) {
      isValid = false;
      setError("Problème avec le champ: Niveau du Diplome. Impossible de procéder avec ce champ vide.");
    };
    
    if (!university) {
      isValid = false;
      setError("Problème avec le champ: Université/Ecole. Impossible de procéder avec ce champ vide.");
    };
    
    if (!expertiseField) {
      isValid = false;
      setError("Problème avec le champ: Damine d'expertise. Impossible de procéder avec ce champ vide.");
    };
    
    if (!profileType) {
      isValid = false;
      setError("Problème avec le champ: Type de profil. Impossible de procéder avec ce champ vide.");
    };
    
    if (!technicalProfileOrNot) {
      isValid = false;
      setError("Problème avec le champ: Catégorie de Profil. Impossible de procéder avec ce champ vide.");
    };
    
    if (!desiredProject) {
      isValid = false;
      setError("Problème avec le champ: Projet désiré. Impossible de procéder avec ce champ vide.");
    };
    
    if (!experience) {
      isValid = false;
      setError("Problème avec le champ: Expérience. Impossible de procéder avec ce champ vide.");
    };
    
    if (!permanentContractExperience) {
      isValid = false;
      setError("Problème avec le champ: Expérience en CDI. Impossible de procéder avec ce champ vide.");
    };
    
    if (!topTechnologies) {
      isValid = false;
      setError("Problème avec le champ: Top technologies. Impossible de procéder avec ce champ vide.");
    };
    
    if (!certifications) {
      isValid = false;
      setError("Problème avec le champ: Certifications. Impossible de procéder avec ce champ vide.");
    };
    
    if (!certificationLevel) {
      isValid = false;
      setError("Problème avec le champ: Niveau de Certification. Impossible de procéder avec ce champ vide.");
    };

    if (!actualContract) {
      isValid = false;
      setError("Problème avec le champ: Type de contrat actuel. Impossible de procéder avec ce champ vide.");
    };
    if (!mobility) {
      isValid = false;
      setError("Problème avec le champ: Mobilité. Impossible de procéder avec ce champ vide.");
    };
    
    if (!baf) {
      isValid = false;
      setError("Problème avec le champ: Banque, Assurances et Finances. Impossible de procéder avec ce champ vide.");
    };
    if (!title) {
      isValid = false;
      setError("Problème avec le champ: Titre. Impossible de procéder avec ce champ vide.");
    };
    if (!village) {
      isValid = false;
      setError("Problème avec le champ: Ville. Impossible de procéder avec ce champ vide.");
    };
    if (!outFrVideosPermission) {
      isValid = false;
      setError("Problème avec le champ: Vidéos: Permission. Impossible de procéder avec ce champ vide.");
    };
    
    if (!CV) {
      isValid = false;
      setError("Problème avec le champ: CV. Impossible de procéder avec ce champ vide.");
    };

    // return isValid;
    // setFormValidation(isValid);
    if (isValid === true)
    {
      const formData = new FormData();
      formData.append("user", user._id);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("gender", gender);
      formData.append("familySituation", familySituation);
  
  
      
      formData.append("age", age);
      formData.append("email", email);
      
      formData.append("whatsappnumber", whatsappnumber);
      formData.append("linkedin", linkedin);
      formData.append("diploma", diploma);
      formData.append("expertiseField", expertiseField);
      formData.append("profileType", profileType);
      formData.append("technicalProfileOrNot", technicalProfileOrNot);
  
      
      formData.append("desiredProject", desiredProject);
      formData.append("experience", experience);
      formData.append("topTechnologies", topTechnologies);
      formData.append("certifications", certifications);
      formData.append("certificationLevel", certificationLevel);
      formData.append("actualContract", actualContract);
      formData.append("mobility", mobility);
      formData.append("baf", baf);
      formData.append("title", title);
      formData.append("village", village);
      formData.append("country", country);
      formData.append("studyLevel", NiveauDiplome);
      formData.append("university", university);
      formData.append("phonenumber", phonenumber);
      formData.append("bacObtained", bacObtained);
      formData.append("permanentContractExperience", permanentContractExperience);
      
  
      formData.append("CV", CV);

      var dataForUserPreferences = JSON.stringify({
        "userID": user._id,
        "outFrVideosPermission": outFrVideosPermission
      });
      console.log("etrangerCDIenFranceCreateProfileV2: dataForUserPreferences: ", dataForUserPreferences);
      
      // console.log("formData: 7777777777777777777777777: ", formData);
      // console.log("CV 22222222222222222222222222222222: : ", CV);

      axios({
        method: "post",
        url: `${config.WS_BASE_URL}users/editUserParameters`,
        data: dataForUserPreferences,
        headers: { "Content-Type": "application/json" },
      })
    .then((respForUserPreferences) => {
        if (respForUserPreferences.data.success) {

          user.outFrVideosPermission = outFrVideosPermission;
          localStorage.setItem("user", JSON.stringify(user));
          {/*
                      user = {
              ...user,
              outFrVideosPermission
            };
        */}

            // localStorage.setItem("user", JSON.stringify(respForUserPreferences.user[0]));
            // window.location.reload();
      
        }
    }
        )
        .catch((errForUserPreferences) => console.log(errForUserPreferences));
      
      instance.post(`outFrSearchCdiFrProfiles/createprofilewithcv`, formData).then((resp) => {

        history.push("/outfrapplypermanentfr/contract/home");
        // console.log("8888888888888: formData: ", formData);
        // history.push('outfrapplypermanentfr/home');
        // history.push("google.fr")
      });




    };
  };

  
  // const createProfileWithCV = async () => {
  const createProfileWithCV = () => {
    // console.log("we are heree function createProfileWithCV");
    handleFormSubmit();
    // setFormValidation(handleFormSubmit);
    if (formValidation === true)
    {
      const formData = new FormData();
      formData.append("user", user._id);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("gender", gender);
      formData.append("familySituation", familySituation);
  
  
      
      formData.append("age", age);
      formData.append("email", email);
      
      formData.append("whatsappnumber", whatsappnumber);
      formData.append("linkedin", linkedin);
      formData.append("diploma", diploma);
      formData.append("expertiseField", expertiseField);
      formData.append("profileType", profileType);
      formData.append("technicalProfileOrNot", technicalProfileOrNot);
  
      
      formData.append("desiredProject", desiredProject);
      formData.append("experience", experience);
      formData.append("topTechnologies", topTechnologies);
      formData.append("certifications", certifications);
      formData.append("certificationLevel", certificationLevel);
      formData.append("actualContract", actualContract);
      formData.append("mobility", mobility);
      formData.append("baf", baf);
      formData.append("title", title);
      formData.append("village", village);
      formData.append("country", country);
      formData.append("studyLevel", NiveauDiplome);
      formData.append("university", university);
      formData.append("phonenumber", phonenumber);
      formData.append("bacObtained", bacObtained);
      formData.append("permanentContractExperience", permanentContractExperience);
      
  
      formData.append("CV", CV);
      
      
      // console.log("formData: 7777777777777777777777777: ", formData);
      // console.log("CV 22222222222222222222222222222222: : ", CV);
      
      instance.post(`outFrSearchCdiFrProfiles/createprofilewithcv`, formData).then((resp) => {
        history.push("/outfrapplypermanentfr/contract/home");
        // console.log("8888888888888: formData: ", formData);
        // history.push('outfrapplypermanentfr/home');
        // history.push("google.fr")
      });
    };

  };


  const checkoutSchema = yup.object().shape({
    // titre: yup.string().required("required"),
  });



  const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  useEffect(() => {
  
    fetchProfile();
  
  }, [location]);
  
  return (
    <>
      <CandidateLayoutV3 />

      <PageSpace />
      <PageTitleStyle
        title="Projet: Je suis hors de la France et je cherche un CDI en France"
      >
        
      </PageTitleStyle>
      

      <Container>
      {alreadyPosted &&
        <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <>
              <label className="px-lg-3">Votre formulaire d'inscription est déjà réalisé</label>
              <OutFrTerminateYourProfileBeforeV2 />
            </>
            
          </Col>

        </Row>
      }
        {!alreadyPosted &&
        <Row className="justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2" >
          </Col>
          <Col xs="10">
            <>
            <Form>
              <Grid container spacing={5}>
              <Grid item xs={12}>
              <label>Prénom*</label>
              <Box pt={3}>
              <TextField 
                  name="Prénom"
                  type="text"
                  // label="Prénom" 
                  // placeholder="Prénom" 
                  // className="w-full "
                  required
                  fullWidth
                  // onBlur={handleBlur} 
                  // value={firstname}
                  // onChange={handleChange} 
                  onChange={e => setFirstname(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
  
              </Box>
  
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Nom*</label>
                  <TextField 
                  name="Nom"
                  required
                  // label="Nom" 
                  // placeholder="Nom" 
                  className="w-full "
                  type="text"
                  // onBlur={handleBlur} 
                  // value={lastname}
                  // onChange={handleChange} 
                  onChange={e => setLastname(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Êtes-vous?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    name="gender"
                    required
                    // placeholder="gender" 
                    className="w-full "
                    options={options_gender}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setGender(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>

                <Grid item xs={12}>
                <label className="px-lg-3">Quelle est votre situation familiale?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    name="familySituation"
                    required
                    // placeholder="gender" 
                    className="w-full "
                    options={options_family_Situation}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setFamilySituation(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Pays*</label>
                {/* console.log("options_Pays: ", options_Pays) */}
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    name="country"
                    required
                    // placeholder="country" 
                    className="w-full"
                    
                    options={options_Pays}
                    onChange={(e) => { setCountry(e.value) }}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    // onChange={handleChanges} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Age (Merci de mettre juste des chiffres et pas de lettre)*</label>
                  <Input 
                  name="Age"
                  required
                  // label="Age" 
                  // placeholder="Age" 
                  className="w-full " 
                  type="number"
                  // onBlur={handleBlur} 
                  // value={age}
                  // onChange={handleChange} 
                  onChange={e => setAge(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
  
  
  
                <Grid item xs={12}>
                <label className="px-lg-3">E-mail*</label>
                <TextField
                  name="Email"
                  required
                  // label="Nom" 
                  // placeholder="Email" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={email}
                  // onChange={handleChange} 
                  onChange={e => setEmail(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
  
                {/* <Grid item xs={12}>
                <label className="px-lg-3">Numéro de téléphone</label>
  
                <PhoneInput
                  name="Numéro de téléphone"
                  // label="Nom" 
                  placeholder="phone" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={phonenumber}
                  // onChange={handleChange} 
                  onChange={e => setPhonenumber(e.target.value)}
  
                  //onChange={e => setPhonenumber(e.target.value)}
  
                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid> */}
  
                <Grid item xs={12}>
                <label className="px-lg-3">Numéro de téléphone*</label>
                <TextField
                  name="Numéro de téléphone"
                  required
                  // label="Nom" 
                  // placeholder="phone" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={phonenumber}
                  // onChange={handleChange} 
                  onChange={e => setPhonenumber(e.target.value)}
  
                  //onChange={e => setPhonenumber(e.target.value)}
  
                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Whatsapp*</label>
                <TextField
                  name="Whatsapp"
                  required
                  // label="Nom" 
                  // placeholder="Whatsapp" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={whatsappnumber}
                  // onChange={handleChange}
                  onChange={e => setWhatsappnumber(e.target.value)} 
  
                  //onChange={e => setPhonenumber(e.target.value)}
  
                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
  
  
                <Grid item xs={12}>
                <label className="px-lg-3">Linkedin (Mettez l'URL svp)*</label>
                  <TextField 
                  name="Linkedin"
                  required
                  // label="Nom" 
                  // placeholder="Linkedin" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={linkedin}
                  // onChange={handleChange} 
                  onChange={e => setLinkedin(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Diplôme*</label>
                  <TextField 
                    name="Diplome"
                    required
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="Diploma" 
                    className="w-full "
                    // onBlur={handleBlur} 
                    // value={diploma}
                    onChange={e => setDiploma(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
                
                <Grid item xs={12}>
                <label className="px-lg-3">Est ce que vous avez déjà obtenu le diplome du Baccalauréat?</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="Niveau du diplome" 
                    className="w-full "
                    required
                    options={options_bac_obtenu}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setBacObtained(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Niveau du diplome*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="Niveau du diplome" 
                    className="w-full "
                    required
                    options={options_niveau_diplome}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setNiveauDiplome(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Quel est votre Ecole/Université/Institut?*</label>
                  <TextField name="university" 
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="university" 
                    className="w-full "
                    required
                    // onBlur={handleBlur} 
                    // value={university}
                    onChange={e => setUniversity(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Quel est votre domaine d'expertise? exemple: Réseau, Réseau et sécurité, Base de données, Système Linux, Système windows, Sharepoint, SAP, Autre.*</label>
                  <TextField 
                    name="expertiseField" 
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="expertiseField" 
                    className="w-full "
                    required
                    // onBlur={handleBlur} 
                    // value={expertiseField}
                    onChange={e => setExpertiseField(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Quel est le profil qui vous corresponde le mieux?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="profile_categories" 
                    className="w-full "
                    required
                    options={options_profile_categories}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setProfileType(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>




                <Grid item xs={12}>
                <label className="px-lg-3">Actuellement, vous faites partie de quelle catégorie de profil?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="profile_categories" 
                    className="w-full "
                    required
                    options={options_profile_technical_or_not}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setTechnicalProfileOrNot(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>


  
                <Grid item xs={12}>
                <label className="px-lg-3">Quel est le type de projet recherché?*</label>
                  <TextField 
                    name="Type de projet" 
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="Type de projet" 
                    className="w-full "
                    required
                    // onBlur={handleBlur} 
                    // value={desiredProject}
                    onChange={e => setDesiredProject(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
  
                <Grid item xs={12}>
                <label>Nombre d'année d'expérience?*</label>
                  <TextField 
                    
                    // label="Quel est votre Ecole/Université/Institut?" 
                    
                    type="text"
                    required
                    // placeholder="experience" 
                    className="w-full " 
                    name="experience"
                    // onBlur={handleBlur} 
                    //value={experience}
                    // value={state.experience}
  
                    // onChange={handleChanges}
  
                    onChange={e => setExperience(e.target.value)}
                    // autoFocus="autofocus"
                    // onChange={e => {
                      // this.cursor = e.target.selectionStart
                      // setExperience(e.target.value)
                  // }
                // }
  
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                    
                </Grid>
                <Grid item xs={12}>
                <label>Durant combien d'année vous étiez en CDI?*</label>
                  <TextField 
                    
                    // label="Quel est votre Ecole/Université/Institut?" 
                    
                    type="text"
                    // placeholder="experience" 
                    className="w-full " 
                    name="experience_CDI"
                    required
                    // onBlur={handleBlur} 
                    //value={experience}
                    // value={state.experience}
  
                    // onChange={handleChanges}
  
                    onChange={e => setPermanentContractExperience(e.target.value)}
                    // autoFocus="autofocus"
                    // onChange={e => {
                      // this.cursor = e.target.selectionStart
                      // setExperience(e.target.value)
                  // }
                // }
  
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                    
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Quelles sont les 3 technologies que vous maîtrisez le plus (Citez moi le top 3 des technologies)?*</label>
                  <TextField 
                    name="topTechnologies"
                    required
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="topTechnologies" 
                    className="w-full " 
                    type="text"
                    // onBlur={handleBlur} 
                    // value={topTechnologies}
                    // onChange={handleChanges}
                    onChange={e => setTopTechnologies(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Quelles sont les certifications que vous avez obtenues?*</label>
                  <TextField 
                    name="certifications"
                    required
                    // label="Quel est votre Ecole/Université/Institut?" 
                    // placeholder="certifications" 
                    className="w-full " 
                    // onBlur={handleBlur} 
                    // value={certifications}
                    onChange={e => setCertifications(e.target.value)}
                    // error={!!touched.university && !!errors.university} 
                    // helperText={touched.university && errors.university} 
                    />
                </Grid>
                <Grid item xs={12}>
                  <label className="px-lg-3">A votre avis, quel est le niveau de vos certifications?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="certification_level" 
                    className="w-full "
                    required
                    options={options_certification_level}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setCertificationLevel(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Actuellement, avez-vous quel type de contrat?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="actual_contract" 
                    className="w-full "
                    required
                    options={options_actual_contract}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setActualContract(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Quelle est votre mobilité?*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="mobility" 
                    className="w-full "
                    required
                    options={options_mobility}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setMobility(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Refusez vous de travailler dans le domaine des BAFs (Banques, Assurances, Finances)*?</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    // placeholder="BAF" 
                    className="w-full "
                    required
                    options={options_BAF}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    // value={baf}
                    onChange={(e) => { setBAF(e.value) }}
                    // onChange={handleChanges} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Titre de votre profil*</label>
                  <TextField 
                  name="titre"
                  required
                  // label="Titre de votre profil" 
                  // placeholder="Titre" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={title}
                  // onChange={handleChange} 
                  onChange={e => setTitle(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Indiquez la ville où vous habitez actuellement*</label>
                  <TextField 
                    name="Village" 
                    // label="Indiquez la ville où vous habitez actuellement" 
                    // placeholder="Village" 
                    className="w-full "
                    required
                    // onBlur={handleBlur} 
                    // value={village}
                    onChange={e => setVillage(e.target.value)}
                    //onChange={handleChange} 
                    // value={values.ville || ""} 
                    // error={!!touched.ville && !!errors.ville} 
                    // helperText={touched.ville && errors.ville} 
                  />
                </Grid>
                <Grid item xs={12}>
                <label className="px-lg-3">Pour que votre candidature soit prise en compte, vous devez passer un entretien vidéo, est ce que vous acceptez que les entreprises en France accèdent à ces vidéos*</label>
                  <Select 
                    //name="Niveau du diplome" 
                    //label="Niveau du diplome" 
                    name="videosPermission"
                    required
                    // placeholder="gender" 
                    className="w-full "
                    options={options_videosPermission}
                    //fullWidth 
                    //onBlur={handleBlur} 
                    // onChange={handleChange} 
                    //value={NiveauDiplome}
                    onChange={(e) => { setOutFrVideosPermission(e.value) }} 
                    // value={values.NiveauDiplome || ""} 
                    //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                    //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                  />
                </Grid>
  
                <Grid item xs={12}>
                <label className="px-lg-3">Votre CV (version française et format PDF)*</label>
                  <Input
                    className="form-control-alternative"
                    required
                    // placeholder="CV" 
                    type="file"
                    accept=".pdf"
                    onChange={(e) =>
                    setCV(e.target.files[0])
                    }
                    // CVinput
                  />
                </Grid>
                {error ? (
                    <div className="text-muted font-italic">
                    <small>
                        error:{" "}
                        <span className="text-red font-weight-700">{error}</span>
                    </small>
                    </div>
                ) : null}
                <Grid item xs={12}>
                  <Button 
                  style={{whiteSpace:'normal'}}
                  variant="contained" 
                  color="primary" 
                  // type="submit" 
                  sx={{ mt: "25px" }}
                  onClick={() => { 
                    // console.log("bouton cliqué 0000000000000000000000 ")
                    handleFormSubmit();
                    // createProfileWithCV()
                    
                  }}
                  >
                    Sauvegarder votre fiche d'inscription
                  </Button>
                </Grid>
              </Grid>


           </Form>
            </>
            
          </Col>

        </Row>
      }
      </Container>
        
    </>
  );
}

export default EtrangerCDIenFranceCreateProfileV2;
