import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import InterviewForm from "./InterviewForm";
// import InterviewFormV2 from "components/InterviewFormV2.js";
// import InterviewFormV3 from "components/interview/InterviewFormV3";
import InterviewFormV5 from "components/interview/InterviewFormV5";
// import InterviewFormInFranceCandidateV4 from "components/interview/InterviewFormInFranceCandidateV4";
import { Button } from "reactstrap";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function Interview({ match }) {
  let user = JSON.parse(localStorage.getItem("user"));
  const [interview, setInterview] = useState({});
  useEffect(() => {
    instance
      .get(`jobs/${match.params.id}/interview`)
      .then((data) => {
        setInterview(data.data.interview);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {JSON.parse(localStorage.getItem("user")) && user.location !== "France" && (
        <>
         
            <>
              <div className="container bg-gradient-info pb-0 pt-5 pt-md-2 flex flex-col items-center">
                <h2 className="text-center text-white">{`${interview?.job?.title} Interview`}</h2>
              </div>

                <InterviewFormV5
                  interview={interview}
                  interviewId={match.params.id}
                />
            </>
          
        </>
      )}
      {JSON.parse(localStorage.getItem("user")) && user.location === "France" && (
        <>
         
            <>
              <div className="container bg-gradient-info pb-0 pt-5 pt-md-2 flex flex-col items-center">
                <h2 className="text-center text-white">{`${interview?.job?.title} Interview`}</h2>
              </div>
              {/*
                <InterviewFormV2
                  interview={interview}
                  interviewId={match.params.id}
                />
              */}
              {/*
                <InterviewFormV3
                  interview={interview}
                  interviewId={match.params.id}
              />
            */}
            {/*
                <InterviewFormInFranceCandidateV4
                  interview={interview}
                  interviewId={match.params.id}
              />
            */}
                <InterviewFormV5
                  interview={interview}
                  interviewId={match.params.id}
                />
            </>
          
        </>
      )}
      {!JSON.parse(localStorage.getItem("user")) && (
        <>
          <div className="container bg-gradient-info pb-0 pt-5 pt-md-2 flex flex-col items-center">
            
          </div>
          <h2 className="text-center text-danger">
              Vous devez être connecté pour pouvoir passer cet entretien !!
            </h2>
          <Row className="mt-9">
          <Col xl="4"></Col>
          <Col xl="4">
          <Button
            style={{
              backgroundColor: "#404954",
              color: "white",
              width: "100%",
            }}
            color="text-white"
            to="/home/login"
            tag={Link}
          >
             Connectez-vous!
          </Button>
          </Col>
          <Col xl="4"></Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Interview;
