import React, { useEffect, useState } from "react";
import axios from "axios";
import { optionsThink, optionsTimes, options_include_in_score_caldulation } from "options";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  ChatAltIcon,
  PencilAltIcon,
} from "@heroicons/react/solid";
import { ClipLoader } from "react-spinners";

// reactstrap components
import {
  Container,
  Label,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";
import config from "config";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { options_videosPermission } from "options";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function AddJobQuestion(props) {
  const { t, i18n } = useTranslation();


  const [question, setQuestion] = useState("");
  const [questionStatementImage, setQuestionStatementImage] = useState("");
  const [selectQuestionType, setSelectQuestionType] = useState(null);
  const [liveQuestion, setLiveQuestion] = useState(null);

  const [newQuestionType, setNewQuestionType] = useState("");
  const [includeToScoreCalculation, setIncludeToScoreCalculation] = useState("");
  const [newQuestionQCMDisplay, setNewQuestionQCMDisplay] = useState(false);
  const [newQuestionQCMOption1, setNewQuestionQCMDOption1] = useState("");
  const [newQuestionQCMOption2, setNewQuestionQCMDOption2] = useState("");
  const [newQuestionQCMOption3, setNewQuestionQCMDOption3] = useState("");
  const [newQuestionQCMOption4, setNewQuestionQCMDOption4] = useState("");
  const [newQuestionQCMOption5, setNewQuestionQCMDOption5] = useState("");
  const [newQuestionQCMOption6, setNewQuestionQCMDOption6] = useState("");
  const [newQuestionQCMCorrectAnswer, setNewQuestionQCMCorrectAnswer] = useState("");
  const [timeLimit, setTimeLimit] = useState({ value: 0, label: "1" });
  // const [takes, setTakes] = useState({ value: "1", label: "1" });
  const [thinkTime, setThinkTime] = useState({ value: 0, label: "1" });
  const [isThink, setIsThink] = useState(false);
  const [isTime, setIsTime] = useState(false);
  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [waitingForAddQuestionRequest, setWaitingForAddQuestionRequest] = useState(false);
  const [error, setError] = useState(null);
  //const [isFollowUpQuestion, setIsFollowUpQuestion] = useState(null);

  const Addvalidation = () => {
    let isValid = true;
    if (!question) {
      setError("impossible de procéder avec un énoncé vide")
      isValid = false;
      return isValid
    }

    if (!newQuestionType) {
      setError("impossible de procéder avec un type de question vide")
      isValid = false;
      return isValid
    }

    if (!(timeLimit.value)) {
      setError("impossible de procéder avec la durée vide")
      isValid = false;
      return isValid
    }

    if (!(thinkTime.value)) {
      setError("impossible de procéder avec temps de réflexion vide")
      isValid = false;
      return isValid
    }
    return isValid
  }

  const addQuestion = (e) => {
    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: ");
    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: question: ", question);
    // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: newQuestionType: ", newQuestionType);
    if (question && newQuestionType === "Video") {

      const formDataVideo = new FormData();
      formDataVideo.append("timeLimit", timeLimit.value);
      formDataVideo.append("thinkTime", thinkTime.value);

      formDataVideo.append("type", newQuestionType);
      formDataVideo.append("question", question);
      questionStatementImage && formDataVideo.append("file1", questionStatementImage);
      liveQuestion && formDataVideo.append("file2", liveQuestion);
      /* if(isFollowUpQuestion === true || isFollowUpQuestion === false){
        formDataVideo.append("isFollowUpQuestion", isFollowUpQuestion);
      } */

      axios({
        method: "post",
        url: `${config.WS_BASE_URL}jobs/createQuestionV3/${props.jobID}/question/v3`,
        data: formDataVideo,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {

          //window.location.reload();
          setWaitingForAddQuestionRequest(false);
          props.fct()
          props.toggle()
          // res.statusText.toLowerCase() === "ok" && fetchData();
          setQuestion("");
        })
        .catch((err) => console.log(err));
    }
    if (question && newQuestionType === "Short Answer") {
      var type = newQuestionType;
      instance
        .post(`jobs/${props.jobID}/question`, {
          type,
          question,
        })
        .then((res) => {
          //window.location.reload();
          setWaitingForAddQuestionRequest(false);
          props.fct()
          props.toggle()
          // res.statusText.toLowerCase() === "ok" && fetchData();
          setQuestion("");
        })
        .catch((err) => console.log(err));
    }
    if (question && newQuestionType === "QCM with only one correct answer") {
      console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: addJobQuestion avec options: ");

      const formData = new FormData();
      formData.append("timeLimit", timeLimit.value);
      formData.append("thinkTime", thinkTime.value);

      formData.append("type", newQuestionType);
      formData.append("question", question);
      questionStatementImage && formData.append("file1", questionStatementImage);
      formData.append("QCMOption1", newQuestionQCMOption1);
      formData.append("QCMOption2", newQuestionQCMOption2);
      formData.append("QCMOption3", newQuestionQCMOption3);
      formData.append("QCMOption4", newQuestionQCMOption4);
      formData.append("QCMOption5", newQuestionQCMOption5);
      formData.append("QCMOption6", newQuestionQCMOption6);
      formData.append("QCMCorrectOption", newQuestionQCMCorrectAnswer);
      formData.append("included_in_the_score_calculation", includeToScoreCalculation);
      liveQuestion && formData.append("file2", liveQuestion);

      axios({
        method: "post",
        url: `${config.WS_BASE_URL}jobs/createQuestionV3/${props.jobID}/question/v3`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {

          //window.location.reload();
          setWaitingForAddQuestionRequest(false);
          props.fct()
          props.toggle()
          // res.statusText.toLowerCase() === "ok" && fetchData();
          setQuestion("");
        })
        .catch((err) => console.log(err));
    }


    {/*
              instance.post(`${config.WS_BASE_URL}jobs/createQuestionV2/${jobId}/question`, {
        type,
        question,
        questionStatementImage,
        QCMOption1,
        QCMOption2,
        QCMOption3,
        QCMOption4,
        QCMOption5,
        QCMOption6,
        QCMCorrectOption,
      })
    */}


    // e.preventDefault();
    setQuestion("");
    setNewQuestionType(null);
    setSelectQuestionType(null);
    setNewQuestionQCMDisplay(false);
  };


  useEffect(() => {

  }, []);



  return (
    <>
      <Container style={{ width: "100%" }}>
        <Row >
          <Col lg="6">
            <Label>{t('States')}*</Label>
            <Input
              //type="text"
              type="textarea"
              rows="5"
              className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Mettre l'énoncé de la question ici"
              value={question}
            />

            <h5 className="font-semibold text-lg text-black mt-2">{t('AddQKey4')}:</h5>

            <Input
              className="form-control-alternative"
              type="file"
              accept="video/*,audio/*"
              onChange={(e) => {
                setLiveQuestion(e.target.files[0])
              }
              }
            />

            <h4 className="font-semibold text-lg text-black mt-2">{t('AddQKey1')}:</h4>

            <Input
              className="form-control-alternative"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => {
                setQuestionStatementImage(e.target.files[0])
                // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: QuestionsV2: e.target.files[0]: ", e.target.files[0])
              }
              }
            />

            <h4 className="font-semibold text-lg text-black mt-2">{t('OutFrSidebarKey2')}:</h4>
            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
              <ClockIcon className="h-6" />
              <h4>{t('Duration')}*</h4>
            </div>
            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
              <div style={{ width: "100px" }}>
                <Select
                  options={optionsTimes}
                  defaultValue={{
                    label: question.timeLimit,
                    value: question.timeLimit,
                  }}
                  onChange={(e) => {
                    setTimeLimit(e);
                    setIsTime(true);
                    if (!isThink) {
                      setThinkTime({
                        value: question.thinkTime,
                        label: question.thinkTime,
                      });
                    }
                    if (!isNewQuestion) {
                      setNewQuestion(question.question);
                    }
                  }}
                />
              </div>
              <h5>minutes</h5>
            </div>
            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
              <ChatAltIcon className="h-6" />
              <h4>{t('ReflexionTime')}*</h4>
            </div>
            <div className="flex space-x-2 items-center pb-2 border-b border-gray-500">
              <div style={{ width: "100px" }}>
                <Select
                  options={optionsThink}
                  defaultValue={{
                    label: question.thinkTime,
                    value: question.thinkTime,
                  }}
                  onChange={(e) => {
                    setThinkTime(e);
                    setIsThink(true);
                    if (!isTime) {
                      setTimeLimit({
                        value: question.timeLimit,
                        label: question.timeLimit,
                      });
                    }

                    if (!isNewQuestion) {
                      setNewQuestion(question.question);
                    }
                  }}
                />
              </div>
              <h5>minutes</h5>
            </div>


            {newQuestionQCMDisplay && (
              <>
                <h3 className="font-semibold text-lg text-black">Options</h3>


                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption1(e.target.value)}
                  placeholder="Option 1"

                >

                </Input>
                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption2(e.target.value)}
                  placeholder="Option 2"
                >

                </Input>
                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption3(e.target.value)}
                  placeholder="Option 3"
                >

                </Input>
                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption4(e.target.value)}
                  placeholder="Option 4"
                >

                </Input>
                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption5(e.target.value)}
                  placeholder="Option 5"
                >

                </Input>
                <Input
                  type="text"
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  onChange={(e) => setNewQuestionQCMDOption6(e.target.value)}
                  placeholder="Option 6"
                >

                </Input>
                <Select
                  className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                  options={config.question_QCM_options_for_recruter}
                  onChange={(e) => setNewQuestionQCMCorrectAnswer(e.value)}
                  placeholder="Sélectionner l'option correcte"
                >


                </Select>



              </>
            )
            }



          </Col>
          <Col lg="6">
            <Label>Type*</Label>
            <Select
              className="h-6"
              options={config.question_types}
              placeholder="Sélectionner le type de la question"
              // value = {selectQuestionType || ''}


              value={
                (config.question_types).filter(option =>
                  option.value === newQuestionType)
              }



              onChange={(e) => {
                setNewQuestionType(e.value)
                setSelectQuestionType(e.value)
                if (e.value === "QCM with only one correct answer") {
                  setNewQuestionQCMDisplay(true)
                }
                console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: addJobQuestion: Select newQuestionType: ", newQuestionType);
                console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii: addJobQuestion: Select e.value: ", e.value);

              }}
            />
            {/*newQuestionType === "Video" && <>
                  <Label className='mt-4'>Ajouter l'option question de suivi</Label>
                  <Select
                    options={options_videosPermission}
                    onChange={opt=> setIsFollowUpQuestion(opt.value) }
                  />
                </>*/}
          </Col>
          {newQuestionType === "QCM with only one correct answer" &&
            <>
              <Col lg="6" className="pt-4">
                <h4>{t('AddQKey2')}?</h4>
                <Select
                  // className="h-6"
                  options={options_include_in_score_caldulation}
                  // placeholder="Voulez-vous inclure cette question dans le calcul du score?"
                  // value = {selectQuestionType || ''}


                  value={
                    (options_include_in_score_caldulation).filter(option =>
                      option.value === includeToScoreCalculation)
                  }



                  onChange={(e) => {
                    setIncludeToScoreCalculation(e.value)

                  }}
                />
              </Col>


            </>

          }

          {
            error && <Alert className='text-center mt-2' color='danger'>{error}</Alert>
          }


        </Row>
        {!waitingForAddQuestionRequest &&
          <Row className="text-center items-center justify-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <Button
              style={{
                backgroundColor: "#404954",
                borderColor: "#404954",
                color: "white",
              }}
              className=""
              color=""
              type="button"
              size="sm"
              onClick={() => {
                if (Addvalidation()) {
                  setError(null)
                  setWaitingForAddQuestionRequest(true);
                  addQuestion();
                }

              }}
            >
              {t('AddQKey3')}
            </Button>
          </Row>
        }
        {waitingForAddQuestionRequest && (
          <Row className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
            <ClipLoader color="#f00" />
          </Row>
        )}

      </Container>
    </>
  );
};

export default AddJobQuestion;
