import React, { useEffect, useState } from "react";
import { options_videosPermission } from 'options';
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";


// reactstrap components
import {
    Container,
    CardHeader,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Button,
    Alert,
} from "reactstrap";
import Select from "react-select";

// core components
import axios from "axios";
import config from "config";
import { Box } from "@mui/material";

const instance = axios.create({
    baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? token : "";
    config.headers.ContentType = "application/json";
    return config;
});

const CandidatePreferences = (props) => {
    const [msg, setMsg] = useState('')

    const [AudiosVideosPermission, setAudiosVideosPermission] = useState(null);
    let user = JSON.parse(localStorage.getItem("user"));




    useEffect(() => {
        if (user.AudiosVideosPermission === true || user.AudiosVideosPermission === false) {
            setAudiosVideosPermission(user.AudiosVideosPermission);
        }
    }, []);

    console.log('user.AudiosVideosPermission', user.AudiosVideosPermission)

    // const editUser = async () => {
    const editUser = () => {
        setMsg('')
        user.AudiosVideosPermission = AudiosVideosPermission;
        localStorage.setItem('user', JSON.stringify(user))

        var data = JSON.stringify({
            "userID": user._id,
            "AudiosVideosPermission": AudiosVideosPermission
        });

        axios({
            method: "post",
            url: `${config.WS_BASE_URL}users/editUserParameters`,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (res.data.success) {
                    setMsg('Votre Préference a été sauvegardée avec succès')

                }
            }
            )
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Container>
                <PageSpace />
                <Row className='justify-content-center align-items-center'>
                    <h2>Préferences</h2>
                </Row>
                <Card className="bg-secondary shadow">
                    <CardBody className="bg-white">
                        <Form>
                            <FormGroup xs={12}>
                                <label className="px-lg-3">Pour augmenter la visibilité de votre profil, seriez-vous prêt(e) à afficher vos candidatures sous forme audio/vidéo dans les pages de recherche consultées par les recruteurs?</label>
                                <Select
                                    //name="Niveau du diplome" 
                                    //label="Niveau du diplome" 
                                    name="videosPermission"
                                    required
                                    // placeholder="gender" 
                                    className="w-full "
                                    options={options_videosPermission}
                                    value={
                                        options_videosPermission.filter(option =>
                                            option.value === AudiosVideosPermission)
                                    }
                                    // placeholder={AudiosVideosPermission}
                                    placeholder={
                                        options_videosPermission.filter(option =>
                                            option.value === AudiosVideosPermission)
                                    }
                                    //fullWidth 
                                    //onBlur={handleBlur} 
                                    // onChange={handleChange} 
                                    //value={NiveauDiplome}
                                    onChange={(e) => { setAudiosVideosPermission(e.value) }}
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                                />
                            </FormGroup>
                            <hr className="my-4" />
                            <FormGroup xs={12} className='d-flex justify-content-center'>
                                <Button
                                    color="primary"
                                    // href="#pablo"
                                    variant="contained"
                                    sx={{ mt: "25px" }}
                                    onClick={editUser}
                                //size="sm"
                                // disabled={isTestUser}
                                >
                                    Sauvegarder
                                </Button>
                            </FormGroup>

                        </Form>
                        {msg && <Alert className='text-center w-50' color='success'>{msg}</Alert>}

                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default CandidatePreferences;
