import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-grad pb-4 pt-4 pt-md-4">
        
        </div>
      </>
    );
  }
}

export default Header;
