import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import { H2, H3 } from "components/Typography";

import Offres from "components/recruteur/jobs";
import CandidatOffres from "components/candidat/CandidatOffres";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Input,
  FormGroup,
 } from "reactstrap";
import Select from "react-select";

import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";

import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";
import { options_Pays, options_profile_categories, options_certification_level, options_actual_contract, options_mobility, options_BAF, options_gender, options_profile_technical_or_not, options_family_Situation } from 'options';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import CandidatOutFrNavbar from "components/Navbars/CandidatOutFrNavbar";
import SidebarCandidatHorsFr from "components/Sidebar/SidebarCandidatHorsFr";
import SidebarCandidatHorsFrV2 from "components/Sidebar/SidebarCandidatHorsFrV2";
import OutFrFreelanceterminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/outFrFreelanceterminateYourProfileBefore";




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

const options_niveau_diplome = [
  { value: 'BAC', label: 'BAC' },
  { value: 'BTS', label: 'BTS' },
  { value: 'License', label: 'License' },
  { value: 'Maitrise', label: 'Maitrise' },
  { value: 'Master juste la première année', label: 'Master juste la première année' },
  { value: 'Master deuxième année', label: 'Master deuxième année' },
  { value: 'BAC+5 (Ingénierie)', label: 'BAC+5 (Ingénierie)' },
  { value: 'Doctorat', label: 'Doctorat' },
  { value: 'Autre', label: 'Autre' }
];


function FillYourCandidateProfile() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [alreadyPosted, setAlreadyPosted] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  //const [familySituation, setFamilySituation] = useState("");
  const [country, setCountry] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [diploma, setDiploma] = useState("");
  const [expertiseField, setExpertiseField] = useState("");
  const [technicalProfileOrNot, setTechnicalProfileOrNot] = useState("");

  
  const [profileType, setProfileType] = useState("");
  const [desiredProject, setDesiredProject] = useState("");
  const [experience, setExperience] = useState("");
  //const [topTechnologies, setTopTechnologies] = useState("");
  const [certifications, setCertifications] = useState("");
 // const [certificationLevel, setCertificationLevel] = useState("");
  const [actualContract, setActualContract] = useState("");
  //const [mobility, setMobility] = useState("");
  //const [baf, setBAF] = useState("");

  

  const [title, setTitle] = useState("");
  const [village, setVillage] = useState("");
  const [CV, setCV] = useState("");
  const [university, setUniversity] = useState("");
  const [NiveauDiplome, setNiveauDiplome] = useState("");

  const [disponibility, setDisponibility] = useState("");
  //const [freelanceDaysNbDisponibility, setFreelanceDaysNbDisponibility] = useState("");
  //const [freelanceTJM, setFreelanceTJM] = useState("");

  

  

  
  

  const [CVinput, setCVinput] = useState(false);
  const handleFormSubmit = async values => {
    //console.log(values);
  };

  // const createProfileWithCV = async () => {
  const createProfileWithCV = () => {
    // console.log("we are heree function createProfileWithCV");
    
    const formData = new FormData();
    formData.append("user", user._id);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("gender", gender);
    //formData.append("familySituation", familySituation);


    
    formData.append("age", age);
    //formData.append("email", email);
    
    formData.append("whatsappnumber", whatsappnumber);
    formData.append("linkedin", linkedin);
    formData.append("diploma", diploma);
    formData.append("expertiseField", expertiseField);
    formData.append("profileType", profileType);
    formData.append("technicalProfileOrNot", technicalProfileOrNot);

    
    formData.append("desiredProject", desiredProject);
    formData.append("experience", experience);
    //formData.append("topTechnologies", topTechnologies);
    formData.append("certifications", certifications);
    //formData.append("certificationLevel", certificationLevel);
    formData.append("actualContract", actualContract);
    //formData.append("mobility", mobility);
    //formData.append("baf", baf);
    formData.append("title", title);
    formData.append("village", village);
    formData.append("country", country);
    formData.append("studyLevel", NiveauDiplome);
    formData.append("university", university);
    formData.append("phonenumber", phonenumber);
    formData.append("disponibility", disponibility);
    // if (freelanceDaysNbDisponibility) formData.append("freelanceDaysNbDisponibility", freelanceDaysNbDisponibility);
    //if (freelanceTJM) formData.append("freelanceTJM", freelanceTJM);


    

    formData.append("CV", CV);
    
    
    console.log("formData: 7777777777777777777777777: ", formData);
    console.log("CV 22222222222222222222222222222222: ", CV);
    
    instance.post(`profiles/createprofilewithcv`, formData).then((resp) => {
      //go_to_home();
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
      window.location.reload()
    });
  };

  /* const go_to_home = () => {
    if (user.location === "France")
    {
      history.push("/infrancecandidate/home")
    }
    

  }; */


  const checkoutSchema = yup.object().shape({
    // titre: yup.string().required("required"),
  });

  useEffect(() => {
  
  
  }, [location]);
  
  return (
    <>
          <Container>
        <Row>
          <Col>
          <Form>

          

            <FormGroup xs={12}>
            <label>Prénom </label>
            <Input 
                  name="Prénom"
                  type="text"
                  // label="Prénom" 
                  // placeholder="Prénom" 
                  // className="w-full " 
                  fullWidth
                  // onBlur={handleBlur} 
                  // value={firstname}
                  // onChange={handleChange} 
                  onChange={e => setFirstname(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />


              </FormGroup>
              <FormGroup xs={12}>
              <label className="px-lg-3">Nom </label>
                <Input 
                  name="Nom"
                  // label="Nom" 
                  // placeholder="Nom" 
                  className="w-full "
                  type="text"
                  // onBlur={handleBlur} 
                  // value={lastname}
                  // onChange={handleChange} 
                  onChange={e => setLastname(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>
              <FormGroup xs={12}>
              <label className="px-lg-3">Êtes-vous? </label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="gender"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_gender}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setGender(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>



              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Quelle est votre situation familiale? </label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="familySituation"
                  // placeholder="gender" 
                  className="w-full "
                  options={options_family_Situation}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setFamilySituation(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup> */}
              <FormGroup xs={12}>
              <label className="px-lg-3">Pays</label>
              {/* console.log("options_Pays: ", options_Pays) */}
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  name="country"
                  // placeholder="country" 
                  className="w-full"
                  
                  options={options_Pays}
                  onChange={(e) => { setCountry(e.value) }}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  // onChange={handleChanges} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>
              <FormGroup xs={12}>
              <label className="px-lg-3">Age</label>
                <Input 
                  name="Age"
                  // label="Nom" 
                  // placeholder="Age" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={age}
                  // onChange={handleChange} 
                  onChange={e => setAge(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>



              {/* <FormGroup xs={12}>
              <label className="px-lg-3">E-mail</label>
              <Input
                  name="Email"
                  // label="Nom" 
                  // placeholder="Email" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={email}
                  // onChange={handleChange} 
                  onChange={e => setEmail(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
              </FormGroup> */}

              {/* <Grid item xs={12}>
              <label className="px-lg-3">Numéro de téléphone</label>

              <PhoneInput
                  name="Numéro de téléphone"
                  // label="Nom" 
                  placeholder="phone" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={phonenumber}
                  // onChange={handleChange} 
                  onChange={e => setPhonenumber(e.target.value)}

                  //onChange={e => setPhonenumber(e.target.value)}

                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
              </Grid> */}

                <FormGroup xs={12}>
              <label className="px-lg-3">Numéro de téléphone</label>
              <Input
                  name="Numéro de téléphone"
                  // label="Nom" 
                  // placeholder="phone" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={phonenumber}
                  // onChange={handleChange} 
                  onChange={e => setPhonenumber(e.target.value)}

                  //onChange={e => setPhonenumber(e.target.value)}

                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Whatsapp</label>
              <Input
                  name="Whatsapp"
                  // label="Nom" 
                  // placeholder="Whatsapp" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={whatsappnumber}
                  // onChange={handleChange}
                  onChange={e => setWhatsappnumber(e.target.value)} 

                  //onChange={e => setPhonenumber(e.target.value)}

                  
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                  />
              </FormGroup>


              <FormGroup xs={12}>
              <label className="px-lg-3">Linkedin (Mettez l'URL svp)</label>
                <Input 
                  name="Linkedin"
                  // label="Nom" 
                  // placeholder="Linkedin" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={linkedin}
                  // onChange={handleChange} 
                  onChange={e => setLinkedin(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Diplôme</label>
                <Input 
                  name="Diplome" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="Diploma" 
                  className="w-full "
                  // onBlur={handleBlur} 
                  // value={diploma}
                  onChange={e => setDiploma(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Niveau du diplome</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="Niveau du diplome" 
                  className="w-full "
                  options={options_niveau_diplome}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setNiveauDiplome(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Quel est votre Ecole/Université/Institut?</label>
                <Input name="university" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="university" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={university}
                  onChange={e => setUniversity(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Quel est votre domaine d'expertise? exemple: Réseau, Réseau et sécurité, Base de données, Système Linux, Système windows, Sharepoint, SAP, Autre.</label>
                <Input 
                  name="expertiseField" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="expertiseField" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={expertiseField}
                  onChange={e => setExpertiseField(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Quel est le profil qui vous corresponde le mieux?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="profile_categories" 
                  className="w-full "
                  options={options_profile_categories}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setProfileType(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>




              <FormGroup xs={12}>
              <label className="px-lg-3">Actuellement, vous faites partie de quelle catégorie de profil?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="profile_categories" 
                  className="w-full "
                  options={options_profile_technical_or_not}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setTechnicalProfileOrNot(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>



              <FormGroup xs={12}>
              <label className="px-lg-3">Quel est le type de projet recherché?</label>
                <Input 
                  name="Type de projet" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="Type de projet" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={desiredProject}
                  onChange={e => setDesiredProject(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup>

              <FormGroup xs={12}>
              <label>Nombre d'année d'expérience?</label>
                <Input 
                    
                  // label="Quel est votre Ecole/Université/Institut?" 
                  
                  type="text"
                  // placeholder="experience" 
                  className="w-full " 
                  name="experience"
                  // onBlur={handleBlur} 
                  //value={experience}
                  // value={state.experience}

                  // onChange={handleChanges}

                  onChange={e => setExperience(e.target.value)}
                  // autoFocus="autofocus"
                  // onChange={e => {
                    // this.cursor = e.target.selectionStart
                    // setExperience(e.target.value)
                  // }
                // }
  
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
                  
              </FormGroup>
              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Quelles sont les 3 technologies que vous maîtrisez le plus (Citez moi le top 3 des technologies)?</label>
                <Input 
                  name="topTechnologies" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="topTechnologies" 
                  className="w-full " 
                  type="text"
                  // onBlur={handleBlur} 
                  // value={topTechnologies}
                  // onChange={handleChanges}
                  onChange={e => setTopTechnologies(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup> */}
              <FormGroup xs={12}>
              <label className="px-lg-3">Quelles sont les certifications que vous avez obtenues??</label>
                <Input 
                  name="certifications" 
                  // label="Quel est votre Ecole/Université/Institut?" 
                  // placeholder="certifications" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={certifications}
                  onChange={e => setCertifications(e.target.value)}
                  // error={!!touched.university && !!errors.university} 
                  // helperText={touched.university && errors.university} 
                  />
              </FormGroup>
              {/* <FormGroup xs={12}>
                <label className="px-lg-3">A votre avis, quel est le niveau de vos certifications?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="certification_level" 
                  className="w-full "
                  options={options_certification_level}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setCertificationLevel(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup> */}
              <FormGroup xs={12}>
              <label className="px-lg-3">Actuellement, avez-vous quel type de contrat?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="actual_contract" 
                  className="w-full "
                  options={options_actual_contract}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setActualContract(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup>
              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Quelle est votre mobilité?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="mobility" 
                  className="w-full "
                  options={options_mobility}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  //value={NiveauDiplome}
                  onChange={(e) => { setMobility(e.value) }} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup> */}

              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Refusez vous de travailler dans le domaine des BAFs (Banques, Assurances, Finances)?</label>
                <Select 
                  //name="Niveau du diplome" 
                  //label="Niveau du diplome" 
                  // placeholder="BAF" 
                  className="w-full "
                  options={options_BAF}
                  //fullWidth 
                  //onBlur={handleBlur} 
                  // onChange={handleChange} 
                  // value={baf}
                  onChange={(e) => { setBAF(e.value) }}
                  // onChange={handleChanges} 
                  // value={values.NiveauDiplome || ""} 
                  //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                  //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                />
              </FormGroup> */}
              <FormGroup xs={12}>
              <label className="px-lg-3">Titre de votre profil</label>
                <Input 
                  name="titre"
                  // label="Titre de votre profil" 
                  // placeholder="Titre" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={title}
                  // onChange={handleChange} 
                  onChange={e => setTitle(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>
              <FormGroup xs={12}>
              <label className="px-lg-3">Indiquez la ville où vous habitez actuellement</label>
                <Input 
                  name="Village" 
                  // label="Indiquez la ville où vous habitez actuellement" 
                  // placeholder="Village" 
                  className="w-full "  
                  // onBlur={handleBlur} 
                  // value={village}
                  onChange={e => setVillage(e.target.value)}
                  //onChange={handleChange} 
                  // value={values.ville || ""} 
                  // error={!!touched.ville && !!errors.ville} 
                  // helperText={touched.ville && errors.ville} 
                />
              </FormGroup>

              <FormGroup xs={12}>
              <label className="px-lg-3">Votre disponibilité (Dans combien de temps, êtes vous disponible?)</label>
                <Input 
                  name="disponibilité"
                  // label="Titre de votre profil" 
                  // placeholder="Titre" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={title}
                  // onChange={handleChange} 
                  onChange={e => setDisponibility(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup>
              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Précisez-moi votre disponibilité en termes de temps de travail (nombre de jours par semaine)</label>
                <Input 
                  name="DaysNbDisponibility"
                  // label="Titre de votre profil" 
                  // placeholder="Titre" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={title}
                  // onChange={handleChange} 
                  onChange={e => setFreelanceDaysNbDisponibility(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup> */}

              {/* <FormGroup xs={12}>
              <label className="px-lg-3">Vos prétentions salariales (Le TJM: tarif journalier moyen)</label>
                <Input 
                  name="freelanceTJM"
                  // label="Titre de votre profil" 
                  // placeholder="Titre" 
                  className="w-full " 
                  // onBlur={handleBlur} 
                  // value={title}
                  // onChange={handleChange} 
                  onChange={e => setFreelanceTJM(e.target.value)}
                  // value={values.titre || ""} 
                  // error={!!touched.titre && !!errors.titre} 
                  // helperText={touched.titre && errors.titre} 
                />
              </FormGroup> */}


              <FormGroup xs={12}>
              <label className="px-lg-3">Votre CV (version française et format PDF)</label>
                  <Input
                  className="form-control-alternative"
                  // placeholder="CV" 
                  type="file"
                  accept=".pdf"
                  onChange={(e) =>
                  setCV(e.target.files[0])
                    }
                  // CVinput
                  />
              </FormGroup>
            <Row className='justify-content-center'>
            <Button 
            style={{whiteSpace:'normal'}}
            variant="contained" 
            color="primary" 
            // type="submit" 
            sx={{ mt: "25px" }}
            onClick={() => { 
              // console.log("bouton cliqué 0000000000000000000000 ")

              createProfileWithCV()
            }}
            >
              Sauvegarder votre fiche d'inscription
            </Button>
            </Row>
            </Form>
            </Col>
          </Row>
          </Container>
    </>
  );
}

export default FillYourCandidateProfile;
