import { ResetQCMQuestionsCatalogAnalysis } from 'network/ApiAxios';
import { CalculateScorebyQC } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Card, CardFooter, Col, Row, Table } from 'reactstrap'

const JobAppScoreByQuestionCatalogReport = ({ candidatId }) => {
    const { t, i18n } = useTranslation();

    const [scoreReport, setScoreReport] = useState(null);
    const [isRelanced, setIsRelanced] = useState(false)

    const CalculateOrgetScorebyQC = async () => {
        try {
            const { data } = await CalculateScorebyQC(candidatId)
            //console.log('scoreQC',data);  
            //console.log('data score', data)
            setScoreReport(data)
        } catch (error) {
            console.log(error);
        }
    }

    const HandleClick= async()=>{
        try {
            const {data} = await ResetQCMQuestionsCatalogAnalysis(candidatId,'QuestionCatalogAnalysis',{
                fieldValue : []
            })
            if(data.success){
                setIsRelanced(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        CalculateOrgetScorebyQC()
    }, [isRelanced])

    return (
        <Row>
            <Col className="order-xl-1">
                <Card className="bg-secondary shadow">
                    <h4 className=" mb-4">Score {t('QuestionCatalogAnalysisKEY1')} - {t('Report')} </h4>
                    <Table className="text-center w-full" responsive bordered>
                        <thead>
                            <tr>
                                <th>{t('QuestionCatalog')}</th>
                                <th>{t('QuestionCatalogAnalysisKEY2')}</th>
                                <th>{t('QuestionCatalogAnalysisKEY3')}</th>
                                <th>{t('QuestionCatalogAnalysisKEY4')}</th>
                                <th>Score (%)</th>
                            </tr>
                        </thead>
                        {/* scoreReport && <tbody>
                            {scoreReport?.QC1_Name && <tr>
                                <th scope="row">{scoreReport?.QC1_Name}</th>
                                <td>{scoreReport?.qcmNumberOfPoints_QC1}</td>
                                <td>{scoreReport?.qcmCorrectPoints_QC1}</td>
                                <td>{scoreReport?.qcmFalsePoints_QC1}</td>
                                {(scoreReport?.qcmScorePercentage_QC1 || scoreReport?.qcmScorePercentage_QC1 === 0) && <td className='font-weight-bold'>{Math.round(scoreReport?.qcmScorePercentage_QC1 * 100) / 100}</td>}
                            </tr>}
                            {scoreReport?.QC2_Name && <tr>
                                <th scope="row">{scoreReport?.QC2_Name}</th>
                                <td>{scoreReport?.qcmNumberOfPoints_QC2}</td>
                                <td>{scoreReport?.qcmCorrectPoints_QC2}</td>
                                <td>{scoreReport?.qcmFalsePoints_QC2}</td>
                                {(scoreReport?.qcmScorePercentage_QC2 || scoreReport?.qcmScorePercentage_QC2 === 0) && <td className='font-weight-bold'>{Math.round(scoreReport?.qcmScorePercentage_QC2 * 100) / 100}</td>}
                            </tr>}
                            {scoreReport?.QC3_Name && <tr>
                                <th scope="row">{scoreReport?.QC3_Name}</th>
                                <td>{scoreReport?.qcmNumberOfPoints_QC3}</td>
                                <td>{scoreReport?.qcmCorrectPoints_QC3}</td>
                                <td>{scoreReport?.qcmFalsePoints_QC3}</td>
                                {(scoreReport?.qcmScorePercentage_QC3 || scoreReport?.qcmScorePercentage_QC3 === 0) && <td className='font-weight-bold'>{Math.round(scoreReport?.qcmScorePercentage_QC3 * 100) / 100}</td>}
                            </tr>}
                            </tbody> */ }
                            {scoreReport?.map(QCanlyse => <tbody>
                            {QCanlyse?.qcmNumberOfPoints ? <tr>
                                <th scope="row">{QCanlyse?.QC_Name}</th>
                                <td>{QCanlyse?.qcmNumberOfPoints}</td>
                                <td>{QCanlyse?.qcmCorrectPoints}</td>
                                <td>{QCanlyse?.qcmFalsePoints}</td>
                                {(QCanlyse?.qcmScorePercentage || QCanlyse?.qcmScorePercentage === 0) && <td className='font-weight-bold'>{QCanlyse?.qcmScorePercentage}</td>}
                            </tr>
                            :
                            null}
                            </tbody>)}
                    </Table>
                    <Row className='m-3 justify-content-end'>
                        <Button onClick={HandleClick} color='primary'>
                           Relancer l'analyse
                        </Button>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default JobAppScoreByQuestionCatalogReport