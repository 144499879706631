import InternShipOffersForInFrCandidate from 'components/candidat/InternShipOffersForInFrCandidate'
import InFranceCandidateLayoutV3 from 'layouts/inFranceCandidateLayoutV3'
import React from 'react'

const InternShipOffersForInFrCandidateView = () => {
  return (
    <>
          <InFranceCandidateLayoutV3 />
          <InternShipOffersForInFrCandidate />

    </>
  )
}

export default InternShipOffersForInFrCandidateView