import axios from "axios";
import UpdateForm from "components/UpdateForm";
import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, useHistory, Link } from "react-router-dom";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
//import JobCandidats from "components/recruteur/JobCandidats";
import LandingPageCandidatures from "components/landingPage/landingPageCandidatures";
import LandingPageTerminatedCandidatures from "components/landingPage/landingPageTerminatedCandidatures";
import config from "config";
import LoginPostul from "components/loginPostul";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function LandingPageCompletedCandidatures({ jobId }) {
  const history = useHistory();
  const [job, setJob] = useState({});
  const [created, setCreated] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewUrl, setInterviewUrl] = useState("");
  const [affiche, setAffiche] = useState({
    update: false,
    questions: false,
  });

  const [openTab, setOpenTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostul, setModalPostul] = useState(false);
  const [CVinput, setCVinput] = useState(false);
  const [CV, setCV] = useState("");
  const [candidatID, setCandidatID] = useState("");
  const [motivLttr, setMotivLttr] = useState("");
  const [loginPostul, setLoginPostul] = useState(false);
  const [applied, setApplied] = useState(false);
  const [url, setUrl] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleOpen = () => setDropdown(!dropdown);
  var user;
  if (localStorage.getItem("user")) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = { role: "none" };
  }
  useEffect(() => {
    instance.get(`jobs/${jobId}`).then((resp) => {
      setJob(resp.data.job);
      setCreated(resp.data.job.createdAt);
      setInterviewUrl(resp.data.job.interview.url);
      setEndDate(resp.data.job.endDate.slice(0, 10));
      if (localStorage.getItem("user")) {
        resp.data.job.participations.forEach((e) => {
          if (JSON.parse(localStorage.getItem("user"))._id == e.candidat._id) {
            setApplied(true);
          }
        });
        if (JSON.parse(localStorage.getItem("user")).jobs) {
          JSON.parse(localStorage.getItem("user")).jobs.forEach((e) => {
            if (e == jobId) {
              setUrl(true);
            }
          });
        }
        if (JSON.parse(localStorage.getItem("user")).role == "employer") {
          setUrl(true);
        }
      }
    });
  }, [jobId]);

  const participate = () => {
    const formData = new FormData();
    formData.append("CV", CV);
    formData.append("candidatID", user._id);
    formData.append("motivLttr", motivLttr);
    instance.put(`jobs/participate/${jobId}`, formData).then((resp) => {
      localStorage.setItem("user", JSON.stringify(resp.data.user));
      history.push(`/interview/${job?.interview?._id}`)
    });
  };


  return (
    <>
      <Container>
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody
                className={openTab === 1 ? "block" : "hidden"}
                id="link1"
              >
                <div className="pl-lg-4">
                  <Row>
                    {/*LandingPageTerminatedCandidatures or LandingPageCandidatures*/}
                    <LandingPageTerminatedCandidatures participants={job} />
                  </Row>
                </div>
              </CardBody>


              
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LandingPageCompletedCandidatures;
