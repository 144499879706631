import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  Badge,
  CardTitle,
  CardText,
  CardImg,
  Container,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import { register, registerCand, registerEmployer } from "../../network/ApiAxios";
import RecruiterImg from '../../assets/img/brand/Recruiter.webp';
import CandidateImg from '../../assets/img/brand/Candidate.webp'


import RegisterEmployer from "components/other/RegisterEmployer";
import RegisterCandidate from "components/other/RegisterCandidate";
import RegisterCandidateV2 from "components/other/RegisterCandidateV2";
import config from "../../config";
import { PencilAltIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { options_Pays } from "options";
import LoginPostul from "components/loginPostul";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {useHistory} from 'react-router-dom'
const RegisterV4 = ({ id, redirect }) => {
  //console.log('1 id', id);
  //console.log('1 redirect', redirect);

  const [openTab, setOpenTab] = React.useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory()

  const cardStyle = {
    
    transition: 'border-color 0.3s',
    width : "20rem"
  };

  const HandleNavigate=(path)=>{
    history.push(`/homepage/${path}`)
  }

  

  return (
    <>

        <Card className="bg-secondary shadow border-0">

          <CardHeader className="bg-transparent pb-5">
            <Row className='ml-2'>
              <Card className='bg-primary pr-1 pl-1'><h1 className="text-white">Bonjour !</h1></Card>
            </Row>
            <h2 className="ml-1">Quel type de compte souhaitez-vous créer ?</h2>

            <Container >
              <ul className="flex mb-0 list-none pt-3 pb-4 flex-row justify-content-center flex-wrap">
                <Card
                  style={{...cardStyle,border: isHovered ? '2px solid #3E7DC0' : '2px solid transparent'}}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="mr-4 p-4 cursor-pointer my-card"
                  onClick={e => {
                    e.preventDefault();
                    HandleNavigate("signup-employer")
                    //setOpenTab(1);
                  }}
                >
                  <CardBody
                    className="text-center">
                    <Row className='justify-content-center'>
                      <CardImg
                        variant=""
                        //className="rounded-circle"
                        style={{
                          width: "130px",
                          height: "124px",
                          alignSelf: "center",
                          objectFit: "cover",
                        }}
                        alt="Responsive image 1"
                        src={RecruiterImg}
                      />
                    </Row>
                    <h2 className='pt-3'>Entreprise</h2>
                    <span style={{fontSize : "20px"}}>Je cherche des candidats</span>
                  </CardBody>
                </Card>

                <Card
                  className="p-4 cursor-pointer"
                  style={{...cardStyle,border: isHovered2 ? '2px solid #3E7DC0' : '2px solid transparent',}}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  onClick={e => {
                    e.preventDefault();
                    //setOpenTab(2);
                    HandleNavigate(`signup-candidate?id=${id}&redirect=${redirect}`)
                  }}
                >
                  <CardBody
                    className="text-center">
                    <Row className='justify-content-center'>
                      <CardImg
                        variant=""
                        //className="rounded-circle"
                        style={{
                          width: "130px",
                          height: "124px",
                          alignSelf: "center",
                          objectFit: "cover",
                        }}
                        alt="Responsive image 1"
                        src={CandidateImg}
                      />
                    </Row>

                    <h2 className='pt-3'>Candidat</h2>
                    <span style={{fontSize : "20px"}}>Je crée mon profil</span>
                  </CardBody>
                </Card>

              </ul>


            </Container>
          </CardHeader>




          <CardBody className="px-lg-5 py-lg-5">

            {/* Recruteur */}
            {/* <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <RegisterEmployer />
            </div> */}

            {/* candidat */}
            {/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <RegisterCandidateV2 id={id} redirect={redirect} />
            </div> */}
          </CardBody>
        </Card>
    </>
  );
};

export default RegisterV4;
