import {

  Container,
  Row,
  Col,

} from "reactstrap";

function PageTitleStyle(props) {
  

  return (
    <>

    <Container fluid>
        <Row className="text-center justify-content-center border-0 pb-2 pb-md-2">
        <Col xs="2" >
        </Col>


        <Col xs="10">
        <h2>{props.title}</h2>
        </Col>
          

        </Row>
    </Container>

   

    </>
  );
}

export default PageTitleStyle;
