import { options_mobility } from 'options';
import React, { useEffect, useState } from 'react'
import { Button, Container, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import { options_Pays } from 'options';
import { EditUserParameters } from 'network/ApiAxios';
import { EditProfileFrCandidate } from 'network/ApiAxios';

const EditLocalisationSection = ({ profileC, candidateUser, getProfileCandidate, getUser,t }) => {
    const [modal, setModal] = useState(false);
    const [EditedProfile, setEditedProfile] = useState({
        mobility: profileC?.mobility,
        location: candidateUser?.location,
        village: profileC?.village
    })

    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        setEditedProfile({ ...EditedProfile, village: profileC?.village, mobility: profileC?.mobility })
    }, [profileC]);

    useEffect(() => {
        setEditedProfile({ ...EditedProfile, location: candidateUser?.location })
    }, [candidateUser]);

    const toggle = () => setModal(!modal);

    const EditLocalisationOrMobility = async() => {
        EditedProfile.location && await EditUserParameters({ userID: candidateUser?._id, location: EditedProfile?.location }, candidateUser?._id)

        if (EditedProfile.location) { user.location = EditedProfile.location; }
        if (EditedProfile.location) { localStorage.setItem("user", JSON.stringify(user)); }

        
        await EditProfileFrCandidate(EditedProfile, candidateUser?._id)

       
        await getUser(user._id)
        await getProfileCandidate(user._id)
        toggle()
    }

    return (
        <Container>
            <svg onClick={toggle} style={{ height: '1.2rem', color: '#5e72e4', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
            </svg>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('EditLocationTitle')}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                        >
                            {t('LocationTitle')}
                        </label>
                        <Select
                            className="w-full "
                            options={options_Pays}
                            onChange={(selectedOpt) => setEditedProfile({ ...EditedProfile, location: selectedOpt.value })}
                            defaultValue={options_Pays.find(el => el.value == EditedProfile.location)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                        >
                            Village
                        </label>
                        <Input
                            type='text'
                            className="form-control-alternative"
                            defaultValue={EditedProfile.village}
                            onChange={(e) => setEditedProfile({ ...EditedProfile, village: e.target.value })}
                        />
                    </FormGroup>
                    <label className="form-control-label"
                        htmlFor="input-username">{t('Mobility')}</label>
                    <Select
                        className="w-full "
                        options={options_mobility}
                        onChange={(selectedOpt) => setEditedProfile({ ...EditedProfile, mobility: selectedOpt.value })}
                        defaultValue={options_mobility.find(el => el.value == EditedProfile.mobility)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={EditLocalisationOrMobility}>
                        {t('save')}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </Modal>

        </Container>
    )
}

export default EditLocalisationSection