import EmployerLayout from "layouts/EmployerLayout";
import JobsEmployer from "components/employer/jobsEmployer";
import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function Jobs() {
  return (
    <>
      <EmployerLayout />
      <Container>
        <Row>
          <Col lg="2">
          </Col>
          <Col lg="10">
            <JobsEmployer />
          </Col>
        </Row>
      </Container> 

    </>
  );
}

export default Jobs;