import PageTitleStyle from 'components/styles/PageTitleStyle'
import PageSpace from 'components/styles/pageSpace'
import CandidatHorsFrLayoutV2 from '../../layouts/CandidateLayoutV3'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import ChangeCountryParameters from 'components/candidat/ChangeCountryParameters'
import useCustomTranslation from 'components/other/useCustomTranslation'

const ChangeCountryParametersView = () => {
    const { t } = useCustomTranslation();

  return (
    <>
            <CandidatHorsFrLayoutV2 />
            <Container>
                <PageSpace />
                <PageTitleStyle
                    title={t('OutFrSidebarKey2')}
                >

                </PageTitleStyle>
                <Row>
                <Col sm='2'></Col>
                <Col col='10'>
                    <ChangeCountryParameters/>
                </Col>
            </Row>
            </Container>
           
        </>
  )
}

export default ChangeCountryParametersView