import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import { H2, H3 } from "components/Typography";

import Offres from "components/recruteur/jobs";
import CandidatOffres from "components/candidat/CandidatOffres";
import { Input } from "reactstrap";
import Select from "react-select";

// import { Grid } from "@mui/material";
import { Container, Box, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
//import { DropzoneArea } from 'material-ui-dropzone';
//import {useDropzone} from 'react-dropzone';

import { Formik } from "formik";
import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";
import { options_Pays, options_profile_categories, options_certification_level, options_actual_contract, options_mobility, options_BAF, options_gender, options_profile_technical_or_not, options_family_Situation } from 'options';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import FlexBox from "components/FlexBox";import CandidatOutFrNavbar from "components/Navbars/CandidatOutFrNavbar";
import SidebarCandidatHorsFr from "components/Sidebar/SidebarCandidatHorsFr";
import SidebarCandidatHorsFrV2 from "components/Sidebar/SidebarCandidatHorsFrV2";
import OutFrFreelanceterminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/outFrFreelanceterminateYourProfileBefore";




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

const options_niveau_diplome = [
  { value: 'BAC', label: 'BAC' },
  { value: 'BTS', label: 'BTS' },
  { value: 'License', label: 'License' },
  { value: 'Maitrise', label: 'Maitrise' },
  { value: 'Master juste la première année', label: 'Master juste la première année' },
  { value: 'Master deuxième année', label: 'Master deuxième année' },
  { value: 'BAC+5 (Ingénierie)', label: 'BAC+5 (Ingénierie)' },
  { value: 'Doctorat', label: 'Doctorat' },
  { value: 'Autre', label: 'Autre' }
];


function FreelanceProfileOutFrCandidateView() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
  
  const history = useHistory();
  const location = useLocation();
  const [alreadyPosted, setAlreadyPosted] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [familySituation, setFamilySituation] = useState("");
  const [country, setCountry] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [whatsappnumber, setWhatsappnumber] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [diploma, setDiploma] = useState("");
  const [expertiseField, setExpertiseField] = useState("");
  const [technicalProfileOrNot, setTechnicalProfileOrNot] = useState("");

  
  const [profileType, setProfileType] = useState("");
  const [desiredProject, setDesiredProject] = useState("");
  const [experience, setExperience] = useState("");
  const [topTechnologies, setTopTechnologies] = useState("");
  const [certifications, setCertifications] = useState("");
  const [certificationLevel, setCertificationLevel] = useState("");
  const [actualContract, setActualContract] = useState("");
  const [mobility, setMobility] = useState("");
  const [baf, setBAF] = useState("");

  

  const [title, setTitle] = useState("");
  const [village, setVillage] = useState("");
  const [CV, setCV] = useState("");
  const [university, setUniversity] = useState("");
  const [NiveauDiplome, setNiveauDiplome] = useState("");

  const [disponibility, setDisponibility] = useState("");
  const [daysNbDisponibility, setDaysNbDisponibility] = useState("");
  const [tjm, setTJM] = useState("");

  

  

  
  

  const [CVinput, setCVinput] = useState(false);
  const handleFormSubmit = async values => {
    //console.log(values);
  };

  // const createProfileWithCV = async () => {
  const createProfileWithCV = () => {
    // console.log("we are heree function createProfileWithCV");
    
    const formData = new FormData();
    formData.append("user", user._id);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("gender", gender);
    formData.append("familySituation", familySituation);


    
    formData.append("age", age);
    formData.append("email", email);
    
    formData.append("whatsappnumber", whatsappnumber);
    formData.append("linkedin", linkedin);
    formData.append("diploma", diploma);
    formData.append("expertiseField", expertiseField);
    formData.append("profileType", profileType);
    formData.append("technicalProfileOrNot", technicalProfileOrNot);

    
    formData.append("desiredProject", desiredProject);
    formData.append("experience", experience);
    formData.append("topTechnologies", topTechnologies);
    formData.append("certifications", certifications);
    formData.append("certificationLevel", certificationLevel);
    formData.append("actualContract", actualContract);
    formData.append("mobility", mobility);
    formData.append("baf", baf);
    formData.append("title", title);
    formData.append("village", village);
    formData.append("country", country);
    formData.append("studyLevel", NiveauDiplome);
    formData.append("university", university);
    formData.append("phonenumber", phonenumber);
    formData.append("disponibility", disponibility);
    formData.append("daysNbDisponibility", daysNbDisponibility);
    formData.append("tjm", tjm);  


    

    formData.append("CV", CV);
    
    
    console.log("formData: 7777777777777777777777777: ", formData);
    console.log("CV 22222222222222222222222222222222: ", CV);
    
    instance.post(`outFrSearchFreelanceInFranceProfiles/createprofilewithcv`, formData).then((resp) => {
      // console.log("8888888888888: formData: ", formData);
      // history.push('outfrapplypermanentfr/home');
      // history.push("google.fr")
    });
  };


  const checkoutSchema = yup.object().shape({
    // titre: yup.string().required("required"),
  });



  const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/users/outFrFreelanceCheckProfileProgress/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(resp.data.profileExistence);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  useEffect(() => {
  
    fetchProfile();
  
  }, [location]);
  
  return (
    <>
      {/* <CandidatHorsFrLayout /> */}
      <CandidateLayoutV3 />
      {/* <Header /> */}
      {/* <SidebarCandidatHorsFrV2/>
      <div className="mt-4 mb-4">
        <CandidatOutFrNavbar/>
  </div> */}
      
      
      
      {/* <Header /> */}
      <Container className="text-center border-0 mr-4 pt-8 pt-md-4 pb-0 pb-md-4">
        {/*<Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>*/}
          <H2>Projet: Je suis hors de la France et je cherche une mission Freelance en France</H2>
      </Container>
      <Box sx={
        { 
          ml: 32,
          p: "30px" 
        }
      }>
        </Box>
        <div> 
          {alreadyPosted &&
          <>
         

              <Box sx={
                { 
                  ml: 32,
                  p: "30px" 
                }
              }>
              <Grid item xs={12}
              sx={{ 
                mt: "25px",
                mb: "25px"      
              }}
              >
                <label className="px-lg-3">Votre formulaire d'inscription est déjà réalisé</label>
                </Grid>

                </Box>
                <OutFrFreelanceterminateYourProfileBefore />
            </>
          }

        {!alreadyPosted &&
                   <Box
                    style={{display:'flex', justifyContent:'center',width:'50%',marginLeft:'300px'}}
                    sx={
                    { 
                     // ml: 32,
                      p: "30px" 
                    }
                    }>
              
                   <form>
                          <Grid container spacing={5}>
                          <Grid item xs={12}>
                          <label>Prénom </label>
                          <Box pt={3}>
                          <TextField 
                               name="Prénom"
                               type="text"
                               // label="Prénom" 
                               // placeholder="Prénom" 
                               // className="w-full " 
                               fullWidth
                               // onBlur={handleBlur} 
                               // value={firstname}
                               // onChange={handleChange} 
                               onChange={e => setFirstname(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
              
                          </Box>
              
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Nom </label>
                              <TextField 
                               name="Nom"
                               // label="Nom" 
                               // placeholder="Nom" 
                               className="w-full "
                               type="text"
                               // onBlur={handleBlur} 
                               // value={lastname}
                               // onChange={handleChange} 
                               onChange={e => setLastname(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Êtes-vous? </label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                name="gender"
                                // placeholder="gender" 
                                className="w-full "
                                options={options_gender}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setGender(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>



                            <Grid item xs={12}>
                            <label className="px-lg-3">Quelle est votre situation familiale? </label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                name="familySituation"
                                // placeholder="gender" 
                                className="w-full "
                                options={options_family_Situation}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setFamilySituation(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Pays</label>
                            {/* console.log("options_Pays: ", options_Pays) */}
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                name="country"
                                // placeholder="country" 
                                className="w-full"
                                
                                options={options_Pays}
                                onChange={(e) => { setCountry(e.value) }}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                // onChange={handleChanges} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Age</label>
                              <TextField 
                               name="Age"
                               // label="Nom" 
                               // placeholder="Age" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={age}
                               // onChange={handleChange} 
                               onChange={e => setAge(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
              
              
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">E-mail</label>
                            <TextField
                               name="Email"
                               // label="Nom" 
                               // placeholder="Email" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={email}
                               // onChange={handleChange} 
                               onChange={e => setEmail(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                               />
                            </Grid>
              
                            {/* <Grid item xs={12}>
                            <label className="px-lg-3">Numéro de téléphone</label>
              
                            <PhoneInput
                               name="Numéro de téléphone"
                               // label="Nom" 
                               placeholder="phone" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={phonenumber}
                               // onChange={handleChange} 
                               onChange={e => setPhonenumber(e.target.value)}
              
                               //onChange={e => setPhonenumber(e.target.value)}
              
                               
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                               />
                            </Grid> */}
              
                             <Grid item xs={12}>
                            <label className="px-lg-3">Numéro de téléphone</label>
                            <TextField
                               name="Numéro de téléphone"
                               // label="Nom" 
                               // placeholder="phone" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={phonenumber}
                               // onChange={handleChange} 
                               onChange={e => setPhonenumber(e.target.value)}
              
                               //onChange={e => setPhonenumber(e.target.value)}
              
                               
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                               />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Whatsapp</label>
                            <TextField
                               name="Whatsapp"
                               // label="Nom" 
                               // placeholder="Whatsapp" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={whatsappnumber}
                               // onChange={handleChange}
                               onChange={e => setWhatsappnumber(e.target.value)} 
              
                               //onChange={e => setPhonenumber(e.target.value)}
              
                               
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                               />
                            </Grid>
              
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Linkedin (Mettez l'URL svp)</label>
                              <TextField 
                               name="Linkedin"
                               // label="Nom" 
                               // placeholder="Linkedin" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={linkedin}
                               // onChange={handleChange} 
                               onChange={e => setLinkedin(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Diplôme</label>
                              <TextField 
                                name="Diplome" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="Diploma" 
                                className="w-full "
                                // onBlur={handleBlur} 
                                // value={diploma}
                                onChange={e => setDiploma(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Niveau du diplome</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="Niveau du diplome" 
                                className="w-full "
                                options={options_niveau_diplome}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setNiveauDiplome(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quel est votre Ecole/Université/Institut?</label>
                              <TextField name="university" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="university" 
                                className="w-full " 
                                // onBlur={handleBlur} 
                                // value={university}
                                onChange={e => setUniversity(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quel est votre domaine d'expertise? exemple: Réseau, Réseau et sécurité, Base de données, Système Linux, Système windows, Sharepoint, SAP, Autre.</label>
                              <TextField 
                                name="expertiseField" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="expertiseField" 
                                className="w-full " 
                                // onBlur={handleBlur} 
                                // value={expertiseField}
                                onChange={e => setExpertiseField(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quel est le profil qui vous corresponde le mieux?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="profile_categories" 
                                className="w-full "
                                options={options_profile_categories}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setProfileType(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>




                            <Grid item xs={12}>
                            <label className="px-lg-3">Actuellement, vous faites partie de quelle catégorie de profil?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="profile_categories" 
                                className="w-full "
                                options={options_profile_technical_or_not}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setTechnicalProfileOrNot(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>


              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quel est le type de projet recherché?</label>
                              <TextField 
                                name="Type de projet" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="Type de projet" 
                                className="w-full " 
                                // onBlur={handleBlur} 
                                // value={desiredProject}
                                onChange={e => setDesiredProject(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label>Nombre d'année d'expérience?</label>
                              <TextField 
                                 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                
                                type="text"
                                // placeholder="experience" 
                                className="w-full " 
                                name="experience"
                                // onBlur={handleBlur} 
                                //value={experience}
                                // value={state.experience}
              
                                // onChange={handleChanges}
              
                                onChange={e => setExperience(e.target.value)}
                                // autoFocus="autofocus"
                                // onChange={e => {
                                  // this.cursor = e.target.selectionStart
                                  // setExperience(e.target.value)
                               // }
                             // }
               
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                                
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quelles sont les 3 technologies que vous maîtrisez le plus (Citez moi le top 3 des technologies)?</label>
                              <TextField 
                                name="topTechnologies" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="topTechnologies" 
                                className="w-full " 
                                type="text"
                                // onBlur={handleBlur} 
                                // value={topTechnologies}
                                // onChange={handleChanges}
                                onChange={e => setTopTechnologies(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quelles sont les certifications que vous avez obtenues??</label>
                              <TextField 
                                name="certifications" 
                                // label="Quel est votre Ecole/Université/Institut?" 
                                // placeholder="certifications" 
                                className="w-full " 
                                // onBlur={handleBlur} 
                                // value={certifications}
                                onChange={e => setCertifications(e.target.value)}
                                // error={!!touched.university && !!errors.university} 
                                // helperText={touched.university && errors.university} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                              <label className="px-lg-3">A votre avis, quel est le niveau de vos certifications?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="certification_level" 
                                className="w-full "
                                options={options_certification_level}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setCertificationLevel(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Actuellement, avez-vous quel type de contrat?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="actual_contract" 
                                className="w-full "
                                options={options_actual_contract}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setActualContract(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Quelle est votre mobilité?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="mobility" 
                                className="w-full "
                                options={options_mobility}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                //value={NiveauDiplome}
                                onChange={(e) => { setMobility(e.value) }} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Refusez vous de travailler dans le domaine des BAFs (Banques, Assurances, Finances)?</label>
                              <Select 
                                //name="Niveau du diplome" 
                                //label="Niveau du diplome" 
                                // placeholder="BAF" 
                                className="w-full "
                                options={options_BAF}
                                //fullWidth 
                                //onBlur={handleBlur} 
                                // onChange={handleChange} 
                                // value={baf}
                                onChange={(e) => { setBAF(e.value) }}
                                // onChange={handleChanges} 
                                // value={values.NiveauDiplome || ""} 
                                //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                                //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Titre de votre profil</label>
                              <TextField 
                               name="titre"
                               // label="Titre de votre profil" 
                               // placeholder="Titre" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={title}
                               // onChange={handleChange} 
                               onChange={e => setTitle(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Indiquez la ville où vous habitez actuellement</label>
                              <TextField 
                                name="Village" 
                                // label="Indiquez la ville où vous habitez actuellement" 
                                // placeholder="Village" 
                                className="w-full "  
                                // onBlur={handleBlur} 
                                // value={village}
                                onChange={e => setVillage(e.target.value)}
                                //onChange={handleChange} 
                                // value={values.ville || ""} 
                                // error={!!touched.ville && !!errors.ville} 
                                // helperText={touched.ville && errors.ville} 
                              />
                            </Grid>

                            <Grid item xs={12}>
                            <label className="px-lg-3">Votre disponibilité (Dans combien de temps, êtes vous disponible?)</label>
                              <TextField 
                               name="disponibilité"
                               // label="Titre de votre profil" 
                               // placeholder="Titre" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={title}
                               // onChange={handleChange} 
                               onChange={e => setDisponibility(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
                            <Grid item xs={12}>
                            <label className="px-lg-3">Précisez-moi votre disponibilité en termes de temps de travail (nombre de jours par semaine)</label>
                              <TextField 
                               name="DaysNbDisponibility"
                               // label="Titre de votre profil" 
                               // placeholder="Titre" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={title}
                               // onChange={handleChange} 
                               onChange={e => setDaysNbDisponibility(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>

                            <Grid item xs={12}>
                            <label className="px-lg-3">Vos prétentions salariales (Le TJM: tarif journalier moyen)</label>
                              <TextField 
                               name="tjm"
                               // label="Titre de votre profil" 
                               // placeholder="Titre" 
                               className="w-full " 
                               // onBlur={handleBlur} 
                               // value={title}
                               // onChange={handleChange} 
                               onChange={e => setTJM(e.target.value)}
                               // value={values.titre || ""} 
                               // error={!!touched.titre && !!errors.titre} 
                               // helperText={touched.titre && errors.titre} 
                              />
                            </Grid>
              
              
                            <Grid item xs={12}>
                            <label className="px-lg-3">Votre CV (version française et format PDF)</label>
                               <Input
                                className="form-control-alternative"
                                // placeholder="CV" 
                                type="file"
                                accept=".pdf"
                                onChange={(e) =>
                                setCV(e.target.files[0])
                                 }
                                // CVinput
                               />
                            </Grid>
                          </Grid>
                          <Button 
                          variant="contained" 
                          color="primary" 
                          type="submit" 
                          sx={{ mt: "25px" }}
                          onClick={() => { 
                            // console.log("bouton cliqué 0000000000000000000000 ")
              
                            createProfileWithCV()
                            history.push("/outfrapplypermanentfr/home")
                          }}
                          >
                            Sauvegarder votre fiche d'inscription
                          </Button>
                    </form>
              
                  </Box>
        }
          
  </div>
    </>
  );
}

export default FreelanceProfileOutFrCandidateView;
