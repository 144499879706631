import axios from "axios";

import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";
import OutFrTerminateYourProfileBeforeV2 from "components/outFrCandidatSearchCdiFr/outFrTerminateYourProfileBeforeV2";


import { Container, Row, Col } from "reactstrap";

import { useHistory, useLocation } from "react-router-dom";
import CandidatMyProfileGenPrezVideo from "components/candidat/CandidatMyProfileGenPrezVideo";
import TableRow from "components/TableRow";
import FlexBox from "components/FlexBox";
import OneJob from "components/recruteur/oneJob";
import OffresOFCF from "components/outFrCandidatSearchCdiFr/offresOFCF";
import InFranceGeneralInterviewOneCard from "components/candidat/inFranceGeneralInterviewOneCard";

import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import Select from "react-select";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});



function EtrangerCDIenFranceGeneralInterviewsViewV3() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };
   const [profileTypes, setProfileTypes] = useState([]);
   const [profileTypesOptions, setProfileTypesOptions] = useState([]);
   const [selectedJobID, setSelectedJobID] = useState("");
   const [selectedProfileTypeID, setSelectedProfileTypeID] = useState("");
  
   const history = useHistory();
   const location = useLocation();

   const [alreadyPosted, setAlreadyPosted] = useState(false);
   const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);

   const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };


    const getProfileTypesFunction = () => {
      // console.log("5555555555555555555555555555: getProfileTypesFunction");
      // var pipeline = "Rejeté";
      // const response = getProfileTypes();
      // const { data } = response;
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response: ", response);
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response.data: ", response.data);
      
      instance.get(`profiletypes/getAllActiveProfileTypes`).then((resp) => {
        // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp: ", resp);
        // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp.data: ", resp.data);
        setProfileTypes(resp.data);
        // setProfileTypesOptions(resp.data);
        let newArray;
        newArray = resp.data;
        // newArray = resp.data;
        // setProfileTypesOptions(newArray.map(item => ({value: item.jobID, label: item.name})));
        setProfileTypesOptions(newArray.map(item => ({value: item._id, label: item.name})));
  
  
        // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));
  
        // setProfileTypesOptions(newArray);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: newArray: ", newArray);
  
        // console.log("5555555555555555555555555555: getProfileTypesFunction: resp: ", resp);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypes: ", profileTypes);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypesOptions: ", profileTypesOptions);
        
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 2:");
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 3 : resp: ", resp);
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
        });
      // console.log("5555555555555555555555555555: getProfileTypesFunction: success");
      // console.log("5555555555555555555555555555: response.data: ", response.data);
      // setVisibility(response.data.visibility);
      // setProfileTypeName(response.data.name);
      // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));
  
  
    };

    useEffect(() => {

      getProfileTypesFunction();
    
    }, [location]);

  
  return (
    <>
      <CandidateLayoutV3 />

      <PageSpace />
      <PageTitleStyle
        title="Projet: Je cherche une opportunité en France"
      >
        
      </PageTitleStyle>
      
      <Container>
        <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
        <Col xs="2" >
        </Col>
          
        <Col xs="10">
          <h3>Test Technique (en vidéo)</h3>
        </Col>

        </Row>
        
       
        
              <>
                <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
                  <Col xs="2">
                  </Col>
                  <Col xs="10">
                    <span> Selon votre profil, choisissez le bon entretien vidéo à passer.</span>
                    <span>C'est un auto-test technique, il n’y a personne qui va vous poser des questions. </span>
                    <span>Vous allez trouver des questions auxquelles vous devez répondre en vidéo, et vos réponses vont être enregistrées au niveau de notre plateforme</span>
                  </Col>
                </Row>
                <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
          <Col xs="2">
          </Col>
          <Col xs="10">
          <label className="px-lg-3">Merci de choisir le profil recherché</label>
                      <Select 
                        //name="Niveau du diplome" 
                        //label="Niveau du diplome" 
                        name="jobid_select"
                        // placeholder="gender" 
                        className="w-full "
                        // options={job_IDs_for_recruters}
                        options={profileTypesOptions}
                        //fullWidth 
                        //onBlur={handleBlur} 
                        // onChange={handleChange} 
                        //value={NiveauDiplome}
                        onChange={(e) => { 
                          // console.log("0000000000000000000: e.value: ", e.value)
                          // setSelectedJobID(e.value);
                          setSelectedProfileTypeID(e.value);
                          // setLoading(false);
                        }} 
                        // value={values.NiveauDiplome || ""} 
                        //error={!!touched.NiveauDiplome && !!errors.NiveauDiplome} 
                        //helperText={touched.NiveauDiplome && errors.NiveauDiplome} 
                      />
          </Col>
                </Row>
                <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
                  <Col xs="2">
                  </Col>
                  <Col xs="10">

                        {selectedProfileTypeID && (
                          <InFranceGeneralInterviewOneCard 
                            key={selectedProfileTypeID} 
                            // jobid_param={selectedJobID} 
                            selectedProfileTypeID = {selectedProfileTypeID}
                          />
                        )
                        }
                  </Col>
                </Row>
              </>
            
      </Container>

    </>
  );
};

export default EtrangerCDIenFranceGeneralInterviewsViewV3;
