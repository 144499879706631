import EmployerLayout from 'layouts/EmployerLayout'
import { CreateOrUpdateOffer } from 'network/ApiAxios'
import React, { useEffect } from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import {useHistory} from 'react-router-dom'
import { AddOrUpdateUserPayments } from 'network/ApiAxios'

const PaymentSuccess = () => {

    const history = useHistory()

    const user = JSON.parse(localStorage.getItem('user'));
    const priceId = localStorage.getItem('priceId');


    const AddUserPaymentsWithCompletedOp = async () => {

        user.payments = [...user?.payments,{priceId, status : "success"}];
        localStorage.setItem("user", JSON.stringify(user));
        await AddOrUpdateUserPayments(user?._id, {priceId, status : "success"} )
    }

     useEffect(()=>{
        AddUserPaymentsWithCompletedOp()
     },[])
    return (
        <>
            <EmployerLayout />
            <Container>
                <Row className='mt-5'>
                    <Col xs="2">
                    </Col>
                    <Col xs="10" className="d-flex flex-column justify-content-center align-items-center">
                        <Container>
                            <Card className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                                color="primary"
                                outline>
                                <CardBody className='mt-5'>
                                    <h3> Le paiement a été effectué avec succès,Vous êtes désormais accéder à la formation. </h3>
                                    <Row className='justify-content-center'>
                                        <Button className='mt-4' style={{whiteSpace:'normal'}} color='primary' onClick={()=> history.push('/candidate/trainings')}>Retourner à la liste de formation </Button>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PaymentSuccess