import React from "react";
import Home from "views/home"
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  // Container,
  Row,
  Col,
  // Navbar,
  // Nav,
  Button,
  NavbarBrand,
  NavItem,
  NavLink
} from "reactstrap";
import { Navbar, Nav, Container } from 'react-bootstrap';
import AdminFooter from "components/Footers/AdminFooter"
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkRRD, useHistory } from 'react-router-dom'
import TranslationLang from "components/other/TranslationLang";

const HomeHeaderV2 = () => {

  const history = useHistory()
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar
        // className="navbar-top navbar-dark"
        // expand="md"
        // bg="light" variant="light"
        // bg="light" variant="light"
        // bg="white" variant="white"
        bg="white" variant="light"

        expand="sm"
        // id="navbar-main"
        fixed="top"
        collapseOnSelect
      // variant="dark"
      // bg="dark"
      >
        <Container fluid>

          <NavbarBrand to="/" tag={Link}
            className="py-4 px-5 flex h-24 items-center justify-center mr-auto my-2 rounded-lg origin-left exact-active active"

          >
            <img
              src={require("assets/img/brand/logo_transparent.png").default}
              style={{
                width: "200%",
                height: "200%",
                // width: 111,
                // height: 40,


                objectFit: "cover",
              }}
              alt="Responsive image"
            />
          </NavbarBrand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>

            <Nav className="ml-auto">
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/" >

                  {t("NavBarLink1")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/homepage/register" >

                  {t("NavBarLink2")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={NavLinkRRD}
                  // onClick={this.closeCollapse}
                  // activeClassName="active"
                  to="/home/login" >

                  {t("NavBarLink3")}
                </NavLink>
              </NavItem>

              <NavItem>
                <a
                  href="https://hirelands.com/trouver-les-meilleurs-offres-demploi"
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="nav-link" 
                >
                  {t("Candidates")}
                </a>
              </NavItem>
              <NavItem>
                <a
                  href="https://hirelands.com/landing-page-recruteurs-en-france-benefices"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  {t("NavBarLink4")}
                </a>
              </NavItem>
              <TranslationLang />
            </Nav>
          </Navbar.Collapse>




        </Container>
      </Navbar>

    </>
  );
}

export default HomeHeaderV2;
