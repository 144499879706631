import JobCardsHomepage from "components/homepage/jobCardsHomepage";
import JobCardsHomepageV2 from "components/homepage/jobCardsHomepageV2";
import PageSpace from "components/styles/pageSpace";

import HomepageLayout from "layouts/HomepageLayout";

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

import React from "react";


function HomepageJobsView() {
  return (
    <>
      <HomepageLayout />
      <Container fluid>
        <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
      </Container>
      <PageSpace />

      

      <JobCardsHomepageV2 />

      
     
    </>
  );
}

export default HomepageJobsView;
