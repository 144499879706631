import React from "react";

import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import InFrCandidateJobsWithPayOrTJMCondition from "components/candidat/InFrCandidateJobsWithPayOrTJMCondition";
function InFranceCandidateJobsWithTJMGreaterThen500View() {
  return (
    <>
      <CandidateLayoutV3 />
      <InFrCandidateJobsWithPayOrTJMCondition isFreelancing />
    </>
  );
}

export default InFranceCandidateJobsWithTJMGreaterThen500View;
