import axios from "axios";
import React, { useState } from "react";
import { useHistory, useLocation, Link} from "react-router-dom";
import qs from "query-string";
import ShowCandidat from "./ShowCandidat";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  SearchIcon,
  VideoCameraIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function OneInterview({ candidats, fetchData }) {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const [search, setSearch] = useState("");
  const [candidat, setCandidat] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [candidatID,setCandidatID]=useState("");
  console.log(candidats[0]?.interview?.job?.questions);
  const removeCandidat = (id) => {
    instance
      .delete(`jobs/candidat/${id}`)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {candidats && !query.candidat && (
        <>
          <div className="flex items-center my-3 px-2 py-2 border-2 mx-auto rounded-full border-gray-400 max-w-md">
            <input
              type="text"
              className="bg-transparent flex-grow text-black px-2"
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className="h-6 text-blue-500" />
          </div>
          <div className="grid grid-cols-3 gap-2 mx-2 my-3">
            {candidats
              .filter((candidat) => {
                if (search === "") {
                  return candidat;
                } else if (
                  candidat.nom.toLowerCase().includes(search.toLowerCase()) ||
                  candidat.prenom
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  candidat.email.toLowerCase().includes(search.toLowerCase())
                ) {
                  return candidat;
                }
              })
              .map((candidat) => (
                <div
                  key={candidat._id}
                  className="bg-white shadow-lg px-2 py-3 flex flex-col items-start space-y-2"
                >
                  <div className="flex items-center space-x-2">
                    <div className="flex justify-center items-center px-4 py-4 border border-gray-400 rounded-full">
                      <h3 className="text-xl">{`${candidat.nom.slice(
                        0,
                        1
                      )}${candidat.prenom.slice(0, 1)}`}</h3>
                    </div>
                    <div className="flex flex-col items-start space-y-1">
                      <h3>{`${candidat.nom} ${candidat.prenom}`}</h3>
                      <span>{candidat.email}</span>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => {
                            setCandidat(candidat);
                            history.push(
                              `${location.pathname}?candidat=${candidat._id}`
                            );
                          }}
                          className="flex items-center px-4 py-2 bg-green-500 text-white focus:outline-none"
                        >
                          <VideoCameraIcon className="h-6 pr-2" />
                          Watch
                        </button>
                        {/* <MailIcon className="h-8 text-gray-400 cursor-pointer" /> */}
                        <XCircleIcon
                          onClick={() => {
                            setCandidatID(candidat._id);
                            setModalOpen(!modalOpen);
                          }}
                          className="h-8 text-red-600 cursor-pointer"
                        />
                        {candidat.drive && (
                          
                            <a
                            href={`${candidat.drive}`}
                              target="_blank"
                              style={{display:"contents"}}
                              className="h-8 text-green-600"
                            ><CheckCircleIcon className="h-8 text-green-600"/>google-Drive</a>
                          
                        )}
                        {!candidat.drive && (
                         <span className="h-8 text-gray" style={{display:"contents"}}> <CheckCircleIcon className="h-8 text-gray" />google-Drive</span>
                        )}
                        <Modal
                          toggle={() => setModalOpen(!modalOpen)}
                          isOpen={modalOpen}
                        >
                          <div className=" modal-header">
                            <h4 className=" modal-title" id="exampleModalLabel">
                              Êtes-vous sûr de vouloir supprimer cette interview
                              ?
                            </h4>
                            <button
                              aria-label="Close"
                              className=" close"
                              type="button"
                              onClick={() => setModalOpen(!modalOpen)}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>

                          <ModalFooter>
                            <Button
                              color="secondary"
                              type="button"
                              onClick={() => setModalOpen(!modalOpen)}
                            >
                              ANNULER
                            </Button>
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => {
                                removeCandidat(candidatID);
                                setModalOpen(!modalOpen);
                              }}
                            >
                              SUPPRIMER
                            </Button>
                          </ModalFooter>
                        </Modal>
                        {/* <PaperClipIcon className="h-8 text-gray-400 cursor-pointer" />
                        <ShieldCheckIcon className="h-8 text-gray-400 cursor-pointer" /> */}
                      </div>
                      {candidat.installed && (
                        <span
                          className="h-8 text-green-600"
                          style={{ display: "inline-flex" }}
                        >
                          Cette vidéo est installée{" "}
                          <CheckCircleIcon className="h-8 text-green-600" />
                        </span>
                      )}
                      {!candidat.installed && candidat.merged && (
                        <span
                          className="h-8 text-red-600"
                          style={{ display: "inline-flex" }}
                        >
                          Vidéo prête pour l'installation
                        </span>
                      )}
                    </div>
                  </div>

                  <span>Type: One Way</span>
                  <span>Status: Completed</span>
                  <span>Job: {candidat?.interview?.job?.title}</span>
                  <span>Created By: </span>
                </div>
              ))}
          </div>
        </>
      )}
      {query.candidat && (
        <ShowCandidat candidatId={query.candidat} candidat={candidat} />
      )}
      {/* Search */}
    </>
  );
}

export default OneInterview;
