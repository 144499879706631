import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import TerminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/terminateYourProfileBefore";

import CandidatOffres from "components/candidat/CandidatOffres";
import JobCardsInFranceCandidate from "components/candidat/jobCardsInFranceCandidate";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

import React from "react";
import axios from "axios";
import config from "config";



function FreelanceMissionsView() {


  return (
    <>
      <CandidateLayoutV3 />
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <h2>Projet: Je suis hors de la France et je cherche une mission Freelance en France</h2>
          <h3>Mon profil</h3>
          <TerminateYourProfileBefore />
      </Container>
      
     
    </>
  );
}

export default FreelanceMissionsView;
