import JobApplicationTitle from "components/employer/jobApplicationTitle";
import ProfileCompetenciesoutFrCandidate from "components/employer/profileCompetenciesoutFrCandidate";
// import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import JobAppNotes from "components/employer/jobAppNotes";
import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";
import JobAppPipeline from "components/employer/jobAppPipeline";
import UserManagementActions from "components/employer/userManagementActions";
import JobAppScoreByQuestionCatalogReport from 'components/employer/jobAppScoreByQuestionCatalogReport'
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Container,
  Button,
  Col,
  Card,
  CardImg,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  CardHeader,
  Table,
  CardBody,
  Form,
  Input,
} from "reactstrap";
import Select from "react-select";
import { Grid, TextField } from "@mui/material";

import { UserIcon } from "@heroicons/react/outline";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
import CVPdfDisplayer from "./CVPdfDisplayer";
import JobAcceptanceCriteria from "./JobAcceptanceCriteria";
import { LightBulbIcon } from "@heroicons/react/solid";
import EvaluateCandidateVideoResponseForRecruiter from "./EvaluateCandidateVideoResponseForRecruiter";
import { useTranslation } from "react-i18next";
import CandidateProfileForRecruiter from "components/candidat/CandidateProfileForRecruiter";
import { getUserById } from "network/ApiAxios";
import { getProfileFrUserByID } from "network/ApiAxios";
import JobAppScoreByOralQCOpenAiReport from "./JobAppScoreByOralQCOpenAiReport";
import qs from "query-string";
import ArchiveJobApp from "./ArchiveJobApp";
import CodeQuestionAnalysisByJobApp from "./CodeQuestionAnalysisByJobApp";
import OpenAiCvCandidateAnalysisReport from "./openAiCvCandidateAnalysisReport";
import CriteriaAcceptanceIAanalysisByJobApp from "./CriteriaAcceptanceIAanalysisByJobApp";
import CheatingCodeAnalysisByJobApp from "./CheatingCodeAnalysisByJobApp";
import JobAppScoreByOralQCOpenAiReportV1 from "./JobAppScoreByOralQCOpenAiReportV1";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function ShowJobAppForOneJob({ candidatId, jobAppDate }) {
  const history = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search);
  const openTabIndex = +(query?.openTabIndex)
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);



  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [openAIResponse, setOpenAIResponse] = useState(null);
  const [codeResponse, setCodeResponse] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [currentQuestionType, setCurrentQuestionType] = useState();
  const [currentQCMQuestionOption1, setCurrentQCMQuestionOption1] = useState();
  const [currentQCMQuestionOption2, setCurrentQCMQuestionOption2] = useState();
  const [currentQCMQuestionOption3, setCurrentQCMQuestionOption3] = useState();
  const [currentQCMQuestionOption4, setCurrentQCMQuestionOption4] = useState();
  const [currentQCMQuestionOption5, setCurrentQCMQuestionOption5] = useState();
  const [currentQCMQuestionOption6, setCurrentQCMQuestionOption6] = useState();
  const [currentQCMQuestionSelectedOption, setCurrentQCMQuestionSelectedOption] = useState();
  const [currentQCMQuestionCorrectOption, setCurrentQCMQuestionCorrectOption] = useState();





  const [candidat, setCandidat] = useState("");
  const [jobApplicationCV, setJobApplicationCV] = useState("");
  const [drive, setDrive] = useState("");
  const [hasDrive, setHasdrive] = useState(false);
  const [motivLttr, setMotivLttr] = useState(false);
  const [applicationPipeline, setApplicationPipeline] = useState("");

  const [openTab, setOpenTab] = useState(openTabIndex || 1);
  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormCV, setJobApplicationFormCV] = useState("");
  const [jobApplicationFormWhatsappNb, setJobApplicationFormWhatsappNb] = useState("");
  const [jobApplicationFormWhatsappNbFiltered, setJobApplicationFormWhatsappNbFiltered] = useState("");

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [profileAnalysis, setProfileAnalysis] = useState("");
  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});
  const [profileC, setProfileC] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );

  /* const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: jobApplicationUserID: ", jobApplicationUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${jobApplicationUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormCV(resp.data.CV);
      setJobApplicationFormWhatsappNb(resp.data.whatsappnumber);
      let input = resp.data.whatsappnumber;
      let input_filtered = input.replace(/[|&;$%@"<>()+,]/g, "");
      setJobApplicationFormWhatsappNbFiltered(input_filtered);
      //console.log("33333333333333333333: fetchForm: input_filtered", input_filtered);
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
}; */

  const FetchForm1 = async () => {
    try {
      const { data } = await await getUserById(jobApplicationUserID)
      const resp = await getProfileFrUserByID(jobApplicationUserID)

      resp.data.CV && setJobApplicationFormCV(resp.data.CV);
      resp.data && setProfileC(resp.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchJobApplication = async () => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        // console.log("ddddddddddddddddddddddddddddddddddd:");
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
        setCandidat(res.data);
        setJobApplicationUserID(res.data.userID);
        setJobApplicationCV(res.data.CV);
        setJobID(res.data.jobID);

        // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);
        setApplicationPipeline(res.data.pipeline);
        if (res.data.drive) {
          setHasdrive(!hasDrive);
        }
        if (res.data.videos.length) {
          setVideos(res.data.videos);
          // if (!res.data.merged) {
          //   startMerge(res.data);
          // }
          // console.log(res.data.videos);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchProfileAnalysis = async () => {
    // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
    // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);

    instance.get(`/jobApplicationAnalysis/getGeneralValidationByUserID/${jobApplicationUserID}`).then((resp) => {
      setProfileAnalysis(resp.data);
      // console.log("333333333333333333: fetchProfileAnalysis: step 2:");
      // console.log("333333333333333333: fetchProfileAnalysis: step 3 : resp: ", resp);
      // console.log("333333333333333333: fetchProfileAnalysis: step 4 : resp.data: ", resp.data);
    });
  };

  const fetchCandidateCompetenciesAnalysis = async () => {
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", jobApplicationUserID);

    instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${jobApplicationUserID}`).then((resp) => {
      setCandidateCompetenciesAnalysis(resp.data);
      // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
      // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
      // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
  };
  const fetchJobApplicationNotes = async () => {
    // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);

    instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${candidatId}`).then((resp) => {
      if (resp.data.length) {
        setJobApplicationNotes(resp.data);
      }

      // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
      // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
      // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
  };
  const fetchOtherNotesForThisCandidate = async () => {
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 0: ");

    instance.get(`/jobApplicationNotes/getNotesByCandidateID/${jobApplicationUserID}`).then((resp) => {
      if (resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0 && resp.data[0]._id && resp.data[0]._id !== null) {
        console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 1: resp.data.length:", resp.data.length);
        setOtherJobApplicationNotes(resp.data);
      }

      console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 2:");
      console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 3 : resp: ", resp);
      console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 4 : resp.data: ", resp.data);
    });
  };



  const fetchAllJobApplicationsForThisUser = async () => {
    // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);

    instance.get(`/jobApplication/getJobApplicationsByUserID/${jobApplicationUserID}`).then((resp) => {
      if (resp.data.length) {
        setAllJobApplicationsForThisUser(resp.data);
      }

      // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
      // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
      // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
  };


  const fetchJobData = () => {
    if (jobID !== "") {
      instance.get(`jobs/${jobID}`).then((resp) => {
        if (resp.data !== null) {
          if ((resp.data.job !== null)) {
            if ((resp.data.job.title !== null)) {
              setJob(resp.data.job);
              setJobTitle(resp.data.job.title);
              // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
              // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
              // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
              // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
            }
          }
        }
      });

    }


  };

  useEffect(() => {
    fetchJobApplication()
  }, [openAIResponse])


  useEffect(() => {
    fetchJobApplication();
    //fetchForm();
    FetchForm1()
    fetchProfileAnalysis();
    fetchCandidateCompetenciesAnalysis();
    fetchJobApplicationNotes();
    fetchAllJobApplicationsForThisUser();
    fetchJobData();
    fetchOtherNotesForThisCandidate();

  }, [candidatId, jobID, jobApplicationUserID, newAddedNote]);

  const videoUrlHandler = (videoUrl, CurrentQType, currentQ, videoId, openAIResponse, idx) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
    setVideoId(videoId)
    setOpenAIResponse(openAIResponse)
    setCurrentQuestionIndex(idx + 1)

  };
  const QCMQuestionVideoUrlHandler = (videoUrl, CurrentQType, currentQ, QCMQuestionOption1, QCMQuestionOption2, QCMQuestionOption3, QCMQuestionOption4, QCMQuestionOption5, QCMQuestionOption6, qcmSelectedOption, QCMCorrectOption, idx) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
    setCurrentQCMQuestionOption1(QCMQuestionOption1);
    setCurrentQCMQuestionOption2(QCMQuestionOption2);
    setCurrentQCMQuestionOption3(QCMQuestionOption3);
    setCurrentQCMQuestionOption4(QCMQuestionOption4);
    setCurrentQCMQuestionOption5(QCMQuestionOption5);
    setCurrentQCMQuestionOption6(QCMQuestionOption6);
    setCurrentQCMQuestionSelectedOption(qcmSelectedOption);
    setCurrentQCMQuestionCorrectOption(QCMCorrectOption);
    setCurrentQuestionIndex(idx + 1)

  };

  const CodeQuestionVideoUrlHandler = (videoUrl, CurrentQType, currentQ, codeResp, idx) => {
    setVideoUrl(videoUrl);
    setCurrentQuestionType(CurrentQType);
    setCurrentQuestion(currentQ);
    setCodeResponse(codeResp)
    console.log('codeResp', codeResp)
    setCurrentQuestionIndex(idx + 1)

  };


  const refreshCandidat = () => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        setCandidat(res.data);
      });
  };

  const { t, i18n } = useTranslation();

  const GoToOfferParameter = (e) => {
    e.preventDefault();
    window.open(`${config.Data_URL}employer/onejob/${jobID}`, '_blank');
  }

  return (
    <>
      <div
        className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
        style={{ marginTop: "" }}
      >
        <div className="flex items-center  ">
          <CardImg
            variant=""
            className="rounded-circle"
            style={{
              width: "80px",
              height: "80px",
              alignSelf: "center",
              objectFit: "cover",
            }}
            alt="Responsive image"
            src={"assets/img/brand/500.png"}
            onError={(e) => {
              e.target.src =
                // require("assets/img/brand/500.png").default;
                require("assets/img/brand/500.png").default;

            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="pt-2 font-bold    ">
            <span
              className="pt-2 left-0 font-bold "
              style={{ display: "inline-flex" }}
            >
              {t('firstName')}:&nbsp;<h4> {candidat.prenom}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 font-bold   "
              style={{ display: "inline-flex" }}
            >
              {t('lastName')}:&nbsp; <h4>{candidat.nom} </h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Email:&nbsp; <h4>{candidat.email}</h4>
            </span>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            className="pt-2 right-0 font-bold   "
            style={{ display: "inline-flex" }}
          >
            {t('Offer')}:&nbsp; <h4>{jobTitle}</h4>
          </span>
        </div>

        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>

                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                      //role="tablist"
                      //onClick={() => history.push('/admin/edit-profile')}
                      // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        {t('Answer')}s
                      </td>

                      <td
                        className={openTab === 2 ? "tdActive " : "td "}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        to="#link1"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        {t('Management')}
                      </td>

                      {/* <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          {t('Form')}
                        </td> */}

                      <>
                        <td
                          className={
                            (openTab === 3)
                              ? "tdActive "
                              : "td "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-users" aria-hidden="true" />{" "}
                          CV
                        </td>
                      </>
                      <>
                        <td
                          className={
                            (openTab === 4)
                              ? "tdActive "
                              : "td "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(4);
                          }}
                          data-toggle="tab"
                          href="#link4"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-users" aria-hidden="true" />{" "}
                          {t('Profile')}
                        </td>
                      </>
                      <>
                        <td
                          className={
                            (openTab === 5)
                              ? "tdActive "
                              : "td "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(5);
                          }}
                          data-toggle="tab"
                          href="#link5"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-users" aria-hidden="true" />{" "}
                          {t('Analysis')}
                        </td>
                      </>
                      <>
                        <td
                          className={
                            (openTab === 6)
                              ? "tdActive "
                              : "td "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(6);
                          }}
                          data-toggle="tab"
                          href="#link6"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-users" aria-hidden="true" />{" "}
                          {t('Remarks')}
                        </td>
                        <td
                          className={
                            (openTab === 7)
                              ? "tdActive "
                              : "td "
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(7);
                          }}
                          data-toggle="tab"
                          href="#link7"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-users" aria-hidden="true" />{" "}
                          {t('Interview')}s
                        </td>
                      </>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>

              {openTab === 1 &&
                <CardBody
                  className={openTab === 1 ? "block" : "hidden"}
                  id="link1"
                >
                  <div
                    className="text-sm"
                    style={{ display: "inline-flex" }}
                  >
                    {" "}
                    <LightBulbIcon
                      className=""
                      style={{ height: "1.2rem" }}
                    />{" "}
                    &nbsp;{" "}
                    <span>
                      {" "}
                      {t('jobAppDate')} : {jobAppDate?.slice(0, 10)}
                    </span>{" "}
                  </div>
                  <hr className="my-4" />

                  {!videos && <h3>{t('jobAppKEY4')}!</h3>}
                  <div className="flex  space-x-2 py-3 justify-between">
                    <ul style={{ overflowY: 'auto', maxHeight: '500px' }} className="w-1/2 py-3 list-decimal list-inside bg-white  shadow-xl h-1/2 rounded-md">
                      {/* <h3 className="flex items-center justify-center py-3 bg-blue-600 text-white rounded-t-md">
                    
                  <button onClick={downloadEmployeeData}>Download Full interview</button>
                        </h3> */}
                      <h3 className="flex px-3 py-3 bg-white text-black font-bold ">
                        {t('InterviewQuestionsTitle')}
                        <span style={{ marginLeft: "auto" }}>
                        </span>
                      </h3>
                      {videos &&
                        videos.map((video, index) => (
                          <>
                            {((video?.question?.type === "QCM with only one corerct answer") || (video?.question?.type === "QCM with only one correct answer")) && (

                              <li
                                className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                                style={{ color: video.qcmSelectedOption === video?.question?.QCMCorrectOption ? "green" : "red" }}
                                key={video._id}
                                onClick={
                                  QCMQuestionVideoUrlHandler.bind(
                                    this,
                                    video.video,
                                    video.question.type,
                                    video.question.question,
                                    video.question.QCMOption1,
                                    video.question.QCMOption2,
                                    video.question.QCMOption3,
                                    video.question.QCMOption4,
                                    video.question.QCMOption5,
                                    video.question.QCMOption6,
                                    video.qcmSelectedOption,
                                    video.question.QCMCorrectOption,
                                    index
                                  )
                                }
                              >
                                {t('QuestionType')}: {video?.question?.type}
                                <br />
                                {t('States')}: {video?.question?.question}
                                <br />
                                {t('CandidateResponse')}: {video.qcmSelectedOption}
                                <br />
                                {t('CorrectOption')}: {video?.question?.QCMCorrectOption}
                              </li>

                            )
                            }
                            {((!video?.question?.type) || ((video?.question?.type === "Video"))) && (
                              <li
                                className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                                key={video._id}
                                onClick={videoUrlHandler.bind(
                                  this,
                                  video.video,
                                  video.question.type,
                                  video.question.question,
                                  video._id,
                                  video.openAIResponse,
                                  index
                                )}
                              >
                                {video?.question?.question}
                              </li>
                            )
                            }

                            {(video?.question?.type === "Code") && (
                              <li
                                className="border-b question border-gray-600 text-sm py-2 px-2 text-left last:border-b-0 text-black cursor-pointer transition-all duration-100 transform hover:bg-gray-400  hover:border-none"
                                key={video._id}
                                onClick={CodeQuestionVideoUrlHandler.bind(
                                  this,
                                  video.video,
                                  video.question.type,
                                  video.question.question,
                                  video.codeResponse,
                                  index
                                )}
                              >
                                {video?.question?.question}
                              </li>
                            )
                            }
                          </>


                        ))}
                    </ul>
                    <div className="w-1/2 py-3 list-decimal list-inside bg-white shadow-xl h-1/2 rounded-md">
                      {/* console.log("33333333333333333333: ShowCandidat:") */}
                      {/* console.log("33333333333333333333: ShowCandidat: videoUrl: ", videoUrl) */}
                      <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                        {currentQuestionIndex}{currentQuestionIndex && '.'} {currentQuestion}
                      </div>
                      {currentQuestionType && currentQuestionType !== null && ((currentQuestionType === "QCM with only one corerct answer") || (currentQuestionType === "QCM with only one correct answer")) &&
                        <>
                          {currentQCMQuestionOption1 && currentQCMQuestionOption1 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 1: {currentQCMQuestionOption1}
                            </div>
                          }
                          {currentQCMQuestionOption2 && currentQCMQuestionOption2 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 2: {currentQCMQuestionOption2}
                            </div>
                          }
                          {currentQCMQuestionOption3 && currentQCMQuestionOption3 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 3: {currentQCMQuestionOption3}
                            </div>
                          }
                          {currentQCMQuestionOption4 && currentQCMQuestionOption4 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 4: {currentQCMQuestionOption4}
                            </div>
                          }
                          {currentQCMQuestionOption5 && currentQCMQuestionOption5 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 5: {currentQCMQuestionOption5}
                            </div>
                          }
                          {currentQCMQuestionOption6 && currentQCMQuestionOption6 !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              Option 6: {currentQCMQuestionOption6}
                            </div>
                          }
                          {currentQCMQuestionSelectedOption && currentQCMQuestionSelectedOption !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              {t('CandidateResponse')}: {currentQCMQuestionSelectedOption}
                            </div>
                          }
                          {currentQCMQuestionCorrectOption && currentQCMQuestionCorrectOption !== null &&
                            <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                              {t('CorrectOption')}: {currentQCMQuestionCorrectOption}
                            </div>
                          }
                        </>
                      }
                      {
                        (currentQuestionType === 'Code' && codeResponse) &&
                        <div className=" question text-sm py-2 px-2 text-left last:border-b-0 text-black">
                          <Input
                            type="textarea"
                            rows="3"
                            readOnly
                            value={`${t('CandidateResponse')} : ${codeResponse}`}
                          />
                        </div>

                      }
                      {videoUrl && !videoUrl.includes("mp3") && (
                        <>

                          <video
                            src={`${config.Video_URL}${videoUrl}`} type="video/webm"
                            controls
                            className="px-4 py-4"
                            controlsList="nodownload"
                            onError={(e) => {
                              e.target.onerror = null; // prevents infinite loop if both URLs fail
                              e.target.src = `${config.Video_URL_2}${videoUrl}`;
                            }}
                          />
                          {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}

                        </>

                      )}

                      {videoUrl && videoUrl.includes("mp3") && (
                        <>
                          <audio
                            src={`${config.Video_URL}${videoUrl}`}
                            type="audio/mp3"
                            controls
                            controlsList="nodownload"
                          // className="px-4 py-4"
                          />
                          {/* <VideoPlayer
                          src={`${config.Data_URL}${videoUrl}`}
                          controls={true}
                        /> */}
                        </>
                      )}
                      <EvaluateCandidateVideoResponseForRecruiter
                        currentQuestion={currentQuestion}
                        currentQuestionType={currentQuestionType}
                        videoUrl={videoUrl}
                        videoId={videoId}
                        openAIResponse={openAIResponse}
                        setOpenAIResponse={setOpenAIResponse}
                      />
                    </div>
                  </div>
                </CardBody>
              }

              {openTab === 2 &&
                <CardBody
                  className={openTab === 2 ? "block" : "hidden"}
                >
                  <JobAppPipeline
                    jobAppID={candidatId}
                  />

                  <ArchiveJobApp
                    jobAppID={candidatId}
                  />

                  <Row className='mt-5 mb-1'>
                    <h4 className="px-lg-3">{t('OfferParameters')}</h4>
                  </Row>
                  <Row className="justify-content-center">
                    <Button
                      color='primary'
                      onClick={GoToOfferParameter}
                    >
                      {t('OfferParameters')}
                    </Button>
                  </Row>
                </CardBody>}
              {/* openTab === 2 &&
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <JobAppFormForOutFrProfile
                  jobApplicationUserID = {jobApplicationUserID}
                />
              </CardBody>
            */}
              {openTab === 3 &&
                <Container fluid>
                  <CardBody
                    className={openTab === 3 ? "block" : "hidden"}
                    id="link3"
                  >
                    {config.Environment === "Dev" &&
                      <>
                        <Row>
                          <label style={{ marginRight: '.5rem' }}>Précédent</label>
                          <label style={{ marginLeft: '.5rem' }}>Suivant</label>

                        </Row>
                        <Row>
                          <hr className="my-2" />
                        </Row>


                        {/*
                    <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">
                    <Document file={`assets/pdf/1648553251-CV-Mamadou-Fode-Bailo-DIALLO.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  </div>
                    */}



                      </>
                    }
                    {/*config.Environment !== "Dev" && jobApplicationCV && jobApplicationFormCV &&
                      <>
                        <Row>
                          <nav>
                            <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
                            <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          </nav>

                        </Row>
                        <Row>
                          <hr className="my-2" />
                        </Row>
                        <Row>
                          <Document file={`${config.Data_URL}${jobApplicationFormCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                          </Document>
                          {/*
                        <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                        </div>

                      */}

                    {/* </Row>
                      </> }
                    */}
                    {/*config.Environment !== "Dev" && jobApplicationCV && !jobApplicationFormCV &&
                      <>
                        <Row>
                          <nav>
                            <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
                            <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
                            <p>
                              Page {pageNumber} of {numPages}
                            </p>
                          </nav>

                        </Row>
                        <Row>
                          <hr className="my-2" />
                        </Row>
                        <Row>
                          {/*

                      <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                    </div>

                    */}
                    {/*  <Document file={`${config.CVs_URL}${jobApplicationCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                          </Document>

                        </Row>
                      </> 
                  */}

                    <Row>
                      <Col>
                        <CVPdfDisplayer
                          candidatId={candidatId}
                          jobApplicationUserID={jobApplicationUserID}
                          jobApplicationCV={jobApplicationCV}
                          jobApplicationFormCV={jobApplicationFormCV}
                        >

                        </CVPdfDisplayer>
                      </Col>
                    </Row>

                  </CardBody>

                </Container>


              }
              {openTab === 4 &&
                <CardBody
                  className={openTab === 4 ? "block" : "hidden"}
                  id="link4"
                >
                  {/* <ProfileCompetenciesoutFrCandidate
                  candidateUserID = {jobApplicationUserID}
                /> */}
                  <CandidateProfileForRecruiter
                    id={jobApplicationUserID}
                    profileC={profileC}
                    getProfileCandidate={FetchForm1}
                  />

                </CardBody>
              }
              {openTab === 5 &&
                <CardBody
                  className={openTab === 5 ? "block" : "hidden"}
                  id="link5"
                >
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: ") */}
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: user.employerCampanyID", user.employerCampanyID) */}
                  {/* <JobAppGeneralAnalysis
                    candidatId={candidatId}
                  />

                  <JobAppScoreReport
                    candidatId={candidatId}
                  /> */}

                  <JobAppScoreByQuestionCatalogReport candidatId={candidatId} />

                  <JobAppScoreByOralQCOpenAiReportV1 candidatId={candidatId} />

                  <CodeQuestionAnalysisByJobApp candidatId={candidatId} />
                  <CheatingCodeAnalysisByJobApp candidatId={candidatId} />

                  <OpenAiCvCandidateAnalysisReport candidatId={candidatId} />

                  <JobAcceptanceCriteria
                    jobID={jobID}
                    candidatId={candidatId}
                  />

                  <CriteriaAcceptanceIAanalysisByJobApp candidatId={candidatId} />

                </CardBody>
              }
              {openTab === 6 &&
                <CardBody
                  className={openTab === 6 ? "block" : "hidden"}
                  id="link6"
                >

                  <JobAppNotes
                    candidatId={candidatId}
                  />
                  <hr className="my-4" />
                  <UserManagementActions
                    candidateUserID={jobApplicationUserID}
                  />
                </CardBody>
              }
              {openTab === 7 &&
                <CardBody
                  className={openTab === 7 ? "block" : "hidden"}
                  id="link7"
                >
                  {console.log("Analyse")}
                  <form>
                    <h4 className=" mb-4">{t('Interview')}s</h4>
                    <Grid item xs={12}>
                      <label className="px-lg-3">{t('NoteKEY7')}:</label>

                    </Grid>

                    {allJobApplicationsForThisUser &&
                      allJobApplicationsForThisUser.map((oneJobApplication) => (

                        <JobApplicationTitle
                          oneJobApplicationID={oneJobApplication._id}
                          jobID={oneJobApplication.jobID}
                          key={oneJobApplication._id}
                        />

                      ))}


                  </form>


                </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ShowJobAppForOneJob;
