import React, { useCallback, useRef, useState } from "react";
import {
  FilmIcon,
  ChatAltIcon,
  ClockIcon,
  VideoCameraIcon,
  CheckIcon,
  BanIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import ChangingProgressProvider from "components/changingProgressProvider";
import Webcam from "react-webcam";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  Button, 
  Container, 
  Row, 
  Col, 
  GroupAddon,
  FormGroup,
  Input,
  Label,
  CardImg
} from "reactstrap";
import Timer from "components/timer";

import config from "config";
function RecordForQCMQuestionV2({
  data,
  interviewId,
  deviceId,
  setQuestionCompleted,
  questionCompleted,
  callback,
  condd,
  setCondd,
  totalQues,
  index,
  question,
  setIndex,
  takesIND,
  setTakesIND,
  unlThinking,
  setUnlThinking,
  unlTakes,
  setUnlTakes,
  setTakesIndexer,
  takesIndexer,
}) {
  const location = useLocation();
  var beginRecord = false;
  const query = qs.parse(location.search);
  const buttonNameRef = useRef();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [beginRecording, setBeginRecording] = useState(false);
  const [stopRecordingFunctionCalled, setStopRecordingFunctionCalled] = useState(false);
  const [lastSaveFunctionCalled, setLastSaveFunctionCalled] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState({});
  const [finishCapturing, setFinishCapturing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingForStartRecording, setLoadingForStartRecording] = useState(true);
  const [loadingForStopRecording, setLoadingForStopRecording] = useState(true);

  const [thinking, setThinking] = useState(true);

  const [timeOut, setTimeOut] = useState(false);

  const [outOfTakes, setoutOfTakes] = useState(false);
  const [opacity, setOpacity]=useState("0.3")
  const [modalOpen, setModalOpen] = useState(false);
  const [nextQuestionModalOpen, setNextQuestionModalOpen] = useState(false);
  const [modalRetakeOpen, setModalRetakeOpen] = useState(false);

  const [QCMResponse, setQCMResponse] = useState("");
  const [questionQCMDOption1, setQuestionQCMDOption1] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  
  

  // console.log("ddddddddddddddd: RecordForQCMQuestion");
  // console.log("id", query.questionId);

  const handleStartCapture = useCallback(() => {
    // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStartCapture: ");
    beginRecord = true;

    setOpacity("1");
    setCapturing(true);
    setBeginRecording(true);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(data);
      }
    },
    [setRecordedChunks]
  );

  const handleStopCapture = useCallback(() => {
    // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: ");
    // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: beginRecording: ", beginRecording);
    if (!stopRecordingFunctionCalled)
    {
      setStopRecordingFunctionCalled(true);
      // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: handleStopCapture: before mediaRecorderRef.current.stop: ");
      mediaRecorderRef.current.stop();
    }
    

    
    setCapturing(false);
    setFinishCapturing(true);
    setStopRecordingFunctionCalled(true);
    // setIndex(index + 1);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const lastSaveVideo = async () => {
    setLastSaveFunctionCalled(true);
    // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: lastSaveVideo: ");
    // setModalOpen(!modalOpen);
    // setNextQuestionModalOpen(!nextQuestionModalOpen);

      const blob = new Blob([recordedChunks], {
        type: "video/webm",
      });
      const video = new File([blob], `${localStorage.getItem("candidatId")}--${question._id}`, {
      lastModified: new Date(),
      type: blob.type,
    });
    const formData = new FormData();

        formData.append("video", video);
    formData.append("interview", interviewId);
    formData.append("question", query.questionId);
    formData.append("candidat", localStorage.getItem("candidatId"));
    formData.append("qcmSelectedOption", selectedOption);

    fetch(
      `${config.WS_BASE_URL}jobs/startQCMQuestionInterview/${interviewId}/${
        query.questionId
      }/${localStorage.getItem("candidatId")}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => 
      {
        // console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: lastSaveVideo: fetch res", res);
        // setLastSaveFunctionCalled(false);
        // setCondd(false);
        // callback();

      }
      )
      .catch((err) => 
        {
          console.log("TTTTTTTTTTTTTTTT: RecordForQCMQuestionV2: lastSaveVideo: fetch : err: ", err);
          // setLastSaveFunctionCalled(false);
          // setCondd(false);
          // callback();

        }
      );

      {/*
              setTimeout(() => {
      
      
        setLastSaveFunctionCalled(false);
        setCondd(false);
        callback();
      
      }, 5000);

      */}


      setLastSaveFunctionCalled(false);
      setCondd(false);
      callback();





    
  };
  const retakeCallback = () => {
    setCondd(false);
    callback();
    setTakesIND(takesIND - 1);

    // console.log(takesIND);
  };


  useEffect(() => {

    setIndex(index);
    if (question.thinkTime === "Unlimited") {
      setUnlThinking(true);
    } else if (question.thinkTime !== "Unlimited") {
      setUnlThinking(false);
    }
    // console.log("indexxx2222222", takesIND);
    // console.log("indexxxeeeeer", takesIndexer);
    // console.log("out of takes",outOfTakes)
   
    if (takesIND <=1 && takesIND>=0) {
      // console.log("indexxx", takesIND);
      // console.log("outOfTakes", outOfTakes);
      setoutOfTakes(true);
    }
    
    setTimeout(() => {
      setLoading(false);
    }, 12000);
  }, []);




  //console.log("unlThininininini", unlThinking);
  return (
    <>

      <div className="relative d-flex flex-column align-items-center">
        {beginRecording &&
        <>
          {!timeOut &&
            <>
              <Row 
                style={{width:"100%" }}
              >
                <Col xs="1" > 
                </Col>


                <Col xs="5">
                  <Row className="square border form-control-label flex items-center justify-center" style={{width:"100%" }}>
                    <h4 className="	text-black"> Question {index + 1} / {totalQues} - Temps restant: {" "}</h4>
                    {!timeOut && (
                    <h4 className="text-black">
                        {/* <ClockIcon className="h-6 pr-2" /> */}
                        <Timer
                          time={question.timeLimit * 60}
                          value={timeOut}
                          setValue={setTimeOut}
                        />
                      </h4>
                    )}

                  </Row>
                  <Row style={{width:"100%" }}>
                    <h4 
                      className="flex items-center justify-center text-black" 
                      style={{textAlign:"center"}}
                    >
                      Enoncé:
                    </h4>
                    </Row>
                    <Row style={{width:"100%" }}>
                    <Input
                      // type="text"
                      type="textarea"
                      rows="5"
                      className="flex items-center justify-center text-black" 
                      // className="border-[.8px] px-2 border-gray-300 flex-grow py-[1.7px] text-sm h-6"
                      value={question.question}
                      readOnly
                    />
                    {/*
                      <p 
                        className="flex items-center justify-center text-black" 
                        style={{textAlign:"center"}}
                      >
                        {question.question}
                      </p>
                    */}

                  </Row>
                  {question.questionStatementImage &&
                    <Row style={{width:"100%" }}>
                        <CardImg
                          src={`${config.Data_URL}${question.questionStatementImage}`}
                          // className="rounded-circle"
                          onError={(e) => {
                            e.target.src =
                              require("assets/img/brand/500.png").default;
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            // height: "150px",
                            // alignSelf: "center",
                            // objectFit: "cover",
                          }}
                          //alt="Responsive image"
                        />
                    </Row>
                  }

                  


                </Col>

                

                <Col xs="5" style={{ pl: 5, pr: 5 }}>
                  <Row className="square border form-control-label flex items-center justify-center">

                    <h4
                        className="flex items-center justify-center text-black" 
                      >
                        Options
                      </h4>

                  </Row>
                  <Row>
                  <FormGroup>
                  {(question.QCMOption1) &&

                    <Label>
                    <Input
                      type="radio"
                      value={question.QCMOption1}
                      checked={selectedOption === "Option 1"}
                      // checked={selectedOption === question.QCMOption1}
                      // onChange={setSelectedOption(question.QCMOption1)}
                      // checked={setSelectedOption("option 1")}
                      // onChange={this.onValueChange}
                      onChange={(e) => 
                        setSelectedOption("Option 1")
                      }
                    />
                    {question.QCMOption1}
                    </Label>

                  }
                  {(question.QCMOption2) &&
                  <>

                    <br />
                    <Label>
                      <Input
                        type="radio"
                        value={question.QCMOption2}
                        checked={selectedOption === "Option 2"}
                        onChange={(e) => 
                          setSelectedOption("Option 2")
                        }
                        // checked={selectedOption === question.QCMOption2}
                        // onChange={setSelectedOption(question.QCMOption2)}
                        // checked={setSelectedOption("option 2")}
                        // checked={this.state.selectedOption === question.QCMOption2}
                        // onChange={this.onValueChange}
                      />
                      {question.QCMOption2}
                    </Label>

                    </>

                  }

                  {(question.QCMOption3) &&
                  <>
                    <br />
                      <Label>
                        <Input
                          type="radio"
                          value={question.QCMOption3}
                          checked={selectedOption === "Option 3"}
                          onChange={(e) => 
                            setSelectedOption("Option 3")
                          }
                          // checked={selectedOption === question.QCMOption3}
                          // onChange={setSelectedOption(question.QCMOption3)}
                          // checked={setSelectedOption("option 3")}
                          // checked={this.state.selectedOption === question.QCMOption3}
                          // onChange={this.onValueChange}
                        />
                        {question.QCMOption3}
                      </Label>
                  </>
                  }

                  {(question.QCMOption4) &&
                    <>
                      <br />
                        <Label>
                          <Input
                            type="radio"
                            value={question.QCMOption4}
                            checked={selectedOption === "Option 4"}
                            onChange={(e) => 
                              setSelectedOption("Option 4")
                            }
                            // checked={selectedOption === question.QCMOption3}
                            // onChange={setSelectedOption(question.QCMOption3)}
                            // checked={setSelectedOption("option 3")}
                            // checked={this.state.selectedOption === question.QCMOption3}
                            // onChange={this.onValueChange}
                          />
                          {question.QCMOption4}
                        </Label>
                    </>
                  }

                  {(question.QCMOption5) &&
                    <>
                    <br />
                      <Label>
                        <Input
                          type="radio"
                          value={question.QCMOption5}
                          checked={selectedOption === "Option 5"}
                          onChange={(e) => 
                            setSelectedOption("Option 5")
                          }
                          // checked={selectedOption === question.QCMOption3}
                          // onChange={setSelectedOption(question.QCMOption3)}
                          // checked={setSelectedOption("option 3")}
                          // checked={this.state.selectedOption === question.QCMOption3}
                          // onChange={this.onValueChange}
                        />
                        {question.QCMOption5}
                      </Label>
                    </>
                  }

                  {(question.QCMOption6) &&
                    <>
                    <br />
                      <Label>
                        <Input
                          type="radio"
                          value={question.QCMOption6}
                          checked={selectedOption === "Option 6"}
                          onChange={(e) => 
                            setSelectedOption("Option 6")
                          }
                          // checked={selectedOption === question.QCMOption3}
                          // onChange={setSelectedOption(question.QCMOption3)}
                          // checked={setSelectedOption("option 3")}
                          // checked={this.state.selectedOption === question.QCMOption3}
                          // onChange={this.onValueChange}
                        />
                        {question.QCMOption6}
                      </Label>
                    </>
                  }
                  {!stopRecordingFunctionCalled && !timeOut &&
                    <Row className="justify-content-center" style={{width:"100%" }}>
                      <Button
                        className="text-white"
                        style={{backgroundColor:"#11a0ef",whiteSpace: 'normal'}}
                        onClick={() => {
                          handleStopCapture();
                          setModalOpen(!modalOpen);
                          setStopRecordingFunctionCalled(true);
                          setIndex(index + 1);
                        }}
                        // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                        // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                        // style={{ backgroundColor: "#00000075", height: "50px" }}
                        // style={{textAlign:"center"}}
                        // className="flex items-center justify-center"
                      >
                         Sauvegarder votre réponse et passer à la question suivante
                      </Button>
                    </Row>
                  }
                      <br />


                  </FormGroup>


                  </Row>


                </Col>

                <Col xs="1" > 
                </Col>
                  

              </Row>

            </>
          }



          {timeOut && !stopRecordingFunctionCalled &&
            <>
               {setIndex(index + 1)}
               {handleStopCapture()}

                <Row className="justify-content-center" style={{width:"100%" }}>
                <Button
                      className="text-white"
                      style={{backgroundColor:"#11a0ef"}}
                      onClick={() => {
                        //handleStopCapture();
                        //setModalOpen(!modalOpen);
                        setStopRecordingFunctionCalled(true);
                        
                      }}
                      // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                      // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                      // style={{ backgroundColor: "#00000075", height: "50px" }}
                      // style={{textAlign:"center"}}
                      // className="flex items-center justify-center"
                    >
                      <p>Passer à la question suivante</p>
                </Button>
                    
                    
      
                </Row>
              
            </>
          }
          {timeOut && stopRecordingFunctionCalled && !lastSaveFunctionCalled &&
            <>
               {/*
                setTimeout(() => 
                { 
                  lastSaveVideo();
                }, 5000)
              */}
                <Row className="justify-content-center" style={{width:"100%" }}>
                <Button
                      className="text-white"
                      style={{backgroundColor:"#11a0ef"}}
                      onClick={() => {
                        setModalOpen(!modalOpen);
                        setStopRecordingFunctionCalled(true);
                        //setIndex(index + 1);
                      }}
                      // className="	flex items-center justify-center py-3 text-white rounded-t-md"
                      // className="btn position-absolute d-flex top-0 left-0  text-green px-3 py-2"
                      // style={{ backgroundColor: "#00000075", height: "50px" }}
                      // style={{textAlign:"center"}}
                      // className="flex items-center justify-center"
                    >
                      <p>Passer à la question suivante</p>
                </Button>
                    
                    
      
                </Row>
              
            </>
          }

      
            

        </>
        }



        <Row>
          {(loading || lastSaveFunctionCalled) && (
            <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
              <ClipLoader color="#f00" />
            </div>
            )}
        </Row>

        <Row>
              {!loading && !capturing && !finishCapturing && (
              <>
                <Button
                  // onClick={handleStartCapture}
                  onClick={() => {
                    handleStartCapture();
                  }}
                  className="text-white"
                  style={{backgroundColor:"#11a0ef"}}
                >

                  <p>Montrer la question</p>
                </Button>
              </>
              )}
        </Row>
      {!timeOut &&
        <Row>
          <>
          
            <Col xs="3" > 
            </Col>
            <Col xs="6">
                <Webcam style={{opacity:opacity}} videoConstraints={{ deviceId: deviceId }} ref={webcamRef} />
            </Col>
            <Col xs="3" > 
            </Col>


          </>
        </Row>

      }



        <Modal
          toggle={() => setModalRetakeOpen(!modalRetakeOpen)}
          isOpen={modalRetakeOpen}
        
        >
          <div className=" modal-header">
            <h4 className=" modal-title" id="exampleModalLabel">
            Êtes-vous sûr de vouloir reprendre la question ?
            </h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => {
                setModalRetakeOpen(!modalRetakeOpen);
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setModalRetakeOpen(!modalRetakeOpen);
              }}
            >
              Annuler
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                retakeCallback();
              }}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </Modal>  
                
        <Modal
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen && !lastSaveFunctionCalled}
          onClosed={() => {
            // setIndex(index - 1);
          }}
        >
          <div className=" modal-header">
            <h4 className=" modal-title" id="exampleModalLabel">
            Soumettre et passer à la question suivante
            </h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => {
                setModalOpen(!modalOpen);
                
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            >
              Annuler
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                lastSaveVideo();
                setTakesIndexer(-1);
              }}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    </>
  );
}

export default RecordForQCMQuestionV2;
