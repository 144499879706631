import { LightBulbIcon, MailIcon } from "@heroicons/react/solid";
import OutFrUserProfileForRecruters from "components/recruteur/outFrUserProfileForRecruters";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";

const OneAcceptedInterviewAccess = ({ candidate,myAcceptedInvitationRequests }) => {


  const AcceptedInterViewDate = myAcceptedInvitationRequests.find(invitation=> invitation.candidateUserEmail == candidate.email)
  console.log(AcceptedInterViewDate);
  
  const [modalOpen, setModalOpen] = useState(false);

 

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return (
    <Row className="p-1 mb-3 flex w-full hover:black hover:border hover:border-black duration-75 hover:z-20 bg-white shadow-lg rounded-sm">
      <Col lg="2">
        <img
          src={`assets/img/brand/500.png`}
          className="rounded-circle"
          onError={(e) => {
            e.target.src = require("assets/img/brand/500.png").default;
          }}
          style={{
            width: "100%",
            height: "100%",

            //objectFit: "cover",
          }}
          //alt="Responsive image"
        />
      </Col>
      <Col lg="10">
        <Col lg="12" className="mb-2">
          <div>
            <span className="card-t">
              {candidate?.name} {candidate?.lastName}{" "}
            </span>
          </div>
        </Col>
        <Row>
          <Col lg="8">
            <Col lg="12">
              <div className="text-sm" style={{ display: "inline-flex" }}>
                {" "}
                <MailIcon
                  className=""
                  style={{ height: "1.2rem" }}
                /> &nbsp; <span>{candidate?.email}</span>{" "}
              </div>
            </Col>

            <Col lg="12">
              <div className="text-sm" style={{ display: "inline-flex" }}>
                {" "}
                <LightBulbIcon className="" style={{ height: "1.2rem" }} />{" "}
                &nbsp;{" "}
                <span>
                  {" "}
                  Date d'acceptation :{" "}
                  {new Date(AcceptedInterViewDate?.updatedAt).toLocaleDateString(
                    "en-GB",
                    options
                  )}
                </span>{" "}
              </div>
            </Col>
          </Col>

          <Col lg="4" className=" flex items-center">
            <div>
              <Button
                onClick={() => setModalOpen(true)}
                color="primary"
                size="sm"
              >
                Voir le profil
              </Button>
              <Modal
                toggle={() => setModalOpen(!modalOpen)}
                isOpen={modalOpen}
                size="lg"
                style={{ maxWidth: "1100px", width: "80%" }}
              >
                <div className=" modal-header">
                  <button
                    aria-label="Close"
                    className=" close"
                    type="button"
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <ModalBody>
                  <OutFrUserProfileForRecruters
                    candidateUserID={candidate._id}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => setModalOpen(!modalOpen)}
                    size="sm"
                  >
                    Fermer
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OneAcceptedInterviewAccess;
