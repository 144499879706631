import axios from "axios";

import EmployerLayout from "layouts/EmployerLayout";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import OneJobApplicationCardSuperAdmin from "components/superAdmin/oneJobApplicationCardSuperAdmin";
import qs from "query-string";

import config from "config";
import {
  DropdownMenu,
  Media,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
  AcademicCapIcon,
  UserIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import SuperAdminLayout from "layouts/SuperAdminLayout";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});


function ProfileTypesSuperAdminView() {
  const [profileTypes, setProfileTypes] = useState([]);

  const getProfileTypes = () => {
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3");
    // console.log("1111111111111111111111111111 JobApplicationsEmployerV3 jobID", jobID);

    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin getJobApplication");
    // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin getJobApplication job_ID", job_Application_ID);
    instance.get(`profiletypes/getAllProfileTypes`).then((resp) => {
      // console.log("getJobApplication: ", resp);
      // setCreatedAt(resp.data.createdAt);
      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: createdAt", createdAt);
      setProfileTypes(resp.data);
      // console.log("1111111111111111111111111111 resp.data: ", resp.data);
      // console.log("1111111111111111111111111111 jobApplication: ", jobApplication);
      // console.log("1111111111111111111111111111 jobID: ", jobID);

      // console.log("1111111111111111111111111111 JobApplicationsEmployerV3: resp : ", resp);
      // console.log("1111111111111111111111111111 oneJobApplicationCardSuperAdmin: getJobApplication: resp.data : ", resp.data);
      
    });
  };


  





  useEffect(() => {
    getProfileTypes();
  }, );

  return (
    <>
      <SuperAdminLayout />
      {/*
      <JobApplicationsCardsEmployer key="627d34016543d2ab235e8126" jobID="627d34016543d2ab235e8126" />
       */}
        <Container>
        <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
        <Card className="bg-transparent w-full">
          <CardBody>
            {profileTypes
              .map((oneProfileType) => (
                <Row>
                <Col lg="2">
                      <img
                        src={`assets/img/brand/500.png`}
                        className="rounded-circle"
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                        style={{
                          width: "100%",
                          height: "100%",

                          //objectFit: "cover",
                        }}
                        //alt="Responsive image"
                      />
                    </Col>
                    <Col lg="10">
                    <Col lg="12" className="mb-2">
                        <div>
                          <span className="card-t">
                            {oneProfileType.name}{" "}
                          </span>
                        </div>
                      </Col>
                      <Row>
                  <Col lg="8">
                    <Col lg="12">
                    <div
                      className="text-sm"
                      style={{ display: "inline-flex" }}
                    >
                      {" "}
                      <MailIcon
                        className=""
                        style={{ height: "1.2rem" }}
                      />{" "}
                      &nbsp; <span>{oneProfileType.jobID}</span>{" "}
                    </div>
                  </Col>



                </Col>
                <Col lg="4" className=" flex items-center">
                          <div>
                            <Button
                              color="primary"
                              size="sm"

                              // href={`http://localhost:5100/${participant.CV}`}
                              //   target="blank"
                            >
                              Voir les détails
                            </Button>
                          </div>
                        </Col>


                      </Row>
                    </Col>


              </Row>




              ))}
            </CardBody>
          <CardFooter className="bg-transparent w-full">

          </CardFooter>
        </Card>
        </Col>

        </Row>

      </Container>
            
    </>
  );
}

export default ProfileTypesSuperAdminView;