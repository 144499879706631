import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import TerminateYourProfileBefore from "components/outFrCandidatSearchCdiFr/terminateYourProfileBefore";
import OutFrTerminateYourProfileBeforeV2 from "components/outFrCandidatSearchCdiFr/outFrTerminateYourProfileBeforeV2";

import CandidatOffres from "components/candidat/CandidatOffres";
import JobCardsInFranceCandidate from "components/candidat/jobCardsInFranceCandidate";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";

import {
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";

import React from "react";


function OutFrContractHomeView() {


  return (
    <>
      <CandidateLayoutV3 />
      <Container className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <h2>Projet: Je suis hors de la France et je cherche un CDI en France</h2>
          <h3>Votre candidature n'est pas prise en compte tant que vous n'avez pas terminé toutes ces étapes</h3>
          <OutFrTerminateYourProfileBeforeV2 />
      </Container>
      
     
    </>
  );
}

export default OutFrContractHomeView;
