import axios from "axios";

import Header from "components/Headers/Header";
import CandidatHorsFrLayout from "layouts/CandidatHorsFrLayout";
import CandidateLayoutV3 from "layouts/CandidateLayoutV3";
import PageSpace from "components/styles/pageSpace";
import PageTitleStyle from "components/styles/PageTitleStyle";
import OutFrTerminateYourProfileBeforeV2 from "components/outFrCandidatSearchCdiFr/outFrTerminateYourProfileBeforeV2";


import { Container, Row, Col } from "reactstrap";

import { useHistory, useLocation } from "react-router-dom";
import CandidatMyProfileGenPrezVideo from "components/candidat/CandidatMyProfileGenPrezVideo";
import TableRow from "components/TableRow";
import FlexBox from "components/FlexBox";
import OneJob from "components/recruteur/oneJob";
import OffresOFCF from "components/outFrCandidatSearchCdiFr/offresOFCF";

import {useCallback} from 'react';
import React, { useEffect, useState } from "react";
import config from "config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});



function EtrangerCDIenFranceGeneralInterviewsView() {
  var user;
  if (localStorage.getItem("user")) {
  user = JSON.parse(localStorage.getItem("user"));
   } else {
   user = { role: "none" };
   };

   const [profileTypesOptions, setProfileTypesOptions] = useState([]);
  
   const history = useHistory();
   const location = useLocation();

   const [alreadyPosted, setAlreadyPosted] = useState(false);
   const [competenciesAlreadyPosted, setCompetenciesAlreadyPosted] = useState(false);

   const fetchProfile = () => {
  

    instance({
      method: "get",
      url: `/outfrsearchcdifrprofiles/getprofilebyuserid/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

  const fetchCandidateCompetencies = () => {
    instance({
      method: "get",
      url: `/candidatesCompetencies/getCompetenciesByUserID/${user._id}`,
      headers: {'Content-Type': 'application/json' },  
    }).then((resp) => {
      setCompetenciesAlreadyPosted(true);
      // console.log("5555555555555555555555555555 resp.data: ", resp.data);
      });
    };

    const getProfileTypesFunction = () => {
      // console.log("5555555555555555555555555555: getProfileTypesFunction");
      // var pipeline = "Rejeté";
      // const response = getProfileTypes();
      // const { data } = response;
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response: ", response);
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaa: getProfileTypesFunction: response.data: ", response.data);
      
      instance.get(`profiletypes/getAllProfileTypes`).then((resp) => {
        // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp: ", resp);
        // console.log("5555555555555555555555555555: etrangerCDIenFranceGeneralInterviewsView getProfileTypesFunction: resp.data: ", resp.data);
        setProfileTypesOptions(resp.data);
        // let newArray;
        // newArray = resp.data;
  
  
        // profileTypesOptions = newArray.map(item => ({value: item._id, label: item.name}));
  
        // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));
  
        // setProfileTypesOptions(newArray);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: newArray: ", newArray);
  
        // console.log("5555555555555555555555555555: getProfileTypesFunction: resp: ", resp);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypes: ", profileTypes);
        // console.log("5555555555555555555555555555: getProfileTypesFunction: profileTypesOptions: ", profileTypesOptions);
        
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 2:");
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 3 : resp: ", resp);
        // console.log("5555555555555555555555555555: fetchCandidateCompetencies: step 4 : resp.data: ", resp.data);
        });
      // console.log("5555555555555555555555555555: getProfileTypesFunction: success");
      // console.log("5555555555555555555555555555: response.data: ", response.data);
      // setVisibility(response.data.visibility);
      // setProfileTypeName(response.data.name);
      // const orderItems = req.body.orderItems.map((x) => ({ ...x, product: x._id }));
  
  
    };

    useEffect(() => {
  
      fetchProfile();
      fetchCandidateCompetencies();
      getProfileTypesFunction();
    
    }, [location]);

  
  return (
    <>
      <CandidateLayoutV3 />
      <PageSpace />
      <PageTitleStyle
        title="Projet: Je suis hors de la France et je cherche un CDI en France"
      >
        
      </PageTitleStyle>
      
      <Container>
        <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
        <Col xs="2" >
        </Col>
          
        <Col xs="10">
          <h3>Profils recherchés - Opportunités</h3>
        </Col>

        </Row>
        <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
          {(!alreadyPosted || !competenciesAlreadyPosted) && (
            <>
              <Col xs="2" >
              </Col>
              <Col xs="10">
                <OutFrTerminateYourProfileBeforeV2 />
              </Col>
            </>

          )
          }
        </Row>
        <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>
          {alreadyPosted && competenciesAlreadyPosted && (
              <>
                <Col xs="2">
                </Col>
                <Col xs="10">
                  <span> Selon votre profil, choisissez le bon entretien vidéo à passer.</span>
                  <span>C'est un auto-test technique, il n’y a personne qui va vous poser des questions. </span>
                  <span>Vous allez trouver des questions auxquelles vous devez répondre en vidéo, et vos réponses vont être enregistrées au niveau de notre plateforme</span>
                </Col>


              </>

            )
            }
        </Row>
        <Row className="text-center justify-content-center border-0 py-2" style={{width:"100%" }}>

          {alreadyPosted && competenciesAlreadyPosted && (
            <>
              <Col xs="2">
              </Col>
              <Col xs="10">

                {profileTypesOptions.map((profileTypeItem) => (
                  <>
                    {profileTypeItem.showToOutFrCandidates === true && (
                      <OffresOFCF 
                        key={profileTypeItem._id} 
                        jobid_param={profileTypeItem.jobID} 
                      />
                    )

                    }
                  </>
              ))
              }
              </Col>


            </>

          )
          }
        </Row>

      </Container>

    </>
  );
};

export default EtrangerCDIenFranceGeneralInterviewsView;
