import React from 'react'
import { Col, Container,Row } from 'reactstrap';
import IlageForTalentedCandidateLAnding from 'assets/img/brand/FindTalentCandidate.png'
import {useHistory} from 'react-router-dom'
import SearchTalentV1 from 'components/other/SearchTalentV1';

const SearchTalentedCandidateForEmployersV1 = () => {
    const history = useHistory();

    const HandleClick = (search,Location)=>{
        history.push(`/home/talentedCandidatesListsV1?search=${search}&location=${Location}`)
    }


    return (
        <>
            <Container className='mw-75'
            >
                <Row>
                    <Col sm='6'>
                        <div
                            className="landingPageStyle"
                            style={{
                                backgroundColor: "white",
                                //objectFit: "cover",
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '100px 40px'
                            }}
                        >
                            <h1>Trouvez le talent parfait pour propulser vos projets</h1>

                        </div>
                    </Col>
                    <Col sm='6'>
                        <img

                            className="rounded-circle"
                            onError={(e) => {
                                e.target.src =
                                    require("assets/img/brand/FindTalentCandidate.png").default;
                            }}
                            style={{

                                padding: "40px",
                                //borderRadius: "50%"
                                //objectFit: "cover",
                            }}
                            src={IlageForTalentedCandidateLAnding}
                            alt="LPImage"
                        />
                    </Col>
                </Row>

                <SearchTalentV1 onClick={HandleClick} />

            </Container>
        </>
    )
}

export default SearchTalentedCandidateForEmployersV1