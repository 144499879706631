import { getOpenAiOralQCsReportByQC } from 'network/ApiAxios'
import React, { useState } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'

const OneQuestionCatalogJob = ({ item, candidatId, callBack }) => {
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'));

    const HandleClick = async () => {
        setIsLoading(true)
        try {
            const { data } = await getOpenAiOralQCsReportByQC(candidatId, item?._id);
            data && setResult(data?.value)

        } catch (error) {
            console.log(error)
        }
        finally {
            setIsLoading(false);
            await callBack()
        }
    }
    return (
        <Row className='bg-white m-2 p-1'>
            <Col sm='6'>
                <h4>{item?.title}</h4>
            </Col>
            {user && <Col sm='6'>
                <Button
                    disabled={result}
                    onClick={HandleClick}
                    color='primary'
                    size='sm'
                >
                    {isLoading &&
                        <Spinner
                            color="white"
                            type="grow"
                        >
                            Loading...
                        </Spinner>
                    }
                    Lancer l'analyse
                </Button>
            </Col>}
        </Row>
    )
}

export default OneQuestionCatalogJob