import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col
} from "reactstrap";
import {checkPassReset, confirmReset, forgotPassword, login} from "../../network/ApiAxios";
import Toast from "react-bootstrap/Toast";
import {useParams, useLocation} from "react-router-dom";
import qs from "query-string";
import { ClipLoader } from "react-spinners";

function ConfirmPassword ({ match }) {
    const location = useLocation();
    const history = useHistory();
    const query = qs.parse(location.search);
    var token = match.params.token;
    const [loading, setLoading] = useState(true);

    console.log("555555555555555555555555: ConfirmPassword: ");
    console.log("555555555555555555555555: ConfirmPassword: query: ", query);
    console.log("555555555555555555555555: ConfirmPassword: token: ", token);
  

    // const {id} = useParams();
    const [validResetPassword, setValidResetPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [userID, setUserID] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const confirm = async () => {
        if (password !== confirmPassword) {
            setError("Passwords have to match");
            return;
        }
        const response = await confirmReset(userID, password);
        const {data} = response;
        if (data.success) {
            history.push("/auth/reset-success");
        } else {
            setError(data.msg);
        }
    };

    const fetchResetPasswordValidation = async () => {
       
        const response = await checkPassReset(token);
        const {data} = response;
        if (data.success) {
            setLoading(false);
            setValidResetPassword(true);
            setUserID(data.userID);
        } else {
            setLoading(false);
            setError(data.msg);
        }

        
      };

    useEffect(() => {
        fetchResetPasswordValidation();
      
      }, []);

    return (
        <>
            {loading && (
                <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-gray-800 w-full h-full opacity-80">
                <ClipLoader color="#f00" />
                </div>
            )}
            {!loading && validResetPassword && 
                <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Password" type="password" autoComplete="new-password" value={password}
                                            onChange={e => setPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Confirm Password" type="password" autoComplete="new-password" value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {error ?
                                <div className="text-muted font-italic">
                                    <small>
                                        error:{" "}
                                        <span className="text-red font-weight-700">{error}</span>
                                    </small>
                                </div> : null }
                            <div className="text-center">
                                <Button className="my-4" color="primary" type="button" onClick={confirm}>
                                    Reset Password
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                </Col>
            }
        </>
    );
}

export default ConfirmPassword;
