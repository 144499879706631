import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap'

const CardComp = ({header,children, hidden,EditModal}) => {
  return (
         <Container>
          <Card className="shadow">
            <CardHeader style={{border:'none'}}>
              <Row>
                <Col sm='10'>
                <h6 className="heading-small text-muted">
                {header}
              </h6>
                </Col>

                <Col sm='2' hidden={hidden}>
                {EditModal}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {children}
            </CardBody>
          </Card>
        </Container>
  )
}

export default CardComp