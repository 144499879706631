import JobApplicationTitle from "components/employer/jobApplicationTitle";
import ProfileCompetenciesoutFrCandidate from "components/employer/profileCompetenciesoutFrCandidate";
// import JobApplicationNote from "components/employer/jobApplicationNote";
import JobAppGeneralAnalysis from "components/employer/jobAppGeneralAnalysis";
import JobAppScoreReport from "components/employer/jobAppScoreReport";
import JobAppNotesForOutFrCandidates from "components/employer/jobAppNotesForOutFrCandidates";
import JobAppFormForOutFrProfile from "components/employer/jobAppFormForOutFrProfile";
import JobAppPipeline from "components/employer/jobAppPipeline";
import JobAppRejectionReason from "components/employer/jobAppRejectionReason";
import ArchiveJobApp from "components/employer/ArchiveJobApp";
import UserManagementActions from "components/employer/userManagementActions";
import JobAppVideos from "components/employer/jobAppVideos";
import OutFrCandidateOtherJobApps from "components/employer/outFrCandidateOtherJobApps";

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";


import { 
  Container, 
  Button, 
  Col, 
  Card, 
  CardImg, 
  CardHeader, 
  Table, 
  CardBody, 
} from "reactstrap";
import "../custom.css";
import Row from "reactstrap/lib/Row";
import config from "config";

import { Document, Page, pdfjs } from "react-pdf";
import JobAcceptanceCriteria from "./JobAcceptanceCriteria";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
// import 'assets/css/react_pdf_css.css';

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function ShowJobAppForOutFrCandidateEmployerV6({ candidatId }) {
  const history = useHistory();
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4");
  // console.log("11111111111111111111111111111: ShowApplicationEmployerV4: candidatId: ", candidatId);

  




  
  const [candidat, setCandidat] = useState("");
  const [jobApplicationCV, setJobApplicationCV] = useState("");

  const [openTab, setOpenTab] = useState(1);
  const [jobApplicationForm, setJobApplicationForm] = useState("");
  const [jobApplicationFormCV, setJobApplicationFormCV] = useState("");
  const [jobApplicationFormWhatsappNb, setJobApplicationFormWhatsappNb] = useState("");
  const [jobApplicationFormWhatsappNbFiltered, setJobApplicationFormWhatsappNbFiltered] = useState("");

  const [jobApplicationUserID, setJobApplicationUserID] = useState("");
  const [profileAnalysis, setProfileAnalysis] = useState("");
  const [candidateCompetenciesAnalysis, setCandidateCompetenciesAnalysis] = useState("");
  const [jobApplicationNotes, setJobApplicationNotes] = useState(null);
  const [otherJobApplicationNotes, setOtherJobApplicationNotes] = useState([]);
  const [addThisNote, setAddThisNote] = useState("");
  const [newAddedNote, setNewAddedNote] = useState(0);
  const [newAddedNoteText, setNewAddedNoteText] = useState("");
  const [allJobApplicationsForThisUser, setAllJobApplicationsForThisUser] = useState([]);
  const [jobID, setJobID] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [job, setJob] = useState({});

  
  
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

  const fetchForm = async () => {
    // console.log("0000000000000000000000000000:");
    // console.log("1111111111111111111111111111: jobApplicationUserID: ", jobApplicationUserID);
    
    instance.get(`/outFrSearchCdiFrProfiles/getprofilebyuserid/${jobApplicationUserID}`).then((resp) => {
      setJobApplicationForm(resp.data);
      setJobApplicationFormCV(resp.data.CV);
      setJobApplicationFormWhatsappNb(resp.data.whatsappnumber);
      let input = resp.data.whatsappnumber;
      let input_filtered = input.replace(/[|&;$%@"<>()+,]/g, "");
      setJobApplicationFormWhatsappNbFiltered(input_filtered);
      console.log("33333333333333333333: fetchForm: input_filtered", input_filtered);
      // console.log("33333333333333333333: fetchForm: resp: ", resp);
      // console.log("33333333333333333333: fetchForm: resp.data: ", resp.data);
      });
};

const fetchJobApplication = async () => {
  instance
  .get(`jobs/candidat/${candidatId}`)
  .then((res) => {
    // console.log("ddddddddddddddddddddddddddddddddddd:");
    // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee: res: ", res);
    setCandidat(res.data);
    setJobApplicationUserID(res.data.userID);
    setJobApplicationCV(res.data.CV);
    setJobID(res.data.jobID);

    // console.log("fffffffffffffffffffffffffff : res.data: ", res.data);


    
  })
  .catch((err) => {
    console.log(err);
  });
};
const fetchProfileAnalysis = async () => {
  // console.log("333333333333333333: fetchProfileAnalysis: step 0: ");
  // console.log("333333333333333333: fetchProfileAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getGeneralValidationByUserID/${jobApplicationUserID}`).then((resp) => {
    setProfileAnalysis(resp.data);
    // console.log("333333333333333333: fetchProfileAnalysis: step 2:");
    // console.log("333333333333333333: fetchProfileAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchProfileAnalysis: step 4 : resp.data: ", resp.data);
    });
};

const fetchCandidateCompetenciesAnalysis = async () => {
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 0: ");
  // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 1: ", jobApplicationUserID);
  
  instance.get(`/jobApplicationAnalysis/getCompetenciesAnalysisByUserID/${jobApplicationUserID}`).then((resp) => {
    setCandidateCompetenciesAnalysis(resp.data);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 2:");
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchCandidateCompetenciesAnalysis: step 4 : resp.data: ", resp.data);
    });
};
const fetchJobApplicationNotes = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplicationNotes/getNotesByJobApplicationID/${candidatId}`).then((resp) => {
    if (resp.data.length) {
      setJobApplicationNotes(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};
const fetchOtherNotesForThisCandidate = async () => {
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 0: ");
  
  instance.get(`/jobApplicationNotes/getNotesByCandidateID/${jobApplicationUserID}`).then((resp) => {
    if ( resp.data && resp.data.length && resp.data.length !== null && resp.data.length !== 0  && resp.data[0]._id && resp.data[0]._id !== null) {
      console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 1: resp.data.length:", resp.data.length);
      setOtherJobApplicationNotes(resp.data);
    }
    
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 2:");
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 3 : resp: ", resp);
    console.log("333333333333333333: fetchOtherNotesForThisCandidate v6: step 4 : resp.data: ", resp.data);
    });
};



const fetchAllJobApplicationsForThisUser = async () => {
  // console.log("333333333333333333: fetchJobApplicationNotes: step 0: ");
  // console.log("333333333333333333: fetchJobApplicationNotes: step 1: ", candidatId);
  
  instance.get(`/jobApplication/getJobApplicationsByUserID/${jobApplicationUserID}`).then((resp) => {
    if (resp.data.length) {
      setAllJobApplicationsForThisUser(resp.data);
    }
    
    // console.log("333333333333333333: fetchJobApplicationNotes: step 2:");
    // console.log("333333333333333333: fetchJobApplicationNotes: step 3 : resp: ", resp);
    // console.log("333333333333333333: fetchJobApplicationNotes: step 4 : resp.data: ", resp.data);
    });
};


const fetchJobData = () => {
  if (jobID !=="") {
    instance.get(`jobs/${jobID}`).then((resp) => {
      if (resp.data !== null) {
        if ((resp.data.job !== null)) {
          if ((resp.data.job.title !== null)) {
          setJob(resp.data.job);
          setJobTitle(resp.data.job.title);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 2:");
          // console.log("5555555555555555555555555555555555: fetchJobData: step 3 : resp: ", resp);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data: ", resp.data);
          // console.log("5555555555555555555555555555555555: fetchJobData: step 4 : resp.data.job.title: ", resp.data.job.title);
          }
        }
      }
    });

  }


};




   useEffect(() => {
    fetchJobApplication();
    fetchForm();
    fetchProfileAnalysis();
    fetchCandidateCompetenciesAnalysis();
    fetchJobApplicationNotes();
    fetchAllJobApplicationsForThisUser();
    fetchJobData();
    fetchOtherNotesForThisCandidate();
  
  }, [candidatId, jobID, jobApplicationUserID, newAddedNote]);


  const refreshCandidat = () => {
    instance
      .get(`jobs/candidat/${candidatId}`)
      .then((res) => {
        setCandidat(res.data);
      });
  };

  return (
    <>
      <div
        className="flex flex-col px-4 space-y-2 bg-white h-1/3 "
        style={{ marginTop: "" }}
      >
        <div className="flex items-center  ">
          <CardImg
            variant=""
            className="rounded-circle"
            style={{
              width: "80px",
              height: "80px",
              alignSelf: "center",
              objectFit: "cover",
            }}
            alt="Responsive image"
            src={"assets/img/brand/500.png"}
            onError={(e) => {
              e.target.src =
                // require("assets/img/brand/500.png").default;
                require("assets/img/brand/500.png").default;
                
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className="pt-2 font-bold    ">
            <span
              className="pt-2 left-0 font-bold "
              style={{ display: "inline-flex" }}
            >
              Prénom:&nbsp;<h4> {candidat.prenom}</h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Nom:&nbsp; <h4>{candidat.nom} </h4>
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Email:&nbsp; <h4>{candidat.email}</h4>
            </span>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              className="pt-2 right-0 font-bold   "
              style={{ display: "inline-flex" }}
            >
              Offre:&nbsp; <h4>{jobTitle}</h4>
            </span>
        </div>




        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Table className="GatTable">
                  <tbody>
                    <tr>
                      <td
                        className={openTab === 1 ? "tdActive " : "td "}
                        //activeClassName={openTab === 1 ? "active" : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        //data-toggle="tab"
                        to="#link1"
                        //role="tablist"
                        //onClick={() => history.push('/admin/edit-profile')}
                        // size="sm"
                      >
                        <i className="fa fa-book" aria-hidden="true" />{" "}
                        Réponses
                      </td>

                        <td
                          className={openTab === 2 ? "tdActive " : "td "}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle="tab"
                          href="#link2"
                          role="tablist"
                          //onClick={() => history.push('/admin/edit-profile')}
                          size="sm"
                        >
                          <i className="fa fa-question-circle" aria-hidden="true" />{" "}
                          Formulaire
                        </td>

                        <>
                          <td
                            className={
                              (openTab === 3)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(3);}}
                            data-toggle="tab"
                            href="#link3"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            CV
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 4)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(4);}}
                            data-toggle="tab"
                            href="#link4"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Compétences
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 5)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(5);}}
                            data-toggle="tab"
                            href="#link5"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Analyse
                          </td>
                        </>
                        <>
                          <td
                            className={
                              (openTab === 6)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(6);}}
                            data-toggle="tab"
                            href="#link6"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Remarques
                          </td>
                          <td
                            className={
                              (openTab === 7)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(7);}}
                            data-toggle="tab"
                            href="#link7"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Entretiens
                          </td>
                          <td
                            className={
                              (openTab === 8)
                                ? "tdActive "
                                : "td "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(8);}}
                            data-toggle="tab"
                            href="#link8"
                            role="tablist"
                            //onClick={() => history.push('/admin/edit-profile')}
                            size="sm"
                          >
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Gestion
                          </td>
                        </>
                    </tr>
                  </tbody>
                </Table>
              </CardHeader>
              {openTab === 1 &&
                <CardBody
                  className={openTab === 1 ? "block" : "hidden"}
                  id="link1"
                >
                <JobAppVideos
                  jobAppID = {candidatId}
                />
                </CardBody>
              }
              {openTab === 2 &&
              <CardBody
                className={openTab === 2 ? "block" : "hidden"}
                id="link2"
              >
                <JobAppFormForOutFrProfile
                  jobApplicationUserID = {jobApplicationUserID}
                />
              </CardBody>
              }
              {openTab === 3 &&
              <Container fluid>
                <CardBody
                className={openTab === 3 ? "block" : "hidden"}
                id="link3"
              >
                {config.Environment === "Dev" &&
                    <>
                      <Row>
                        <label style={{ marginRight: '.5rem' }}>Précédent</label>
                        <label style={{ marginLeft: '.5rem' }}>Suivant</label>

                      </Row>
                      <Row>
                        <hr className="my-2" />
                      </Row>

                      <Row>
                      <img
                        src={"assets/img/brand/500.png"}
                        onError={(e) => {
                          e.target.src =
                            require("assets/img/brand/500.png").default;
                        }}
                    />
                      </Row>

                  
                    {/*
                    <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">
                    <Document file={`assets/pdf/1648553251-CV-Mamadou-Fode-Bailo-DIALLO.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} />
                  </Document>
                  </div>
                    */}


                  
                  </>
                }
                {config.Environment !== "Dev" && jobApplicationCV && jobApplicationFormCV &&
                  <>
                    <Row>
                      <nav>
                      <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
                      <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    </nav>

                    </Row>
                    <Row>
                        <hr className="my-2" />
                    </Row>
                    <Row>
                      <Document file={`${config.Data_URL}${jobApplicationFormCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                          <Page pageNumber={pageNumber} />
                      </Document>
                      {/*
                        <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                        </div>

                      */}

                    </Row>
                  </>
                }
                 {config.Environment !== "Dev" && jobApplicationCV && !jobApplicationFormCV &&
                  <>
                    <Row>
                      <nav>
                        <Button style={{ marginRight: '.5rem' }} onClick={goToPrevPage}>Précédent</Button>
                        <Button style={{ marginLeft: '.5rem' }} onClick={goToNextPage}>Suivant</Button>
                        <p>
                          Page {pageNumber} of {numPages}
                        </p>
                      </nav>

                    </Row>
                    <Row>
                        <hr className="my-2" />
                    </Row>
                    <Row>
                      {/*

                      <div className="flex justify-center items-center top-0 left-0 z-50 w-full h-full opacity-100">

                    </div>

                    */}
                      <Document file={`${config.CVs_URL}${jobApplicationCV}`} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                      </Document>

                    </Row>     
                  </>
                }

              </CardBody>

              </Container>

              }
             {openTab === 4 &&
              <CardBody
                className={openTab === 4? "block" : "hidden"}
                id="link4"
              >
                <ProfileCompetenciesoutFrCandidate
                  candidateUserID = {jobApplicationUserID}
                />
              </CardBody>
              }
              {openTab === 5 &&
                <CardBody
                  className={openTab === 5? "block" : "hidden"}
                  id="link5"
                >
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: ") */}
                  {/* console.log("77777777777777777: showJobAppForOutFrCandidateEmployerV6: user.employerCampanyID", user.employerCampanyID) */}
                    <JobAppGeneralAnalysis
                      candidatId = {candidatId}
                    />

                  <JobAppScoreReport
                    candidatId = {candidatId}
                  />

                   <JobAcceptanceCriteria
                    jobID ={jobID}
                    candidatId={candidatId}
                  />



                </CardBody>
              }
              {openTab === 6 &&
              <CardBody
                className={openTab === 6? "block" : "hidden"}
                id="link6"
              >

                <JobAppNotesForOutFrCandidates
                    candidatId = {candidatId}
                  />
                <hr className="my-4" />
                <UserManagementActions
                  candidateUserID = {jobApplicationUserID}
                />
              </CardBody>
              }
              {openTab === 7 &&
              <CardBody
                className={openTab === 7? "block" : "hidden"}
                id="link7"
              >
                <OutFrCandidateOtherJobApps
                  jobApplicationUserID = {jobApplicationUserID}
                />

              </CardBody>
              }
              {openTab === 8 &&
              <CardBody
                className={openTab === 8? "block" : "hidden"}
                id="link8"
              >
                <JobAppPipeline
                  jobAppID = {candidatId}
                />
                <JobAppRejectionReason
                  jobAppID = {candidatId}
                />
                {/*
                  <ArchiveJobApp
                  jobAppID = {candidatId}
                />
                */}


              </CardBody>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ShowJobAppForOutFrCandidateEmployerV6;
