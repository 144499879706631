import Header from "components/Headers/Header";
import Offres from "components/recruteur/jobs";
import JobsEmployer from "components/employer/jobsEmployer";
import React from "react";

function Tab2Employer() {
  return (
    <>
      <Header />
      <JobsEmployer />
    </>
  );
}

export default Tab2Employer;
