import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  FormText,
  FormFeedback,
} from "reactstrap";

import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import Header from "components/Headers/Header.js";
import config from "../../config";
import axios from "axios";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

const InvitationLinkForOneCompetition = (props) => {
  const history = useHistory();




  return (
    <>
      <Container fluid className="mt-4">
      <Row>
        <Col lg="2">
        </Col>
        <Col lg="10">
          <h4>Inviter:</h4>
          <p>Pour que les candidats puissent participer, merci de leur envoyer cet URL:</p>
          <p>{config.DOMAIN_NAME}/outFrCandidate/Competitions/oneCompetition/{props.competitionID}</p>

        </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvitationLinkForOneCompetition;