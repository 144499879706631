import axios from "axios";
import ReactPaginate from "react-paginate";
//import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import ViewJob from "components/recruteur/ViewJob";
import { SearchIcon } from "@heroicons/react/outline";
import config from "config";
import "../app.css";
import oneJobCard from "components/recruteur/oneJobCard";
import CardJob from "components/Card";
import Header from "components/Headers/Header";
import HomepageLayout from "layouts/HomepageLayout";
import {
  PencilAltIcon,
  LocationMarkerIcon,
  BriefcaseIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  CodeIcon,
  ArchiveIcon,
  OfficeBuildingIcon,
  PaperClipIcon,
} from "@heroicons/react/solid";
import {
  Button,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormText,
  FormFeedback,
  CardText,
} from "reactstrap";
import OneJob from "components/recruteur/oneJob";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
function JobsHomepage() {
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [searchLaunched, setSearchLaunched] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  
  const history = useHistory();
  const query = qs.parse(location.search);
  // const [totalJobsNumber, setTotalJobsNumber] = useState("");
  var totalJobsNumber;
  var pageNumber = (query.pageNumber) || 1;
  // console.log("pageNumber: ", pageNumber);
  const user = JSON.parse(localStorage.getItem("user"));

    instance.get("jobs/getJobsNumber").then((resp) => {
      // console.log("resp: ", resp);
      // console.log("resp.data.cnt", resp.data.cnt);
      // setTotalJobsNumber(resp.data.cnt);
      totalJobsNumber = resp.data.cnt;
      // console.log("totalJobsNumber: ", totalJobsNumber);
      // console.log("perPage: ", perPage);
      setPageCount(Math.ceil(totalJobsNumber / perPage));
      // console.log("pageCount: ", pageCount);

    });


  const fetchData = () => {
    // console.log("22222222222222: fetchData: ");
    // console.log("22222222222222: fetchData: pageNumber", pageNumber);
    // console.log("22222222222222: fetchData: perPage", perPage);
    // console.log("22222222222222: fetchData: url", `jobs/getJobsPerPage/${pageNumber}/${perPage}`);

    instance.get(`jobs/getJobsPerPage/${pageNumber}/${perPage}`).then((resp) => {
      setJobs(resp.data);
      
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
  };

  const handlePageClickForSearching = (e) => {
    const selectedPage = e.selected;
    // console.log("3333333333333333333333: selectedpage: ", selectedPage);
    // setOffset(selectedPage + selectedPage * 5);
    history.push(`/homepage/jobs/page/?pageNumber=${selectedPage+1}`);
  };

  const launchTheSearchFunction = () => {
    if ( search !== "") {
      setSearchLaunched(true);

      instance.get(`jobs/searchJobsByKeywordsNumber/${search}`).then((resp) => {
        // console.log("resp: ", resp);
        // console.log("resp.data.cnt", resp.data.cnt);
        // setTotalJobsNumber(resp.data.cnt);
        totalJobsNumber = resp.data.cnt;
        // console.log("totalJobsNumber: ", totalJobsNumber);
        // console.log("perPage: ", perPage);
        setPageCount(Math.ceil(totalJobsNumber / perPage));
        // console.log("pageCount: ", pageCount);
  
      });

      instance.get(`jobs/searchJobsByKeywords/${search}/${pageNumber}/${perPage}`).then((resp) => {
        setJobs(resp.data);

        // const data = resp.data;
        // const DATA = [];
        // console.log("666666666666666: launchTheSearchFunction: data: ", data);
        // console.log("data", data);
        {/*
          for (let i = data.length - 1; i >= 0; i--) {
          DATA.push(data[i]);
          }
          // setData(DATA.slice(offset, offset + perPage));
          setData(DATA);
        */}

        // console.log("666666666666666: launchTheSearchFunction: data after slice: ", data);
        
      });
    }
    

  };


  useEffect(() => {
    // fetchTotalJobsNumber(); 
    fetchData();
  }, [pageNumber]);




  return (
    <>
    {jobs && !query.jobId && (
      <>
      <HomepageLayout />
      <Container className="container-fluid ">
      <Row className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></Row>
        <Row>
          <Card className="bg-transparent w-full">
            <CardHeader className="bg-transparent  w-full">
              <Row>

              <Col lg="4">

              </Col>

              <Col lg="4">
              <div
                className="homePageStyle"
                style={{
                    //backgroundColor: "#f7fafc",
                    //objectFit: "cover",
                    justifyContent:'center',
                    alignItems:'center',
                    width: "100%",
                    height: "75%",
                    paddingRight: 0,
                    paddingLeft: 0,
                }}
              >
              <FormGroup>
                  <Input
                    className="form-control-alternative"
                    id="input-Recherche"
                    placeholder="Rechercher"
                    onChange={(e) => 
                      setSearch(e.target.value)
                    }
                    type="text"
                  />
                </FormGroup>
            
           </div>
              </Col>


              <Col lg="4">
                <Button 
                  variant="contained" 
                  color="primary" 
                  type="submit" 
                  // sx={{ mt: "25px" }}
                  onClick={() => { 
                    // console.log("bouton cliqué 0000000000000000000000 ")
      
                    launchTheSearchFunction()
                  }}
                  >
                    Lancez la recherche
                </Button>
              </Col>
              </Row>
            </CardHeader>
            
              {searchLaunched && (
                <>
                <CardBody className="grid grid-cols-1 gap-2 mx-2 my-3">
                  {jobs
                    .map((job) => (
                      <Row className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm" 
                       key={job._id}
                       onClick={() => 
                        user?(
                        user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                             : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                             : (history.push(`/jobs/apply?jobId=${job._id}`))
                      }>
                        <Col lg="2">
                          <img
                            src={`${config.Data_URL}${job.image}`}
                            onError={(e) => {
                              e.target.src =
                                require("assets/img/brand/job_offer_HL_300_225.jpg").default;
                            }}
                            style={{
                              width: "120x",
                              height: "120px",

                              objectFit: "cover",
                            }}
                            alt="Responsive image"
                          />
                        </Col>
                        <Col lg="8">
                          <div className="flex items-center space-x-3 mb-2">
                            <div>
                              <h3 className="card-t">{job.title}</h3>
                              <span
                                className="text-sm"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "2" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {job.description}
                              </span>
                            </div>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span>{job.entreprise}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                        </Col>
                        <Col lg="2" className=" flex items-center">
                          <div>
                            <div className="text-center">
                              {job.createdAt.slice(0, 10)}
                            </div>
                            <Button
                             className="btn-secondary"
                            >
                              Voir Plus
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </CardBody>
                  <CardFooter className="bg-transparent  w-full">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClickForSearching}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </CardFooter>
                </>
              )}
               {!searchLaunched && (
                <>
                <CardBody className="grid grid-cols-1 gap-2 mx-2 my-3">
                  {jobs
                    .map((job) => (
                      <Row className=" px-2 py-3 flex  hover:black hover:border hover:border-black cursor-pointer duration-75 hover:z-20 bg-white shadow-lg rounded-sm"
                        key={job._id}
                        onClick={() => 
                          user?(
                          user.role=="employer"? (history.push(`/admin/tab2?jobId=${job._id}`))
                                               : (history.push(`/candidat/tab2?jobId=${job._id}`))) 
                                               : (history.push(`/jobs/apply?jobId=${job._id}`))
                        }>
                        <Col lg="2">
                          <img
                            src={`${config.Data_URL}${job.image}`}
                            onError={(e) => {
                              e.target.src =
                                require("assets/img/brand/job_offer_HL_300_225.jpg").default;
                            }}
                            style={{
                              width: "120x",
                              height: "120px",

                              objectFit: "cover",
                            }}
                            alt="Responsive image"
                          />
                        </Col>
                        <Col lg="8">
                          <div className="flex items-center space-x-3 mb-2">
                            <div>
                              <h3 className="card-t">{job.title}</h3>
                              <span
                                className="text-sm"
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp:
                                    "2" /* number of lines to show */,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {job.description}
                              </span>
                            </div>
                          </div>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <ArchiveIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.technologies}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <PaperClipIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.contract}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <OfficeBuildingIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.entreprise}</span>{" "}
                              </div>
                            </Col>
                            <Col lg="6">
                              <div style={{ display: "inline-flex" }}>
                                {" "}
                                <LocationMarkerIcon
                                  style={{ height: "1.2rem" }}
                                />{" "}
                                &nbsp; <span> {job.location}</span>{" "}
                              </div>
                            </Col>
                          </Row>
                          {/* <div>
                            Status:{" "}
                            <span
                              className={`${
                                job.status === "inactive"
                                  ? "text-red-600"
                                  : "text-green-600"
                              }`}
                            >
                              {job.status}
                            </span>
                          </div>
                          <div>
                            Updated on:{" "}
                            {`${job.updatedAt.slice(
                              0,
                              10
                            )} ${job.updatedAt.slice(11, 19)}`}
                          </div> */}
                        </Col>
                        <Col lg="2" className=" flex items-center">
                          <div>
                            <div className="text-center">
                              {job.createdAt.slice(0, 10)}
                            </div>
                            <Button
                             type="button"
                             color="primary"
                            >
                              Postuler
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    ))}
                </CardBody>
                <CardFooter className="bg-transparent  w-full">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </CardFooter>
                </>
              )}
            

          </Card>
        </Row>
      </Container>
      </>
    )}
      {query.jobId && <OneJob jobId={query.jobId} />}
    </>
  );
}

export default JobsHomepage;
