import config from 'config';
import { createUniqueSessionForCheckout } from 'network/ApiAxios';
import { CreateSessionForPortailBillingStripe } from 'network/ApiAxios';
import { FetchSubscriptionProduct } from 'network/ApiAxios';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Label, Row } from 'reactstrap'

const OneSubscription = ({ productId, hasActiveSubscription, candidateActiveSubscription, user, t }) => {
    const [subscription, setSubscription] = useState(null);

    const GetSubscriptionProduct = async () => {
        try {
            const { data } = await FetchSubscriptionProduct(productId);
            data && setSubscription(data)
        } catch (error) {
            console.log(error)
        }
    }

    const CreateSession = async () => {
        try {
            if (!hasActiveSubscription && !candidateActiveSubscription.length && !findMatchSub) {
                const { data } = await createUniqueSessionForCheckout({ email: user.email, priceId: subscription?.default_price, success_url : `${config.DOMAIN_NAME}/candidate/trainings/Course`, cancel_url : `${config.DOMAIN_NAME}/candidate/home` })
                console.log(data.url);
                //history.push(data.url)
                window.location.href = data.url;
            }

        } catch (error) {
            console.log(error);
        }
    }

    const CreateSessionForStripePortailBilling = async () => {
        try {
            if (hasActiveSubscription && candidateActiveSubscription.length && findMatchSub) {
                const { data } = await CreateSessionForPortailBillingStripe(user?.email);
                console.log(data.url);
                //history.push(data.url)
                window.location.href = data.url;
            }

        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        GetSubscriptionProduct()
    }, [productId])

    const findMatchSub = candidateActiveSubscription[0]?.items?.data?.find(item => item?.plan?.id === subscription?.default_price)

    const contentBtn = (hasActiveSubscription && candidateActiveSubscription.length && findMatchSub) ? `${t('BtnSubscription2')}` : `${t('BtnSubscription1')}`;


    return (
        <>
            {
                subscription && <Card
                    className="my-2 text-center p-4 shadow mb-5 bg-white rounded"
                    color="primary"
                    outline
                    style={{ width: "22rem" }}
                >
                    <CardBody>
                        <CardTitle tag="h1">
                            {t('Offer')} {subscription.name}
                        </CardTitle>

                        <Row style={{ height: "4rem" }}>
                            <CardText
                                className='w-400 text-break'>
                                {subscription.description}
                            </CardText>

                        </Row>


                        <h2 className='mt-3'>{subscription?.unit_amount / 100}€ /mois</h2>
                        <Button disabled={hasActiveSubscription && candidateActiveSubscription.length && findMatchSub} onClick={CreateSession} id='Premium' className='rounded-pill mt-4' color='primary'>
                            {contentBtn}
                        </Button>
                        <CardText style={{cursor: 'pointer'}} className='mt-1' onClick={CreateSessionForStripePortailBilling} >
                        { (hasActiveSubscription && candidateActiveSubscription.length && findMatchSub) &&
                            <ins>{t('SubscriptionManagement')}</ins>}
                        </CardText>
                    </CardBody>
                </Card>
            }
        </>
    )
}

export default OneSubscription