import React, { useCallback, useEffect, useRef, useState } from "react";
import RecordVideoAndScreen from "components/interview/RecordVideoAndScreen";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import Popup from "reactjs-popup";
import { Button, Modal, ModalBody, ModalFooter, Card,CardBody, Row, Col } from "reactstrap";
import {
  ClockIcon,
  VideoCameraIcon,
  PlusCircleIcon,
  DuplicateIcon,
  FilmIcon,
  ChatAltIcon,
} from "@heroicons/react/solid";
import CameraDetect from "components/CameraDetect";
import { ChatAlt2Icon } from "@heroicons/react/outline";

function InterviewInfoVideoAndScreen({
  question,
  index,
  totalQues,
  setIndex,
  callback,
  data,
  interviewId,
  deviceId,
  questionCompleted,
  setQuestionCompleted,
}) {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);
  const [modalOpen, setModalOpen] = useState(false);

  const [cond, setCond] = useState(false);
  const [condd, setCondd] = useState(false);
  const [takesIND, setTakesIND] = useState(question.takes);
  const [takesIndexer, setTakesIndexer] = useState(0);
  const [unlThinking,setUnlThinking]=useState(false);
  const [unlTakes, setUnlTakes] = useState(false);

  //console.log("-----", unlTakes);

  return (
    <div className="container">
      {!condd ? (
        <>
        <Card
        style={{
          width: "100%",
          top: "",
          //height: "50px",
          //marginBottom: "-3%",
          backgroundColor: "#00000075",
          //alignItems:"center"
        }}
        className="  d-flex  px-3 py-2"
      >
        <CardBody
          style={{ padding: "0.5rem", color: "white"}}
        >
          <Row>
            <Col sm='6'>
            <div
            className="d-flex"
          >
            <ClockIcon className="h-6 pr-2" />
            <h4 className="justify-left text-white" style={{whiteSpace:'normal'}}>
              Time limit: {question.timeLimit} minutes
            </h4>
          </div>
            </Col>
            <Col sm='6'>
            <div
            style={{ display: "inline-flex" }}
            className="position-absolute d-flex top-0 right-0 text-white px-3 py-2"
          >
            <FilmIcon className="h-6 pr-2" />
            {unlTakes &&  <h4 className="justify-left text-white">Remaining Takes: Unlimited</h4>}
            {!unlTakes && takesIND<0 &&  <h4 className="justify-left text-white" style={{whiteSpace:'normal'}}>Remaining Takes: {question.takes}</h4>}
            {!unlTakes && takesIND>=0 &&  <h4 className="justify-left text-white" style={{whiteSpace:'normal'}}>Remaining Takes: {takesIND-1}</h4>}
            {/* <h4 className="justify-left text-white">
             {unlTakes&&}
              {unlTakes
                ? `Remaining Takes: Unlimited`
                : {takesIND<0 && (`Remaining Takes: ${takesIND-1}`)} {`Remaining Takes: ${question.takes}`}}
            </h4> */}
          </div>
            </Col>

          </Row>
        </CardBody>
      </Card>
        <div className="container d-flex flex-column align-items-center">
          <ul className=" list-inside shadow-xl rounded-md mt-3 w-1/3 h-1/2 text-lg">
            <span className="flex items-center justify-center py-3  text-white rounded-t-md" style={{backgroundColor:"#858686"}}>
              Question: {index + 1} of {totalQues}
            </span>
            <li
              className="list-item font-semibold text-base font-sans"
              style={{ display: "block" }}
            >
              <span style={{ display: "inline-flex" }} className="pt-2 font-bold text-black">
                <ChatAltIcon className="h-6 pr-2" />
                Think Time: &nbsp;<h4>{question.thinkTime} (minutes)</h4>
              </span>
            </li>
            <li
              className="list-item font-semibold text-base font-sans"
              style={{ display: "block" }}
            >
              <span style={{ display: "inline-flex" }} className="pt-2 font-bold text-black">
                <ClockIcon className="h-6 pr-2" />
                Time Limit:&nbsp; <h4>{question.timeLimit} (minutes)</h4>
              </span>
            </li>
            <li
              className="list-item font-semibold text-base font-sans"
              style={{ display: "block" }}
            >
              <span style={{ display: "inline-flex" }} className="pt-2 font-bold text-black">
                <FilmIcon className="h-6 pr-2" />
                Allowed Takes:&nbsp; <h4>{question.takes}</h4>
              </span>
            </li>
          </ul>
          
          <Button
            
            type="button"
            className="shadow-xl text-white"
            style={{backgroundColor:"#11a0ef"}}
            onClick={() => setModalOpen(!modalOpen)}
          >
            Voir la question

          </Button>
          <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
            <div className=" modal-header">
              <h4 className=" modal-title" id="exampleModalLabel">
              Prêt pour commencer ?
              </h4>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>

            <ModalFooter>
              <Button
                color="secondary"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                NON
              </Button>
              <Button
                color="primary"
                type="button"
                onClick={() => {
                  setCondd(true);
                  if (question.takes == "Unlimited") {
                    setTakesIND(100);
                    setUnlTakes(true);
                  } else if (question.takes !== "Unlimited") {
                    if (takesIndexer == -1) {
                      setTakesIND(question.takes);
                      setTakesIndexer(0);
                    }
                    console.log("indexxx1111", takesIND);
                    setUnlTakes(false);
                  }
                  
                  setModalOpen(!modalOpen);
                }}
              >
                OUI
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        </>
      ) : (
        <RecordVideoAndScreen
          data={data}
          interviewId={interviewId}
          deviceId={deviceId}
          questionCompleted={questionCompleted}
          setQuestionCompleted={setQuestionCompleted}
          callback={callback}
          condd={condd}
          setCondd={setCondd}
          totalQues={totalQues}
          index={index}
          question={question}
          setIndex={setIndex}
          takesIND={takesIND}
          setTakesIND={setTakesIND}
          unlThinking={unlThinking}
          setUnlThinking={setUnlThinking}
          unlTakes={unlTakes}
          setUnlTakes={setUnlTakes}
          takesIndexer={takesIndexer}
          setTakesIndexer={setTakesIndexer}
        />
      )}
    </div>
  );
}

export default InterviewInfoVideoAndScreen;
