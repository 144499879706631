import { ClockIcon, InboxIcon } from '@heroicons/react/solid'
import React from 'react'
import { Col, Row } from 'reactstrap'

const GetOneInvitationAccessInterview = ({ invitation }) => {
    const status = invitation.candidateAcceptTheRequestion ? "Acceptée" : "Refusée"
    const styleColorclassName = invitation.candidateAcceptTheRequestion ? "text-success" : "text-danger"
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };
    return (
        <Row className="mb-2 p-2 flex  hover:black hover:border hover:border-black duration-75 hover:z-20 bg-white shadow-lg rounded-sm">
            <Col lg="8">
                <div className="flex flex-column  mb-2">

                    <div style={{ display: "inline-flex" }}>
                        {" "}
                        <InboxIcon
                            style={{ height: "1.2rem" }}
                        />{" "}
                        &nbsp;<h3 className="card-t">{invitation?.candidateUserEmail}</h3>

                    </div>
                    <div style={{ display: "inline-flex" }}>
                        {" "}
                        <ClockIcon
                            style={{ height: "1.2rem" }}
                        />{" "}
                        &nbsp; <span> {new Date(invitation.createdAt).toLocaleDateString('en-GB', options)}</span>{" "}
                    </div>
                </div>
            </Col>
            <Col lg='4' className='d-flex justify-content-center align-items-center '>
                <h5 className={('candidateAcceptTheRequestion' in invitation) ? styleColorclassName : "text-info"}>{('candidateAcceptTheRequestion' in invitation) ? status : 'Pas de réponse'}</h5>
            </Col>

        </Row>
    )
}

export default GetOneInvitationAccessInterview