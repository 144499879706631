import CandidateLayoutV2 from "layouts/CandidateLayoutV2";
import CandidatOffres from "components/candidat/CandidatOffres";
import { Box } from "@mui/material";

import React from "react";

function CandidateJobsView() {
  return (
    <>
      <CandidateLayoutV2 />
      <Box 
        sx={
          { 
            ml: 32,
            p: "30px" 
          }
        }>
        <CandidatOffres />
      </Box>
     
    </>
  );
}

export default CandidateJobsView;
