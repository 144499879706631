import { getUserById, EditUserParameters } from 'network/ApiAxios'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, FormGroup, Input, Label, Row, UncontrolledAlert } from 'reactstrap'

const FreelanceProfile = () => {

    const [user, setUser] = useState(null);
    const [style, setStyle] = useState('grey');
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const connectedUser = JSON.parse(localStorage.getItem('user'));

    const getUser = async () => {
        setIsLoading(true)
        try {
            const { data } = await getUserById(connectedUser?._id)
            data && setUser(data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const UpdateFreelanceParameters = async () => {
        try {
            const { _id, Freelance_disponibility, Freelance_daysNbDisponibility, TJM } = user
            const { data } = await EditUserParameters({ Freelance_disponibility: Freelance_disponibility, Freelance_daysNbDisponibility, TJM, userID: _id });
            if (data.success) {
                setMsg('Votre profil de Freelance a été sauvegardé avec succès')
            }

        } catch (error) {
            console.log(error)
        }
    }

    const HandleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: +(e.target.value) })
    }

    useEffect(() => {
        getUser()
    }, [])
    return (
        <Container className='d-flex justify-content-center'>

            {!isLoading ? <Card className="bg-secondary shadow w-75">
                <CardHeader className='d-flex justify-content-between'>

                    <Card style={{ backgroundColor: '#f7f7f9' }}>

                        <CardBody>
                            <Label>Tarif indicatif</Label>
                            {user?.TJM && <p className='h4'><span className='font-weight-bold'>{user?.TJM}</span> €/ jour</p>}

                        </CardBody>
                    </Card>
                    {(user?.Freelance_disponibility) ? <h2><Badge className='p-2' color='success'>disponible</Badge></h2> : <h2><Badge className='p-2' color='warning'>Indisponible</Badge></h2>}</CardHeader>
                <CardBody className="bg-white">
                    <div className="form-group">
                        <Label>Êtes-vous disponible pour recevoir des propositions ?</Label>
                        <Row>
                            <Col sm="6">
                                <Card
                                    className='w-75 cursor-pointer'
                                    style={{ border: `2px solid ${user?.Freelance_disponibility ? 'green' : style}` }}
                                    onClick={(e) => setUser({ ...user, Freelance_disponibility: true })}

                                >
                                    <CardBody>
                                        {' '}
                                        <Label>
                                            oui
                                        </Label>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className='w-75 cursor-pointer'
                                    style={{ border: `2px solid ${!(user?.Freelance_disponibility) ? 'red' : style}` }}
                                    onClick={(e) => setUser({ ...user, Freelance_disponibility: false })}
                                >
                                    <CardBody>
                                        {' '}
                                        <Label >
                                            non
                                        </Label>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {user?.Freelance_disponibility && <>
                        <div className='form-group'>
                            <Label>
                                Combien de jours par semaine êtes-vous disponible ?
                            </Label>
                            <Input
                                name='Freelance_daysNbDisponibility'
                                type='number'
                                defaultValue={user?.Freelance_daysNbDisponibility}
                                onChange={HandleInputChange}
                            />

                        </div>
                        <div className='form-group'>
                            <Label>Changer votre tarif TJM </Label>
                            <Input
                                name='TJM'
                                type='number'
                                defaultValue={user?.TJM}
                                onChange={HandleInputChange}
                            />
                        </div>
                    </>}
                </CardBody>
                {user?._id === connectedUser?._id && <CardFooter className='d-flex flex-column align-items-center'>
                    <Button
                        style={{ backgroundColor: '#f7f7f9' }}
                        onClick={UpdateFreelanceParameters}
                    >
                        Sauvegarder
                    </Button>
                    {msg && <Row className='m-2'>
                        <UncontrolledAlert color="success">
                            {msg}
                        </UncontrolledAlert>
                    </Row>}
                </CardFooter>}
            </Card>
                :
                <div className="absolute flex justify-center items-center top-0 left-0 z-50 bg-secondary w-full h-full opacity-80">
                    <ClipLoader color="black" />
                </div>}
        </Container>
    )
}

export default FreelanceProfile