import React, { useState } from 'react'
import { Button, Progress, Tooltip } from 'reactstrap'
import EditLevelCompetency from './EditLevelCompetency';
import DeleteOneCompetency from './DeleteOneCOmpetencyModal';

const OneCompetencyCandidate = ({ compentency, user, candidateUser, competencyID, getCandidateCompetencies }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggleToolip = () => setTooltipOpen(!tooltipOpen);
    const toggleToolip1 = () => setTooltipOpen1(!tooltipOpen);
    const getValue = () => {
        switch (compentency.level) {
            case 'débutant':
                return 25
            case 'Intermédiaire':
                return 50
            case 'confirmé':
                return 75
            case 'expert':
                return 100

            default:
                return 0
        }
    }
    return (

        <tr>
            <td className='font-weight-bold'>{compentency.name}</td>
            <td>
                <span>
                    <Progress
                       id={`TooltipExample${compentency._id}`}                      
                         style={{
                            height: '10px',
                        }} value={getValue()}></Progress>
                    <Tooltip
                        isOpen={tooltipOpen}
                        target={`TooltipExample${compentency._id}`}
                        toggle={toggleToolip}
                    >
                        {compentency.level}
                    </Tooltip>
                </span>
            </td>
            <td>
                <EditLevelCompetency competencyID={competencyID} compentency={compentency} user={user} candidateUser={candidateUser} getCandidateCompetencies={getCandidateCompetencies} />
            </td>
            <td>
                <DeleteOneCompetency competencyID={competencyID} compentency={compentency} user={user} candidateUser={candidateUser} getCandidateCompetencies={getCandidateCompetencies} />
            </td>
        </tr>

    )
}

export default OneCompetencyCandidate