import QualifyComp from 'components/candidat/SendEmailInvitationForQualifiedCandidate'
import EmployerLayout from 'layouts/EmployerLayout'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Qualify = () => {
  return (
    <>
    <EmployerLayout />
        <Container>
        <Row className='ml-2'>
        <Col xs="2">
        </Col>
        <Col xs="10" className="d-flex flex-column justify-content-center align-items-center">
        <QualifyComp/>
        </Col>
        </Row>
        </Container>
    </>
  )
}

export default Qualify